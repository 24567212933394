import makeStyles from "@material-ui/core/styles/makeStyles";

import bg from "assets/images/login.png";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(" ${bg} ")`,
    minHeight: "100vh",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "0",
  },
  media: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    position: "absolute",
    left: "50%",
    top: -theme.spacing(5),
    transform: "translateX(-50%)",
  },
  card: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(4),
    borderRadius: "6px",
    maxWidth: "482px",
    width: "100%",
    position: "relative",
    overflow: "visible",
    boxShadow: "0 2px 8px rgba(0,0,0,.16)",
  },
  content: {
    margin: "0 auto",
    borderLeftWidth: theme.spacing(1),
    borderLeftColor: theme.palette.secondary.main,
    borderLeftStyle: "solid",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  button: {
    display: "flex",
    justifyContent: "center",
  },
  btn: {
    minWidth: theme.spacing(6),
    borderRadius: theme.spacing(3),
  },
}));

export default useStyles;
