/* eslint-disable camelcase */
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";

import theme from "AppTheme";
import HasPermission from "components/has-permissions/HasPermissions";
import Tooltip from "@material-ui/core/Tooltip";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {},
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalContent: {
    textAlign: "center",
    "& p": {
      marginBottom: 0,
    },
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  modalActions: {
    justifyContent: "center",
  },
});

const LightTooltip = withStyles(() => ({
  tooltip: {
    marginTop: -theme.spacing(0.5),
    maxWidth: theme.spacing(40),
    backgroundColor: fade(theme.palette.text.primary, 0.9),
    color: theme.palette.common.white,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(2),
  },
}))(Tooltip);

const CardTenant = ({ tenant, checkTenant, id, deleteLocalMarket }) => {
  const {
    locale: { title: name },
    environments,
  } = tenant;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenDialogDelete = () => {
    setOpenDialogDelete(!openDialogDelete);
  };
  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-widget": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-error": false,
        "MuiCard-outlined-warning": false,
        "MuiCard-outlined-info": false,
        "MuiCard-outlined-success": false,
        "MuiCard-rounded": true,
        animate: true,
      })}
      data-testid="card_tenant"
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <CardActionArea
            onClick={() => {
              checkTenant({ _id: id, ...tenant });
            }}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs>
                <CardContent>
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item xs={12} sm="auto">
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <Typography variant="body1" component="h5">
                            {name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Grid>
        <Grid item xs="auto">
          <CardActions disableSpacing>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <LightTooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={`${
                    environments.length
                      ? environments.map(e => e.title).join(", ")
                      : ""
                  }`}
                  interactive
                >
                  <Typography variant="body2">{`${environments.length} Environments`}</Typography>
                </LightTooltip>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="add to favorites"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  data-testid="menuTenant_btn"
                >
                  <VfSvgIcon icon="more" viewBox={24} />
                </IconButton>
                <Menu
                  id="simple-menu"
                  elevation={0}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  getContentAnchorEl={null}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <HasPermission permissionKey="edit_tenant" disabled>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        checkTenant({ _id: id, ...tenant });
                      }}
                      data-testid="edit_btn"
                    >
                      EDIT
                    </MenuItem>
                  </HasPermission>
                  <HasPermission permissionKey="delete_tenant" disabled>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleOpenDialogDelete();
                      }}
                      data-testid="delete_btn"
                    >
                      DELETE
                    </MenuItem>
                  </HasPermission>
                </Menu>
              </Grid>
            </Grid>
          </CardActions>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={openDialogDelete}
        onClose={handleClose}
        maxWidth="xs"
      >
        <DialogContent className={classes.modalContent}>
          <DialogContentText gutterBottom>
            Are you sure you want to delete
          </DialogContentText>
          <DialogContentText>
            <strong>{name}</strong>
          </DialogContentText>
          <DialogContentText>tenant permanently?</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button
            color="default"
            variant="outlined"
            onClick={() => handleOpenDialogDelete()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              deleteLocalMarket(id);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default CardTenant;
