/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest, select } from "redux-saga/effects";

import {
  APPLICATIONS_REQUEST,
  APPLICATIONS_REQUEST_SUCCESS,
  APPLICATIONS_REQUEST_FAIL,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_REQUEST_SUCCESS,
  CREATE_APPLICATION_REQUESTT_FAIL,
  SHOW_CREATE_APPLICATION_DIALOG,
  SHOW_APPLICATION,
  VALIDATE_NAME_REQUEST_SUCCESS,
  VALIDATE_NAME_REQUESTT_FAIL,
  VALIDATE_NAME_REQUEST,
  SET_APPLICATION_ENVIRONMENT,
  DEPLOY_APPLICATION_REQUEST,
  UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST,
  UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST_SUCCESS,
  UNDEPLOY_APPLICATION_REQUEST,
  GET_PAGES_REQUEST,
  GET_PAGES_REQUEST_SUCCESS,
  GET_PAGES_REQUEST_FAIL,
  SAVE_PAGE_REQUEST,
  SAVE_PAGE_REQUEST_SUCCESS,
  SAVE_PAGE_REQUEST_FAIL,
  SET_APPLICATION_HOMEPAGE_REQUEST,
  SET_APPLICATION_HOMEPAGE_REQUEST_SUCCESS,
  SET_APPLICATION_HOMEPAGE_REQUEST_FAIL,
  DELETE_PAGE_REQUEST,
  DELETE_PAGE_REQUEST_SUCCESS,
  DELETE_PAGE_REQUEST_FAIL,
  SHOW_SNACKBAR,
  DELETE_UNMANAGED_APP_REQUEST,
  DELETE_UNMANAGED_APP_REQUEST_SUCCESS,
  DELETE_UNMANAGED_APP_REQUEST_FAIL,
  DELETE_APP_REQUEST,
  DELETE_APP_REQUEST_SUCCESS,
  DELETE_APP_REQUEST_FAIL,
  SAVE_APP_VERSION_REQUEST,
  SAVE_APP_VERSION_REQUEST_SUCCESS,
  GET_APP_LATEST_VERSION_REQUEST,
  GET_APP_LATEST_VERSION_REQUEST_SUCCESS,
  GET_APP_LATEST_VERSION_REQUEST_FAIL,
  CLEAR_APP_LATEST_VERSION,
  CLEAR_APPLICATION,
  CLEAR_APPLICATION_ENVIRONMENT,
  RESOLVE_PUBLIC_PACKAGE,
  RESOLVE_PUBLIC_PACKAGE_SUCCESS,
  RESOLVE_PUBLIC_PACKAGE_FAIL,
} from "actions/applicationsActions";
import AppsService from "services/ApplicationsService";
import { CLEAR_NEXUS_WIDGETS_LIST } from "../actions/nexusWidgetsActions";

function* appsWorker({ payload }) {
  try {
    const data = yield call(AppsService.fetchApps, payload);
    yield put({
      type: APPLICATIONS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: APPLICATIONS_REQUEST_FAIL, error });
  }
}

function* appsWatcher() {
  yield takeLatest(APPLICATIONS_REQUEST, appsWorker);
}

function* createAppWorker({ payload }) {
  try {
    const data = yield call(AppsService.postApplicationData, payload);
    if (data.name === "Error") throw data.message;
    yield put({
      type: CREATE_APPLICATION_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: SHOW_CREATE_APPLICATION_DIALOG,
      payload: false,
    });
    yield put({
      type: SET_APPLICATION_ENVIRONMENT,
      payload: "GLOBAL",
    });
    yield put({
      type: SHOW_APPLICATION,
      payload: true,
    });
    yield put({
      type: APPLICATIONS_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
  } catch (error) {
    yield put({ type: CREATE_APPLICATION_REQUESTT_FAIL, error });
    yield put({
      type: SHOW_CREATE_APPLICATION_DIALOG,
      payload: false,
    });
  }
}

function* createAppWatcher() {
  yield takeLatest(CREATE_APPLICATION_REQUEST, createAppWorker);
}

function* validateNameWorker({ payload }) {
  try {
    const { isValid } = yield call(AppsService.validateName, payload);
    yield put({
      type: VALIDATE_NAME_REQUEST_SUCCESS,
      payload: isValid,
    });
  } catch (error) {
    yield put({ type: VALIDATE_NAME_REQUESTT_FAIL, error });
  }
}

function* validateNameWatcher() {
  yield takeLatest(VALIDATE_NAME_REQUEST, validateNameWorker);
}

function* deployAppWorker({ payload }) {
  try {
    const data = yield call(AppsService.deployApplication, payload);
    if (data.isAxiosError) {
      throw new Error(data);
    }
    yield put({
      type: APPLICATIONS_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        show: true,
        message: "Application is deploying",
        severity: 3,
      },
    });
  } catch (error) {
    yield put({ type: APPLICATIONS_REQUEST_FAIL, error: error.message });
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        show: true,
        message: error.message,
        severity: 0,
      },
    });
  }
}

function* deployAppWatcher() {
  yield takeLatest(DEPLOY_APPLICATION_REQUEST, deployAppWorker);
}

function* undeployAppWorker({ payload }) {
  try {
    const data = yield call(AppsService.undeployApplication, payload);
    if (data.isAxiosError) {
      throw new Error(data);
    }
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        show: true,
        message: "Application is undeploying",
        severity: 3,
      },
    });
  } catch (error) {
    console.log("Error :>> ", error.message);
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        show: true,
        message: error.message,
        severity: 0,
      },
    });
  }
}

function* undeployAppWatcher() {
  yield takeLatest(UNDEPLOY_APPLICATION_REQUEST, undeployAppWorker);
}

function* updateDeployedAppSecurityGroupsWorker({ payload }) {
  try {
    const data = yield call(
      AppsService.updateDeployedAppSecurityGroups,
      payload
    );
    if (data.isAxiosError) {
      throw new Error(data);
    }
    yield put({
      type: UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST_SUCCESS,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
        deployment: data,
      },
    });
    yield put({
      type: APPLICATIONS_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
  } catch (error) {
    yield put({ type: APPLICATIONS_REQUEST_FAIL, error: error.message });
  }
}

function* updateDeployedAppSecurityGroupsWatcher() {
  yield takeLatest(
    UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST,
    updateDeployedAppSecurityGroupsWorker
  );
}

function* pagesWorker({ payload }) {
  try {
    const data = yield call(AppsService.fetchPages, payload);
    yield put({
      type: GET_PAGES_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: GET_PAGES_REQUEST_FAIL, error });
  }
}

function* pagesWatcher() {
  yield takeLatest(GET_PAGES_REQUEST, pagesWorker);
}

function* createPageWorker({ payload }) {
  try {
    const data = yield call(AppsService.savePages, payload);
    yield put({
      type: SAVE_PAGE_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        show: true,
        message: "Application was successfully saved.",
        severity: 3,
      },
    });
  } catch (error) {
    yield put({ type: SAVE_PAGE_REQUEST_FAIL, error });
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        show: true,
        message: error.message,
        severity: 1,
      },
    });
  }
}

function* createPageWatcher() {
  yield takeLatest(SAVE_PAGE_REQUEST, createPageWorker);
}

function* createAppVersionWorker({ payload }) {
  try {
    const data = yield call(AppsService.saveAppVersion, payload);
    yield put({
      type: SAVE_APP_VERSION_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: CLEAR_APP_LATEST_VERSION,
    });
    yield put({
      type: APPLICATIONS_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
    yield put({
      type: SHOW_APPLICATION,
      payload: false,
    });
    yield put({
      type: CLEAR_APPLICATION,
    });
    yield put({
      type: CLEAR_APPLICATION_ENVIRONMENT,
    });
    yield put({
      type: CLEAR_NEXUS_WIDGETS_LIST,
    });
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        show: true,
        message: "New version was successfully created.",
        severity: 3,
      },
    });
  } catch (error) {
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        show: true,
        message: error.message,
        severity: 0,
      },
    });
  }
}

function* createAppVersionWatcher() {
  yield takeLatest(SAVE_APP_VERSION_REQUEST, createAppVersionWorker);
}

function* getAppLatestVersionWorker({ payload }) {
  try {
    const data = yield call(AppsService.getAppLatestVersion, payload);
    yield put({
      type: GET_APP_LATEST_VERSION_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: GET_APP_LATEST_VERSION_REQUEST_FAIL, error });
  }
}

function* getAppLatestVersionWatcher() {
  yield takeLatest(GET_APP_LATEST_VERSION_REQUEST, getAppLatestVersionWorker);
}

function* setHomepageWorker({ payload }) {
  try {
    const data = yield call(AppsService.setHomepage, payload);
    yield put({
      type: SET_APPLICATION_HOMEPAGE_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: SET_APPLICATION_HOMEPAGE_REQUEST_FAIL, error });
  }
}

function* setHomepageWatcher() {
  yield takeLatest(SET_APPLICATION_HOMEPAGE_REQUEST, setHomepageWorker);
}

function* deletePageWorker({ payload }) {
  try {
    const data = yield call(AppsService.deletePage, payload);
    yield put({
      type: DELETE_PAGE_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: DELETE_PAGE_REQUEST_FAIL, error });
  }
}

function* deletePageWatcher() {
  yield takeLatest(DELETE_PAGE_REQUEST, deletePageWorker);
}

function* deleteUnmanagedAppWorker({ payload }) {
  try {
    const data = yield call(AppsService.deleteUnmanagedApp, payload);
    yield put({
      type: DELETE_UNMANAGED_APP_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: APPLICATIONS_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
  } catch (error) {
    yield put({ type: DELETE_UNMANAGED_APP_REQUEST_FAIL, error });
  }
}

function* deleteUnmanagedAppWatcher() {
  yield takeLatest(DELETE_UNMANAGED_APP_REQUEST, deleteUnmanagedAppWorker);
}

function* deleteAppWorker({ payload }) {
  try {
    const data = yield call(AppsService.deleteApp, payload);
    yield put({
      type: DELETE_APP_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: APPLICATIONS_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
  } catch (error) {
    const err = {
      status: error.response.status,
      message: error.message,
      data: error.response.data,
      type: "DeleteApp",
      applicationId: payload.applicationId,
    };
    yield put({ type: DELETE_APP_REQUEST_FAIL, error: err });
  }
}

function* deleteAppWatcher() {
  yield takeLatest(DELETE_APP_REQUEST, deleteAppWorker);
}

function* resolvePublicPackageWorker({ payload }) {
  try {
    const auth = yield select(state => state.authentication);
    const data = yield call(
      AppsService.resolvePublicPackage,
      auth.access_token,
      auth.id_token,
      payload
    );
    yield put({
      type: RESOLVE_PUBLIC_PACKAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: RESOLVE_PUBLIC_PACKAGE_FAIL, error });
  }
}

function* resolvePublicPackageWatcher() {
  yield takeLatest(RESOLVE_PUBLIC_PACKAGE, resolvePublicPackageWorker);
}

const appsSaga = [
  appsWatcher(),
  createAppWatcher(),
  validateNameWatcher(),
  deployAppWatcher(),
  undeployAppWatcher(),
  updateDeployedAppSecurityGroupsWatcher(),
  pagesWatcher(),
  createPageWatcher(),
  createAppVersionWatcher(),
  getAppLatestVersionWatcher(),
  setHomepageWatcher(),
  deletePageWatcher(),
  deleteUnmanagedAppWatcher(),
  deleteAppWatcher(),
  resolvePublicPackageWatcher(),
];

export default appsSaga;
