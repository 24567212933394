import { call, put, takeLatest } from "redux-saga/effects";
import SpainIntegrationService from "services/SpainIntegrationService";
import {
  DEPLOY_SPAIN_APPLICATION_REQUEST,
  UNDEPLOY_SPAIN_APPLICATION_REQUEST,
  UNDEPLOY_SPAIN_APPLICATION_REQUEST_SUCCES,
  UNDEPLOY_SPAIN_APPLICATION_REQUEST_FAIL,
} from "actions/spainIntegrationActions";
import { APPLICATIONS_REQUEST, APPLICATIONS_REQUEST_FAIL } from "actions/applicationsActions";

function* deploySpainAppWorker({ payload }) {
  try {
    const data = yield call(SpainIntegrationService.deploySpainApplication, payload);
    if (data.isAxiosError) {
      throw new Error(data);
    }
    yield put({
      type: APPLICATIONS_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
  } catch (error) {
    yield put({ type: APPLICATIONS_REQUEST_FAIL, error: error.message });
  }
}

function* deploySpainAppWatcher() {
  yield takeLatest(DEPLOY_SPAIN_APPLICATION_REQUEST, deploySpainAppWorker);
}

function* undeploySpainAppWorker({ payload }) {
  try {
    const { data, error } = yield call(SpainIntegrationService.undeploySpainApplication, payload);
    if (error) {
      yield put({ type: UNDEPLOY_SPAIN_APPLICATION_REQUEST_FAIL, error });
    } else {
      yield put({
        type: UNDEPLOY_SPAIN_APPLICATION_REQUEST_SUCCES,
        payload: data,
      });
    }
  } catch (error) {
    yield put({ type: UNDEPLOY_SPAIN_APPLICATION_REQUEST_FAIL, error });
  }
}

function* undeploySpainAppWatcher() {
  yield takeLatest(UNDEPLOY_SPAIN_APPLICATION_REQUEST, undeploySpainAppWorker);
}
const spainIntegrationSaga = [deploySpainAppWatcher(), undeploySpainAppWatcher()];

export default spainIntegrationSaga;
