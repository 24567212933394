/* eslint-disable no-useless-computed-key */
import React from "react";
import classNames from "classnames";
import {
  Button,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import startImage from "../../assets/images/page.svg";

const theme = createMuiTheme();

const AppsBuilderStart = ({ handleOpenDialogPage, appBarHeight }) => {
  const useStyles = makeStyles({
    root: {
      padding: theme.spacing(3),
      minHeight: `calc(100vh - ${appBarHeight}px)`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      ["& .MuiCardMedia-root"]: {
        height: theme.spacing(19),
        maxWidth: theme.spacing(38),
        width: "100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        opacity: 0.5,
        [theme.breakpoints.down("xs")]: {
          height: theme.spacing(11),
        },
      },
      ["& .MuiCardContent-root"]: {
        maxWidth: theme.spacing(48),
        textAlign: "center",
      },
      ["& .MuiCardActions-root"]: {
        padding: theme.spacing(2),
      },
    },
  });

  const classes = useStyles();

  return (
    <Card
      square
      className={classNames(classes.root, "MuiCard-grey")}
      elevation={0}
    >
      <CardMedia image={startImage} />
      <CardContent>
        <Typography className="bold" variant="h5" component="h2">
          Add a page
        </Typography>
        <Typography variant="body1" component="p">
          Add the first page and start building your app
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleOpenDialogPage(true)}
        >
          Add page
        </Button>
      </CardActions>
    </Card>
  );
};

export default AppsBuilderStart;
