/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Divider,
  ListItemText,
  MenuItem,
  TextField,
  ClickAwayListener,
  ListItem,
} from "@material-ui/core";
import SwaggerClient from "swagger-client";

import { shallowEqual, useSelector } from "react-redux";
import VfSvgIcon from "../icons/VfSvgIcon";
import ApisService from "../../services/ApisService";

const SearchFieldWrapper = props => {
  const { searchFieldPlaceholder, setQuery } = props;

  return (
    <ClickAwayListener onClickAway={() => null}>
      <ListItem>
        <TextField
          variant="outlined"
          color="primary"
          fullWidth
          placeholder={searchFieldPlaceholder || "Search"}
          onChange={e => {
            setQuery(e.target.value);
          }}
          onKeyDown={e => {
            // Prevent MUI-Autoselect while typing
            e.stopPropagation();
          }}
        />
      </ListItem>
    </ClickAwayListener>
  );
};

// eslint-disable-next-line camelcase
const AppField = ({ apis, selectedEffect, setSelectedEffect }) => {
  // eslint-disable-next-line camelcase
  const { access_token, id_token } = useSelector(
    state => state.authentication,
    shallowEqual
  );
  const [searchText, setSearchText] = useState("");

  return (
    <TextField
      select
      fullWidth
      label="Select Api"
      value={selectedEffect.api}
      onChange={async event => {
        event.preventDefault();
        const a = apis.find(api => api.name === event.target.value);
        const fileData = await ApisService.fetchApiVersionFileData({
          access_token,
          id_token,
          // eslint-disable-next-line no-underscore-dangle
          apiId: a._id,
          version: a.selectedVersion,
        });
        if (fileData) {
          const clientPromise = new SwaggerClient({
            spec: fileData,
          });
          clientPromise.then(
            client => {
              const operations = Object.entries(client.apis).flatMap(
                ([tag, v]) =>
                  Object.keys(client.apis[tag]).map(operation => ({
                    tag,
                    operation,
                  }))
              );
              setSelectedEffect({
                ...selectedEffect,
                fileData,
                api: a.name,
                operations,
                parameters: [],
                arguments: { children: {} },
              });
            },
            reason => console.log(reason)
          );
        }
      }}
      variant="outlined"
      color="primary"
      endIcon={<VfSvgIcon icon="chevronDown" viewBox={24} />}
      SelectProps={{
        renderValue: () => {
          return selectedEffect.api;
        },
        MenuProps: {
          onEnter: () => {
            setSearchText("");
          },
          onExit: () => {
            setSearchText("");
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      }}
    >
      <SearchFieldWrapper setQuery={setSearchText} />
      <Divider />
      {apis
        .filter(a => {
          if (searchText)
            return (
              a.name.toLowerCase().includes(searchText.toLowerCase()) ||
              a.tags
                .join()
                .toLowerCase()
                .includes(searchText.toLowerCase())
            );
          return a;
        })
        .map(a => {
          return (
            <MenuItem
              key={a.name}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(a.name === selectedEffect.api ? { selected: true } : {})}
              value={a.name}
            >
              <ListItemText primary={a.name} secondary={a.tags.join(" ")} />
            </MenuItem>
          );
        })}
    </TextField>
  );
};

export default AppField;
