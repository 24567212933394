/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from "react";
import { is } from "bpmn-js/lib/util/ModelUtil";
import { Grid, TextField, Box } from "@material-ui/core";
import { shallowEqual, useSelector } from "react-redux";

import ApisService from "services/ApisService";
import SwaggerClient from "swagger-client";

import Ace from "./ace";

import {
  destructure,
  structureEffect,
  findOperationById,
  codeOrExpressionValidation,
  findSelectedWidget,
  findChildSchema,
  constructConfigActionSchema,
} from "./ElementsUtils";

import StartEventElement from "./StartEventElement";
import EndEventElement from "./EndEventElement";
import ServiceTaskRightPart from "./ServiceTaskRightPart";
import ServiceTaskLeftPart from "./ServiceTaskLeftPart";
import EndEventPayload from "./EndEventPayload";
import { EventSchema, globalReduxSchema, CallApiSchema } from "./schema";

const ElementProperties = ({
  currentElement,
  modeler,
  modalTab,
  setMessage,
  setOpenProsDialog,
}) => {
  const { access_token, id_token } = useSelector(
    state => state.authentication,
    shallowEqual
  );
  const apis = useSelector(
    state => state.applications.current.appInfo.apis,
    shallowEqual
  );
  const appWidgets = useSelector(
    state => state.applications.current.appInfo.widgets,
    shallowEqual
  );
  const categoryWidgets = useSelector(
    state => state.nexusWidgets.data,
    shallowEqual
  );

  const [element, setElementState] = useState(null);
  const [selectedEffect, setSelectedEffect] = useState({
    effect: "",
    api: "",
    operation: "",
    arguments: { children: {} },
    callee: "",
    selectExpression: "",
    pickExpression: "",
    callExpression: "",
    putExpression: "",
    type: "",
    payload: "",
    fileData: {},
    operations: [],
    parameters: [],
  });

  function updateValue(value, customAttr) {
    const modeling = modeler.get("modeling");
    if (
      is(currentElement.element, "bpmn:StartEvent") &&
      !!currentElement.element?.businessObject.get("eventDefinitions")?.length
    ) {
      const schema = {
        ...Object.values(
          element?.actions[element.selectedWidget].find(a =>
            value.includes(Object.keys(a)[0])
          )
        )[0],
      };
      if (schema.type !== "object") {
        modeling.updateProperties(currentElement.element, {
          contextSchema: JSON.stringify(
            (EventSchema.properties.payload = { ...schema })
          ),
        });
      } else
        modeling.updateProperties(currentElement.element, {
          contextSchema: JSON.stringify(
            (EventSchema.properties.payload.properties = { ...schema })
          ),
        });
    } else if (
      (is(currentElement.element, "bpmn:ServiceTask") &&
        selectedEffect.effect === "select") ||
      selectedEffect.effect === "select-js"
    ) {
      const id = (
        selectedEffect.selectExpression || selectedEffect.pickExpression
      )
        .split(".")
        .slice(1);
      const child = findChildSchema(globalReduxSchema, id);
      let schema = {};
      if (child) {
        schema = {
          title: currentElement.element?.businessObject.get("name"),
          type: "object",
          properties: child,
        };
      }
      modeling.updateProperties(currentElement.element, {
        contextSchema: JSON.stringify(schema),
      });
    } else if (
      is(currentElement.element, "bpmn:ServiceTask") &&
      selectedEffect.effect === "call-api"
    ) {
      const oper = findOperationById(
        selectedEffect.fileData,
        selectedEffect.operation
      );
      CallApiSchema.properties.body = {
        ...CallApiSchema.properties.body,
        ...oper?.responses["200"]?.schema,
      };
      modeling.updateProperties(currentElement.element, {
        contextSchema: JSON.stringify(CallApiSchema),
      });
    }
    modeling.updateProperties(currentElement.element, {
      [customAttr]: value,
    });
  }

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === "type") {
      setSelectedEffect({
        ...selectedEffect,
        [name]: value,
      });
    } else
      setElementState({
        ...element,
        [name]: value,
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    let isValid = { outcome: true, reason: null };
    const customType = is(currentElement.element, "custom:ActionTypeHolder")
      ? "custom:actionType"
      : is(currentElement.element, "custom:ScriptHolder")
      ? "custom:code"
      : is(currentElement.element, "custom:EffectHolder") ||
        is(currentElement.element, "custom:EffectPutHolder")
      ? "custom:effect"
      : is(currentElement.element, "custom:ConditionHolder")
      ? "custom:condition"
      : "";
    if (customType === "custom:effect") {
      if (selectedEffect.effect === "put-js") {
        isValid = codeOrExpressionValidation(
          selectedEffect.putExpression,
          currentElement.element
        );
      } else if (selectedEffect.effect === "put") {
        isValid = {
          outcome: true,
          reason: null,
        };
      } else if (selectedEffect.effect === "call-js") {
        isValid = codeOrExpressionValidation(
          selectedEffect.callExpression,
          currentElement.element
        );
      } else if (selectedEffect.effect === "select-js") {
        isValid = codeOrExpressionValidation(
          selectedEffect.selectExpression,
          currentElement.element
        );
      } else if (selectedEffect.effect === "call-api") {
        // const allParamsHasValue = selectedEffect.parameters.every(
        //   ({ name }) =>
        //     selectedEffect.arguments.children[name]?.values?.length > 0
        // );
        const allParamsHasValue = true;
        isValid = {
          outcome: allParamsHasValue,
          reason: allParamsHasValue
            ? null
            : `Missing parameters for the ${selectedEffect.operation} operation.`,
        };
      }
    } else if (customType !== "custom:actionType") {
      isValid = codeOrExpressionValidation(
        element[customType.substring(7)],
        currentElement.element
      );
    }
    try {
      if (customType && isValid.outcome) {
        updateValue(
          is(currentElement.element, "bpmn:ServiceTask") ||
            (is(currentElement.element, "bpmn:EndEvent") &&
              !!currentElement.element?.businessObject.get("eventDefinitions")
                ?.length)
            ? structureEffect(selectedEffect)
            : element[customType.substring(7)],
          customType
        );
        setOpenProsDialog(false);
      } else {
        setMessage(isValid.reason);
      }
    } catch (e) {
      setMessage(e.message);
    }
  };

  useEffect(() => {
    if (!currentElement.element) return;
    let fatWidgetActions = {};
    const fatWidgets = categoryWidgets
      .filter(
        ({ _id }) =>
          _id !== "Protons" && _id !== "Atoms" && _id !== "Custom Widgets"
      )
      .flatMap(({ widgets }) =>
        widgets.filter(({ _id }) => appWidgets.some(w => w._id === _id))
      )
      .filter(({ appFramework }) => appFramework.actions);
    fatWidgets.push({
      name: "Global",
      description: "Global",
      appFramework: {
        actions: {
          APPLICATION_MOUNTED: {
            type: "object",
            title: "APPLICATION_MOUNTED",
            properties: {},
          },
          LOGGED_IN: {
            type: "object",
            title: "LOGGED_IN",
            properties: {
              access_token: {
                type: "string",
              },
              id_token: {
                type: "string",
              },
              name: {
                type: "string",
              },
              ctry: {
                type: "string",
              },
            },
          },
          PAGE_MOUNTED: {
            type: "object",
            title: "PAGE_MOUNTED",
            properties: {
              pageTitle: {
                type: "string",
              },
            },
          },
          PAGE_UNMOUNTED: {
            type: "object",
            title: "PAGE_UNMOUNTED",
            properties: {
              pageTitle: {
                type: "string",
              },
            },
          },
          CONFIG_REQUEST_SUCCESS: {
            title: "config",
            type: "object",
            properties: {
              data: {
                title: "data",
                type: "object",
                properties: {
                  global:
                    globalReduxSchema.properties.config.properties.data
                      .properties.global,
                  widgets: {
                    title: "widgets",
                    type: "object",
                    properties: constructConfigActionSchema(
                      fatWidgets,
                      appWidgets
                    ),
                  }, // todo
                },
              },
            },
          },
          LANGUAGE_LIST_REQUEST_SUCCESS: {
            type: "object",
            title: "LANGUAGE_LIST_REQUEST_SUCCESS",
            properties: {
              languages: {
                type: "object", // dynamic keys, cannot be described by a schema
              },
            },
          },
          SET_PROPS: {
            type: "object",
            title: "SET_PROPS Set props",
            properties: {
              comp: {
                type: "string",
              },
              props: {
                type: "object", // open-ended
              },
            },
          },
          SAVE_VAL: {
            type: "object",
            title: "SAVE_VAL Save value",
            properties: {
              name: {
                type: "string",
              },
              boolean: {
                type: "boolean",
              },
              number: {
                type: "number",
              },
              string: {
                type: "string",
              },
            },
          },
          GOTO: {
            type: "object",
            title: "GOTO Navigation",
            properties: {
              path: {
                type: "string",
              },
            },
          },
        },
      },
    });
    fatWidgets.forEach(({ appFramework, name }) => {
      fatWidgetActions = {
        ...fatWidgetActions,
        [name]: Object.entries(appFramework.actions).map(e => ({
          [e[0]]: e[1],
        })),
      };
    });
    setElementState({
      actionType:
        currentElement.element.businessObject.get("custom:actionType") || "",
      effect: currentElement.element.businessObject.get("custom:effect") || "",
      code: currentElement.element.businessObject.get("custom:code") || "",
      condition:
        currentElement.element.businessObject.get("custom:condition") || "",
      actions: fatWidgetActions,
      widgetList: fatWidgets,
      selectedWidget: findSelectedWidget(currentElement.element),
    });
  }, [currentElement.element, categoryWidgets, appWidgets]);

  useEffect(() => {
    if (element?.effect) {
      const params = destructure(element.effect);
      if (params && params.api) {
        const a = apis.find(api => api.name === params.api);
        ApisService.fetchApiVersionFileData({
          access_token,
          id_token,
          apiId: a._id,
          version: a.selectedVersion,
        }).then(fileData => {
          const { parameters } = findOperationById(fileData, params.operation);
          const clientPromise = new SwaggerClient({
            spec: fileData,
          });
          clientPromise.then(
            client => {
              const operations = Object.entries(client.apis).flatMap(
                // eslint-disable-next-line no-unused-vars
                ([tag, v]) =>
                  Object.keys(client.apis[tag]).map(operation => ({
                    tag,
                    operation,
                  }))
              );
              setSelectedEffect({
                ...selectedEffect,
                ...params,
                effect: "call-api",
                fileData,
                operations,
                parameters,
              });
            },
            reason => console(reason)
          );
        });
      } else if (params?.effect === "put") {
        if (params.putExpression) {
          setSelectedEffect({
            ...selectedEffect,
            ...params,
            effect: "put-js",
          });
        } else {
          const { arguments: args, instanceId, type, effect } = params;
          setSelectedEffect({
            ...selectedEffect,
            type,
            effect,
            arguments: {
              children: {
                payload: {
                  ...args,
                },
                instanceId: {
                  ...(instanceId || {}),
                },
              },
            },
          });
        }
      } else {
        setSelectedEffect({
          ...selectedEffect,
          ...params,
          effect: params.pickExpression
            ? "select"
            : params?.selectExpression
            ? "select-js"
            : "call-js",
        });
      }
    }
  }, [element]);

  const renderForm = () => {
    if (is(currentElement.element, "bpmn:ScriptTask")) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Ace
              value={element}
              aid="master-editor"
              prop="code"
              setValue={setElementState}
            />
          </Grid>
        </Grid>
      );
    }
    if (
      is(currentElement.element, "bpmn:StartEvent") &&
      !!currentElement.element?.businessObject.get("eventDefinitions")?.length
    ) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box p={3}>
              <Grid container spacing={3}>
                <StartEventElement
                  handleInputChange={handleInputChange}
                  element={element}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      );
    }
    if (
      is(currentElement.element, "bpmn:EndEvent") &&
      !!currentElement.element?.businessObject.get("eventDefinitions")?.length
    ) {
      return (
        <Grid container>
          <Grid item xs={3}>
            <Box p={3}>
              <Grid container spacing={3}>
                <EndEventElement
                  handleInputChange={handleInputChange}
                  element={element}
                  selectedEffect={selectedEffect}
                  setSelectedEffect={setSelectedEffect}
                />
              </Grid>
            </Box>
          </Grid>
          {selectedEffect.effect === "put-js" && (
            <Grid item xs={9}>
              <Ace
                isForExpression
                prop="putExpression"
                value={selectedEffect}
                aid="master-editor"
                setValue={setSelectedEffect}
              />
            </Grid>
          )}
          {selectedEffect.type && selectedEffect.effect === "put" && (
            <Grid item xs={9}>
              <Box p={3}>
                <EndEventPayload
                  modeler={modeler}
                  element={element}
                  selectedEffect={selectedEffect}
                  setSelectedEffect={setSelectedEffect}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      );
    }
    if (is(currentElement.element, "bpmn:Gateway")) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Condition"
                    name="condition"
                    type="text"
                    value={element.condition || ""}
                    onChange={handleInputChange}
                    variant="outlined"
                    color="primary"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      );
    }
    if (is(currentElement.element, "bpmn:ServiceTask")) {
      return (
        <Grid container>
          <Grid item xs={3}>
            <Box p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ServiceTaskLeftPart
                    modalTab={modalTab}
                    selectedEffect={selectedEffect}
                    setSelectedEffect={setSelectedEffect}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <ServiceTaskRightPart
              modeler={modeler}
              element={element}
              selectedEffect={selectedEffect}
              setSelectedEffect={setSelectedEffect}
            />
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  return (
    <Grid container direction="row">
      {currentElement?.selectedElements?.length === 1 && element && (
        <Grid item xs={12}>
          <form id="bpmnPropertiesForm" onSubmit={handleSubmit}>
            {element && renderForm()}
          </form>
        </Grid>
      )}

      {currentElement?.selectedElements?.length === 0 && (
        <Grid item xs={12}>
          <span>Please select an element.</span>
        </Grid>
      )}

      {currentElement?.selectedElements?.length > 1 && (
        <Grid item xs={12}>
          <span>Please select a single element.</span>
        </Grid>
      )}
    </Grid>
  );
};
export default ElementProperties;
