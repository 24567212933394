/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-boolean-value */
import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
}));

const ObjectFieldTemplate = ({
  DescriptionField,
  description,
  TitleField,
  title,
  ColorField,
  bgColor,
  properties,
  required,
  uiSchema,
  idSchema,
}) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  return (
    <>
      {(uiSchema["ui:title"] || title) && (
        <TitleField
          id={`${idSchema.$id}-title`}
          title={title}
          required={required}
        />
      )}
      {description && (
        <DescriptionField
          id={`${idSchema.$id}-description`}
          description={description}
        />
      )}
      {bgColor && (
        <ColorField
          id={`${idSchema.$id}-bgColor`}
          bgColor={bgColor}
        />
      )}
      <Grid container={true} spacing={4}>
        {properties.map((element, index) => (
          <Grid item={true} xs={12} key={index}>
            {element.content}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ObjectFieldTemplate;
