import React, { useState } from "react";
import image from "../../assets/images/placeholder.jpg";

const CardWidgetImage = ({ widget }) => {
  const getWidgetImage = () => {
    const defaultImage = widget.images
      ? widget.images.find(img => img.isDefault)
      : null;

    return defaultImage && defaultImage.url ? defaultImage.url : image;
  };
  const [widgetImage] = useState(getWidgetImage);

  return (
    <img
      src={widgetImage}
      title={widget.title ? widget.title : widget.description}
      style={{ maxWidth: "100%", height: "auto" }}
      alt={widget.title ? widget.title : widget.description}
    />
  );
};

export default CardWidgetImage;
