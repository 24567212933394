import React, { useState, useMemo } from "react";
import {
  Typography, Grid, Button, TextField,
  FormControl, FormControlLabel, Switch
} from "@material-ui/core";

function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

const AppDialogConfigOptions = ({
  onNext,
  externalConfig,
  setExternalConfig,
  externalConfigUrl,
  setExternalConfigUrl
}) => {
  const [error, setError] = useState(false);
  const handleNext = () => {
    if (externalConfig && !isValidURL(externalConfigUrl)) {
      setError(true);
    } else {
      setError(false);
      onNext();
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="center" justify="space-between">
          <Grid item xs="auto">
            <Typography variant="body1">
              Fetch Configuration {externalConfig ? "Externally" : "Internally"}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <FormControlLabel
              control={
                <Switch
                  name="externalConfig"
                  color="primary"
                  checked={externalConfig}
                  onChange={e => setExternalConfig(e.target.checked)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="External Configuration"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            type="text"
            disabled={!externalConfig}
            name="externalConfigUrl"
            variant="outlined"
            label="External Configuration URL"
            onChange={e => setExternalConfigUrl(e.target.value)}
            value={externalConfigUrl}
            {...(error ? {
              error: true,
              helperText: "Please enter a valid URL",
            } : {})}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNext}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppDialogConfigOptions;
