/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import VfSvgIcon from "../icons/VfSvgIcon";
import ApiParameter from "./ApiParameter";

const useStyles = makeStyles(theme => ({
  root: {
    ["& .MuiExpansionPanelSummary-root"]: {
      ["& .MuiExpansionPanelSummary-content"]: {
        ["&.Mui-expanded"]: {
          marginTop: theme.spacing(1.5),
          marginBottom: theme.spacing(1.5),
          color: theme.palette.secondary.main,
        },
      },
      ["& .MuiExpansionPanelSummary-expandIcon"]: {
        transform: "rotate(270deg)",
        ["&.Mui-expanded"]: {
          transform: "rotate(0deg)",
          ["& .MuiSvgIcon-root"]: {
            fill: theme.palette.secondary.main,
          },
        },
      },
    },
  },
}));

const ApiParameterWithExpansion = ({
  param,
  setSelectedEffect,
  selectedEffect,
  element,
  modeler,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <ExpansionPanel
      className={classes.root}
      square
      elevation={0}
      expanded={expanded === param.name}
      onChange={handleChange(param.name || "default")}
    >
      <ExpansionPanelSummary
        IconButtonProps={{ edge: false }}
        expandIcon={<VfSvgIcon icon="chevronDown" viewBox={24} />}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <Typography variant="body1">{param.name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <ApiParameter
          param={param}
          setSelectedEffect={setSelectedEffect}
          selectedEffect={selectedEffect}
          element={element}
          modeler={modeler}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ApiParameterWithExpansion;
