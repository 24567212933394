import React, { useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const AuthMessage = ({ liveness, message }) => {
  const severity = ["error", "warning", "info", "success"];
  useEffect(() => {
    if (liveness) {
      liveness.update();
    }
  });
  const useStyles = makeStyles(theme => ({
    root: {
      padding: 0,
      borderRadius: "5px",
      overflow: "hidden",
      marginTop: theme.spacing(2),
      "&.MuiAlert-outlinedError": {
        color: theme.palette.text.primary,
        borderColor: theme.palette.error.main,
        backgroundColor: theme.palette.common.white,
        "& .MuiAlert-icon": {
          backgroundColor: theme.palette.error.main,
        },
      },
      "&.MuiAlert-outlinedSuccess": {
        color: theme.palette.text.primary,
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.common.white,
        "& .MuiAlert-icon": {
          backgroundColor: theme.palette.success.main,
        },
      },
      "&.MuiAlert-outlinedWarning": {
        color: theme.palette.text.primary,
        borderColor: theme.palette.warning.main,
        backgroundColor: theme.palette.common.white,
        "& .MuiAlert-icon": {
          backgroundColor: theme.palette.warning.main,
        },
      },
      "&.MuiAlert-outlinedInfo": {
        color: theme.palette.text.primary,
        borderColor: theme.palette.info.main,
        backgroundColor: theme.palette.common.white,
        "& .MuiAlert-icon": {
          backgroundColor: theme.palette.info.main,
        },
      },
      "& .MuiAlert-icon": {
        opacity: 1,
        color: theme.palette.common.white,
        width: theme.spacing(7),
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
      },
      "& .MuiAlert-message": {
        "&": theme.typography.body1,
        flexGrow: 1,
        padding: theme.spacing(1),
      },
      "& .MuiAlertTitle-root": {
        "&": theme.typography.h5,
      },
    },
  }));
  const classes = useStyles();

  return message ? (
    <Alert
      className={classnames(classes.root)}
      variant="outlined"
      severity={severity[0]}
      icon={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Box
          component="span"
          className={classnames("icon", `icon-${severity[0]}`)}
        />
      }
    >
      {message}
    </Alert>
  ) : null;
};

const mapStateToProps = state => ({
  message: state.authentication ? state.authentication.error : null,
});

export default connect(mapStateToProps)(AuthMessage);
