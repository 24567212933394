/* eslint-disable camelcase */
import axios from "axios";
import getCookie from "utils/getCookie";

class MediaService {
  fetchMedia = async ({
    searchText = "",
    page,
    limit,
    filters = "",
  }) => {
    const params = {
      searchText,
    };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/media?skip=${page}&limit=${limit}&filters=${filters}`,
        params,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch media service:", err);
      return err;
    }
  };

  uploadMedia = async ({ access_token, id_token, payload }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/media/upload`,
        maxContentLength: 20 * 1024 * 1024,
        headers: {
          "content-type": "multipart/form-data",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
        data: payload,
      });
      return data;
    } catch (err) {
      console.log("Error in upload media service:", err);
      return err;
    }
  };

  updateMedia = async ({ access_token, id_token, key, title, tags }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/media/update/${key}`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
        },
        data: { title, tags },
      });
      return data;
    } catch (err) {
      console.log("Error in update media service:", err);
      return err;
    }
  };

  deleteMedia = async ({ access_token, id_token, keys }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/media/delete`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
        },
        data: { keys },
      });
      return data;
    } catch (err) {
      console.log("Error in delete media service:", err);
      return err;
    }
  };
}

const inst = new MediaService();
export default inst;
