/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  ListItemText,
  ClickAwayListener,
  ListItem,
  Divider,
} from "@material-ui/core";
import { shallowEqual, useSelector } from "react-redux";
import { set, get, groupBy } from "lodash";

const THRESHOLD = 3;

const SearchFieldWrapper = props => {
  const { searchFieldPlaceholder, handleInputChange } = props;

  return (
    <ClickAwayListener onClickAway={() => null}>
      <ListItem>
        <TextField
          variant="outlined"
          color="primary"
          fullWidth
          placeholder={searchFieldPlaceholder || "Search"}
          onChange={e => {
            handleInputChange(e.target.value);
          }}
          onKeyDown={e => {
            // Prevent MUI-Autoselect while typing
            e.stopPropagation();
          }}
        />
      </ListItem>
    </ClickAwayListener>
  );
};

const FunctionField = ({
  setSelectedEffect,
  selectedEffect,
  paramName,
  paramProperty,
  show,
}) => {
  const [fn, setFn] = useState("");
  const [searchText, setSearchText] = useState("");
  const appInfo = useSelector(
    state => state.applications.current.appInfo,
    shallowEqual
  );

  const functionType = ({ type, parameters }) => {
    if (!(type && parameters)) return "[untyped]";
    const joinedParameters = parameters
      .map(({ name, type: paramType }) => `${name}: ${paramType}`)
      .join(",");
    return `(${joinedParameters}) => ${type}`;
  };

  const functions = [
    {
      f: "not",
      t: "(x: any) => boolean",
    },
    {
      f: "toString",
      t: "(x: any) => string",
    },
    ...(appInfo.snippets || []).map(s => ({
      f: `functions.${s.data.name}`,
      t: functionType(s),
    })),
    ...(appInfo.packages || []).flatMap(p =>
      p.declarations.map(d => ({
        f: `${p.name.replace(/-/g, "_").toLowerCase()}.${d.name}`,
        t: functionType(d),
      }))
    ),
  ];

  const handleOnChange = e => {
    setFn(e.target.value);
    if (
      selectedEffect?.arguments?.children[paramName]?.values?.length ||
      get(selectedEffect.arguments, `${paramProperty}.values`)?.length
    ) {
      let obj;
      if (paramProperty) {
        obj = {
          ...selectedEffect.arguments,
        };
        set(obj, `${paramProperty}.function`, e.target.value);
      } else {
        obj = { ...selectedEffect.arguments };
        obj.children[paramName].function = e.target.value;
      }
      setSelectedEffect({
        ...selectedEffect,
        arguments: {
          ...selectedEffect.arguments,
          // [paramName]: {
          //   ...selectedEffect.arguments[paramName],
          //   ...(!paramProperty ? { function: e.target.value } : obj),
          // },
          ...obj,
        },
      });
    }
  };

  const onInputChange = value => {
    setSearchText(value);
  };

  useEffect(() => {
    if (
      !selectedEffect?.arguments?.children[paramName]?.values?.length &&
      !paramProperty
    )
      setFn("");
    else if (
      selectedEffect?.arguments?.children[paramName] &&
      !get(selectedEffect.arguments, `${paramProperty}.values`)?.length &&
      paramProperty
    )
      setFn("");
    else {
      setFn(
        !paramProperty
          ? selectedEffect?.arguments?.children[paramName]?.function
          : get(selectedEffect.arguments, `${paramProperty}.function`) ?? ""
      );
    }
  }, [selectedEffect.arguments]);

  return show ? (
    <TextField
      select
      name="Function"
      SelectProps={{
        renderValue: () => {
          return fn;
        },
        MenuProps: {
          onEnter: () => {
            setSearchText("");
          },
          onExit: () => {
            setSearchText("");
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      }}
      onChange={handleOnChange}
      label="Select Function"
      value={fn}
      variant="outlined"
      color="primary"
      fullWidth
      InputProps={{
        disableUnderline: true,
      }}
    >
      <SearchFieldWrapper handleInputChange={onInputChange} />
      <Divider />
      {Object.entries(groupBy(functions, x => x.f))
        .map(([f, xs]) => ({ f, xs }))
        .filter(f => {
          if (searchText)
            return f.f.toLowerCase().includes(searchText.toLowerCase());
          return f;
        })
        .filter((x, index) => {
          return index < THRESHOLD;
        })
        .map(({ f, xs }) => (
          <MenuItem
            key={f}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(f === fn ? { selected: true } : {})}
            value={f}
          >
            <ListItemText primary={f} secondary={xs.map(x => x.t).join(" ")} />
          </MenuItem>
        ))}
    </TextField>
  ) : null;
};

export default FunctionField;
