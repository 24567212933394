import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Login from "components/signin/Login";

const Signin = ({ authentication }) => {
  return !authentication || !authentication.name ? (
    <Login />
  ) : (
    <Redirect to={`/${authentication.ctry}`} />
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
