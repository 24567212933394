// eslint-disable-next-line import/prefer-default-export
export const handleErrorResponse = (responsePayload, defaultMessage) => {
  if (
    responsePayload &&
    responsePayload.response &&
    responsePayload.response.data &&
    responsePayload.response.data.message
  ) { 
     let message;
    if(typeof(responsePayload.response.data.message)=="string"){
         message = responsePayload.response.data.message ;
    } else if(typeof(responsePayload.response.data.message)=="object") {
         message = responsePayload.response.data.message.reduce((acc,next)=> {
          return acc + (next.stack || next.message) ;
      } ,'') 
    }  

   //  const retError = { message: responsePayload.response.data.message };
    return  {message};
  }
  const returnError = {
    message: responsePayload.message || defaultMessage,
  };
  return returnError;
};
