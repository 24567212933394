const LOGOUT = "LOGOUT";
const LOGGED_IN = "LOGGED_IN";
const LOGOUT_MESSAGE = "LOGOUT_MESSAGE";
const CLEAR_LOGOUT_MESSAGE = "CLEAR_LOGOUT_MESSAGE";
const LOGIN_ERROR = "LOGIN_ERROR";
const SET_SELECTED_MARKET = "SET_SELECTED_MARKET";
const UPDATE_AUTH_ID_TOKEN = "UPDATE_AUTH_ID_TOKEN";

const loggedIn = payload => ({
  type: LOGGED_IN,
  payload,
});

const loginError = error => ({
  type: LOGIN_ERROR,
  error,
});

const loggedOut = () => ({
  type: LOGOUT,
});

const loggedOutMessage = payload => ({
  type: LOGOUT_MESSAGE,
  payload,
});

const clearLogoutMessage = () => ({
  type: CLEAR_LOGOUT_MESSAGE,
});

const setSelectedMarket = payload => ({
  type: SET_SELECTED_MARKET,
  payload,
});

const updateAuthIdToken = payload => ({
  type: UPDATE_AUTH_ID_TOKEN,
  payload,
});

export {
  LOGGED_IN,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_MESSAGE,
  CLEAR_LOGOUT_MESSAGE,
  SET_SELECTED_MARKET,
  UPDATE_AUTH_ID_TOKEN,
  loggedIn,
  loginError,
  loggedOut,
  loggedOutMessage,
  clearLogoutMessage,
  setSelectedMarket,
  updateAuthIdToken,
};
