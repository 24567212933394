/* eslint-disable no-underscore-dangle */
import { cloneDeep, range } from "lodash";
import jp from "jsonpath";
import uuidv4 from "uuid/v4";
import { gridColumnsMap } from "./layout-to-djr";

const addRow = (cols, page) => {
  const currentPage = cloneDeep(page);
  const columns = range(cols);
  const { djr } = currentPage;
  const gridPath = "$.__props.children";
  jp.apply(djr, gridPath, children => [
    ...children,
    {
      __component: "Grid",
      __props: {
        id: uuidv4(),
        spacing: 3,
        children: columns.map(() => {
          return {
            __component: "GridItem",
            __props: {
              id: uuidv4(),
              ...gridColumnsMap[cols],
              children: [
                {
                  __component: "CardHeader",
                  __props: {
                    title: "Text",
                    id: uuidv4().replaceAll("-", "_"),
                  },
                },
              ],
            },
          };
        }),
      },
    },
  ]);

  return { djr };
};

const moveRow = (dragIndex, hoverIndex, djr) => {
  const dragGrid = jp.query(djr, `$.__props.children[${dragIndex}]`)[0];
  jp.apply(djr, `$.__props.children`, children => {
    const newVar = [...children];
    newVar.splice(dragIndex, 1);
    newVar.splice(hoverIndex, 0, dragGrid);
    return newVar;
  });

  return djr;
};

const deleteRow = (id, djr) => {
  const tempDJR = cloneDeep(djr);
  jp.apply(tempDJR, "$.__props.children", children =>
    children.filter(child => child.__props.id !== id)
  );
  return tempDJR;
};

const addCol = (id, page) => {
  const currentPage = cloneDeep(page);

  const { djr } = currentPage;
  const gridPath = `$..[?(@.id == '${id}')].children`;
  jp.apply(djr, gridPath, children => [
    ...children.map(child => ({
      ...child,
      __props: {
        // eslint-disable-next-line no-underscore-dangle
        ...child.__props,
        ...gridColumnsMap[children.length + 1],
      },
    })),
    {
      __component: "GridItem",
      __props: {
        id: uuidv4(),
        ...gridColumnsMap[children.length + 1],
        children: [
          {
            __component: "CardHeader",
            __props: {
              title: "Text",
              id: uuidv4().replaceAll("-", "_"),
            },
          },
        ],
      },
    },
  ]);
  return { djr };
};

const moveCol = (dragIndex, hoverIndex, page) => {
  const dragCol = page.rows[0].cols[dragIndex];
  const rows = [...page.rows];
  rows.splice(dragIndex, 1);
  rows.splice(hoverIndex, 0, dragCol);
};

const deleteCol = (rowIndex, colIndex, page) => {
  const currentPage = cloneDeep(page);

  const { djr } = currentPage;
  const gridPath = `$.__props.children[${rowIndex}].__props.children`;
  jp.apply(djr, gridPath, children => {
    const newVar = [...children];
    newVar.splice(colIndex, 1);
    const newVar2 = newVar.map(child => ({
      ...child,
      __props: {
        // eslint-disable-next-line no-underscore-dangle
        ...child.__props,
        ...gridColumnsMap[children.length - 1],
      },
    }));
    return newVar2;
  });

  return { djr };
};

export { addRow, moveRow, deleteRow, addCol, moveCol, deleteCol };
