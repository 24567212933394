const nodeName = (node, index) => {
  const s = `Item ${index + 1}`;
  if (node.name) return `${s} (${node.name})`;
  if (typeof node === "string") return `${s} (${node})`;
  if (typeof node[Object.keys(node)[0]] !== "object")
    return `${s} (${
      node[
        Object.keys(node).find(i => i === "title")
          ? "title"
          : Object.keys(node)[0]
      ]
    })`;
  return s;
};

export default nodeName;
