import React from "react";
import {
  Typography,
  Box,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Table,
  TableBody,
} from "@material-ui/core";
import ReactDiffViewer from "react-diff-viewer";
import VfSvgIcon from "../../icons/VfSvgIcon";

const RenderRow = ({ snippet }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow>
        <TableCell>
          {snippet?.versionFrom ? (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">
                {snippet?.versionFrom?.name}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body2">Not Used</Typography>
          )}
        </TableCell>
        <TableCell>
          {snippet?.versionTo ? (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">
                {snippet?.versionTo?.name}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body2">Not Used</Typography>
          )}
        </TableCell>
        <TableCell align="right" width={48}>
          <IconButton onClick={() => setOpen(!open)}>
            <VfSvgIcon icon={open ? "chevronDown" : "chevronDown"} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={3}>
          <Box>
            <Collapse in={open} unmountOnExit>
              <ReactDiffViewer
                oldValue={snippet?.versionFrom?.javascriptString || ""}
                newValue={snippet?.versionTo?.javascriptString || ""}
                showDiffOnly
                extraLinesSurroundingDiff={0}
                splitView
              />
            </Collapse>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

const SnippetTab = ({ snippets }) => {
  return (
    <Table>
      <TableBody>
        {snippets && snippets.map(snippet => <RenderRow snippet={snippet} />)}
      </TableBody>
    </Table>
  );
};

export default SnippetTab;
