/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
} from "@material-ui/core";
import theme from "AppTheme";

import {
  setShowAddTemplate as setShowAddTemplateAction,
  setCurrentPage as setCurrentPageAction,
  showSideBar,
  savePage,
} from "actions/applicationsActions";

import store from "../../store/configureStore";

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  content: {
    minHeight: theme.spacing(19),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const CardGridStarter = ({
  setCurrentPage,
  setShowAddTemplate,
  template,
  templateFunc,
  dispatchshowSideBar,
  auth,
  environment,
  dispatchSavePage,
}) => {
  const classes = useStyles();

  const getChangedPages = changedPages => {
    return (
      Object.values(changedPages)
        // eslint-disable-next-line no-shadow
        .filter(page => !!page)
        // eslint-disable-next-line no-shadow
        .map(page => ({
          ...page.pageInfo,
          _id:
            page.pageInfo._id.indexOf("-") >= 0 ? undefined : page.pageInfo._id,
          data: page.djr,
        }))
    );
  };

  const { access_token, id_token } = auth;

  const handleSave = () => {
    const {
      applications: { current: app },
    } = store.getState();
    dispatchSavePage({
      access_token,
      id_token,
      pages: getChangedPages(app.changedPages),
      application: app.appInfo,
      envId: environment.id,
    });
  };

  return (
    <Card
      elevation={0}
      className={classNames(classes.root, {
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-dashed": true,
        "MuiCard-rounded": true,
      })}
    >
      <CardActionArea
        onClick={() => {
          setCurrentPage(templateFunc(template));
          handleSave();
          setShowAddTemplate(true);
          dispatchshowSideBar(true);
        }}
      >
        <CardContent className={classes.content}>
          <Typography variant="body1" align="center">
            or Start from scratch
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    application: state.applications.current,
    auth: state.authentication,
    environment: state.applications.env,
  };
};

const mapDispatchToProps = dispatch => ({
  setCurrentPage: payload => dispatch(setCurrentPageAction(payload)),
  setShowAddTemplate: payload => dispatch(setShowAddTemplateAction(payload)),
  dispatchshowSideBar: payload => dispatch(showSideBar(payload)),
  dispatchSavePage: payload => dispatch(savePage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardGridStarter);
