import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  Dialog,
  DialogContent,
  Slide,
  Fade,
  Toolbar,
  AppBar,
  Grid,
  Divider,
  Button,
  Typography,
} from "@material-ui/core";
import theme from "../../AppTheme";
import VfDialogConfirm from "./VfDialogConfirm";

const appBarHeight = theme.spacing(13);

const useStyles = makeStyles({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    paddingRight: "0 !important",
    overflow: "hidden",
  },
  toolBar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minHeight: appBarHeight,
    maxHeight: appBarHeight,
  },
  dialogContent: {
    padding: 0,
    backgroundColor: theme.palette.grey[100],
  },
  gridDividerExpand: {
    marginTop: -theme.spacing(4),
    marginBottom: -theme.spacing(4),
    alignSelf: "stretch",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Fade ref={ref} {...props}>
      <div style={{ height: "100%" }}>
        <Slide direction="up" ref={ref} {...props} />
      </div>
    </Fade>
  );
});

const VfDialogFull = ({
  openDialog,
  appBar,
  appBarBottom,
  children,
  buttonConfirmAction,
  buttonConfirmText,
  buttonConfirmDisabled,
  buttonCloseAction,
  buttonCloseText,
  showConfirmDialog,
  saveAction,
  hasValidations,
  buttonConfirmGroup,
  classDialogContent,
}) => {
  const classes = useStyles();
  const [dialogConfirm, setDialogConfirm] = React.useState(false);
  const handleDialogConfirm = val => {
    setDialogConfirm(val);
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      TransitionComponent={Transition}
      // transitionDuration={1000}
      transitionDuration={theme.transitions.duration.complex}
      scroll="paper"
    >
      {appBar && (
        <AppBar color="inherit" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Grid container alignItems="center" wrap="nowrap" spacing={3}>
              <Grid item xs>
                {appBar}
              </Grid>
              <Grid item xs="auto" className={classes.gridDividerExpand}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs="auto">
                <Button
                  autoFocus
                  color="default"
                  variant="outlined"
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    showConfirmDialog
                      ? handleDialogConfirm(true)
                      : buttonCloseAction();
                  }}
                  data-testid="CloseDialogConfg_btn"
                >
                  {buttonCloseText || "Cancel"}
                </Button>
                <VfDialogConfirm
                  openDialog={dialogConfirm}
                  maxWidth="xs"
                  buttonCancelText="No"
                  buttonCancelAction={() => handleDialogConfirm(false)}
                  buttonConfirmText="Yes"
                  buttonConfirmAction={() => {
                    handleDialogConfirm(false);
                    buttonCloseAction();
                  }}
                >
                  <Typography variant="body1" align="center">
                    Are you sure you want to discard your changes?
                  </Typography>
                </VfDialogConfirm>
              </Grid>
              {buttonConfirmText && buttonConfirmAction && (
                <Grid item xs="auto">
                  <Button
                    data-testid="saveDialogConfg_btn"
                    autoFocus
                    color="secondary"
                    variant="contained"
                    disabled={buttonConfirmDisabled}
                    onClick={() => {
                      saveAction();
                      if (!hasValidations) buttonCloseAction();
                    }}
                  >
                    {buttonConfirmText}
                  </Button>
                </Grid>
              )}
              {buttonConfirmGroup}
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <DialogContent
        className={classNames(classDialogContent, classes.dialogContent)}
      >
        {children}
      </DialogContent>
      {appBarBottom && (
        <AppBar color="inherit" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>{appBarBottom}</Toolbar>
        </AppBar>
      )}
    </Dialog>
  );
};

export default VfDialogFull;
