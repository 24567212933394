/* eslint-disable no-underscore-dangle */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import EnvironmentsSelection from "./EnvironmentsSelection";
import VfSvgIcon from "../icons/VfSvgIcon";

const EnvironmentsDetails = ({ environments, title }) => {
  return (
    <Box py={4} bgcolor="common.white">
      <Container maxWidth="md">
        <Paper elevation={0} className="MuiCard-selection animate">
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item xs>
              <Typography variant="h4" component="h1" color="textPrimary">
                {title}
              </Typography>
            </Grid>
            {!!environments.length && (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {environments.map(
                    env =>
                      !env.isGlobal && (
                        <Grid key={env._id} item xs={6} sm={3}>
                          <EnvironmentsSelection
                            title={env.title}
                            /* eslint-disable */
                            image={
                              <VfSvgIcon
                                icon={env.type}
                                viewBox={56}
                                fontSize="large"
                              />
                            }
                            type={env.type}
                            id={env._id}
                            /* eslint-enable */
                          />
                        </Grid>
                      )
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default EnvironmentsDetails;
