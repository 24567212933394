import {
  SET_ENV,
  CLEAR_ENV,
  ENVS_REQUEST_SUCCESS,
  ENVS_REQUEST_FAIL,
  URLS_RESOURCES_REQUEST,
  URLS_RESOURCES_REQUEST_SUCCESS,
  URLS_RESOURCES_REQUEST_FAIL,
  CLEAR_URLS_RESOURCES,
} from "actions/envsActions";

const initialState = {
  current: "",
  id: "",
  list: [],
  error: null,
  envUrls: {},
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case SET_ENV:
      return {
        ...state,
        current: payload.type,
        id: payload.id,
      };
    case CLEAR_ENV:
      return {
        ...state,
        current: "",
        id: "",
      };
    case ENVS_REQUEST_SUCCESS:
      return {
        ...state,
        list: payload.data,
        defaultCoreAppJob: payload.defaultCoreAppJob
      };
    case ENVS_REQUEST_FAIL:
      return {
        ...state,
        ...error,
      };
    case URLS_RESOURCES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case URLS_RESOURCES_REQUEST_SUCCESS:
      return {
        ...state,
        envUrls: payload,
        loading: false,
      };
    case URLS_RESOURCES_REQUEST_FAIL:
      return {
        ...state,
        ...error,
        loading: false,
      };
    case CLEAR_URLS_RESOURCES:
      return {
        ...state,
        envUrls: {},
      };
    default:
      return state;
  }
};
