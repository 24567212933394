/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, memo } from "react";
import { connect } from "react-redux";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  Drawer,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withTheme } from "react-jsonschema-form";
import { isEmpty, intersectionBy, cloneDeep, debounce } from "lodash";
import FormTheme from "components/custom-mui-components/FormTheme";
import schemaToTree from "utils/schema-to-tree";
import {
  clearCurrRowCol,
  setCurrentPage as setCurrentPageAction,
  clearSelectedWidget,
} from "actions/applicationsActions";
import { getDjslProps, setDjslProps } from "utils/djsl-utils";
import createInputForRole from "components/input/customInputForPropertyRole";
import VfSvgIcon from "../icons/VfSvgIcon";
import WidgetFormTree from "./WidgetFormTree";
import theme from "../../AppTheme";
import WidgetFormOrderList from "./WidgetFormOrderList"
import AddItemWidgetForm from "./AddItemWidgetForm"
const jp = require("jsonpath");

const Form = withTheme(FormTheme);

const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
  drawer: {
    width: theme.spacing(42),
    flexShrink: 0,
  },
  drawerPaper: {
    top: theme.spacing(19),
    height: "calc(100vh - 152px)",
    width: theme.spacing(36),
    flexShrink: 0,
    left: "336px",
    zIndex: -1,
    overflowX: "hidden",
    padding: "24px",
  },
});

const WidgetProps = ({
  setSidebarItem,
  selectedWdgt,
  clearRowCol,
  application,
  setCurrentPage,
  nexusWidgets,
  clearWidget,
  parent,
  maximumRequestBodySize,
}) => {
  const { appInfo, currentPage, pages } = application;
  const classes = useStyles();
  const [tree, setTree] = useState(null);
  const [selectedTreeItem, setSelectedTreeItem] = useState(null);

  const [addItem, setAddItem] = useState(false);
  const [newItemSchema, setItemSchema] = useState({});
  const [newItemData, setItemData] = useState({});

  const onAddItem = () => setAddItem(!addItem);
  const onSetItemSchema = schema => setItemSchema(schema);
  const onSetItemData = data => setItemData(data);

  const addMissingProperties = (obj, path, val) => {
    const keys = path.split(".");
    const lastKey = keys.pop();
    const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
    lastObj[lastKey] = val;
  };

  const getPropsFunc = getDjslProps;
  const setPropsFunc = setDjslProps;

  const propsHeading =
    { Grid: "Grid Props", GridItem: "Grid Item Props" }[selectedWdgt.name] ||
    "Widget Props";

  const schema =
    typeof selectedWdgt.propsSchema === "string"
      ? JSON.parse(selectedWdgt.propsSchema)
      : selectedWdgt.propsSchema;
  const uiSchemaEntries = Object.entries(schema.properties || [])
    .filter(
      ([_, prop]) =>
        prop.type === "string" &&
        prop.__role &&
        prop.__role !== "pages" &&
        prop.__role !== "components"
    ).map(([field, prop]) => [
      field,
      {
         "ui:widget": createInputForRole(prop.__role, maximumRequestBodySize),
      },
  ]);
  const uiSchema = Object.fromEntries(uiSchemaEntries);
  schema.title = "";
  let allWidgets = [];
  nexusWidgets.forEach(({ widgets }) => {
    allWidgets = [...allWidgets, ...widgets];
  });
  const widgets = intersectionBy(allWidgets, appInfo.widgets, "_id");
  const appWidgetsNames = widgets.map(w => w.name);
  const appWidgetsDescription = widgets.map(w => w.description);
  const appPages = pages.map(p => p.title);
  const findCustomProp = (obj, role) => {
    const rslt = Object.entries(obj || []).filter(
      // eslint-disable-next-line no-unused-vars
      ([_, prop]) =>
        prop.type === "string" && prop.__role && prop.__role === role
    );
    return rslt.length ? rslt[0][0] : null;
  };
 
  Object.entries(schema.properties || []).forEach(
    // eslint-disable-next-line no-unused-vars
    ([_, prop]) => {
      if (prop.type === "string" && prop.__role && prop.__role === "pages") {
        prop.enum = appPages;
        prop.default = appPages[0];
      }
      if (
        prop.type === "string" &&
        prop.__role &&
        prop.__role === "components"
      ) {
        prop.enum = appWidgetsNames;
        prop.enumNames = appWidgetsDescription;
        prop.default = appWidgetsNames[0];
      }
    }
  );
  const pagesCustomField = findCustomProp(schema.properties, "pages");
  const componentsCustomField = findCustomProp(schema.properties, "components");
  const allProps = getPropsFunc(currentPage.djr, parent);
  const props = Object.fromEntries(
    Object.entries(allProps).filter(p => p[0] !== "__image")
  );
  if (pagesCustomField && !isEmpty(props))
    props[pagesCustomField] = pages
      .filter(p => p._id === props[pagesCustomField])
      .map(p => p.title)[0];
  if (componentsCustomField && !isEmpty(props))
    props[componentsCustomField] = widgets
      .filter(w => w.name === props[componentsCustomField])
      .map(w => w.name)[0];
  const [widgetData, setWidgetData] = useState(allProps);

  useEffect(() => {
    const treeData = schemaToTree(undefined, "$", schema, allProps);
    setTree(treeData);
    setSelectedTreeItem({
      schema: treeData.__schema__,
      id: treeData.id,
      name: treeData.name,
      isArray: treeData.showArray,
      childrenSchema: treeData.childrenSchema,
    });
  }, []);
  useEffect(() => {
    setAddItem(false)
  }, [selectedTreeItem])
  useEffect(() => {
    if (
      Object.keys(widgetData).length === 0 &&
      widgetData.constructor === Object
    )
      return;
    const djr = setPropsFunc(currentPage.djr, widgetData, parent);
    setCurrentPage({
      ...currentPage,
      djr,
    });
  }, [widgetData]);

  const onSelectTreeItem = node => {
    setSelectedTreeItem({
      schema: node.__schema__,
      id: node.id.replace(/\s/g, ""),
      name: node.name,
      isArray: node.showArray,
      childrenSchema: node.childrenSchema,
    });
  };

  const handleOnChanged = debounce(formData => {
    if (pagesCustomField)
      formData.formData[pagesCustomField] = pages
        .filter(p => p.title === formData.formData[pagesCustomField])
        .map(p => p._id)[0];
    if (componentsCustomField)
      formData.formData[componentsCustomField] = widgets
        .filter(w => w.name === formData.formData[componentsCustomField])
        .map(w => w.name)[0];
    const djr = setPropsFunc(currentPage.djr, formData.formData, parent);
    setCurrentPage({
      ...currentPage,
      djr,
    });
  }, 1000);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box mt={3} px={3} className="MuiBox-searchOverlay" position="relative">
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item>
              <IconButton
                onClick={() => {
                  clearRowCol();
                  clearWidget();
                }}
              >
                <VfSvgIcon icon="close" viewBox={24} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography className="bold" variant="h5" component="h3">
                {propsHeading}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {!Object.values(schema.properties || []).find(
          prop => prop.type === "object" || prop.type === "array"
        ) ? (
            <Box py={3} px={3}>
              <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={props}
                showErrorList={false}
                onChange={handleOnChanged}
              >
                <span />
              </Form>
            </Box>
          ) : (
            <>
              {tree && (
                <Box px={2}>
                  <WidgetFormTree
                    data={[tree]}
                    setSelectedTreeItem={onSelectTreeItem}
                  />
                </Box>
              )}
              <Drawer
                open={!!selectedTreeItem}
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {selectedTreeItem &&
                  (<Box pb={3}>
                    <Grid
                      container
                      wrap="nowrap"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography
                          className="bold"
                          variant="h5"
                          component="h3"
                        >
                          {selectedTreeItem.name?.charAt(0).toUpperCase() +
                            selectedTreeItem.name?.slice(1) || "Properties"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setSelectedTreeItem(null);
                          }}
                        >
                          <VfSvgIcon icon="close" viewBox={24} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                  )}
                {addItem ? (
                  <AddItemWidgetForm
                    newItemSchema={newItemSchema}
                    newItemData={newItemData}
                    configForm={{
                      schema, tree, selectedTreeItem, data: widgetData
                    }}
                    updateConfigData={setWidgetData}
                    setAddItem={setAddItem}
                    setConfigTree={setTree}
                  />
                ) : selectedTreeItem && selectedTreeItem.isArray ?
                    (
                      <WidgetFormOrderList
                        configForm={{
                          schema, tree, selectedTreeItem, data: widgetData
                        }}
                        updateConfigData={setWidgetData}
                        onAddItem={onAddItem}
                        setConfigTree={setTree}
                        onSetItemSchema={onSetItemSchema}
                        onSetItemData={onSetItemData}
                      />
                    ) : selectedTreeItem && !selectedTreeItem.isArray && (
                      <Form
                        schema={selectedTreeItem.schema || {}}
                        uiSchema={uiSchema}
                        formData={
                          jp.query(
                            widgetData,
                            selectedTreeItem.id.replace(/\s/g, "")
                          )[0]
                        }
                        showErrorList={false}
                        onChange={formData => {
                          if (pagesCustomField)
                            formData.formData[pagesCustomField] = pages
                              .filter(
                                p => p.title === formData.formData[pagesCustomField]
                              )
                              .map(p => p._id)[0];
                          if (componentsCustomField)
                            formData.formData[
                              componentsCustomField
                            ] = widgets
                              .filter(
                                w =>
                                  w.name ===
                                  formData.formData[componentsCustomField]
                              )
                              .map(w => w.name)[0];
                          if (selectedTreeItem.id.length > 2) {
                            const cloneData = cloneDeep(widgetData);
                            if (
                              !jp.query(
                                cloneData,
                                selectedTreeItem.id.replace(/\s/g, "")
                              ).length
                            )
                              addMissingProperties(
                                cloneData,
                                selectedTreeItem.id.substring(2),
                                null
                              );
                            jp.apply(
                              cloneData,
                              selectedTreeItem.id.replace(/\s/g, ""),
                              () => {
                                return formData.formData;
                              }
                            );
                            setWidgetData(cloneData);
                          } else {
                            if (pagesCustomField && !isEmpty(props))
                              formData.formData[pagesCustomField] = pages
                                .filter(
                                  p => p._id === formData.formData[pagesCustomField]
                                )
                                .map(p => p.title)[0];
                            if (
                              componentsCustomField &&
                              !isEmpty(formData.formData)
                            )
                              formData.formData[
                                componentsCustomField
                              ] = widgets
                                .filter(
                                  w =>
                                    w.name ===
                                    formData.formData[componentsCustomField]
                                )
                                .map(w => w.name)[0];
                            setWidgetData(widgetData => ({
                              ...widgetData,
                              ...formData.formData,
                            }));
                          }
                        }}
                      >
                        <span />
                      </Form>
                    )}
              </Drawer>
            </>
          )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    application: state.applications.current,
    rowIndex: state.applications.selectedRow,
    colIndex: state.applications.selectedColumn,
    parent: state.applications.parent,
    nexusWidgets: state.nexusWidgets.data,
    maximumRequestBodySize: state.settings.maximumRequestBodySize,
  };
};

const mapDispatchToProps = dispatch => ({
  clearRowCol: () => dispatch(clearCurrRowCol()),
  setCurrentPage: payload => dispatch(setCurrentPageAction(payload)),
  clearWidget: () => dispatch(clearSelectedWidget()),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(WidgetProps));