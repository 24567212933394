/* eslint-disable camelcase */
import axios from "axios";
import getCookie from "utils/getCookie";

class PermissionsService {
  fetchPermissions = async () => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/permissions`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch applications service:", err);
      return err;
    }
  };
}

const inst = new PermissionsService();
export default inst;
