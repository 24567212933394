const DEPLOY_SPAIN_APPLICATION_REQUEST = "DEPLOY_SPAIN_APPLICATION_REQUEST";
const DEPLOY_SPAIN_APPLICATION_REQUEST_SUCCESS =
  "DEPLOY_SPAIN_APPLICATION_REQUEST_SUCCESS";
const DEPLOY_SPAIN_APPLICATION_REQUEST_FAIL =
  "DEPLOY_SPAIN_APPLICATION_REQUEST_FAIL";
const UNDEPLOY_SPAIN_APPLICATION_REQUEST = "UNDEPLOY_SPAIN_APPLICATION_REQUEST";
const UNDEPLOY_SPAIN_APPLICATION_REQUEST_SUCCES =
  "UNDEPLOY_SPAIN_APPLICATION_REQUEST_SUCCES";
const UNDEPLOY_SPAIN_APPLICATION_REQUEST_FAIL =
  "UNDEPLOY_SPAIN_APPLICATION_REQUEST_FAIL";

const deploySpainApplication = payload => ({
  type: DEPLOY_SPAIN_APPLICATION_REQUEST,
  payload,
});

const undeploySpainApplication = payload => ({
  type: UNDEPLOY_SPAIN_APPLICATION_REQUEST,
  payload,
});

export {
  DEPLOY_SPAIN_APPLICATION_REQUEST,
  DEPLOY_SPAIN_APPLICATION_REQUEST_SUCCESS,
  DEPLOY_SPAIN_APPLICATION_REQUEST_FAIL,
  UNDEPLOY_SPAIN_APPLICATION_REQUEST,
  UNDEPLOY_SPAIN_APPLICATION_REQUEST_SUCCES,
  UNDEPLOY_SPAIN_APPLICATION_REQUEST_FAIL,
  deploySpainApplication,
  undeploySpainApplication,
};
