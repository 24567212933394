import { call, put, takeLatest } from "redux-saga/effects";

import {
  SETTINGS_REQUEST,
  SETTINGS_REQUEST_SUCCESS,
  SETTINGS_REQUEST_FAIL,
} from "actions/settingsActions";
import settingsService from "services/SettingsService";

function* configWorker() {
  try {
    const { data } = yield call(settingsService.fetchSettings);
    yield put({
      type: SETTINGS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: SETTINGS_REQUEST_FAIL, error });
  }
}

function* configWatcher() {
  yield takeLatest(SETTINGS_REQUEST, configWorker);
}

const configSaga = [configWatcher()];

export default configSaga;
