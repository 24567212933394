import React from "react";
import {
  Collapse,
  Typography,
  IconButton,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

import ReactDiffViewer from "react-diff-viewer";
import VfSvgIcon from "../../icons/VfSvgIcon";

const RenderRow = ({ flow }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow>
        <TableCell>
          {flow?.versionFrom ? (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">{flow?.versionFrom?.name}</Typography>
            </Box>
          ) : (
            <Typography variant="body2">Not Used</Typography>
          )}
        </TableCell>
        <TableCell>
          {flow?.versionTo ? (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">{flow?.versionTo?.name}</Typography>
            </Box>
          ) : (
            <Typography variant="body2">Not Used</Typography>
          )}
        </TableCell>
        <TableCell align="right" width={48}>
          <IconButton onClick={() => setOpen(!open)}>
            <VfSvgIcon icon={open ? "chevronDown" : "chevronDown"} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={3}>
          <Box>
            <Collapse in={open} unmountOnExit>
              <ReactDiffViewer
                oldValue={flow?.versionFrom?.bpmn || ""}
                newValue={flow?.versionTo?.bpmn || ""}
                extraLinesSurroundingDiff={0}
                splitView
                showDiffOnly
              />
            </Collapse>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
const FlowTab = ({ flows }) => {
  return (
    <Table>
      <TableBody>
        {flows && flows.map(flow => <RenderRow flow={flow} />)}
      </TableBody>
    </Table>
  );
};

export default FlowTab;
