const PACKAGES_REQUEST = "PACKAGES_REQUEST";
const PACKAGES_REQUEST_SUCCESS = "PACKAGES_REQUEST_SUCCESS";
const PACKAGES_REQUEST_FAIL = "PACKAGES_REQUEST_FAIL";
const SHOW_CREATE_PACKAGE_DIALOG = "SHOW_CREATE_PACKAGE_DIALOG";
const SAVE_PACKAGE_REQUEST = "SAVE_PACKAGE_REQUEST";
const SAVE_PACKAGE_REQUEST_SUCCESS = "SAVE_PACKAGE_REQUEST_SUCCESS";
const SAVE_PACKAGE_REQUEST_FAIL = "SAVE_PACKAGE_REQUEST_FAIL";
const PACKAGE_DETAILS_REQUEST = "PACKAGE_DETAILS_REQUEST";
const PACKAGE_DETAILS_REQUEST_SUCCESS = "PACKAGE_DETAILS_REQUEST_SUCCESS";
const PACKAGE_DETAILS_REQUEST_FAIL = "PACKAGE_DETAILS_REQUEST_FAIL";
const CLEAR_PACKAGE_DETAILS = "CLEAR_PACKAGE_DETAILS";
const DELETE_PACKAGE_REQUEST = "DELETE_PACKAGE_REQUEST";
const DELETE_PACKAGE_REQUEST_SUCCESS = "DELETE_PACKAGE_REQUEST_SUCCESS";
const DELETE_PACKAGE_REQUEST_FAIL = "DELETE_PACKAGE_REQUEST_FAIL";

const fetchPackages = payload => ({
  type: PACKAGES_REQUEST,
  payload,
});

const showCreatePackageDialog = payload => ({
  type: SHOW_CREATE_PACKAGE_DIALOG,
  payload,
});

const savePackage = payload => ({
  type: SAVE_PACKAGE_REQUEST,
  payload,
});

const fetchPackageDetails = payload => ({
  type: PACKAGE_DETAILS_REQUEST,
  payload,
});

const clearPackageDetails = () => ({
  type: CLEAR_PACKAGE_DETAILS,
});

const deletePackage = payload => ({
  type: DELETE_PACKAGE_REQUEST,
  payload,
});

export {
  PACKAGES_REQUEST,
  PACKAGES_REQUEST_SUCCESS,
  PACKAGES_REQUEST_FAIL,
  SHOW_CREATE_PACKAGE_DIALOG,
  SAVE_PACKAGE_REQUEST,
  SAVE_PACKAGE_REQUEST_SUCCESS,
  SAVE_PACKAGE_REQUEST_FAIL,
  PACKAGE_DETAILS_REQUEST,
  PACKAGE_DETAILS_REQUEST_SUCCESS,
  PACKAGE_DETAILS_REQUEST_FAIL,
  CLEAR_PACKAGE_DETAILS,
  DELETE_PACKAGE_REQUEST,
  DELETE_PACKAGE_REQUEST_SUCCESS,
  DELETE_PACKAGE_REQUEST_FAIL,
  fetchPackages,
  showCreatePackageDialog,
  clearPackageDetails,
  savePackage,
  fetchPackageDetails,
  deletePackage,
};
