const Liveness = {
  lastActionTimestamp: null,
  store: null,
  timeout: null,
  isAuthenticated: false,
  updateTimeout: () => {
    Liveness.update();
    const state = Liveness.store.getState();
    if (state.settings && state.settings.screenLockTimeout) {
      // eslint-disable-next-line radix
      const number = parseInt(state.settings.screenLockTimeout);
      if (number > 0) {
        Liveness.timeout = number * 1000;
      }
    }
    Liveness.isAuthenticated =
      state.authentication && state.authentication.id_token;
  },
  setStore: store => {
    Liveness.store = store;
    Liveness.updateTimeout();
    store.subscribe(Liveness.updateTimeout);
  },
  update: () => {
    if (Liveness.timeout) {
      Liveness.lastActionTimestamp = Date.now();
    }
  },
};

setInterval(() => {
  if (Liveness.timeout && Liveness.lastActionTimestamp) {
    const inactivityDuration = Date.now() - Liveness.lastActionTimestamp;
    if (inactivityDuration > Liveness.timeout && Liveness.isAuthenticated) {
      console.log(`Inactive for ${inactivityDuration / 1000}s`);
      const {
        settings: { tenantId, logoutRedirectUri },
      } = Liveness.store.getState();
      window.location.href = `https://login.windows.net/${tenantId}/oauth2/v2.0/logout?post_logout_redirect_uri=${logoutRedirectUri}&state=autoLogout`;
    }
  }
}, 5000);

export default Liveness;
