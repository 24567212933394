/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_ROLES_REQUEST,
  GET_ROLES_REQUEST_SUCCESS,
  GET_ROLES_REQUEST_FAIL,
  SAVE_ROLE_REQUEST,
  SAVE_ROLE_REQUEST_SUCCESS,
  SAVE_ROLE_REQUEST_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_REQUEST_SUCCESS,
  DELETE_ROLE_REQUEST_FAIL,
  SHOW_ROLE,
  CLEAR_ROLE,
} from "actions/rolesActions";
import RolesService from "services/RolesService";

function* rolesWorker({ payload }) {
  try {
    const data = yield call(RolesService.fetchRoles, payload);
    yield put({
      type: GET_ROLES_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: GET_ROLES_REQUEST_FAIL, error });
  }
}

function* rolesWatcher() {
  yield takeLatest(GET_ROLES_REQUEST, rolesWorker);
}

function* createRoleWorker({ payload }) {
  try {
    const data = yield call(RolesService.saveRole, payload);
    yield put({
      type: SAVE_ROLE_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: GET_ROLES_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
    yield put({
      type: SHOW_ROLE,
    });
    yield put({
      type: CLEAR_ROLE,
    });
  } catch (error) {
    yield put({ type: SAVE_ROLE_REQUEST_FAIL, error });
  }
}

function* createRoleWatcher() {
  yield takeLatest(SAVE_ROLE_REQUEST, createRoleWorker);
}

function* deleteRoleWorker({ payload }) {
  try {
    const data = yield call(RolesService.deleteRole, payload);
    yield put({
      type: DELETE_ROLE_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: GET_ROLES_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
  } catch (error) {
    yield put({ type: DELETE_ROLE_REQUEST_FAIL, error });
  }
}

function* deleteRoleWatcher() {
  yield takeLatest(DELETE_ROLE_REQUEST, deleteRoleWorker);
}

const rolesSaga = [rolesWatcher(), createRoleWatcher(), deleteRoleWatcher()];

export default rolesSaga;
