/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
export default class CustomContextPad {
  constructor(config, contextPad, create, elementFactory, injector, translate) {
    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;

    if (config.autoPlace !== false) {
      this.autoPlace = injector.get("autoPlace", false);
    }

    contextPad.registerProvider(this);
  }

  getContextPadEntries(element) {
    const { autoPlace, create, elementFactory, translate } = this;

    function appendServiceTask(event, element) {
      if (autoPlace) {
        const shape = elementFactory.createShape({ type: "bpmn:ServiceTask" });

        autoPlace.append(element, shape);
      } else {
        appendServiceTaskStart(event, element);
      }
    }

    function appendScriptTask(event, element) {
      if (autoPlace) {
        const shape = elementFactory.createShape({ type: "bpmn:ScriptTask" });

        autoPlace.append(element, shape);
      } else {
        appendScriptTaskStart(event, element);
      }
    }

    function appendServiceTaskStart(event) {
      const shape = elementFactory.createShape({ type: "bpmn:ServiceTask" });

      create.start(event, shape, element);
    }

    function appendScriptTaskStart(event) {
      const shape = elementFactory.createShape({ type: "bpmn:ScriptTask" });

      create.start(event, shape, element);
    }

    function openProps(event) {
      const d = document.getElementById("diagram");
      const ev = new Event("openPropsDialog", {
        bubbles: true,
        cancelable: true,
      });
      d.dispatchEvent(ev);
    }
    return {
      "append.service-task": {
        group: "model",
        className: "bpmn-icon-service-task",
        title: translate("Append ServiceTask"),
        action: {
          click: appendServiceTask,
          dragstart: appendServiceTaskStart,
        },
      },
      "append.script-task": {
        group: "model",
        className: "bpmn-icon-script-task",
        title: translate("Append ScriptTask"),
        action: {
          click: appendScriptTask,
          dragstart: appendScriptTaskStart,
        },
      },
      "append.props": {
        group: "model",
        className: "bpmn-icon-service",
        title: translate("Configure Props"),
        action: {
          click: openProps,
        },
      },
    };
  }
}

CustomContextPad.$inject = [
  "config",
  "contextPad",
  "create",
  "elementFactory",
  "injector",
  "translate",
];
