import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import VfSvgIcon from "../icons/VfSvgIcon";

const FilterMenuCategories = ({ categories, handleFilters }) => {
  const [anchorElCats, setAnchorElCats] = useState(null);
  const [filterActive, setFilterActive] = useState("All categories");
  const handleClickCats = event => {
    setAnchorElCats(event.currentTarget);
  };
  const handleCloseCats = () => {
    setAnchorElCats(null);
  };
  return (
    <>
      <Button
        variant="contained"
        size="small"
        endIcon={<VfSvgIcon icon="chevronDown" fontSize="small" />}
        onClick={handleClickCats}
      >
        {filterActive}
      </Button>
      <Menu
        id="simple-menu"
        elevation={0}
        anchorEl={anchorElCats}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorElCats)}
        onClose={handleCloseCats}
      >
        <MenuItem
          data-name="category"
          data-value={null}
          onClick={e => {
            handleCloseCats();
            handleFilters(e);
            setFilterActive("All categories");
          }}
          selected={filterActive === "All categories"}
        >
          All categories
        </MenuItem>
        {categories?.map(category => (
          <MenuItem
            key={category}
            data-name="category"
            data-value={category}
            selected={filterActive === category}
            onClick={e => {
              handleCloseCats();
              handleFilters(e);
              setFilterActive(category);
            }}
          >
            {category}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FilterMenuCategories;
