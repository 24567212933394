/* eslint-disable camelcase */
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import classNames from "classnames";
import { setApp } from "../../actions/appsActions";
import { fetchConfigListWidget } from "../../actions/widgetsActions";
import image from "../../assets/images/bck.jpg";
import VfSvgIcon from "../icons/VfSvgIcon";

const ApplicationsItemGrid = ({
  auth,
  envID,
  application,
  data,
  isSaved,
  setApplication,
  getConfigListWidget,
}) => {
  const { locale, env, touchpoint } = useParams();
  const { app, tags, version, imageBase64 } = data;
  const [anchorEl, setAnchorEl] = useState(null);
  const { access_token, id_token } = auth;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-application": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-success": !!isSaved,
        "MuiCard-rounded": true,
        "MuiCard-grid": true,
        animate: true,
      })}
    >
      <CardActionArea
        disableRipple
        disableTouchRipple
        component={Link}
        to={`/${locale}/env/${env}/${touchpoint}/${application}`}
        onClick={() => {
          setApplication(application);
          getConfigListWidget({
            envID,
            access_token,
            id_token,
            touchpoint,
            application,
          });
        }}
      >
        <CardMedia image={imageBase64 || image} title={app} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h5">
            <Typography component="span">{app}</Typography>
            <Typography component="span" color="secondary">
              <Box component="span" ml={2}>
                {version}
              </Box>
            </Typography>
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            {tags}
          </Typography>
          <Typography
            className="label"
            variant="body2"
            style={{ color: "#009900" }}
          >
            {isSaved ? "Ready for Deployment" : false}
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions disableSpacing>
        <IconButton
          className="MuiIconButton-colorWhite"
          color="secondary"
          aria-label="add to favorites"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <VfSvgIcon icon="more" viewBox={24} />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={0}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>VIEW INFO</MenuItem>
          <MenuItem onClick={handleClose}>PROMOTE TO TESTING</MenuItem>
          <MenuItem onClick={handleClose}>PROMOTE TO PRODUCTION</MenuItem>
        </Menu>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = state => ({
  auth: state.authentication,
  envID: state.envs.id,
});

const mapDispatchToProps = dispatch => ({
  setApplication: app => dispatch(setApp(app)),
  getConfigListWidget: payload => dispatch(fetchConfigListWidget(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsItemGrid);
