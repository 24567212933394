import {
  FETCH_APP_VERSIONS_SUCCESS,
  FETCH_APP_VERSIONS_DETAILS_SUCCESS,
  FETCH_APP_VERSIONS,
  FETCH_APP_VERSIONS_FAIL,
  CLOSE_DIALOG,
  SET_VERSION_FROM,
  SET_VERSION_TO,
} from "actions/compareAppVersionsActions";

const initialState = {
  app: {},
  versions: [],
  dialogOpen: false,
  versionFrom: {},
  versionTo: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_APP_VERSIONS_SUCCESS: {
      const versionFrom = payload.find(
        version => version.version === state.app.version
      );

      if (!versionFrom._id) return initialState;

      return {
        ...state,
        versions: payload,
        dialogOpen: true,
        versionFrom,
        versionTo: payload[0],
      };
    }
    case FETCH_APP_VERSIONS_DETAILS_SUCCESS: {
      return {
        ...state,
        data: payload,
      };
    }
    case FETCH_APP_VERSIONS: {
      const { app } = payload;
      return {
        ...state,
        app,
      };
    }
    case FETCH_APP_VERSIONS_FAIL: {
      return initialState;
    }
    case SET_VERSION_FROM: {
      return {
        ...state,
        versionFrom: payload,
      };
    }
    case SET_VERSION_TO: {
      return {
        ...state,
        versionTo: payload,
      };
    }
    case CLOSE_DIALOG: {
      return initialState;
    }
    default:
      return state;
  }
};
