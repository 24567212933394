import React, { useState } from 'react'
import { Button, Menu, MenuItem } from "@material-ui/core";
import VfSvgIcon from 'components/icons/VfSvgIcon';

const PagesList = ({ app, fetchWidgetList }) => {
    const [pages, setPages] = useState();
    const [selectedPage, setSelectedPage] = useState(app?.pages[0]|| null);

    const handleClickPages = event => {
        setPages(event.currentTarget);
    };

    const handleClose = () => {
        setPages(null);
    };

    const handlePageChange = (page) => {
        fetchWidgetList({
            application: app._id,
            envID: app.envId,
            pageId: page._id
        });
        handleClose();
        setSelectedPage(page);
    }
    return selectedPage?
            <>
             <Button
                variant="contained"
                size="small"
                endIcon={<VfSvgIcon icon="chevronDown" fontSize="small" />}
                onClick={handleClickPages}
                data-testid="pageList_btns"
            >
                {selectedPage?.title}
            </Button>
            <Menu
                id="simple-menu"
                elevation={0}
                anchorEl={pages}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(pages)}
                onClose={handleClose}
            >{
                    app.pages.map(page => <MenuItem
                        data-name="page"
                        data-value={null}
                        onClick={e => {
                            handlePageChange(page)
                        }}
                        selected={selectedPage?.title === page.title}
                    >
                        {page.title}
                    </MenuItem>)
                }
            </Menu>
            </>
            : null
}

export default PagesList