/* eslint-disable camelcase */
import axios from "axios";
import getCookie from "utils/getCookie";
import { handleErrorResponse } from "./ErrorHandling";

class ConfigurationService {
  fetchConfigListApp = async ({
    theme,
    appType,
    platform,
    searchText,
    envID,
    type,
  }) => {
    const params = {
      theme,
      appType : JSON.stringify(appType),
      platform: JSON.stringify(platform),
      type,
      searchText,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/configuration/applications",
        params,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config applications service:", err);
      return  handleErrorResponse(err, "Something went wrong!.");
    }
  };

  fetchConfigListWidget = async ({
    application,
    envID,
    searchText,
    pageId
  }) => {
    const params = {
      searchText,
      pageId
    };
    try {
      const data = await axios({
        method: "get",
        url: `/api/appbuilder/configuration/widgets/${application}`,
        params,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config widget list service:", err);
      return err;
    }
  };

  resetConfigApp = async ({ application, envID, access_token, id_token }) => {
    const params = {
      application,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/configuration/reset",
        params,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config widget reset service:", err);
      return err;
    }
  };

  deployConfigApp = async ({
    application,
    componentId,
    envID,
    access_token,
    id_token,
  }) => {
    const params = {
      application,
      componentId,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/configuration/deploy",
        params,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config widget deploy service:", err);
      return  handleErrorResponse(err, "Something went wrong!.");
    }
  };
}

const inst = new ConfigurationService();
export default inst;
