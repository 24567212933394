/* eslint-disable camelcase */
import axios from "axios";
import getCookie from "utils/getCookie";

class HistoryService {
  fetchConfigHistory = async ({
    application,
    componentId,
    envID,
  }) => {
    const params = {
      application,
      componentId,
    };
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/history",
        params,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config widget list service:", err);
      return err;
    }
  };
}

const inst = new HistoryService();
export default inst;
