/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

const jp = require("jsonpath");

const schemaToTreeBpmn = (name, id, schema, parentData) => {
  const tree = {
    name,
    id,
    children: [],
  };
  if (schema instanceof Array) {
    let i = 1;
    for (const itemSchema of schema) {
      let name = itemSchema.title || `Item ${i}`;
      let subTree = schemaToTreeBpmn(
        name,
        `${id}[${i - 1}]`,
        itemSchema,
        parentData
      );
      tree.children.push(subTree);
      i++;
    }
  } else {
    let properties = schema.properties || schema.items;
    for (let property in properties) {
      let propertySpec = properties[property];
      let name = property;
      let { type } = propertySpec;
      switch (type) {
        case "object":
          {
            let subTree = schemaToTreeBpmn(
              name,
              `${id}.${property}.children`,
              propertySpec,
              parentData
            );
            tree.children.push(subTree);
          }
          break;

        case "array":
          {
            let arraySchema = propertySpec.items;
            let subTree;
            if (arraySchema instanceof Array) {
              subTree = schemaToTreeBpmn(
                name,
                `${id}.${property}`,
                arraySchema,
                parentData
              );
            } else {
              let id1 = `${id}.${property}.elements`;
              let data = jp.query(parentData, id1)[0];

              if (data?.length) {
                subTree = {
                  name,
                  showArray: true,
                  id: id1,
                  childrenSchema: arraySchema,
                  children: data.map((e, i) =>
                    schemaToTreeBpmn(
                      `Item ${i}`,
                      arraySchema.type === "object"
                        ? `${id1}[${i}].children`
                        : `${id1}[${i}]`,
                      arraySchema,
                      parentData
                    )
                  ),
                };
              } else {
                subTree = {
                  name,
                  showArray: true,
                  id: id1,
                  childrenSchema: arraySchema,
                  children: [],
                };
              }
            }
            if (subTree) {
              tree.children.push(subTree);
            }
          }
          break;

        default:
          tree.children.push({
            name,
            id: `${id}.${property}`,
          });

          break;
      }
    }
  }

  return tree;
};

export default schemaToTreeBpmn;
