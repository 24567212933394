/* eslint-disable camelcase */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Zoom from "@material-ui/core/Zoom";
import Lottie from "react-lottie";
import theme from "AppTheme";
import animationData404 from "assets/animations/404-3.json";

import notfoundleftimage from "assets/images/404-bottom-left-icon.svg";
import notfoundrightimage from "assets/images/404-top-right-icon.svg";

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.common.white,
    minHeight: "100vh",
  },
  box: {
    minHeight: `calc(100vh)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(3),
    position: "relative",
    overflow: "hidden",
  },
  boxInner: {
    position: "relative",
    textAlign: "center",
    maxWidth: 480,
    width: "100%",
  },
  btn: {
    marginTop: theme.spacing(3),
  },
  img: {
    maxWidth: 480,
    width: "100%",
    height: "auto",
    marginBottom: theme.spacing(3),
  },
  imgLeft: {
    maxWidth: 480,
    width: "100%",
    height: "auto",
    position: "absolute",
    bottom: 0,
    left: 0,
    display: "flex",
    "& img": {
      flexGrow: 1,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 360,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 240,
    },
  },
  imgRight: {
    maxWidth: 480,
    width: "100%",
    height: "auto",
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    "& img": {
      flexGrow: 1,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 360,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 240,
    },
  },
});

const NotFound = () => {
  const classes = useStyles();
  const [checked] = useState(true);
  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData404,
  };

  return (
    <main className={classes.root}>
      <Box className={classes.box}>
        <Box className={classes.imgRight}>
          <Slide
            direction="left"
            in={checked}
            mountOnEnter
            unmountOnExit
            timeout={{
              appear: 100,
              enter: 800,
              exit: 800,
            }}
          >
            <img alt="Page not found" src={notfoundrightimage} />
          </Slide>
        </Box>
        <Box className={classes.imgLeft}>
          <Slide
            direction="right"
            in={checked}
            mountOnEnter
            unmountOnExit
            timeout={{
              appear: 100,
              enter: 800,
              exit: 800,
            }}
          >
            <img alt="Page not found" src={notfoundleftimage} />
          </Slide>
        </Box>
        <Box className={classes.boxInner}>
          <Zoom
            in={checked}
            timeout={{
              appear: 1100,
              enter: 1200,
              exit: 800,
            }}
          >
            <Lottie options={defaultOptions} height={400} width={480} />
          </Zoom>
          <Typography component="h1" variant="h3">
            Page not found
          </Typography>
          <Typography component="h2" variant="body1">
            Sorry we can’t find the page you are looking for.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            onClick={() => history.push("/")}
          >
            Back to Home
          </Button>
        </Box>
      </Box>
    </main>
  );
};

export default NotFound;
