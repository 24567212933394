/* eslint-disable camelcase */
import { Box, Container, Grid, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTheme } from "react-jsonschema-form";
import FormTheme from "components/custom-mui-components/FormTheme";
import { fetchPages } from "actions/applicationsActions";
import { makeStyles } from "@material-ui/core/styles";

const Form = withTheme(FormTheme);

const useStyles = makeStyles({
  root: {
    '& div[role="radiogroup"]': {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
    "& .rjsf": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "230px",
    },
  },
});

const MenuUrlBox = ({
  onChange,
  setDialogIsOpen,
  inputValue,
  setInputValue,
  getAppPages,
  pages,
  auth,
  currentAppId,
}) => {
  useEffect(() => {
    const { access_token, id_token } = auth;
    getAppPages({ access_token, id_token, application: currentAppId });
  }, []);

  const classes = useStyles();

  const appPagesNames = pages.map(p => p.title);
  const appPagesIds = pages.map(p => (p.isHomePage ? `/` : `/page/${p._id}/`));
  const schema = {
    type: "object",
    oneOf: [
      {
        title: "Existing route",
        properties: {
          ExistingRoute: {
            type: "string",
            enum: appPagesIds,
            enumNames: appPagesNames,
          },
        },
        required: ["ExistingRoute"],
      },
      {
        title: "Widget internal route",
        properties: {
          PagesRoute: {
            type: "string",
            enum: appPagesIds,
            enumNames: appPagesNames,
          },
          RestInternalRoute: {
            type: "string",
            default: "",
          },
        },
        required: ["PagesRoute"],
      },
      {
        title: "External URL",
        properties: {
          ExternalURL: {
            type: "string",
          },
        },
        required: ["ExternalURL"],
      },
    ],
  };

  const uiSchema = {
    "ui:widget": "radio",
  };

  const ObjectFieldTemplate = ({ properties }) => {
    return (
      <>
        <Grid container spacing={4}>
          {properties.map((element, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item xs={12} key={index}>
              {element.content}
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Box py={2} className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Form
              schema={schema}
              uiSchema={uiSchema}
              formData={inputValue}
              ObjectFieldTemplate={ObjectFieldTemplate}
              showErrorList={false}
              onSubmit={formData => {
                if (Object.keys(formData.formData).length > 1) {
                  onChange(
                    `${Object.values(formData.formData)[1]}${
                      Object.values(formData.formData)[0]
                    }`
                  );
                } else {
                  onChange(Object.values(formData.formData)[0]);
                }
                setInputValue(formData.formData);
                setDialogIsOpen(false);
              }}
            >
              <Box pt={2}>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={3}
                >
                  <Grid item xs="auto">
                    <Button
                      variant="outlined"
                      onClick={() => setDialogIsOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs="auto">
                    <Button type="submit" variant="outlined" color="secondary">
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    pages: state.applications.current.pages,
    auth: state.authentication,
    currentAppId: state.configuration.currentApp._id,
  };
};

const mapDispatchToProps = dispatch => ({
  getAppPages: payload => dispatch(fetchPages(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuUrlBox);
