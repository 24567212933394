/* eslint-disable no-prototype-builtins */
import React, { useRef, useState, useEffect } from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import {
  TextField,
  Paper,
  Popper,
  Fade,
  MenuItem,
  ClickAwayListener,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { is } from "bpmn-js/lib/util/ModelUtil";
import { get, set } from "lodash";

import VfSvgIcon from "../icons/VfSvgIcon";
import { schemaToTreeForBpmn } from "./ElementsUtils";
import theme from "../../AppTheme";

const { parseScript } = require("shift-parser");

const useStyles = makeStyles({
  popperPaper: {
    padding: theme.spacing(0, 2),
    maxHeight: theme.spacing(28),
    overflowY: "auto",
  },
  textFieldSelect: {
    minWidth: theme.spacing(20),
    "& .MuiOutlinedInput-notchedOutline": {
      borderRightColor: "transparent",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  textFieldTree: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
});

const CallPutTreeViewField = ({
  title,
  element,
  modeler,
  selectedEffect,
  setSelectedEffect,
  fieldIndex,
  paramProperty,
  paramName,
  fValue,
  valueType,
}) => {
  const selectRef = useRef();
  const styles = useStyles();
  const [type, setType] = useState(valueType || "dynamic");
  const [expanded, setExpanded] = useState(["$.state"]);
  const [treeData, setTreeData] = useState([]);
  const [searchTreeData, setSearchTreeData] = useState([]);

  const [value, setValue] = useState(
    type === "dynamic" && fValue ? `context.${fValue}` : fValue || ""
  );
  const [isValid, setIsValid] = useState({ outcome: true });
  const moveFocusToInput = () => {
    selectRef.current.focus();
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const search = (items, term) => {
    return items.reduce((acc, item) => {
      if (item.id.toLowerCase().indexOf(term.toLowerCase()) >= 0) {
        const newExpaned = expanded;
        newExpaned.unshift(item.id);
        setExpanded(newExpaned);
        acc.push(item);
      } else if (item.children && item.children.length > 0) {
        const newItems = search(item.children, term);
        if (newItems && newItems.length > 0) {
          const newExpaned = expanded;
          newExpaned.unshift(item.id);
          setExpanded(newExpaned);
          acc.push({
            id: item.id,
            name: item.name,
            children: newItems,
          });
        }
      }
      return acc;
    }, []);
  };

  const isValidJavascript = text => {
    const jsText = `x = (${text})`;
    try {
      // eslint-disable-next-line no-unused-vars
      const ast = parseScript(jsText);

      return { outcome: true };
    } catch (ex) {
      return { outcome: false, reason: `${ex}` };
    }
  };

  const handleInputChange = e => {
    const term = e.target.value;
    if (type === "javascript")
      setIsValid({ ...isValidJavascript(e.target.value) });
    if (!term) setSearchTreeData(treeData);
    setValue(term);
    const r = search(treeData, term);
    setSearchTreeData(r);
  };

  const handleClickNode = id => {
    setValue(id.substring(2));
  };

  const renderTree = node => {
    return (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        label={<Typography variant="body2">{node.name}</Typography>}
        onClick={() => {
          handleClickNode(node.id);
          moveFocusToInput();
        }}
      >
        {Array.isArray(node.children)
          ? node.children.map(n => renderTree(n))
          : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    if (
      selectedEffect?.effect === "call-api" ||
      selectedEffect?.effect === "put"
    ) {
      const newArray = [
        ...(!paramProperty
          ? selectedEffect.arguments.children[paramName].values
          : get(selectedEffect.arguments, `${paramProperty}.values`)),
      ];
      if (type === "dynamic") {
        if (newArray[fieldIndex].hasOwnProperty("literal"))
          delete newArray[fieldIndex].literal;
        if (newArray[fieldIndex].hasOwnProperty("code"))
          delete newArray[fieldIndex].code;
        newArray[fieldIndex].path = value.substring(8);
      } else if (type === "static") {
        if (newArray[fieldIndex].hasOwnProperty("path"))
          delete newArray[fieldIndex].path;
        if (newArray[fieldIndex].hasOwnProperty("code"))
          delete newArray[fieldIndex].code;
        newArray[fieldIndex].literal = value;
      } else if (type === "javascript") {
        if (newArray[fieldIndex].hasOwnProperty("path"))
          delete newArray[fieldIndex].path;
        if (newArray[fieldIndex].hasOwnProperty("literal"))
          delete newArray[fieldIndex].literal;
        newArray[fieldIndex].code = value;
      }
      let obj = {};
      if (paramProperty) {
        obj = {
          ...selectedEffect.arguments,
        };
        set(obj, `${paramProperty}.values`, newArray);
      } else {
        obj = { ...selectedEffect.arguments };
        obj.children[paramName].values = newArray;
      }
      setSelectedEffect({
        ...selectedEffect,
        arguments: {
          ...selectedEffect.arguments,
          ...obj,
        },
      });
    }
  }, [value, type]);

  // useEffect(() => {
  //   console.log(JSON.stringify(selectedEffect.arguments));
  // }, [selectedEffect.arguments]);

  useEffect(() => {
    if (!element) return;
    if (
      !treeData.length &&
      (selectedEffect.effect === "call-api" || selectedEffect.effect === "put")
    ) {
      setExpanded(["$.context"]);
      const elementRegistry = modeler
        .get("elementRegistry")
        .filter(function(e) {
          return (
            e.type !== "label" &&
            (is(e, "bpmn:ScriptTask") ||
              is(e, "bpmn:StartEvent") ||
              is(e, "bpmn:EndEvent") ||
              is(e, "bpmn:ServiceTask") ||
              is(e, "bpmn:Gateway"))
          );
        });
      let schema = {
        title: "Context",
        type: "object",
        properties: {},
      };
      elementRegistry.forEach(elem => {
        const name = elem.businessObject.get("name");
        const elementSchema = elem.businessObject.get("custom:contextSchema");
        if (name && elementSchema)
          schema = {
            ...schema,
            properties: {
              ...schema.properties,
              [name]: JSON.parse(elementSchema),
            },
          };
      });
      const tree = schemaToTreeForBpmn(schema, "$.context");
      setTreeData([tree]);
      setSearchTreeData([tree]);
    }
  }, [element, selectedEffect]);
  return (
    <>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Box position="relative" zIndex={10} display="flex">
          <TextField
            select
            label={title}
            type="text"
            inputRef={selectRef}
            value={type}
            onChange={e => setType(e.target.value)}
            variant="outlined"
            color="primary"
            className={styles.textFieldSelect}
          >
            <MenuItem value="static">Static</MenuItem>
            <MenuItem value="dynamic">Dynamic</MenuItem>
            <MenuItem value="javascript">Javascript</MenuItem>
          </TextField>
          <TextField
            type="text"
            inputRef={selectRef}
            value={value}
            onChange={handleInputChange}
            variant="outlined"
            color="primary"
            fullWidth
            className={styles.textFieldTree}
            InputProps={{
              onFocus: event => setAnchorEl(event.currentTarget),
            }}
            error={!isValid.outcome}
            helperText={isValid.reason || ""}
          />
          <Popper
            open={Boolean(anchorEl)}
            placement="bottom"
            disablePortal
            transition
            style={{
              left: 0,
              right: 0,
              position: "absolute",
              top: "100%",
              zIndex: 1,
            }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper elevation={3} className={styles.popperPaper}>
                  {type === "dynamic" && (
                    <TreeView
                      defaultCollapseIcon={
                        <VfSvgIcon icon="chevronDown" fontSize="small" />
                      }
                      defaultExpandIcon={
                        <VfSvgIcon icon="chevronRight" fontSize="small" />
                      }
                      expanded={expanded}
                      onNodeToggle={(e, newValue) => setExpanded(newValue)}
                    >
                      {Array.isArray(treeData)
                        ? searchTreeData.map(node => renderTree(node))
                        : null}
                    </TreeView>
                  )}
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default CallPutTreeViewField;
