const SET_APPLICATION = "SET_APPLICATION";
const CLEAR_APPLICATION = "CLEAR_APPLICATION";
const SET_APPLICATION_ENVIRONMENT = "SET_APPLICATION_ENVIRONMENT";
const CLEAR_APPLICATION_ENVIRONMENT = "CLEAR_APPLICATION_ENVIRONMENT";
const SHOW_APPLICATION = "SHOW_APPLICATION";
const APPLICATIONS_REQUEST = "APPLICATIONS_REQUEST";
const APPLICATIONS_REQUEST_SUCCESS = "APPLICATIONS_REQUEST_SUCCESS";
const APPLICATIONS_REQUEST_FAIL = "APPLICATIONS_REQUEST_FAIL";
const SET_APPLICATIONS_VIEW = "SET_APPLICATIONS_VIEW";
const CREATE_APPLICATION_REQUEST = "CREATE_APPLICATION_REQUEST";
const CREATE_APPLICATION_REQUEST_SUCCESS = "CREATE_APPLICATION_REQUEST_SUCCESS";
const CREATE_APPLICATION_REQUESTT_FAIL = "CREATE_APPLICATION_REQUEST_FAIL";
const SHOW_CREATE_APPLICATION_DIALOG = "SHOW_CREATE_APPLICATION_DIALOG";
const VALIDATE_NAME_REQUEST_SUCCESS = "VALIDATE_NAME_REQUEST_SUCCESS";
const VALIDATE_NAME_REQUESTT_FAIL = "VALIDATE_NAME_REQUESTT_FAIL";
const VALIDATE_NAME_REQUEST = "VALIDATE_NAME_REQUEST";
const SHOW_ADD_PAGE = "SHOW_ADD_PAGE";
const SHOW_ADD_TEMPLATE = "SHOW_ADD_TEMPLATE";
const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const DJSL_DELETE_COMPONENT = "DJSL_DELETE_COMPONENT";
const DJSL_ADD_COMPONENT = "DJSL_ADD_COMPONENT";
const DJSL_EDIT_COMPONENT = "DJSL_EDIT_COMPONENT";
const SET_BPMN_OF_SAGA = "SET_BPMN_OF_SAGA";
const SET_NAME_OF_SAGA = "SET_NAME_OF_SAGA";
const REMOVE_SAGA = "REMOVE_SAGA";
const ADD_NEW_SAGA = "ADD_NEW_SAGA";
const ADD_NEW_API = "ADD_NEW_API";
const EDIT_API = "EDIT_API";
const REMOVE_API = "REMOVE_API";
const ADD_NEW_PACKAGE = "ADD_NEW_PACKAGE";
const EDIT_PACKAGE = "EDIT_PACKAGE";
const REMOVE_PACKAGE = "REMOVE_PACKAGE";
const ADD_NEW_SNIPPET = "ADD_NEW_SNIPPET";
const REMOVE_SNIPPET = "REMOVE_SNIPPET";
const ADD_PAGE = "ADD_PAGE";
const CLEAR_PAGE = "CLEAR_PAGE";
const CLEAR_PAGES = "CLEAR_PAGES";
const DEPLOY_APPLICATION_REQUEST = "DEPLOY_APPLICATION_REQUEST";
const DEPLOY_APPLICATION_REQUEST_SUCCESS = "DEPLOY_APPLICATION_REQUEST_SUCCESS";
const UNDEPLOY_APPLICATION_REQUEST = "UNDEPLOY_APPLICATION_REQUEST";
const UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST =
  "UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST";
const UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST_SUCCESS =
  "UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST_SUCCESS";
const UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST_ERROR =
  "UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST_ERROR";
const CLEAR_DEPLOYMENT_INFO = "CLEAR_DEPLOYMENT_INFO";
const SAVE_PAGE_REQUEST = "SAVE_PAGE_REQUEST";
const SAVE_PAGE_REQUEST_SUCCESS = "SAVE_PAGE_REQUEST_SUCCESS";
const SAVE_PAGE_REQUEST_FAIL = "SAVE_PAGE_REQUEST_FAIL";
const SAVE_APP_VERSION_REQUEST = "SAVE_APP_VERSION_REQUEST";
const SAVE_APP_VERSION_REQUEST_SUCCESS = "SAVE_APP_VERSION_REQUEST_SUCCESS";
const SAVE_APP_VERSION_REQUEST_FAIL = "SAVE_APP_VERSION_REQUEST_FAIL";
const GET_APP_LATEST_VERSION_REQUEST = "GET_APP_LATEST_VERSION_REQUEST";
const GET_APP_LATEST_VERSION_REQUEST_SUCCESS =
  "GET_APP_LATEST_VERSION_REQUEST_SUCCESS";
const GET_APP_LATEST_VERSION_REQUEST_FAIL =
  "GET_APP_LATEST_VERSION_REQUEST_FAIL";
const CLEAR_APP_LATEST_VERSION = "CLEAR_APP_LATEST_VERSION";
const GET_PAGES_REQUEST = "GET_PAGES_REQUEST";
const GET_PAGES_REQUEST_SUCCESS = "GET_PAGES_REQUEST_SUCCESS";
const GET_PAGES_REQUEST_FAIL = "GET_PAGES_REQUEST_FAIL";
const SHOW_APPLICATION_PAGES = "SHOW_APPLICATION_PAGES";
const SHOW_APPLICATION_SIDEBAR = "SHOW_APPLICATION_SIDEBAR";
const SHOW_APPLICATION_CREATE_PAGE = "SHOW_APPLICATION_CREATE_PAGE";
const SET_SELECTED_WIDGET = "SET_SELECTED_WIDGET";
const CLEAR_SELECTED_WIDGET = "CLEAR_SELECTED_WIDGET";
const SET_SELECTED_WIDGET_VERSIONS = "SET_SELECTED_WIDGET_VERSIONS";
const CLEAR_SELECTED_WIDGET_VERSIONS = "CLEAR_SELECTED_WIDGET_VERSIONS";
const SET_APPLICATION_HOMEPAGE_REQUEST = "SET_APPLICATION_HOMEPAGE_REQUEST";
const SET_APPLICATION_HOMEPAGE_REQUEST_SUCCESS =
  "SET_APPLICATION_HOMEPAGE_REQUEST_SUCCESS";
const SET_APPLICATION_HOMEPAGE_REQUEST_FAIL =
  "SET_APPLICATION_HOMEPAGE_REQUEST_FAIL";
const DELETE_PAGE_REQUEST = "DELETE_PAGE_REQUEST";
const DELETE_PAGE_REQUEST_SUCCESS = "DELETE_PAGE_REQUEST_SUCCESS";
const DELETE_PAGE_REQUEST_FAIL = "DELETE_PAGE_REQUEST_FAIL";
const SHOW_SNACKBAR = "SHOW_SNACKBAR";
const SET_CURRENT_ROW_COL = "SET_CURRENT_ROW_COL";
const CLEAR_CURRENT_ROW_COL = "CLEAR_CURRENT_ROW_COL";
const DELETE_UNMANAGED_APP_REQUEST = "DELETE_REFERENCE_APP_REQUEST";
const DELETE_UNMANAGED_APP_REQUEST_SUCCESS =
  "DELETE_REFERENCE_APP_REQUEST_SUCCESS";
const DELETE_UNMANAGED_APP_REQUEST_FAIL = "DELETE_REFERENCE_APP_REQUEST_FAIL";
const SET_APPLICATION_SECTION = "SET_APPLICATION_SECTION";
const SET_SIDEBAR_ITEM = "SET_SIDEBAR_ITEM";

const DELETE_APP_REQUEST = "DELETE_APP_REQUEST";
const DELETE_APP_REQUEST_SUCCESS = "DELETE_APP_REQUEST_SUCCESS";
const DELETE_APP_REQUEST_FAIL = "DELETE_APP_REQUEST_FAIL";
const CLEAR_DELETE_APP_ERROR = "CLEAR_DELETE_APP_ERROR";
const ADD_SECURITY_DEFS_API = "ADD_SECURITY_DEF_API";
const UPDATE_SNIPPET = "UPDATE_SNIPPET";
const RESOLVE_PUBLIC_PACKAGE = "RESOLVE_PUBLIC_PACKAGE";
const RESOLVE_PUBLIC_PACKAGE_SUCCESS = "RESOLVE_PUBLIC_PACKAGE_SUCCESS";
const RESOLVE_PUBLIC_PACKAGE_FAIL = "RESOLVE_PUBLIC_PACKAGE_FAIL";
const SHOW_TENANT_SELECTION_DIALOG = "SHOW_TENANT_SELECTION_DIALOG";

const fetchApps = payload => ({
  type: APPLICATIONS_REQUEST,
  payload,
});

const setShowApp = payload => ({
  type: SHOW_APPLICATION,
  payload,
});

const setShowEditApp = payload => ({
  type: SHOW_CREATE_APPLICATION_DIALOG,
  payload,
});

const setAppsView = payload => ({
  type: SET_APPLICATIONS_VIEW,
  payload,
});

const setApp = payload => ({
  type: SET_APPLICATION,
  payload,
});

const clearApp = () => ({
  type: CLEAR_APPLICATION,
});

const setAppEnv = payload => ({
  type: SET_APPLICATION_ENVIRONMENT,
  payload,
});

const clearAppEnv = () => ({
  type: CLEAR_APPLICATION_ENVIRONMENT,
});

const createApplication = payload => ({
  type: CREATE_APPLICATION_REQUEST,
  payload,
});

const validateAppName = payload => ({
  type: VALIDATE_NAME_REQUEST,
  payload,
});

const setShowAddPage = payload => ({
  type: SHOW_ADD_PAGE,
  payload,
});

const setShowAddTemplate = payload => ({
  type: SHOW_ADD_TEMPLATE,
  payload,
});

const setCurrentPage = payload => ({
  type: SET_CURRENT_PAGE,
  payload,
});

const djslDeleteComponent = payload => ({
  type: DJSL_DELETE_COMPONENT,
  payload,
});

const djslAddComponent = payload => ({
  type: DJSL_ADD_COMPONENT,
  payload,
});

const djslEditComponent = payload => ({
  type: DJSL_EDIT_COMPONENT,
  payload,
});

const setBpmnOfSaga = payload => ({
  type: SET_BPMN_OF_SAGA,
  payload,
});

const setNameOfSaga = payload => ({
  type: SET_NAME_OF_SAGA,
  payload,
});

const removeSaga = payload => ({
  type: REMOVE_SAGA,
  payload,
});

const addNewSaga = payload => ({
  type: ADD_NEW_SAGA,
  payload,
});

const addNewApi = payload => ({
  type: ADD_NEW_API,
  payload,
});

const editApi = payload => ({
  type: EDIT_API,
  payload,
});

const removeApi = payload => ({
  type: REMOVE_API,
  payload,
});

const addNewPackage = payload => ({
  type: ADD_NEW_PACKAGE,
  payload,
});

const editPackage = payload => ({
  type: EDIT_PACKAGE,
  payload,
});

const removePackage = payload => ({
  type: REMOVE_PACKAGE,
  payload,
});

const addNewSnippet = payload => ({
  type: ADD_NEW_SNIPPET,
  payload,
});

const removeSnippet = payload => ({
  type: REMOVE_SNIPPET,
  payload,
});

const clearCurrentPage = () => ({
  type: SET_CURRENT_PAGE,
});

const addPage = () => ({
  type: ADD_PAGE,
});

const clearPage = payload => ({
  type: CLEAR_PAGE,
  payload,
});

const clearPages = () => ({
  type: CLEAR_PAGES,
});

const deployApplication = payload => ({
  type: DEPLOY_APPLICATION_REQUEST,
  payload,
});

const undeployApplication = payload => ({
  type: UNDEPLOY_APPLICATION_REQUEST,
  payload,
});

const updateDeployedAppSecurityGroups = payload => ({
  type: UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST,
  payload,
});

const clearDeploymentInfo = () => ({
  type: CLEAR_DEPLOYMENT_INFO,
});

const savePage = payload => ({
  type: SAVE_PAGE_REQUEST,
  payload,
});

const fetchPages = payload => ({
  type: GET_PAGES_REQUEST,
  payload,
});

const showPages = payload => ({
  type: SHOW_APPLICATION_PAGES,
  payload,
});

const saveAppVersion = payload => ({
  type: SAVE_APP_VERSION_REQUEST,
  payload,
});

const getAppLatestVersion = payload => ({
  type: GET_APP_LATEST_VERSION_REQUEST,
  payload,
});

const clearAppLatestVersion = () => ({
  type: CLEAR_APP_LATEST_VERSION,
});

const showSideBar = payload => ({
  type: SHOW_APPLICATION_SIDEBAR,
  payload,
});

const showCreatePage = payload => ({
  type: SHOW_APPLICATION_CREATE_PAGE,
  payload,
});

const setSelectedWidget = payload => ({
  type: SET_SELECTED_WIDGET,
  payload,
});

const clearSelectedWidget = () => ({
  type: CLEAR_SELECTED_WIDGET,
});

const setSelectedWidgetVersions = payload => ({
  type: SET_SELECTED_WIDGET_VERSIONS,
  payload,
});

const clearSelectedWidgetVersions = () => ({
  type: CLEAR_SELECTED_WIDGET_VERSIONS,
});

const setApplicationHomepage = payload => ({
  type: SET_APPLICATION_HOMEPAGE_REQUEST,
  payload,
});

const deletePage = payload => ({
  type: DELETE_PAGE_REQUEST,
  payload,
});

const showSnackbar = payload => ({
  type: SHOW_SNACKBAR,
  payload,
});

const setCurrRowCol = payload => ({
  type: SET_CURRENT_ROW_COL,
  payload,
});

const clearCurrRowCol = () => ({
  type: CLEAR_CURRENT_ROW_COL,
});

const deleteUnmanagedApp = payload => ({
  type: DELETE_UNMANAGED_APP_REQUEST,
  payload,
});

const setApplicationSection = payload => ({
  type: SET_APPLICATION_SECTION,
  payload,
});

const setAppSidebar = payload => ({
  type: SET_SIDEBAR_ITEM,
  payload,
});

const deleteApp = payload => ({
  type: DELETE_APP_REQUEST,
  payload,
});

const clearDeleteAppError = () => ({
  type: CLEAR_DELETE_APP_ERROR,
});

const addSecurityDefs = payload => ({
  type: ADD_SECURITY_DEFS_API,
  payload,
});

const updateSnippet = payload => ({
  type: UPDATE_SNIPPET,
  payload,
});

const resolvePublicPackage = (appId, pack) => ({
  type: RESOLVE_PUBLIC_PACKAGE,
  payload: {
    appId,
    pack,
  },
});

const showTenantSelectionDialog = payload => ({
  type: SHOW_TENANT_SELECTION_DIALOG,
  payload,
});

export {
  SET_APPLICATION,
  CLEAR_APPLICATION,
  SET_APPLICATION_ENVIRONMENT,
  CLEAR_APPLICATION_ENVIRONMENT,
  SHOW_APPLICATION,
  APPLICATIONS_REQUEST,
  APPLICATIONS_REQUEST_SUCCESS,
  APPLICATIONS_REQUEST_FAIL,
  SET_APPLICATIONS_VIEW,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_REQUEST_SUCCESS,
  CREATE_APPLICATION_REQUESTT_FAIL,
  SHOW_CREATE_APPLICATION_DIALOG,
  VALIDATE_NAME_REQUEST_SUCCESS,
  VALIDATE_NAME_REQUESTT_FAIL,
  VALIDATE_NAME_REQUEST,
  SHOW_ADD_PAGE,
  SHOW_ADD_TEMPLATE,
  SET_CURRENT_PAGE,
  DJSL_DELETE_COMPONENT,
  DJSL_ADD_COMPONENT,
  DJSL_EDIT_COMPONENT,
  ADD_PAGE,
  CLEAR_PAGE,
  CLEAR_PAGES,
  DEPLOY_APPLICATION_REQUEST,
  DEPLOY_APPLICATION_REQUEST_SUCCESS,
  UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST,
  UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST_SUCCESS,
  UPDATE_DEPLOYED_APP_SECURITY_GROUPS_REQUEST_ERROR,
  CLEAR_DEPLOYMENT_INFO,
  UNDEPLOY_APPLICATION_REQUEST,
  SAVE_PAGE_REQUEST,
  SAVE_PAGE_REQUEST_SUCCESS,
  SAVE_PAGE_REQUEST_FAIL,
  SAVE_APP_VERSION_REQUEST,
  SAVE_APP_VERSION_REQUEST_SUCCESS,
  SAVE_APP_VERSION_REQUEST_FAIL,
  GET_APP_LATEST_VERSION_REQUEST,
  GET_APP_LATEST_VERSION_REQUEST_SUCCESS,
  GET_APP_LATEST_VERSION_REQUEST_FAIL,
  CLEAR_APP_LATEST_VERSION,
  GET_PAGES_REQUEST,
  GET_PAGES_REQUEST_SUCCESS,
  GET_PAGES_REQUEST_FAIL,
  SHOW_APPLICATION_PAGES,
  SHOW_APPLICATION_SIDEBAR,
  SHOW_APPLICATION_CREATE_PAGE,
  SET_SELECTED_WIDGET,
  CLEAR_SELECTED_WIDGET,
  SET_SELECTED_WIDGET_VERSIONS,
  CLEAR_SELECTED_WIDGET_VERSIONS,
  SET_APPLICATION_HOMEPAGE_REQUEST,
  SET_APPLICATION_HOMEPAGE_REQUEST_SUCCESS,
  SET_APPLICATION_HOMEPAGE_REQUEST_FAIL,
  DELETE_PAGE_REQUEST,
  DELETE_PAGE_REQUEST_SUCCESS,
  DELETE_PAGE_REQUEST_FAIL,
  SHOW_SNACKBAR,
  SET_CURRENT_ROW_COL,
  CLEAR_CURRENT_ROW_COL,
  DELETE_UNMANAGED_APP_REQUEST,
  DELETE_UNMANAGED_APP_REQUEST_SUCCESS,
  DELETE_UNMANAGED_APP_REQUEST_FAIL,
  SET_APPLICATION_SECTION,
  SET_SIDEBAR_ITEM,
  DELETE_APP_REQUEST,
  DELETE_APP_REQUEST_SUCCESS,
  DELETE_APP_REQUEST_FAIL,
  CLEAR_DELETE_APP_ERROR,
  ADD_SECURITY_DEFS_API,
  UPDATE_SNIPPET,
  RESOLVE_PUBLIC_PACKAGE,
  RESOLVE_PUBLIC_PACKAGE_SUCCESS,
  RESOLVE_PUBLIC_PACKAGE_FAIL,
  SET_BPMN_OF_SAGA,
  SET_NAME_OF_SAGA,
  REMOVE_SAGA,
  ADD_NEW_SAGA,
  ADD_NEW_API,
  REMOVE_API,
  EDIT_API,
  ADD_NEW_PACKAGE,
  EDIT_PACKAGE,
  REMOVE_PACKAGE,
  ADD_NEW_SNIPPET,
  REMOVE_SNIPPET,
  SHOW_TENANT_SELECTION_DIALOG,
  fetchApps,
  setShowApp,
  setAppsView,
  setApp,
  clearApp,
  setAppEnv,
  clearAppEnv,
  createApplication,
  setShowEditApp,
  validateAppName,
  setShowAddPage,
  setShowAddTemplate,
  setCurrentPage,
  djslDeleteComponent,
  djslEditComponent,
  djslAddComponent,
  clearCurrentPage,
  addPage,
  clearPage,
  clearPages,
  deployApplication,
  undeployApplication,
  updateDeployedAppSecurityGroups,
  clearDeploymentInfo,
  savePage,
  fetchPages,
  showPages,
  showSideBar,
  showCreatePage,
  setSelectedWidget,
  clearSelectedWidget,
  setSelectedWidgetVersions,
  clearSelectedWidgetVersions,
  setApplicationHomepage,
  deletePage,
  showSnackbar,
  clearCurrRowCol,
  setCurrRowCol,
  deleteUnmanagedApp,
  setApplicationSection,
  setAppSidebar,
  deleteApp,
  clearDeleteAppError,
  addSecurityDefs,
  updateSnippet,
  saveAppVersion,
  getAppLatestVersion,
  clearAppLatestVersion,
  resolvePublicPackage,
  setBpmnOfSaga,
  setNameOfSaga,
  removeSaga,
  addNewSaga,
  addNewApi,
  editApi,
  removeApi,
  addNewPackage,
  editPackage,
  removePackage,
  addNewSnippet,
  removeSnippet,
  showTenantSelectionDialog,
};
