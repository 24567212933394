/* eslint-disable no-underscore-dangle */
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import Ajv from "ajv";
import { cloneDeep } from "lodash";
import jp from "jsonpath";
import { setProps } from "actions/propActions";
import { NEXUS_WIDGETS_REQUEST_SUCCESS } from "actions/nexusWidgetsActions";
import { render, registry } from "renderer";
import { Grid as MaterialGrid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import atomics from "vf-comps/build/components";
import * as DCL from "@vf-dcl/dcl-components";
import { MVA10 } from "./utils/constants";

const theme = "MVA10";

const Grid =
  theme === MVA10 ? props => <MaterialGrid container {...props} /> : DCL.Grid;

const GridItem =
  theme === MVA10 ? props => <MaterialGrid item {...props} /> : DCL.Grid.Item;

const widgets = [
  {
    _id: `Widget-custom`,
    name: "Custom",
    version: "1.0.0",
    djr: {
      __component: "Fragment",
      __props: {
        children: [
          {
            __component: "Typography",
            __props: {
              variant: "h1",
              text: "I am Custom",
            },
          },
          {
            __component: "Typography",
            __props: {
              variant: "h2",
            },
            __mapStateToProps: {
              text: "props.my.text",
            },
          },
          {
            __component: "Typography",
            __props: {
              variant: "body",
            },
            __mapStateToProps: {
              text: "props.my.text",
            },
          },
        ],
      },
    },
    propsSchema: {
      type: "object",
      properties: {
        text: {
          type: "string",
        },
      },
    },
    author: "vfteam",
    description: "Custom DJR",
    images: [],
    appFramework: {
      "widget-category": "Atomics",
      theme: [MVA10],
      author: "vfteam",
      description: "Custom DJR",
    },
  },
];

const specializeForId = (djrOrig, id) => {
  const djr = cloneDeep(djrOrig);
  jp.apply(djr, `$..__mapStateToProps`, msp => {
    Object.entries(msp).forEach(([k, v]) => {
      if (v.startsWith(`props.my.`)) {
        // eslint-disable-next-line no-param-reassign
        msp[k] = v.replaceAll("props.my.", `props.${id}.`);
      }
    });
    return msp;
  });
  return djr;
};

const DjrComponent = ({ name, id, ...props }) => {
  const componentJson = widgets.find(w => w.name === name).djr;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setProps(id, props));
  }, [props, id, dispatch]);
  const djr = specializeForId(componentJson, id);
  return render(djr);
};

// eslint-disable-next-line no-plusplus
for (let i = 0; i < widgets.length; i++) {
  const w = widgets[i];
  // eslint-disable-next-line no-param-reassign
  w.component = DjrComponent;
  registry[w.name] = w.component;
}

const linkSchema = {
  title: "Link",
  type: "object",
  properties: {
    to: {
      type: "string",
    },
  },
};

const gridItemPropSchema = {
  title: "GridItem",
  type: "object",
  required: ["xs", "sm", "md"],
  properties: {
    xs: {
      oneOf: [
        {
          type: "string",
        },
        {
          type: "boolean",
        },
        {
          type: "number",
        },
      ],
      title: "xs",
      default: "12",
      enum: [false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
    sm: {
      oneOf: [
        {
          type: "string",
        },
        {
          type: "boolean",
        },
        {
          type: "number",
        },
      ],
      title: "sm",
      default: "12",
      enum: [false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
    md: {
      oneOf: [
        {
          type: "string",
        },
        {
          type: "boolean",
        },
        {
          type: "number",
        },
      ],
      title: "md",
      default: "12",
      enum: [false, "auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
  },
};

const gridPropSchema = {
  title: "Grid",
  type: "object",
  required: ["spacing"],
  properties: {
    spacing: {
      type: "integer",
      title: "spacing",
      default: 0,
      enum: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    justify: {
      type: "string",
      title: "justify",
      default: "",
      enum: [
        "",
        "flex-start",
        "center",
        "flex-end",
        "space-between",
        "space-around",
        "space-evenly",
      ],
    },
    alignItems: {
      type: "string",
      title: "alignItems",
      default: "",
      enum: ["", "flex-start", "center", "flex-end", "stretch", "baseline"],
    },
    direction: {
      type: "string",
      title: "direction",
      default: "",
      enum: [
        "",
        "row",
        "row-reverse",
        // "column",
        // "column-reverse",
      ],
    },
  },
};

const atomicsAndWidgets = [
  ...atomics,
  {
    _id: "Layout",
    widgets: [
      {
        _id: `Layout-Link`,
        name: "Link",
        component: Link,
        version: "1.0.0",
        propsSchema: linkSchema,
        author: "mui",
        description: "Link",
        images: [],
        appFramework: {
          "widget-category": "Layout",
          theme: [MVA10],
          author: "mui",
          description: "Link",
        },
      },
      {
        _id: `Layout-GridItem`,
        name: "GridItem",
        component: GridItem,
        version: "1.0.0",
        propsSchema: gridItemPropSchema,
        author: "mui",
        description: "Grid Item",
        images: [],
        appFramework: {
          "widget-category": "Layout",
          theme: [MVA10],
          author: "mui",
          description: "Grid Item",
        },
      },
      {
        _id: `Layout-Grid`,
        name: "Grid",
        component: Grid,
        version: "1.0.0",
        propsSchema: gridPropSchema,
        author: "mui",
        description: "Grid",
        images: [],
        appFramework: {
          "widget-category": "Layout",
          theme: [MVA10],
          author: "mui",
          description: "Grid",
        },
      },
    ],
  },
  {
    _id: "Custom Widgets",
    widgets,
  },
];

const register = store => {
  store.dispatch({
    type: NEXUS_WIDGETS_REQUEST_SUCCESS,
    payload: {
      data: atomicsAndWidgets.map(category => ({
        ...category,
        type: "SYSTEM",
      })),
    },
  });
};

const ajv = new Ajv({
  useDefaults: true,
  allErrors: true,
  jsonPointers: true,
});

const findActionProps = (obj, role) => {
  const rslt = Object.entries(obj)
    .filter(
      // eslint-disable-next-line no-unused-vars
      ([_, prop]) =>
        prop.type === "object" && prop.__role && prop.__role === role
    )
    .map(p => p[0]);
  return rslt;
};

// eslint-disable-next-line no-shadow
const atomicsWithPropsValidation = atomicsAndWidgets.map(({ _id, widgets }) => {
  return {
    _id,
    widgets: widgets.map(w => {
      return {
        ...w,
        propsSchemaValidation: ajv.compile(w.propsSchema),
        actionProps: findActionProps(w.propsSchema.properties, "action"),
      };
    }),
  };
});

const atomicsValidationRegistry = Object.fromEntries(
  atomicsWithPropsValidation
    .flatMap(c => c.widgets)
    .map(w => [w.name, w.propsSchemaValidation])
);

const atomicsActionPropsRegistry = Object.fromEntries(
  atomicsWithPropsValidation
    .flatMap(c => c.widgets)
    .map(w => [w.name, w.actionProps])
);

export { register, atomicsValidationRegistry, atomicsActionPropsRegistry };
