/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PaletteProvider from "bpmn-js/lib/features/palette/PaletteProvider";
import ContextPadProvider from "bpmn-js/lib/features/context-pad/ContextPadProvider";
import BpmnModeler from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import classNames from "classnames";
import { checkdiagram, diagramToSaga } from "bpmntosaga";
import { Box, Button, Snackbar, Tabs, Tab, Grid, IconButton, Typography } from "@material-ui/core";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Handlebars from "handlebars";
import hljs from "highlight.js";
import ElementProperties from "components/bpmn-custom-components/ElementProperties";
import theme from "../../AppTheme";
import VfAlert from "../vf/VfAlert";
import { setBpmnOfSaga } from "../../actions/applicationsActions";
import customModdleExtension from "../bpmn-custom-components/moddle/custom.json";
import customControlsModule from "../bpmn-custom-components/customPalleteAndPad";
import CustomReplaceMenuProvider from "../bpmn-custom-components/customMenuProvider";
import VfDialogDrawer from "../vf/VfDialogDrawer";

import VfSvgIcon from "../icons/VfSvgIcon";
import "highlight.js/scss/default.scss";

const useStyles = makeStyles({
  root: {},
  button1: {
    position: "fixed",
    bottom: "20px",
    right: "100px",
  },
});
let modeler;

const AppEditSaga = ({ sagaId }) => {
  const appInfo = useSelector(state => state.applications.current.appInfo, shallowEqual);

  const importFunctions = Handlebars.compile(`import * as functions from 'functions';
{{#each packages}}
import * as {{jsname}} from '{{name}}';
{{/each}}`)({
    snippets: appInfo.snippets,
    packages: appInfo.packages,
  });

  const categoryWidgets = useSelector(state => state.nexusWidgets.data, shallowEqual);

  const dispatch = useDispatch();

  const setBpmn = bpmn => dispatch(setBpmnOfSaga({ sagaId, bpmn }));

  const styles = useStyles();

  const [message, setMessage] = useState(null);
  const [openProsDialog, setOpenProsDialog] = useState(false);
  const [sagaCode, setSagaCode] = useState(null);
  const [currentElement, setCurrentElement] = useState({
    selectedElements: [],
    element: null,
  });

  const customizePaletteAndContextPad = () => {
    const _getPaletteEntries = PaletteProvider.prototype.getPaletteEntries;
    PaletteProvider.prototype.getPaletteEntries = function(element) {
      const entries = _getPaletteEntries.apply(this);
      delete entries["create.task"];
      delete entries["create.data-store"];
      delete entries["create.group"];
      delete entries["create.participant-expanded"];
      delete entries["create.subprocess-expanded"];
      delete entries["create.data-object"];
      delete entries["create.intermediate-event"];

      return entries;
    };

    const _getContextPadEntries = ContextPadProvider.prototype.getContextPadEntries;
    ContextPadProvider.prototype.getContextPadEntries = function(element) {
      const entries = _getContextPadEntries.apply(this, [element]);
      delete entries["append.intermediate-event"];
      delete entries["append.append-task"];
      return entries;
    };
  };

  const handleEvent = () => {
    setOpenProsDialog(true);
  };

  const bpmnOfSelectedSaga = appInfo.sagas.find(s => s.id === sagaId).bpmn;
  useEffect(() => {
    if (sagaCode) return;
    document.addEventListener("openPropsDialog", handleEvent);
    customizePaletteAndContextPad();
    modeler = new BpmnModeler({
      container: "#diagram",
      moddleExtensions: {
        custom: customModdleExtension,
      },
      additionalModules: [customControlsModule, CustomReplaceMenuProvider],
    });

    modeler.importXML(appInfo.sagas.find(s => s.id === sagaId).bpmn);

    modeler.on("selection.changed", e => {
      setCurrentElement({
        selectedElements: e.newSelection,
        element: e.newSelection[0],
      });
    });

    modeler.on("element.changed", e => {
      const { element } = e;

      const { element: current } = currentElement;
      if (!current) {
        return;
      }

      // update panel, if currently selected element changed
      if (element.id === current.id) {
        setCurrentElement({
          ...currentElement,
          element,
        });
      }
    });

    modeler.on("commandStack.changed", async () => {
      const { xml } = await modeler.saveXML({ format: true });
      setBpmn(xml);
      // const ret = await checkdiagram(xml);
      // if (!ret.outcome) {
      //   setMessage(ret.reason);
      // } else {
      //   setMessage(null);
      // }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener("openPropsDialog", handleEvent);
      const element = document.querySelector("#diagram");
      if (element) element.innerHTML = "";
    };
  }, [sagaId, sagaCode, bpmnOfSelectedSaga]);

  useEffect(() => {
    if (!sagaCode) return;
    document.querySelectorAll("pre code").forEach(e => {
      hljs.highlightBlock(e);
    });
  }, [sagaCode]);

  const [modalTab, setModalTab] = useState("general");

  return (
    <>
      {!sagaId && (
        <main id="diagram" className={styles.root} style={{ height: `calc(100vh - ${theme.spacing(21)}px` }} />
      )}
      {sagaId && !sagaCode && (
        <span>
          <main id="diagram" className={styles.root} style={{ height: `calc(100vh - ${theme.spacing(21)}px` }} />
          <Button
            autoFocus
            color="default"
            variant="outlined"
            className={classNames(styles.button1)}
            onClick={async () => {
              const { xml } = await modeler.saveXML({ format: true });
              setBpmn(xml);
              const c = await diagramToSaga(xml, {
                imports: `import axios from 'axios';
import dot from 'dot-object';
import { push, replace, go, goBack, goForward } from 'connected-react-router';
${importFunctions}
`,
              });
              setSagaCode(c);
            }}
          >
            Show Saga
          </Button>

          <pre>{sagaCode}</pre>
          <VfDialogDrawer
            title={currentElement.element?.businessObject.get("name") || currentElement.element?.id || "Editor"}
            openDialog={openProsDialog}
            buttonConfirmAction={() => {
              const ele = document.getElementById("bpmnPropertiesForm");
              const event = new Event("submit", {
                bubbles: true,
                cancelable: true,
              });
              ele.dispatchEvent(event);
            }}
            buttonCloseAction={() => {
              setOpenProsDialog(false);
            }}
            hasValidations
            buttonConfirmText="Submit"
            buttonCancelText="Cancel"
            /* eslint-disable */
            appBar={
              false ? (
                <Box ml={-3}>
                  <Tabs
                    value={modalTab}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setModalTab(newValue);
                    }}
                  >
                    <Tab label="General" value="general" />
                    <Tab label="API" value="api" />
                  </Tabs>
                </Box>
              ) : null
            }
            /* eslint-enable */
          >
            {modeler && currentElement?.element && (
              <>
                <ElementProperties
                  modalTab={modalTab}
                  modeler={modeler}
                  currentElement={currentElement}
                  setMessage={setMessage}
                  setOpenProsDialog={setOpenProsDialog}
                />
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  open={!!message}
                  onClose={() => setMessage(null)}
                >
                  <VfAlert severity={0} message={message} />
                </Snackbar>
              </>
            )}
          </VfDialogDrawer>
        </span>
      )}
      {sagaCode && (
        <>
          <Grid container spacing={3} direction="row" alignItems="center" style={{ marginTop: "60px" }}>
            <Grid item xs={false}>
              <IconButton onClick={() => setSagaCode(null)}>
                <VfSvgIcon icon="arrowLeft" viewBox={24} />
              </IconButton>
            </Grid>
            <Grid item sm>
              <Typography variant="h4" component="h1" color="textPrimary">
                Back to the Diagram
              </Typography>
            </Grid>
          </Grid>
          <pre>
            <code className="javascript">{sagaCode}</code>
          </pre>
        </>
      )}
    </>
  );
};

export default AppEditSaga;
