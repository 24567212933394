/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDrag } from "react-dnd";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import {
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  Popover,
} from "@material-ui/core";
import theme from "AppTheme";
import CardWidgetPopover from "./CardWidgetPopover";
import VfSvgIcon from "../icons/VfSvgIcon";
import CardWidgetImage from "../widgets/CardWidgetImage";
import WidgetMoreMenu from "./widgets/WidgetMoreMenu";

const useStyles = makeStyles({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  listItemAction: {
    right: theme.spacing(3),
    top: theme.spacing(2),
    transform: "none",
  },
});

const CardWidgetTile = ({ widget, isSearched }) => {
  const classes = useStyles();
  const [openDialogNotes, setOpenDialogNotes] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const componentId = 1;

  const handleOpenDialogNotes = () => {
    setOpenDialogNotes(!openDialogNotes);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [{ isDragging }, drag] = useDrag({
    item: { name: widget.name, type: "widget" },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Card
      ref={drag}
      elevation={0}
      className={classNames({
        "MuiCard-grey": true,
        "MuiCard-widgetTile": true,
        "MuiCard-rounded": true,
        "MuiCard-shadowHover": true,
      })}
    >
      <CardActionArea>
        <CardWidgetImage widget={widget} />
        <CardContent>
          {isSearched && (
            <Typography variant="body2" color="textSecondary">
              {widget.appFramework && widget.appFramework["widget-category"]
                ? widget.appFramework["widget-category"]
                : "Uncategorized"}
            </Typography>
          )}
          <Typography variant="body1" color="textPrimary">
            {widget.description}
            <Typography component="span" color="secondary">
              &nbsp;
              {`v${widget.version}`}
            </Typography>
          </Typography>
          {/* todo only view on search */}
          {isSearched && (
            <Typography variant="body2" color="textSecondary">
              {widget.keywords ? widget.keywords.join(", ") : ""}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing>
        <IconButton
          title="View more info"
          size="small"
          className="MuiIconButton-colorWhite"
          onClick={handleClick}
        >
          <VfSvgIcon icon="info" viewBox={24} />
        </IconButton>
        <IconButton
          title="View readme file"
          size="small"
          className="MuiIconButton-colorWhite"
          onClick={() => {
            handleOpenDialogNotes();
          }}
        >
          <VfSvgIcon icon="readme" viewBox={24} />
        </IconButton>
        {widget?.type !== "SYSTEM" && (
          <WidgetMoreMenu
            widget={widget}
            className="MuiIconButton-colorWhite"
          />
        )}
      </CardActions>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <CardWidgetPopover widget={widget} />
      </Popover>
      <Dialog
        fullScreen={fullScreen}
        open={openDialogNotes}
        onClose={handleOpenDialogNotes}
        aria-labelledby={`responsive-dialog-title-${componentId}`}
        maxWidth="md"
      >
        <DialogTitle
          id={`responsive-dialog-title-${componentId}`}
          disableTypography
          className={classes.modalTitle}
        >
          <Typography variant="h5" component="h3">
            {/* todo for translation */}
            Readme file
          </Typography>
          <Divider
            className={classes.modalDivider}
            flexItem
            orientation="vertical"
          />
          <IconButton aria-label="close" onClick={handleOpenDialogNotes}>
            <VfSvgIcon icon="close" viewBox={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent className="MuiDialogContent-git">
          <ReactMarkdown source={widget.readme} />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default CardWidgetTile;
