import { SET_PROPS } from "actions/propActions";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PROPS: {
      const { comp, props } = payload;
      return {
        ...state,
        [comp]: props,
      };
    }
    default:
      return state;
  }
};
