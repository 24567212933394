/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { withTheme } from "react-jsonschema-form";
import FormTheme from "components/custom-mui-components/FormTheme";
import {
  clearCurrRowCol,
  clearSelectedWidget,
  setApp,
} from "actions/applicationsActions";
import VfSvgIcon from "../icons/VfSvgIcon";

const Form = withTheme(FormTheme);

const schema = {
  type: "object",
  properties: {
    name: {
      type: "string",
    },
  },
};

const WidgetInstanceNameForm = ({
  setSidebarItem,
  selectedWdgt,
  application,
  instanceId,
  instanceName,
  clearRowCol,
  clearWidget,
  setApplication,
}) => {
  const { appInfo } = application;
  const [formData, setFormData] = useState({
    name: instanceName || instanceId,
  });

  const clonedAppInfo = cloneDeep(appInfo);
  const selectedWidget = clonedAppInfo.widgets.find(
    wdgt => wdgt.name === selectedWdgt.name
  );
  const selectedInstance = selectedWidget?.instances.find(
    inst => inst.uuid === instanceId
  );
  const instancesObj =
    selectedWidget?.instances.reduce((prev, curr) => {
      prev[curr.name] = curr.uuid;
      return prev;
    }, {}) || {};

  useEffect(() => {
    setFormData({ name: instanceName || instanceId });
  }, [instanceId]);

  const updateAppWidgetInstance = (_, data = "") => {
    data = data.trim();
    const isInvalid =
      !data ||
      (instancesObj[data] && instancesObj[data] !== selectedWdgt.instanceId);

    if (!isInvalid) {
      selectedInstance.name = data;
      setApplication(clonedAppInfo);
    }
  };

  const validate = ({ name = "" }, errors) => {
    name = name.trim();
    if (!name) {
      errors.name.addError("Name is required");
    }

    if (instancesObj[name] && instancesObj[name] !== selectedWdgt.instanceId) {
      errors.name.addError("Name should be unique!");
    }

    return errors;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box mt={3} px={3} className="MuiBox-searchOverlay" position="relative">
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item>
              <IconButton
                onClick={() => {
                  clearRowCol();
                  clearWidget();
                  setSidebarItem("widgetList");
                }}
              >
                <VfSvgIcon icon="close" viewBox={24} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography className="bold" variant="h5" component="h3">
                Widget Instance Name
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box py={3} px={3}>
          <Form
            schema={schema}
            formData={formData}
            onChange={e => setFormData(e.formData)}
            onBlur={updateAppWidgetInstance}
            validate={validate}
            liveValidate
            // ErrorList={() => null}
            // showErrorList={false}
          >
            <span />
          </Form>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  instanceId: state.applications.selectedWidget.instanceId,
  instanceName: state.applications.selectedWidget.instanceName,
});

const mapDispatchToProps = dispatch => ({
  clearRowCol: () => dispatch(clearCurrRowCol()),
  clearWidget: () => dispatch(clearSelectedWidget()),
  setApplication: payload => dispatch(setApp(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetInstanceNameForm);
