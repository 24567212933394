/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import {
  Tab,
  Box,
  Grid,
  Card,
  Tabs,
  TextField,
  Switch,
  FormControlLabel,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { addSecurityDefs } from "actions/applicationsActions";
import EditorFormNoData from "components/editor/EditorFormNoData";
import theme from "../../AppTheme";
import ApisService from "../../services/ApisService";

const merge = require("deepmerge");

const useStyles = makeStyles({
  root: {
    "& .MuiTabs-root": {
      "& .MuiIconButton-root": {
        margin: "11px 12px",
      },
    },
  },
  tabsContainer: {
    overflow: "hidden",
    borderBottomColor: theme.palette.grey[100],
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    paddingRight: theme.spacing(1.5),
  },
  dialogActions: {
    justifyContent: "space-between",
  },
});

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={4}>{children}</Box>}
    </Box>
  );
};

const SecurityDefinition = ({ apiId, minHeight }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [selectedApi, setSelectedApi] = useState(null);
  const [securityDefinitions, setSecurityDefinitions] = useState({
    values: [],
    authorizationsStatic: {},
    authorizationsDynamic: {},
  });

  const { access_token, id_token } = useSelector(
    state => state.authentication,
    shallowEqual
  );

  const appInfo = useSelector(
    state => state.applications.current.appInfo,
    shallowEqual
  );

  const dispatch = useDispatch();
  const addSecurityDefsToApi = api => dispatch(addSecurityDefs(api));

  const authToValuesByFlag = (auth, isDynamic) =>
    Object.fromEntries(
      Object.entries(auth).map(([name, props]) => [
        name,
        {
          props: Object.entries(props).map(([propname, propvalue]) => ({
            name: propname,
            value: propvalue,
            dynamic: isDynamic,
          })),
        },
      ])
    );

  const authToValues = (authorizationsStatic, authorizationsDynamic) => {
    const reconstructedValuesDyn = authToValuesByFlag(
      authorizationsDynamic,
      true
    );
    // console.log(JSON.stringify(reconstructedValuesDyn, null, 2));
    const reconstructedValuesStat = authToValuesByFlag(
      authorizationsStatic,
      false
    );
    // console.log(JSON.stringify(reconstructedValuesStat, null, 2));

    const reconstructedValuesMerged = merge(
      reconstructedValuesDyn,
      reconstructedValuesStat
    );

    const reconstructedValues = Object.entries(reconstructedValuesMerged).map(
      ([name, v]) => ({
        name,
        ...v,
      })
    );

    return reconstructedValues;
  };

  const initializeState = (secDefs, api) => {
    const reconstructedValues = authToValues(
      api?.authorizationsStatic || {},
      api?.authorizationsDynamic || {}
    );

    return {
      values:
        reconstructedValues.length > 0
          ? reconstructedValues
              .map(r => {
                return {
                  ...r,
                  type: Object.entries(secDefs).find(([key, val]) => {
                    return key === r.name;
                  })[1].type,
                };
              })
              .map(r => {
                if (r.type === "oauth2") {
                  r.props.push({
                    name: "scopes",
                    value: Object.keys(
                      Object.entries(secDefs).find(([key, val]) => {
                        return key === r.name;
                      })[1].scopes
                    ).map(s => {
                      return { name: s };
                    }),
                  });
                }
                return r;
              })
          : !secDefs
          ? []
          : Object.entries(secDefs).map(([key, val]) => {
              const props = [];
              switch (val.type) {
                case "apiKey":
                  props.push({ name: "value", value: "", dynamic: false });
                  break;
                case "basic":
                  props.push({ name: "username", value: "", dynamic: false });
                  props.push({ name: "password", value: "", dynamic: false });
                  break;
                case "oauth2":
                  props.push({ name: "token", value: "", dynamic: false });
                  props.push({
                    name: "scopes",
                    value: Object.keys(val.scopes).map(s => {
                      return { name: s };
                    }),
                  });
                  break;
                default:
                  break;
              }
              return {
                props,
                type: val.type,
                name: key,
              };
            }),
      authorizationsStatic: api?.authorizationsStatic ?? {},
      authorizationsDynamic: api?.authorizationsDynamic ?? {},
    };
  };

  useEffect(() => {
    const a = appInfo.apis.find(a => a._id === apiId);
    setSelectedApi(a);
    ApisService.fetchApiVersionFileData({
      access_token,
      id_token,
      apiId: a._id,
      version: a.selectedVersion,
    }).then(fileData => {
      setSecurityDefinitions(initializeState(fileData.securityDefinitions, a));
    });
  }, [apiId]);

  // console.log(JSON.stringify(initializeState()));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const propsToFields = (props, isDynamic) =>
    Object.fromEntries(
      props.filter(p => p.dynamic === isDynamic).map(p => [p.name, p.value])
    );

  const valuesToAuth = (values, isDynamic) =>
    Object.fromEntries(
      values.map(v => [v.name, propsToFields(v.props, isDynamic)])
    );

  const handleInputChange = e => {
    const { name, value, checked } = e.target;
    const nameFromEvent = name.split(".");
    const index = securityDefinitions.values.findIndex(
      s => s.name === nameFromEvent[0]
    );
    const propIndex = securityDefinitions.values[index].props.findIndex(
      p => p.name === nameFromEvent[1]
    );
    let newSecurityDefinitions = [];
    if (nameFromEvent.length === 3 && nameFromEvent[2] === "checked")
      newSecurityDefinitions = securityDefinitions.values.map((sc, i) => {
        if (i === index) {
          return {
            ...sc,
            props: sc.props.map((p, j) => {
              if (j === propIndex) {
                return { ...p, dynamic: checked };
              }

              return p;
            }),
          };
        }
        return sc;
      });
    else if (nameFromEvent.length === 2)
      newSecurityDefinitions = securityDefinitions.values.map((sc, i) => {
        if (i === index) {
          return {
            ...sc,
            props: sc.props.map((p, j) => {
              if (j === propIndex) {
                return { ...p, value };
              }

              return p;
            }),
          };
        }
        return sc;
      });
    const authorizationsStatic = valuesToAuth(newSecurityDefinitions, false);
    const authorizationsDynamic = valuesToAuth(newSecurityDefinitions, true);

    setSecurityDefinitions({
      authorizationsStatic,
      authorizationsDynamic,
      values: newSecurityDefinitions,
    });
  };

  const handleFocusOut = e => {
    const { authorizationsStatic, authorizationsDynamic } = securityDefinitions;
    addSecurityDefsToApi({
      ...selectedApi,
      authorizationsStatic,
      authorizationsDynamic,
    });
  };

  return securityDefinitions.values.length ? (
    <Box
      p={3}
      className={classes.root}
      bgcolor="grey.100"
      minHeight={`calc(100vh - ${theme.spacing(minHeight - 6)}px)`}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className="MuiCard-rounded MuiCard-white MuiCard-shadow">
            <Box className={classes.tabsContainer}>
              <Grid container spacing={3} wrap="nowrap" alignItems="center">
                <Grid item xs>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {securityDefinitions.values.map(({ name }) => (
                      <Tab label={name} key={`tab-${name}`} />
                    ))}
                  </Tabs>
                </Grid>
              </Grid>
            </Box>
            {securityDefinitions.values.map(({ props, name: sdName }, i) => (
              <TabPanel key={`tabpanel-${sdName}`} value={value} index={i}>
                {props.map(({ name, value, dynamic }) =>
                  name !== "scopes" ? (
                    <Grid key={name} container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          name={`${sdName}.${name}`}
                          label={name}
                          value={value}
                          variant="outlined"
                          onChange={e => {
                            handleInputChange(e);
                            // moveToRedux();
                          }}
                          onBlur={handleFocusOut}
                          rowsMax="3"
                          rows="1"
                          multiline
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <FormControlLabel
                                control={
                                  // eslint-disable-next-line react/jsx-wrap-multilines
                                  <Switch
                                    size="small"
                                    name={`${sdName}.${name}.checked`}
                                    color="primary"
                                    checked={dynamic}
                                    onChange={handleInputChange}
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                  />
                                }
                                label="Dynamic"
                                labelPlacement="start"
                              />
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <List
                      key={`list-${name}`}
                      subheader={<ListSubheader>Scopes:</ListSubheader>}
                      className={classes.root}
                    >
                      {value.map(scope => (
                        <Grid key={scope.name} container>
                          <Grid item xs={12}>
                            <ListItem>
                              <ListItemText primary={scope.name} />
                            </ListItem>
                          </Grid>
                        </Grid>
                      ))}
                    </List>
                  )
                )}
              </TabPanel>
            ))}
          </Card>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box p={3} height="100%" bgcolor="grey.100">
      <EditorFormNoData
        minHeight={minHeight}
        text="Security definition does not exist."
      />
    </Box>
  );
};

export default SecurityDefinition;
