import React from "react";

import Breadcrumb from "components/breadcrumb/Breadcrumb";
import EditorForm from "./EditorForm";

const Editor = ({ applications, widgets }) => {
  return (
    <main>
      <Breadcrumb applications={applications} widgets={widgets} />
      <EditorForm />
    </main>
  );
};

export default Editor;
