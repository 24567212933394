const S3_SET_ENV = "S3_SET_ENV";
const S3_CLEAR_ENV = "S3_CLEAR_ENV";
const S3_ENVS_REQUEST = "S3_ENVS_REQUEST";
const S3_ENVS_REQUEST_SUCCESS = "S3_ENVS_REQUEST_SUCCESS";
const S3_ENVS_REQUEST_FAIL = "S3_ENVS_REQUEST_FAIL";
const S3_URLS_RESOURCES_REQUEST = "S3_URLS_RESOURCES_REQUEST";
const S3_URLS_RESOURCES_REQUEST_SUCCESS = "S3_URLS_RESOURCES_REQUEST_SUCCESS";
const S3_URLS_RESOURCES_REQUEST_FAIL = "S3_URLS_RESOURCES_REQUEST_FAIL";
const S3_CLEAR_URLS_RESOURCES = "S3_CLEAR_URLS_RESOURCES";

const fetchS3EnvsByLocale = payload => ({
  type: S3_ENVS_REQUEST,
  payload,
});

const setS3Env = payload => ({
  type: S3_SET_ENV,
  payload,
});

const clearS3Env = () => ({
  type: S3_CLEAR_ENV,
});

const fetchS3EnvURLs = payload => ({
  type: S3_URLS_RESOURCES_REQUEST,
  payload,
});

const clearS3EnvURLs = () => ({
  type: S3_CLEAR_URLS_RESOURCES,
});

export {
  S3_SET_ENV,
  S3_CLEAR_ENV,
  S3_ENVS_REQUEST,
  S3_ENVS_REQUEST_SUCCESS,
  S3_ENVS_REQUEST_FAIL,
  S3_URLS_RESOURCES_REQUEST,
  S3_URLS_RESOURCES_REQUEST_SUCCESS,
  S3_URLS_RESOURCES_REQUEST_FAIL,
  S3_CLEAR_URLS_RESOURCES,
  fetchS3EnvsByLocale,
  setS3Env,
  clearS3Env,
  fetchS3EnvURLs,
  clearS3EnvURLs,
};
