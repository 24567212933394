/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { cloneDeep } from "lodash";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
  Menu,
  MenuItem,
  Fade,
} from "@material-ui/core";
import jp from "jsonpath";
import { djrToLayout } from "utils/djr-to-layout";
import VfDialogConfirm from "../vf/VfDialogConfirm";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiCardActions-root": {
      padding: 0,
    },
  },
});

const CardPageListItem = ({
  page,
  auth,
  currentPage,
  deletePage,
  dispatchShowAddTemplate,
  setCurrentPage,
  setShowAddPage,
  setAppHomepage,
  dispatchShowCreatePage,
  setApplication,
  appInfo,
  showSnackBar,
  setAppSection,
  setSidebarItem,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogConfirm, setDialogConfirm] = useState(false);

  const categoryWidgets = useSelector(state => state.nexusWidgets.data);

  const fatWidgetList = categoryWidgets.flatMap(({ widgets }) =>
    widgets.filter(w => w.images.length > 0)
  );

  const handleDialogConfirm = val => {
    setDialogConfirm(val);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const { access_token, id_token } = auth;
  const {
    _id: pageId,
    application: applicationId,
    title,
    data: djrData,
  } = page;

  const removeWidgetFromApplication = fatWidgets => {
    const clonedAppInfo = cloneDeep(appInfo);
    fatWidgets.forEach(widget => {
      const found = clonedAppInfo.widgets.findIndex(
        w => w.name === widget.__component
      );
      if (clonedAppInfo.widgets[found].occurrences <= 1) {
        clonedAppInfo.widgets.splice(found, 1);
      } else {
        clonedAppInfo.widgets.splice(found, 1, {
          _id: clonedAppInfo.widgets[found]._id,
          name: widget.__component,
          version: clonedAppInfo.widgets[found].version,
          occurrences: clonedAppInfo.widgets[found].occurrences - 1,
          instances: clonedAppInfo.widgets[found].instances.filter(
            inst => inst.uuid !== widget.__props.__instanceId
          ),
        });
      }
    });

    setApplication(clonedAppInfo);
    deletePage({
      access_token,
      id_token,
      pageId,
      appInfo: clonedAppInfo,
    });
  };

  const fatWidget = name => fatWidgetList.find(w => w.name === name);

  const containsFatComp = comp => {
    const propPath = `$..children`;
    return jp
      .query(comp, propPath)
      .flatMap(x => x)
      .filter(c => {
        return c?.__component && !!fatWidget(c.__component);
      });
  };

  return (
    <Card
      elevation={0}
      className={classNames(classes.card, {
        "MuiCard-outlined": true,
        "MuiCard-white": true,
        "MuiCard-rounded": true,
      })}
    >
      <CardActionArea>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              {page.isHomePage && <VfSvgIcon icon="home" viewBox={24} />}
              {!page.isHomePage && <VfSvgIcon icon="page" viewBox={24} />}
            </Grid>
            <Grid item>
              <Typography variant="body1">{title}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <IconButton onClick={handleClick} data-testid="cardPagemenu_btn">
          <VfSvgIcon icon="more" viewBox={24} />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={0}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              setAppHomepage({ access_token, id_token, applicationId, pageId });
            }}
            data-testid="setAsHomePage_btn"
          >
            SET AS HOMEPAGE
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              const { data, ...rest } = page;
              setCurrentPage(
                data.__props.id
                  ? { djr: data, pageInfo: rest }
                  : {
                      djr: data,
                      pageInfo: rest,
                      spacing: 3,
                      rows: djrToLayout(data).rows,
                    }
              );
              dispatchShowCreatePage(true);
            }}
            data-testid="editPage_btn"
          >
            EDIT PAGE
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              const { data, ...rest } = page;
              setCurrentPage(
                data.__props.id
                  ? { djr: data, pageInfo: rest }
                  : {
                      djr: data,
                      pageInfo: rest,
                      spacing: 3,
                      rows: djrToLayout(data).rows,
                    }
              );
              setAppSection("design");
              setSidebarItem("widgetList");
              setShowAddPage(true);
              dispatchShowAddTemplate(true);
            }}
            data-testid="designPage_btn"
          >
            DESIGN PAGE
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleDialogConfirm(true);
            }}
            data-testid="deletePage_btn"
          >
            DELETE PAGE
          </MenuItem>
        </Menu>
        <VfDialogConfirm
          openDialog={dialogConfirm}
          maxWidth="xs"
          buttonCancelText="Cancel"
          buttonCancelAction={() => handleDialogConfirm(false)}
          buttonConfirmText="Delete"
          buttonConfirmAction={() => {
            handleDialogConfirm(false);
            if (page.isHomePage) {
              showSnackBar({
                show: true,
                message: `Page ${page.title} can't be deleted. Change Application Home Page.`,
                severity: 0,
              });
            } else {
              const fatWidgetsInPage = containsFatComp(djrData);
              removeWidgetFromApplication(fatWidgetsInPage);
              if (currentPage.pageInfo._id === pageId) {
                setCurrentPage({});
                setShowAddPage(false);
                dispatchShowAddTemplate(false);
              }
            }
          }}
        >
          <Typography variant="body1" align="center">
            Are you sure you want to delete
            <br />
            <strong>{title}</strong>
            <br />
            permanently?
          </Typography>
        </VfDialogConfirm>
      </CardActions>
    </Card>
  );
};

export default CardPageListItem;
