import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";
import classNames from "classnames";
import theme from "../../AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  dialogPaper: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  dialogPaperFull: {
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "100%",
    overflow: "hidden",
  },
  dialogTitle: {
    padding: 0,
    display: "none",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  dialogContentAudio: {
    padding: 0,
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  dialogContentImage: {
    padding: 0,
    position: "relative",
    overflow: "hidden",
    height: "calc(100vh - 64px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 48px)",
    },
  },
  dialogContentFile: {
    padding: 0,
    position: "relative",
    overflow: "hidden",
  },
  dialogContentVideo: {
    padding: 0,
    paddingTop: "calc(100% / (1280 / 720))",
    position: "relative",
  },
  dialogPlayerVideo: {
    position: "absolute",
    outline: "none",
    backgroundColor: "#000",
    left: 0,
    top: 0,
  },
  dialogPlayerAudio: {
    width: "100%",
    outline: "none",
  },
  dialogPlayerImage: {
    width: "100%",
    maxHeight: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: "absolute",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  dialogActions: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(3),
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VfDialogMedia = ({
  openDialog,
  maxWidth,
  closeAction,
  item,
  scrollable = false,
}) => {
  const classes = useStyles();
  const [scroll] = useState("paper");
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      classes={{
        paper: classNames({
          [classes.dialogPaper]: ["video", "image", "audio"].includes(
            item.type.split("/")[0]
          ),
          [classes.dialogPaperFull]: !["video", "image", "audio"].includes(
            item.type.split("/")[0]
          ),
        }),
      }}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      open={openDialog}
      onClose={closeAction}
      maxWidth={maxWidth}
      {...(scrollable ? { scroll } : {})}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <IconButton
          className="MuiIconButton-colorWhite"
          aria-label="close"
          onClick={closeAction}
        >
          <VfSvgIcon icon="close" viewBox={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={classNames({
          [classes.dialogContentImage]: item.mediaType === "image",
          [classes.dialogContentAudio]: item.mediaType === "audio",
          [classes.dialogContentVideo]: item.mediaType === "video",
          [classes.dialogContentFile]: item.mediaType === "other",
        })}
      >
        {item.mediaType === "image" && (
          <Box
            className={classes.dialogPlayerImage}
            alt={item.title}
            style={{ backgroundImage: `url(${item.url})` }}
          />
        )}
        {item.mediaType === "video" && (
          <video
            className={classes.dialogPlayerVideo}
            controls
            width="100%"
            height="100%"
          >
            <source src={item.url} type="video/webm" />
            <track kind="captions" />
          </video>
        )}
        {item.mediaType === "audio" && (
          <audio className={classes.dialogPlayerAudio} controls>
            <source src={item.url} type="audio/wav" />
            <track kind="captions" />
          </audio>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default VfDialogMedia;
