import React from "react";
import GridWrapper from "./GridWrapper";
import GridItemWrapper from "./GridItemWrapper";

export default (componentJSON, Element) => {
  switch (componentJSON.__component) {
    case "Grid":
      return props => <GridWrapper componentJSON={componentJSON} {...props} />;
    case "GridItem":
      return props => (
        <GridItemWrapper componentJSON={componentJSON} {...props} />
      );
    default:
      return Element;
  }
};
