import React from "react";
import { connect } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { clearEnv } from "actions/envsActions";
import { clearTouchpoint } from "actions/touchpointsActions";
import { clearApp, clearAppsList } from "actions/appsActions";
import { clearWidget, clearWidgetsList } from "actions/widgetsActions";
import { clearSelectedTreeItem as clearingSelectedTreeItem } from "actions/configFormActions";
import { clearTranslationWidgetList } from "actions/translationsActions";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";

import { makeStyles } from "@material-ui/core/styles";
import theme from "AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    borderBottomColor: theme.palette.grey.A100,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    "& .MuiBreadcrumbs-ol": {
      display: "inline-flex",
      border: "1px solid #E0E6ED",
      borderTopLeftRadius: 3,
      borderBottomLeftRadius: 3,
      borderRight: 0,
      "& .MuiBreadcrumbs-separator": {
        display: "none",
      },
      "& .MuiBreadcrumbs-li": {
        "&:first-child": {
          "& .MuiLink-animate": {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.secondary.main,
            paddingLeft: theme.spacing(2),
            "& .MuiSvgIcon-root": {
              marginRight: 0,
            },
            "& .MuiBreadcrumbs-arrow": {
              "&:before": {
                backgroundColor: "#E0E6ED",
              },
              "&:after": {
                backgroundColor: "#fff",
              },
            },
          },
        },
        "&:last-child": {
          "& .MuiLink-animate": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            "& .MuiSvgIcon-root": {
              "& rect": {
                fill: theme.palette.common.white,
              },
              "& circle": {
                fill: theme.palette.common.white,
              },
              "& path": {
                fill: theme.palette.common.white,
              },
              "& polygon": {
                fill: theme.palette.common.white,
              },
            },
            "& .MuiBreadcrumbs-arrow": {
              "&:before": {
                backgroundColor: "#E0E6ED",
              },
              "&:after": {
                backgroundColor: theme.palette.secondary.main,
              },
            },
          },
        },
        "& .MuiLink-animate": {
          display: "flex",
          alignItems: "center",
          backgroundColor: "#F3F5FA",
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(2),
          color: theme.palette.grey[700],
          fontWeight: 400,
          height: theme.spacing(4),
          "& .MuiSvgIcon-root": {
            marginRight: theme.spacing(1),
          },
          "& .MuiTypography-root": {
            position: "relative",
            zIndex: 2,
            fontSize: 14,
          },
          "& .MuiBreadcrumbs-arrow": {
            position: "relative",
            zIndex: 1,
            display: "inline-block",
            right: -17,
            "&:before": {
              content: "''",
              position: "absolute",
              width: 23,
              height: 23,
              background: "#E0E6ED",
              borderRadius: "1px 6px 1px 0",
              transform: "rotate(45deg) translateY(-5px)",
              margin: 0,
              right: -4,
              top: "50%",
              transformOrigin: "right",
            },
            "&:after": {
              content: "''",
              position: "absolute",
              width: 22,
              height: 22,
              background: "#F3F5FA",
              borderRadius: "1px 6px 1px 0",
              transform: "rotate(45deg) translateY(-16px)",
              margin: 0,
              right: 2,
              top: "50%",
            },
          },
        },
      },
    },
  },
});

const Breadcrumb = ({
  clearEnvironment,
  clearTchpoint,
  clearApplication,
  clearWdgt,
  clearApplicationsList,
  clearWdgtsList,
  clearSelectedTreeItem,
  clearTranslationList,
  applications,
  widgets,
}) => {
  const { locale, env, touchpoint, app, widget, version } = useParams();
  const currentApp = applications
    ? applications.find(a => a.application === app)
    : null;
  const currentWidget = widgets
    ? widgets.find(w => w.componentId && w.componentId === widget)
    : null;
  const classes = useStyles();
  const {
    location: { pathname },
  } = useHistory();
  const isTranslationPath =
    pathname.indexOf("translations") === -1 ? "env" : "translations";
  return (
    <Box component="nav" py={4} bgcolor="common.white" className={classes.root}>
      <Container maxWidth={false}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            className="MuiLink-animate"
            to={`/${locale}`}
            onClick={() => {
              clearEnvironment();
              clearTchpoint();
              clearApplication();
              clearWdgt();
              clearApplicationsList();
              clearWdgtsList();
            }}
          >
            <HomeIcon fontSize="small" />
            <Box component="span" className="MuiBreadcrumbs-arrow" />
          </Link>
          {locale && (
            <Link
              className="MuiLink-animate"
              to={`/${locale}/env`}
              onClick={() => {
                clearEnvironment();
                clearTchpoint();
                clearApplication();
                clearWdgt();
                clearApplicationsList();
                clearWdgtsList();
                clearTranslationList();
              }}
            >
              <VfSvgIcon icon="dev" viewBox={24} />
              <Typography variant="body1" component="span">
                {locale}
              </Typography>
              <Box component="span" className="MuiBreadcrumbs-arrow" />
            </Link>
          )}
          {pathname.indexOf("translations") !== -1 && (
            <Link
              className="MuiLink-animate"
              to={`/${locale}/translations/`}
              onClick={() => {
                clearEnvironment();
                clearTranslationList();
              }}
            >
              <VfSvgIcon icon="translate" viewBox={24} />
              <Typography variant="body1" component="span">
                Translations
              </Typography>
              <Box component="span" className="MuiBreadcrumbs-arrow" />
            </Link>
          )}
          {env && (
            <Link
              className="MuiLink-animate"
              to={`/${locale}/${isTranslationPath}/${env}`}
              onClick={() => {
                clearTchpoint();
                clearApplication();
                clearWdgt();
                clearApplicationsList();
                clearWdgtsList();
              }}
            >
              <VfSvgIcon icon="test" viewBox={24} />
              <Typography variant="body1" component="span">
                {env}
              </Typography>
              <Box component="span" className="MuiBreadcrumbs-arrow" />
            </Link>
          )}
          {touchpoint && (
            <Link
              className="MuiLink-animate"
              to={`/${locale}/env/${env}/${touchpoint}`}
              onClick={() => {
                clearApplication();
                clearWdgt();
                clearWdgtsList();
              }}
            >
              <VfSvgIcon icon="touchPoint" viewBox={56} />
              <Typography variant="body1" component="span">
                {touchpoint}
              </Typography>
              <Box component="span" className="MuiBreadcrumbs-arrow" />
            </Link>
          )}
          {app && (
            <Link
              className="MuiLink-animate"
              to={`/${locale}/env/${env}/${touchpoint}/${app}`}
              onClick={() => {
                clearWdgt();
                clearSelectedTreeItem();
              }}
            >
              <VfSvgIcon icon="apps" viewBox={24} />
              <Typography variant="body1" component="span">
                {currentApp.data.app}
              </Typography>
              <Box component="span" className="MuiBreadcrumbs-arrow" />
            </Link>
          )}
          {widget && pathname.indexOf("translations") === -1 && (
            <Link
              className="MuiLink-animate"
              to={`/${locale}/env/${env}/${touchpoint}/${app}/${widget}/form`}
            >
              <VfSvgIcon icon="widget" viewBox={56} />
              <Typography variant="body1" component="span">
                {applications.length &&
                widgets.length &&
                currentWidget === undefined
                  ? "global"
                  : currentWidget.data.component}
              </Typography>
              <Box component="span" className="MuiBreadcrumbs-arrow" />
            </Link>
          )}
          {widget && pathname.indexOf("translations") !== -1 && (
            <Link
              className="MuiLink-animate"
              to={`/${locale}/translations/${env}/${widget}/${version}`}
            >
              <VfSvgIcon icon="widget" viewBox={56} />
              <Typography variant="body1" component="span">
                {`${widget} ${version}`}
              </Typography>
              <Box component="span" className="MuiBreadcrumbs-arrow" />
            </Link>
          )}
        </Breadcrumbs>
      </Container>
    </Box>
  );
};

const mapDispatchToProps = dispatch => ({
  clearEnvironment: () => dispatch(clearEnv()),
  clearTchpoint: () => dispatch(clearTouchpoint()),
  clearApplication: () => dispatch(clearApp()),
  clearWdgt: () => dispatch(clearWidget()),
  clearApplicationsList: () => dispatch(clearAppsList()),
  clearWdgtsList: () => dispatch(clearWidgetsList()),
  clearSelectedTreeItem: () => dispatch(clearingSelectedTreeItem()),
  clearTranslationList: () => dispatch(clearTranslationWidgetList()),
});

export default connect(null, mapDispatchToProps)(Breadcrumb);
