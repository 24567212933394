import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, matchPath, Redirect } from "react-router-dom";
import { hasPermissions } from "utils/permissions-utils";
import routesPermissions from "utils/routes-permission";
import { userPermissionsMsg } from "actions/permissionsActions";

const PermissionRoute = ({
  userPermissions,
  dispatchUserPermissionsMsg,
  children,
}) => {
  const { enablePermissions, permissions } = userPermissions;
  const location = useLocation();
  const routePermission = routesPermissions.find(route =>
    matchPath(location.pathname, {
      path: route.value,
      exact: true,
      strict: false,
    })
  );

  useEffect(() => {
    if (
      !hasPermissions(
        permissions,
        routePermission.permission || [],
        enablePermissions
      )
    ) {
      dispatchUserPermissionsMsg(
        `Access Denied, You don’t have permission to access this URL.`
      );
    }
  }, []);

  return hasPermissions(
    permissions,
    routePermission.permission || [],
    enablePermissions
  ) ? (
    <div>{children}</div>
  ) : (
    <Redirect to="/" />
  );
};

const mapStateToProps = state => ({
  userPermissions: state.userPermissions,
});

const mapDispatchToProps = dispatch => ({
  dispatchUserPermissionsMsg: payload => dispatch(userPermissionsMsg(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionRoute);
