import { SET_LIST_GRID_VIEW } from "actions/listGridViewActions";

const initialState = {
  appView: "list",
  widgetView: "list",
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LIST_GRID_VIEW:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
