const GET_PERMISSIONS_REQUEST = "GET_PERMISSIONS_REQUEST";
const GET_PERMISSIONS_REQUEST_SUCCESS = "GET_PERMISSIONS_REQUEST_SUCCESS";
const GET_PERMISSIONS_REQUEST_FAIL = "GET_PERMISSIONS_REQUEST_FAIL";
const USER_PERMISSIONS = "USER_PERMISSIONS";
const USER_PERMISSIONS_ERROR = "USER_PERMISSIONS_ERROR";
const USER_PERMISSIONS_MSG = "USER_PERMISSIONS_MSG";
const CLEAR_USER_PERMISSIONS_MSG = "CLEAR_USER_PERMISSIONS_MSG";

const fetchPermissions = payload => ({
  type: GET_PERMISSIONS_REQUEST,
  payload,
});

const userPermissions = payload => ({
  type: USER_PERMISSIONS,
  payload,
});

const userPermissionsError = payload => ({
  type: USER_PERMISSIONS,
  payload,
});

const userPermissionsMsg = payload => ({
  type: USER_PERMISSIONS_MSG,
  payload,
});

const clearUserPermissionsMsg = () => ({
  type: USER_PERMISSIONS_MSG,
});

export {
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_REQUEST_SUCCESS,
  GET_PERMISSIONS_REQUEST_FAIL,
  USER_PERMISSIONS_ERROR,
  USER_PERMISSIONS,
  USER_PERMISSIONS_MSG,
  CLEAR_USER_PERMISSIONS_MSG,
  fetchPermissions,
  userPermissions,
  userPermissionsError,
  userPermissionsMsg,
  clearUserPermissionsMsg,
};
