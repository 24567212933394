import React from "react";

import Header from "../../components/header/Header";
import AppRouter from "../../routes/AppRouter";

const Container = () => {
  return (
    <>
      <Header />
      <AppRouter />
    </>
  );
};

export default Container;
