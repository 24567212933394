/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";

import {
  saveTenantData,
  fetchTenants,
  updateTenant,
} from "actions/tenantActions";
import VfDialogFull from "../vf/VfDialogFull";
import EditorForm from "../editor/EditorForm";
import VfAlert from "../vf/VfAlert";

const DialogAddTenant = ({ showDialog, setShowDialog }) => {
  const { data } = useSelector(state => state.configForm, shallowEqual);
  const { access_token, id_token } = useSelector(
    state => state.authentication,
    shallowEqual
  );
  const validations = useSelector(
    state => state.configForm.error,
    shallowEqual
  );
  const postDataSuccess = useSelector(
    state => state.configForm.postData,
    shallowEqual
  );
  const currentTenant = useSelector(
    state => state.tenants.current,
    shallowEqual
  );

  const dispatch = useDispatch();
  const saveTenant = params => dispatch(saveTenantData(params));
  const getTenants = params => dispatch(fetchTenants(params));
  const dispatchUpdateTenant = params => dispatch(updateTenant(params));

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (postDataSuccess) {
      getTenants({ access_token, id_token });
      setShowDialog();
    }
  }, [postDataSuccess]);

  useEffect(() => {
    if (validations) {
      console.log(validations);
      setOpenSnackbar(true);
    }
  }, [validations]);

  return (
    <VfDialogFull
      /* eslint-disable */
      appBar={
        <Grid container spacing={3} justify="space-between">
          <Grid item xs="auto">
            <Typography variant="body1" component="h5">
              {data?.locale?.title ? data.locale.title : "Add Tenant"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" component="h5">
              {data?.environments.length > 0
                ? `${
                    data.environments.length > 3
                      ? `${data.environments
                          .slice(0, 3)
                          .map(e => e.title)
                          .join(", ")} +${data.environments.length - 3}`
                      : data.environments.map(e => e.title).join(", ")
                  }`
                : ""}
            </Typography>
          </Grid>
        </Grid>
      }
      /* eslint-enable */
      buttonConfirmText="Save"
      hasValidations
      buttonConfirmDisabled={false}
      buttonConfirmAction={() => {}}
      saveAction={() => {
        if (!currentTenant)
          saveTenant({
            data,
            id_token,
            access_token,
          });
        else {
          dispatchUpdateTenant({
            data,
            id_token,
            access_token,
          });
        }
      }}
      buttonCloseAction={() => {
        setShowDialog();
      }}
      openDialog={showDialog}
    >
      <>
        <EditorForm isTenant currentTenant={currentTenant} />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
        >
          <VfAlert
            severity={0}
            message={
              typeof validations !== "undefined" && validations?.length
                ? "Please fill in all required fields."
                : validations?.message
            }
          />
        </Snackbar>
      </>
    </VfDialogFull>
  );
};

export default DialogAddTenant;
