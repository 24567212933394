/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
import React from "react";
import { connect } from "react-redux";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { loggedOut } from "actions/authenticationActions";
import VfSvgIcon from "../icons/VfSvgIcon";

const LoginLogoutBtn = props => {
  const { greeting, settings, auth, logout } = props;

  return (
    <div>
      {auth && auth.name ? (
        <span>
          {greeting && greeting.length ? (
            <span>
              {greeting}, {auth.name}
            </span>
          ) : null}
          <span className="nav-link" onClick={logout}>
            Logout
          </span>
        </span>
      ) : settings && settings.tenantId ? (
        <Fab
          color="secondary"
          href={`https://login.microsoftonline.com/${settings.tenantId}/oauth2/v2.0/authorize?client_id=${settings.clientId}
&response_type=code
&redirect_uri=${settings.redirectUri}
&response_mode=query
&scope=${settings.azureAppScope}
&state=12345`}
          rel="noopener noreferrer"
        >
          <VfSvgIcon icon="arrowRight" viewBox={24} />
        </Fab>
      ) : (
        <Typography vaiant="body1">Initializing...</Typography>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.authentication,
  settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(loggedOut()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginLogoutBtn);
