/* eslint-disable no-underscore-dangle */
import {
  GET_TENANTS_REQUEST,
  GET_TENANTS_REQUEST_SUCCESS,
  GET_TENANTS_REQUEST_FAIL,
  SET_CURRENT_TENANT,
  SHOW_TENANT_DIALOG,
  TENANT_VALIDATIONS_REQUEST,
  TENANT_VALIDATIONS_REQUEST_SUCCESS,
  TENANT_VALIDATIONS_REQUEST_FAIL,
  DELETE_ENV_FROM_TENANT,
  DELETE_TENANT_REQUEST_FAIL,
  FETCH_TENANT_BASIC_INFO_REQUEST_SUCCESS,
  FETCH_TENANT_BASIC_INFO_REQUEST_FAIL,
} from "actions/tenantActions";

const initialState = {
  loading: false,
  show: false,
  current: null,
  list: [],
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case GET_TENANTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TENANTS_REQUEST_SUCCESS:
      return {
        ...state,
        list: payload,
        loading: false,
      };
    case GET_TENANTS_REQUEST_FAIL:
      return {
        ...state,
        ...error,
        loading: false,
      };
    case TENANT_VALIDATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TENANT_VALIDATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        list: [
          ...state.list.slice(
            0,
            state.list.findIndex(item => item._id === payload._id)
          ),
          payload,
          ...state.list.slice(
            state.list.findIndex(item => item._id === payload._id) + 1
          ),
        ],
        loading: false,
      };
    case TENANT_VALIDATIONS_REQUEST_FAIL:
      return {
        ...state,
        ...error,
        loading: false,
      };
    case SET_CURRENT_TENANT:
      return {
        ...state,
        current: payload._id ? payload : null,
      };
    case DELETE_ENV_FROM_TENANT:
      return {
        ...state,
        current: {
          ...state.current,
          environments: state.current.environments.filter(
            ({ _id }) => _id !== payload
          ),
        },
      };
    case SHOW_TENANT_DIALOG:
      return {
        ...state,
        show: !state.show,
      };
    case DELETE_TENANT_REQUEST_FAIL:
      return {
        ...state,
        error,
      };
    case FETCH_TENANT_BASIC_INFO_REQUEST_FAIL:
      return {
        ...state,
        error,
      };
    case FETCH_TENANT_BASIC_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        current: payload,
      };
    default:
      return state;
  }
};
