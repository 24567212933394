/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import classNames from "classnames";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
// import ButtonGroup from "@material-ui/core/ButtonGroup";
// import Button from "@material-ui/core/Button";

import { setTouchpointsList } from "actions/touchpointsActions";
import theme from "AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    "& .MuiCard-details": {
      position: "relative",
    },
    "& .MuiChip-root": {
      "&.MuiChip-selected": {
        backgroundColor: "#007C92",
        color: theme.palette.common.white,
        "& .MuiSvgIcon-root": {
          "& path": {
            fill: theme.palette.common.white,
          },
          "& rect": {
            fill: theme.palette.common.white,
          },
          "& circle": {
            fill: theme.palette.common.white,
          },
          "& polygon": {
            fill: theme.palette.common.white,
          },
        },
      },
      "&.MuiChip-clickable": {
        "&:focus": {
          backgroundColor: undefined,
        },
      },
    },
  },
});

const AppsDetails = ({ touchpoints, setTouchpoints, showEdit, show }) => {
  const classes = useStyles();
  const history = useHistory();
  const { locale } = useParams();

  const handleClickChip = touchpoint => {
    let currentTouchpoints;
    if (touchpoints.includes(touchpoint)) {
      currentTouchpoints = touchpoints.filter(x => x !== touchpoint);
      setTouchpoints(currentTouchpoints);
    } else {
      currentTouchpoints = touchpoints.concat(touchpoint);
      setTouchpoints(currentTouchpoints);
    }
  };

  if (show || showEdit) return null;

  return (
    <Box bgcolor="common.white" className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <Card elevation={0} className="MuiCard-details animate">
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <CardContent>
                <Grid container spacing={3} direction="row" alignItems="center" data-testid="myAppsTitle_container">
                  <Grid item xs={false}>
                    <IconButton
                      onClick={() => {
                        history.push(`/${locale}/landing`);
                      }}
                      data-testid="back_btn"
                    >
                      <VfSvgIcon icon="arrowLeft" viewBox={24} />
                    </IconButton>
                  </Grid>
                  <Grid item sm data-testid="myAppsTitle_id">
                    <Typography variant="h4" component="h1" color="textPrimary">
                      My Apps
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs="auto">
                        <Chip
                          /* eslint-disable */
                          icon={
                            <VfSvgIcon
                              className="MuiChip-leftIcon"
                              icon="ios"
                              viewBox={24}
                            />
                          }
                          /* eslint-enable */
                          label="ios"
                          onClick={() => handleClickChip("ios")}
                          className={classNames({
                            "MuiChip-selected": touchpoints.includes("ios"),
                          })}
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <Chip
                          /* eslint-disable */
                          icon={
                            <VfSvgIcon
                              className="MuiChip-leftIcon"
                              icon="android"
                              viewBox={24}
                            />
                          }
                          /* eslint-enable */
                          label="android"
                          onClick={() => handleClickChip("android")}
                          className={classNames({
                            "MuiChip-selected": touchpoints.includes("android"),
                          })}
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <Chip
                          /* eslint-disable */
                          icon={
                            <VfSvgIcon
                              className="MuiChip-leftIcon"
                              icon="desktop"
                              viewBox={24}
                            />
                          }
                          /* eslint-enable */
                          label="web"
                          onClick={() => handleClickChip("web")}
                          className={classNames({
                            "MuiChip-selected": touchpoints.includes("web"),
                          })}
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <Chip
                          /* eslint-disable */
                          icon={
                            <VfSvgIcon
                              className="MuiChip-leftIcon"
                              icon="kiosk"
                              viewBox={24}
                            />
                          }
                          /* eslint-enable */
                          label="kiosk"
                          onClick={() => handleClickChip("kiosk")}
                          className={classNames({
                            "MuiChip-selected": touchpoints.includes("kiosk"),
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    touchpoints: state.touchpoints.list,
    show: state.applications.show,
    showEdit: state.applications.showEdit,
  };
};

const mapDispatchToProps = dispatch => ({
  setTouchpoints: payload => dispatch(setTouchpointsList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppsDetails);
