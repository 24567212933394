/* eslint-disable camelcase */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Fade from "@material-ui/core/Fade";
import theme from "AppTheme";
import { Box, Tooltip } from "@material-ui/core";

const LightTooltip = withStyles(() => ({
  tooltip: {
    marginTop: -theme.spacing(0.5),
    maxWidth: theme.spacing(40),
    backgroundColor: fade(theme.palette.text.primary, 0.9),
    color: theme.palette.common.white,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(2),
  },
}))(Tooltip);

const VfTooltip = ({ title, children }) => {
  return (
    <LightTooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title={title}
      interactive
    >
      <Box>{children}</Box>
    </LightTooltip>
  );
};

export default VfTooltip;
