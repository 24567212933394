const getVersionValidationMsg = (latestVer, inputVer, suggestedVer,appVersions) => {
  const currentVersionParts = latestVer.split(".");
  if (currentVersionParts.length !== 3) {
    return null;
  }

  try {
    const parts = inputVer.split(".");
    if (parts.length !== 3) {
      return `A valid version format should be {major}.{minor}.{patch}`;
    }

    if (appVersions.includes(inputVer)) {
      return `This Vesion Already exists`;
    }

    const major = parseInt(parts[0], 10);
    const minor = parseInt(parts[1], 10);
    const patch = parseInt(parts[2], 10);

    if (Number.isNaN(major) || Number.isNaN(minor) || Number.isNaN(patch)) {
      return `A valid version format should be {major}.{minor}.{patch}, where major, minor and patch are numbers.`;
    }

  } catch {
    return `Please provide a valid version. The suggested version is ${suggestedVer}`;
  }

  return null;
};

const getSuggestedVersion = latestVersion => {
  if (!latestVersion) {
    return "";
  }
  try {
    const parts = latestVersion.split(".");
    const patchNumber = parseInt(parts[2], 10) + 1;
    return `${parts[0]}.${parts[1]}.${patchNumber}`;
  } catch (e) {
    return "";
  }
};

// eslint-disable-next-line import/prefer-default-export
export { getVersionValidationMsg, getSuggestedVersion };
