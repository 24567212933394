import React from "react";
import { MenuItem, Grid, TextField } from "@material-ui/core";
import classNames from "classnames";

const EndEventElement = ({
  handleInputChange,
  element,
  selectedEffect,
  setSelectedEffect,
}) => {
  const effectTypes = [
    { name: "Put", value: "put" },
    { name: "Put Javascript", value: "put-js" },
  ];
  return (
    <>
      <Grid item xs={12}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={e => {
            setSelectedEffect({
              ...selectedEffect,
              effect: e.target.value,
            });
          }}
          label="Select Effect"
          value={selectedEffect.effect}
          variant="outlined"
          color="primary"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classNames("MuiFilledInput-disableUnderline"),
          }}
        >
          {effectTypes.map(({ name, value }) => (
            <MenuItem key={name.trim().toLowerCase()} value={value}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {selectedEffect.effect === "put" && (
        <Grid item xs={12}>
          <TextField
            select
            name="selectedWidget"
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              },
            }}
            onChange={e => {
              if (!selectedEffect.effect)
                setSelectedEffect({
                  ...selectedEffect,
                  effect: "put",
                  arguments: { children: {} },
                });
              handleInputChange(e);
            }}
            label="Select Widget"
            value={element.selectedWidget || ""}
            variant="outlined"
            color="primary"
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
          >
            {element?.widgetList.map(a => {
              return (
                <MenuItem key={a.name} value={a.name}>
                  {a.description}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
      )}
      {selectedEffect.effect === "put" && (
        <Grid item xs={12}>
          <TextField
            select
            name="type"
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              },
            }}
            onChange={handleInputChange}
            label="Select Action"
            value={selectedEffect.type || ""}
            variant="outlined"
            color="primary"
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
          >
            {element.selectedWidget &&
              element?.actions[element.selectedWidget].map(a => {
                const [key] = Object.keys(a);
                return (
                  <MenuItem
                    key={key}
                    value={
                      element.selectedWidget === "Global"
                        ? key
                        : `@${element.selectedWidget}/${key}`
                    }
                  >
                    {element.selectedWidget === "Global"
                      ? key
                      : `@${element.selectedWidget}/${key}`}
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
      )}
    </>
  );
};

export default EndEventElement;
