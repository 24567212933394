const routesPermissions = [
  {
    label: "locale",
    value: "/:locale",
  },
  {
    label: "landing",
    value: "/:locale/landing/",
  },
  {
    label: "configuration",
    value: "/:locale/env",
    permission: ["view_configurations"],
  },
  {
    label: "configuration",
    value: "/:locale/configurator",
    permission: ["view_configurations"],
  },
  {
    label: "envConfiguration",
    value: "/:locale/env/:env",
    permission: ["view_configurations"],
  },
  {
    label: "touchpointConfiguration",
    value: "/:locale/env/:env/:touchpoint",
    permission: ["view_configurations"],
  },
  {
    label: "appConfiguration",
    value: "/:locale/env/:env/:touchpoint/:app",
    permission: ["view_configurations"],
  },
  {
    label: "formConfiguration",
    value: "/:locale/env/:env/:touchpoint/:app/:widget/form",
    permission: ["view_configurations"],
  },
  {
    label: "translation",
    value: "/:locale/translations",
    permission: ["view_translations"],
  },
  {
    label: "envTranslation",
    value: "/:locale/translations/:env",
    permission: ["view_translations"],
  },
  {
    label: "widgetTranslation",
    value: "/:locale/translations/:env/:widget/:version",
    permission: ["view_translations"],
  },
  {
    label: "roles",
    value: "/:locale/roles/",
    permission: ["view_roles"],
  },
  {
    label: "media",
    value: "/:locale/media/",
    permission: ["view_media"],
  },
  {
    label: "createApp",
    value: "/:locale/apps/",
    permission: ["view_apps"],
  },
  {
    label: "tenant",
    value: "/tenant",
    permission: ["view_tenant"],
  },
  {
    label: "sagas",
    value: "/:locale/apps/sagas/:sagaId",
    permission: ["view_apps"],
  },
  {
    label: "apis",
    value: "/:locale/apis/",
    permission: ["view_apis"],
  },
];

export default routesPermissions;
