/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import classNames from "classnames";
import VfSvgIcon from "../icons/VfSvgIcon";

const FilterMenuEnvironments = ({
  envs,
  clearEnvironment,
  setEnvironment,
  activeEnv,
  skipAllOption,
  isDisabled,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterActive, setFilterActive] = useState(
    activeEnv || "All environments"
  );

  useEffect(() => {
    return () => {
      clearEnvironment();
    };
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        startIcon={
          <VfSvgIcon icon={filterActive.toLowerCase()} fontSize="small" />
        }
        endIcon={<VfSvgIcon icon="chevronDown" fontSize="small" />}
        onClick={handleClick}
        className={classNames({
          [`MuiButton-${filterActive.toLowerCase()}`]: true,
          "MuiButton-selected": filterActive !== "All environments",
        })}
        disabled={isDisabled}
        data-testid="allenvironments_btn"
      >
        {filterActive}
      </Button>
      <Menu
        id="simple-menu"
        elevation={0}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          {!skipAllOption && (
            <MenuItem
              onClick={() => {
                handleClose();
                setFilterActive("All environments");
                clearEnvironment();
              }}
              selected={filterActive === "All environments"}
              className="MuiListItem"
              data-testid="allenvironments_listItem"
            >
              <ListItemText secondary="All environments" />
            </MenuItem>
          )}

          {envs.list
            .filter(e => !e.isGlobal)
            .sort((a, b) => a.order - b.order)
            .map(env => {
              return (
                <MenuItem
                  key={env._id}
                  onClick={() => {
                    handleClose();
                    setFilterActive(env.title);
                    setEnvironment({ type: env.type, id: env._id });
                  }}
                  selected={env.title === filterActive}
                  className={`MuiListItem-${env.title.toLowerCase()} ${env.title &&
                    "MuiListItem-withListIcon"}`}
                >
                  {env.title && (
                    <ListItemIcon>
                      <VfSvgIcon icon={env.title.toLowerCase()} />
                    </ListItemIcon>
                  )}
                  <ListItemText secondary={env.title} />
                </MenuItem>
              );
            })}
        </div>
      </Menu>
    </>
  );
};

export default FilterMenuEnvironments;
