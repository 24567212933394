/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useMemo, useState } from "react";
import { isEmpty } from "lodash";
import {
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
  Typography,
} from "@material-ui/core";
import EditorFormNoData from "components/editor/EditorFormNoData";
import AceEditor from "react-ace";
import ReactMarkdown from "react-markdown";
import { makeStyles } from "@material-ui/core/styles";
import SecurityDefinitions from "./SecurityDefinitions";
import AppsBuilder from "./AppsBuilder";
import AppsBuilderStart from "./AppsBuilderStart";
import AppsTemplates from "./AppsTemplates";
import AppEditSaga from "./AppEditSaga";
import AppsMenus from "./AppsMenus";
import AppEditBlock from "./AppEditBlock";
import AppEditCode from "./AppEditCode";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import VfSvgIcon from "../icons/VfSvgIcon";

const minHeightDialogHeaderPlusAppBar = 25;
const toolBarHeight = 49;

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiExpansionPanelSummary-root": {
      "& .MuiExpansionPanelSummary-content": {
        "&.Mui-expanded": {
          marginTop: theme.spacing(1.5),
          marginBottom: theme.spacing(1.5),
          color: theme.palette.secondary.main,
        },
      },
      "& .MuiExpansionPanelSummary-expandIcon": {
        transform: "rotate(270deg)",
        "&.Mui-expanded": {
          transform: "rotate(0deg)",
          "& .MuiSvgIcon-root": {
            fill: theme.palette.secondary.main,
          },
        },
      },
    },
  },
}));

const functionType = ({ type, parameters }) => {
  if (!(type && parameters)) return "[untyped]";
  const joinedParameters = parameters.map(({ name, type: paramType }) => `${name}: ${paramType}`).join(",");
  return `(${joinedParameters}) => ${type}`;
};

const AppDialogStarter = ({
  application,
  appBarHeight,
  dispatchShowCreatePage,
  sidebarItem,
  sagaId,
  apiId,
  snippetId,
  packageId,
  updateSnippet,
  snippetCode,
  djr,
  setDjr,
}) => {
  const classes = useStyles();
  const [expandedReadme, setExpandedReadme] = useState(false);
  const [expandedFunctions, setExpandedFunctions] = useState(false);

  const selectedPackage = useMemo(() => application.appInfo.packages?.find(p => p._id === packageId) || {}, [
    packageId,
  ]);
  const renderStarterComponent = () => {
    const { showAddPage, appSection } = application;
    if (appSection === "design" && sidebarItem === "pageList" && !showAddPage && !application.showAddTemplate)
      return (
        <Box p={3} height="100%" bgcolor="grey.100">
          <EditorFormNoData text="Please make a selection from the menu on the left" />
        </Box>
      );
    if (appSection === "design" && !showAddPage && !application.pages.length && !application.loading)
      return <AppsBuilderStart appBarHeight={appBarHeight} handleOpenDialogPage={dispatchShowCreatePage} />;
    if (appSection === "design" && showAddPage && !application.showAddTemplate)
      return <AppsTemplates appBarHeight={appBarHeight} currentPage={application.currentPage} />;
    if (appSection === "design" && showAddPage && application.showAddTemplate)
      return (
        <>
          {!isEmpty(application.currentPage) &&
            application.currentPage.djr &&
            !application.currentPage.djr.__props.id && (
              <AppsBuilder
                isDeployed={application.appInfo.isDeployed}
                spacing={application.currentPage.spacing}
                djr={application.currentPage.djr}
              />
            )}
          {!isEmpty(application.currentPage) &&
            application.currentPage.djr &&
            application.currentPage.djr.__props.id && (
              <AppEditBlock appBarHeight={appBarHeight} page={application.currentPage} />
            )}
        </>
      );
    if (appSection === "logic" && sidebarItem === "javascript" && snippetId) {
      return (
        <Box p={3} height="100%" bgcolor="grey.100">
          <AceEditor
            style={{ height: "calc(100vh - 201px)", width: "100%" }}
            mode="javascript"
            theme="tomorrow"
            value={snippetCode}
            onChange={code => updateSnippet(snippetId, code)}
            debounceChangePeriod={1000}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
            }}
          />
        </Box>
      );
    }
    if (appSection === "logic" && sidebarItem === "packagesList" && packageId) {
      return (
        <Box p={3} height="100%">
          <ExpansionPanel
            className={classes.root}
            square
            elevation={0}
            expanded={expandedReadme}
            onChange={() => setExpandedReadme(!expandedReadme)}
          >
            <ExpansionPanelSummary
              IconButtonProps={{ edge: false }}
              expandIcon={<VfSvgIcon icon="chevronDown" viewBox={24} />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography variant="h4">Read Me</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box>
                <ReactMarkdown source={selectedPackage.readme || selectedPackage.description} />
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <Divider />
          <ExpansionPanel
            className={classes.root}
            square
            elevation={0}
            expanded={expandedFunctions}
            onChange={() => setExpandedFunctions(!expandedFunctions)}
          >
            <ExpansionPanelSummary
              IconButtonProps={{ edge: false }}
              expandIcon={<VfSvgIcon icon="chevronDown" viewBox={24} />}
              aria-controls="panel1d-content"
              id="panel1d-header2"
            >
              <Typography variant="h4">Exports</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box>
                {selectedPackage.declarations.map(({ parameters, name, type }) => (
                  <div>
                    <Typography variant="h5">{name}</Typography>
                    {functionType({ type, parameters })}
                  </div>
                ))}
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>
      );
    }
    if (appSection === "logic")
      if (sidebarItem === "apiList" && !application.appInfo.apis) {
        return (
          <Box p={3} height="100%" bgcolor="grey.100">
            <EditorFormNoData minHeight={minHeightDialogHeaderPlusAppBar} />
          </Box>
        );
      } else if (sidebarItem === "apiList" && !apiId) {
        return (
          <Box p={3} height="100%" bgcolor="grey.100">
            <EditorFormNoData
              minHeight={minHeightDialogHeaderPlusAppBar}
              text="Please make a selection from the menu on the left"
            />
          </Box>
        );
      } else if (sidebarItem === "apiList" && apiId) {
        return <SecurityDefinitions minHeight={minHeightDialogHeaderPlusAppBar} apiId={apiId} />;
      } else if (sidebarItem === "appLogicList" && application.appInfo.sagas.length === 0)
        return (
          <Box p={3} height="100%" bgcolor="grey.100">
            <EditorFormNoData minHeight={minHeightDialogHeaderPlusAppBar} />
          </Box>
        );
      else if (sidebarItem === "appLogicList" && sagaId) {
        return <AppEditSaga sagaId={sagaId} />;
      } else {
        return (
          <Box p={3} height="100%" bgcolor="grey.100">
            <EditorFormNoData
              minHeight={minHeightDialogHeaderPlusAppBar}
              text="Please make a selection from the menu on the left"
            />
          </Box>
        );
      }
    if (appSection === "editor") return <AppEditBlock />;
    if (appSection === "code")
      return <AppEditCode djr={djr} setDjr={setDjr} appBarHeight={appBarHeight} toolBarHeight={toolBarHeight} />;
    if (appSection === "menu")
      return (
        <Box maxWidth={600} mx="auto">
          <AppsMenus />
        </Box>
      );
    return null;
  };

  return renderStarterComponent();
};

export default AppDialogStarter;
