/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "AppTheme";
import MenuIconButton from "../common/MenuIconButton";
import UpdateWidgetDialog from "./UpdateWidgetDialog";

const useStyles = makeStyles({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  listItemAction: {
    right: theme.spacing(3),
    top: theme.spacing(2),
    transform: "none",
  },
});

const WidgetMoreMenu = ({ widget, className }) => {
  const classes = useStyles();
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  const items = [{ id: 1, label: "Update" }];
  const onItemClick = item => {
    if (item.id === 1) {
      setOpenUpdateDialog(true);
    }
  };

  return (
    <>
      <MenuIconButton
        items={items}
        disabled={false}
        className={className}
        onClick={onItemClick}
        onClose={() => {}}
      />
      <UpdateWidgetDialog
        isOpen={openUpdateDialog}
        widget={widget}
        onCancel={() => setOpenUpdateDialog(false)}
      />
    </>
  );
};

export default WidgetMoreMenu;
