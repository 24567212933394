/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { cloneDeep } from "lodash";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import theme from "AppTheme";
import { fade } from "@material-ui/core/styles/colorManipulator";

import { deleteRow, addCol, moveRow } from "utils/template-utils";
import {
  setCurrentPage as setCurrentPageAction,
  showSideBar,
  showSnackbar,
  setApp,
  setSelectedWidget,
  setCurrRowCol,
  clearSelectedWidget,
} from "actions/applicationsActions";
import { registry } from "renderer";
import jp from "jsonpath";
import { useDrag, useDrop } from "react-dnd";
import VfSvgIcon from "../icons/VfSvgIcon";
import { setAppSidebar } from "../../actions/applicationsActions";

const useStyles = makeStyles({
  root: {
    height: "calc(100% + 24px)",
    position: "relative",
    overflow: "hidden",
    "&.Mui-hover": {
      "& .MuiBox-root": {
        opacity: 1,
      },
    },
    "&.Mui-selected": {
      "& .MuiBox-root": {
        opacity: 1,
        borderColor: theme.palette.primary.main,
        "& .MuiAppBar-root": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
  overlay: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.text.primary,
    pointerEvents: "none",
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.standard,
    }),
  },
  appbar: {
    pointerEvents: "all",
    backgroundColor: fade(theme.palette.text.primary, 0.9),
  },
  toolbar: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  hide: {
    height: 0,
    opacity: 0,
    paddingTop: 0,
    paddingBottom: 0,
    transition: theme.transitions.create(
      ["height", "opacity", "padding-top", "padding-bottom"],
      {
        duration: 2000,
      }
    ),
  },
});

const gridColumnsMap = [
  {
    xs: 12,
    sm: 12,
    md: 12,
  },
  {
    xs: 12,
    sm: 6,
    md: 6,
  },
  {
    xs: 12,
    sm: 4,
    md: 4,
  },
  {
    xs: 12,
    sm: 6,
    md: 3,
  },
  {
    xs: 12,
    sm: 6,
    md: 2,
  },
];

const GridWrapper = ({
  application,
  setCurrentPage,
  spacing,
  setSidebarItem,
  showSnackBar,
  setApplication,
  setWidget,
  componentJSON,
  children,
  clearWidget,
  isDeployment,
}) => {
  const { currentPage, appInfo } = application;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hover, setHover] = useState(false);
  const [hideRow, setHideRow] = useState(true);
  const colLimit = 4;
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHover = value => {
    setHover(value);
  };

  const removeWidgetFromApplication = () => {
    const clonedAppInfo = cloneDeep(appInfo);
    const childrenNodes = jp.query(
      componentJSON,
      "$..children[?(@.__props.__instanceId)]"
    );
    childrenNodes.forEach(({ __props: widget }) => {
      const isFatComponent = !registry[widget.name];
      if (widget && widget.name && isFatComponent) {
        const found = clonedAppInfo.widgets.findIndex(
          w => w.name === widget.name
        );
        if (clonedAppInfo?.widgets[found]?.occurrences <= 1) {
          clonedAppInfo.widgets.splice(found, 1);
        } else {
          clonedAppInfo?.widgets[found]? clonedAppInfo.widgets.splice(found, 1, {
            _id: clonedAppInfo?.widgets[found]?._id,
            version: clonedAppInfo?.widgets[found].version,
            name: widget.name,
            occurrences: clonedAppInfo?.widgets[found].occurrences - 1,
            instances: clonedAppInfo.widgets[found].instances.filter(
              inst => inst.uuid !== widget.__instanceId
            ),
          }) : null;
        }
      }
    });
    setApplication(clonedAppInfo);
  };

  const ref = useRef(null);
  const acceptElm = "row";
  const jsonPath = jp.paths(
    currentPage.djr,
    `$.__props.children[?(@.__props.id == '${componentJSON.__props.id}')]`
  )[0];
  const index = jsonPath?.[jsonPath.length - 1] || 0;
  const [, drop] = useDrop({
    accept: acceptElm,
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      setSidebarItem("widgetList");
      const djr = moveRow(dragIndex, hoverIndex, currentPage.djr);
      setCurrentPage({ ...currentPage, djr });
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: acceptElm, id: componentJSON.__props.id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  return (
    <Grid item xs={12} ref={!isDeployment ? ref : null} style={{ opacity }}>
      <Collapse in={hideRow} timeout={theme.transitions.duration.complex}>
        <Grid
          container
          spacing={spacing}
          className={classNames(classes.root, {
            "Mui-hover": hover,
            "Mui-selected": false,
          })}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          {React.Children.map(children, child => (
            <Grid item {...gridColumnsMap[React.Children.count(children) - 1]}>
              {child}
            </Grid>
          ))}

          <Box className={classes.overlay}>
            <AppBar position="static" className={classes.appbar}>
              <Toolbar
                variant="dense"
                className={classes.toolbar}
                disableGutters
              >
                <Box>
                  <IconButton
                    className="MuiIconButton-colorWhite"
                    title="Move row"
                  >
                    <VfSvgIcon icon="move" viewBox={24} />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    className="MuiIconButton-colorWhite"
                    onClick={handleClick}
                  >
                    <VfSvgIcon icon="more" viewBox={24} />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    elevation={0}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    getContentAnchorEl={null}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setWidget({
                          name: "Grid",
                          id: componentJSON.__props.id,
                          propsSchema: JSON.stringify({
                            title: "Grid",
                            type: "object",
                            required: ["spacing"],
                            properties: {
                              spacing: {
                                type: "integer",
                                title: "spacing",
                                default: 0,
                                enum: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                              },
                              style: {
                                type: "object",
                                title: "Style",
                                default: {},
                                properties: {
                                  paddingTop: {
                                    type: "integer",
                                    title: "Padding Top",
                                    default: 0,
                                  },
                                  paddingBottom: {
                                    type: "integer",
                                    title: "Padding Bottom",
                                    default: 0,
                                  },
                                  paddingLeft: {
                                    type: "integer",
                                    title: "Padding Left",
                                    default: 0,
                                  },
                                  paddingRight: {
                                    type: "integer",
                                    title: "Padding Right",
                                    default: 0,
                                  },
                                  margin: {
                                    type: "string",
                                    title: "Margin",
                                    default: "0px 0px 0px 0px"
                                  }
                                },
                              },
                              justify: {
                                type: "string",
                                title: "justify",
                                default: "",
                                enum: [
                                  "",
                                  "flex-start",
                                  "center",
                                  "flex-end",
                                  "space-between",
                                  "space-around",
                                  "space-evenly",
                                ],
                              },
                              alignItems: {
                                type: "string",
                                title: "alignItems",
                                default: "",
                                enum: [
                                  "",
                                  "flex-start",
                                  "center",
                                  "flex-end",
                                  "stretch",
                                  "baseline",
                                  "stretch",
                                ],
                              },
                              direction: {
                                type: "string",
                                title: "direction",
                                default: "",
                                enum: ["", "row", "row-reverse"],
                              },
                            },
                          }),
                        });
                        setSidebarItem("widgetEditor");
                      }}
                    >
                      EDIT SECTION
                    </MenuItem>
                    {!isDeployment && (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          if (React.Children.count(children) < colLimit) {
                            const { djr } = addCol(
                              componentJSON.__props.id,
                              currentPage
                            );
                            setCurrentPage({
                              ...currentPage,
                              djr,
                            });
                          } else {
                            showSnackBar({
                              show: true,
                              message: `Error: Column limit is ${colLimit}`,
                              severity: 0,
                            });
                          }
                        }}
                      >
                        ADD COLUMN
                      </MenuItem>
                    )}
                    {!isDeployment && (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          clearWidget();
                          if (
                            jp.query(
                              currentPage.djr,
                              `$.__props.children.length`
                            )[0] < 2
                          ) {
                            showSnackBar({
                              show: true,
                              message: "You cant remove last row",
                              severity: 0,
                            });
                          } else {
                            setHideRow(false);
                            removeWidgetFromApplication();
                            setTimeout(() => {
                              const djr = deleteRow(
                                componentJSON.__props.id,
                                currentPage.djr
                              );
                              setCurrentPage({
                                ...currentPage,
                                djr,
                              });
                            }, theme.transitions.duration.complex);
                          }
                        }}
                      >
                        DELETE ROW
                      </MenuItem>
                    )}
                  </Menu>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
        </Grid>
      </Collapse>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    application: state.applications.current,
    selectedRow: state.applications.selectedRow,
    selectedColumn: state.applications.selectedColumn,
    isDeployment: state.applications.current?.appInfo?.isDeployed,
  };
};

const mapDispatchToProps = dispatch => ({
  setCurrentPage: payload => dispatch(setCurrentPageAction(payload)),
  dispatchshowSideBar: payload => dispatch(showSideBar(payload)),
  showSnackBar: payload => dispatch(showSnackbar(payload)),
  setApplication: payload => dispatch(setApp(payload)),
  setWidget: payload => dispatch(setSelectedWidget(payload)),
  setRowCol: payload => dispatch(setCurrRowCol(payload)),
  setSidebarItem: payload => dispatch(setAppSidebar(payload)),
  clearWidget: () => dispatch(clearSelectedWidget()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridWrapper);
