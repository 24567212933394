/* eslint-disable camelcase */
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Fade from "@material-ui/core/Fade";

import theme from "AppTheme";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {},
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalContent: {
    textAlign: "center",
    "& p": {
      marginBottom: 0,
    },
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  modalActions: {
    justifyContent: "center",
  },
});

const LightTooltip = withStyles(() => ({
  tooltip: {
    marginTop: -theme.spacing(0.5),
    maxWidth: theme.spacing(40),
    backgroundColor: fade(theme.palette.text.primary, 0.9),
    color: theme.palette.common.white,
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(2),
  },
}))(Tooltip);

const CardRole = ({
  name,
  showRole,
  securityGroups,
  permissions,
  setCurrentRole,
  deleteRole,
  auth,
  id,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const { access_token, id_token } = auth;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenDialogDelete = () => {
    setOpenDialogDelete(!openDialogDelete);
  };

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-widget": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-error": false,
        "MuiCard-outlined-warning": false,
        "MuiCard-outlined-info": false,
        "MuiCard-outlined-success": false,
        "MuiCard-rounded": true,
        animate: true,
      })}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <CardActionArea
            onClick={() => {
              showRole();
              setCurrentRole({ id, name, securityGroups, permissions });
            }}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs>
                <CardContent>
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item xs={12} sm="auto">
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <Typography variant="body1" component="h5">
                            {name}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <LightTooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={`${
                              permissions
                                ? permissions.map(e => e.name).join(", ")
                                : ""
                            }`}
                            interactive
                          >
                            <Typography variant="body2" color="textSecondary">
                              {`${
                                permissions.length > 3
                                  ? `${permissions
                                      .slice(0, 3)
                                      .map(e => e.name)
                                      .join(", ")} +${permissions.length - 3}`
                                  : permissions.map(e => e.name).join(", ")
                              }`}
                            </Typography>
                          </LightTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Grid>
        <Grid item xs="auto">
          <CardActions disableSpacing>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <LightTooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={`${securityGroups ? securityGroups.join(", ") : ""}`}
                  interactive
                >
                  <Typography variant="body2">{`${securityGroups.length} Groups`}</Typography>
                </LightTooltip>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="add to favorites"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <VfSvgIcon icon="more" viewBox={24} />
                </IconButton>
                <Menu
                  id="simple-menu"
                  elevation={0}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  getContentAnchorEl={null}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setCurrentRole({ id, name, securityGroups, permissions });
                      showRole();
                    }}
                  >
                    EDIT
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      handleOpenDialogDelete();
                    }}
                  >
                    DELETE
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </CardActions>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={openDialogDelete}
        onClose={handleClose}
        maxWidth="xs"
      >
        <DialogContent className={classes.modalContent}>
          <DialogContentText gutterBottom>
            Are you sure you want to delete
          </DialogContentText>
          <DialogContentText>
            <strong>{name}</strong>
          </DialogContentText>
          <DialogContentText>role permanently?</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button
            color="default"
            variant="outlined"
            onClick={() => handleOpenDialogDelete()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => deleteRole({ access_token, id_token, id })}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default CardRole;
