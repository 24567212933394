/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from "react";
import { Grid, Button, IconButton } from "@material-ui/core";
import uuidv4 from "uuid/v4";
import { set, get, omit } from "lodash";

import ApiParamsTree from "./ApiParamsTree";
import VfSvgIcon from "../icons/VfSvgIcon";
import CallPutTreeViewField from "./CallPutTreeViewField";
import FunctionField from "./FunctionField";
import ArrayList from "./ArrayList";

const EndEventPayload = ({
  selectedEffect,
  setSelectedEffect,
  element,
  modeler,
}) => {
  const [paramProperty, setParamProperty] = useState(null);
  const [isLeaf, setIsLeaf] = useState(false);
  const [schema, setSchema] = useState({});
  const [isArray, setIsArray] = useState(false);
  const [childrenSchema, setChildrenSchema] = useState(null);
  const [expanded, setExpanded] = useState(["$", `$.children`]);

  const handleSetExpanded = useCallback(value => setExpanded(value), []);

  useEffect(() => {
    const actionSchema = {
      ...Object.values(
        element?.actions[element.selectedWidget].find(a =>
          selectedEffect.type.includes(Object.keys(a)[0] ?? "")
        ) ?? {}
      )[0],
    };
    setSchema({
      title: "Action Schema",
      type: "object",
      properties: {
        instanceId: {
          title: "instanceId",
          type: "string",
        },
        payload: {
          title: "payload",
          ...actionSchema,
        },
      },
    });
  }, [selectedEffect.type]);

  const handleClickNode = node => {
    setParamProperty(node.id.substring(2));
    if (node.showArray) {
      setIsArray(true);
      setChildrenSchema(node.childrenSchema);
    } else {
      setIsArray(false);
      setChildrenSchema(null);
    }
    if ((!node.children || !node.children.length) && !node.showArray) {
      setIsLeaf(true);
    } else {
      setIsLeaf(false);
    }
  };

  const onAddItem = () => {
    if (isLeaf) {
      const newParamFields = [
        ...(get(selectedEffect.arguments, `${paramProperty}.values`) ?? []),
      ];
      newParamFields.push({
        key: uuidv4(),
        value: "",
      });
      let obj;
      if (paramProperty) {
        obj = {
          ...selectedEffect.arguments,
        };
        set(obj, `${paramProperty}.values`, newParamFields);
      }
      setSelectedEffect({
        ...selectedEffect,
        arguments: {
          ...selectedEffect.arguments,
          ...obj,
        },
      });
    } else if (!get(selectedEffect.arguments, paramProperty)) {
      let obj;
      if (paramProperty) {
        obj = {
          ...(selectedEffect.arguments ?? {}),
        };
        set(obj, paramProperty, {});
      }

      const newExpaned = expanded;
      newExpaned.unshift(`$.${paramProperty}`);
      setExpanded(newExpaned);

      setSelectedEffect({
        ...selectedEffect,
        arguments: {
          ...selectedEffect.arguments,
          ...obj,
        },
      });
    } else {
      const obj = omit(selectedEffect.arguments, [paramProperty]);
      setSelectedEffect({
        ...selectedEffect,
        arguments: {
          ...obj,
        },
      });
    }
  };

  const onDeleteItem = index => {
    const newParamFields = [
      ...(paramProperty
        ? get(selectedEffect.arguments, `${paramProperty}.values`) ?? []
        : []),
    ];
    newParamFields.splice(index, 1);
    let obj;
    if (paramProperty) {
      obj = {
        ...selectedEffect.arguments,
      };
      set(obj, `${paramProperty}.values`, newParamFields);
    }
    setSelectedEffect({
      ...selectedEffect,
      arguments: {
        ...selectedEffect.arguments,
        ...obj,
      },
    });
  };

  const onAddElement = () => {
    const newParamFields =
      get(selectedEffect.arguments, `${paramProperty}`) ?? [];
    if (childrenSchema?.type === "object") {
      newParamFields.push({
        key: uuidv4(),
        children: {},
      });
    } else {
      newParamFields.push({
        key: uuidv4(),
        function: "",
        values: [{ key: uuidv4(), path: "" }],
      });
    }

    const newExpaned = expanded;
    newExpaned.unshift(`$.${paramProperty}`);
    setExpanded(newExpaned);

    const obj = {
      ...(selectedEffect.arguments ?? {}),
    };
    set(obj, `${paramProperty}`, newParamFields);

    setSelectedEffect({
      ...selectedEffect,
      arguments: {
        ...selectedEffect.arguments,
        ...obj,
      },
    });
  };

  const onRemoveElement = index => {
    const newParamFields =
      get(selectedEffect.arguments, `${paramProperty}`) ?? [];

    newParamFields.splice(index, 1);
    const obj = {
      ...(selectedEffect.arguments ?? {}),
    };
    set(obj, `${paramProperty}`, newParamFields);

    setSelectedEffect({
      ...selectedEffect,
      arguments: {
        ...selectedEffect.arguments,
        ...obj,
      },
    });
  };

  return (
    <Grid container>
      <Grid item xs={3}>
        <ApiParamsTree
          schema={schema}
          name="Root"
          isPutEffect
          args={selectedEffect.arguments}
          handleClickNode={handleClickNode}
          handleSetExpanded={handleSetExpanded}
          parentExpanded={expanded}
        />
      </Grid>
      <Grid item xs={9}>
        {paramProperty && !isArray && (
          <Grid container spacing={3}>
            {[
              ...(paramProperty
                ? get(selectedEffect.arguments, `${paramProperty}.values`) ?? []
                : []),
            ].map((f, fIndex) => (
              <Grid key={f.key} item xs={12}>
                <Grid
                  container
                  spacing={3}
                  wrap="nowrap"
                  alignItems="center"
                  key={f.key}
                >
                  <Grid item xs>
                    <CallPutTreeViewField
                      key={`${f.key}-field`}
                      title={paramProperty?.split(".")?.pop()}
                      element={element}
                      modeler={modeler}
                      setSelectedEffect={setSelectedEffect}
                      selectedEffect={selectedEffect}
                      fieldIndex={fIndex}
                      paramProperty={paramProperty}
                      fValue={f.path || f.code || f.literal}
                      valueType={
                        f.literal ? "static" : f.code ? "javascript" : "dynamic"
                      }
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton
                      onClick={() => {
                        onDeleteItem(fIndex);
                      }}
                    >
                      <VfSvgIcon icon="delete" viewBox={24} fontSize="large" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs="auto">
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<VfSvgIcon icon="add" viewBox={24} />}
                    onClick={onAddItem}
                  >
                    {isLeaf
                      ? "Add item"
                      : !get(selectedEffect.arguments, paramProperty)
                      ? "Add item"
                      : "Remove item"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FunctionField
                show={
                  !!get(selectedEffect.arguments, `${paramProperty}.values`)
                    ?.length
                }
                paramProperty={paramProperty}
                setSelectedEffect={setSelectedEffect}
                selectedEffect={selectedEffect}
              />
            </Grid>
          </Grid>
        )}
        {paramProperty && isArray && (
          <ArrayList
            onAddElement={onAddElement}
            onRemoveElement={onRemoveElement}
            items={get(selectedEffect.arguments, `${paramProperty}`) ?? []}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EndEventPayload;
