/* eslint-disable camelcase */
import axios from "axios";
import getCookie from "utils/getCookie";

class ConfigService {
  fetchConfigSchema = async ({ widget, envID }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/configuration/schema/${widget}`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config schema service:", err);
      return err;
    }
  };

  fetchConfigData = async params => {
    try {
      const {
        application,
        widget,
        envID,
        id_token,
        access_token,
        widgetInstanceId,
      } = params;
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/configuration/${application}/${widget}/?instance=${widgetInstanceId}`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
          dbname: envID,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in config data service:", err);
      return err;
    }
  };

  postConfigData = async payload => {
    const { access_token, id_token, envID } = payload;
    const body = {
      data: payload.data,
      componentId: payload.widget,
      widgetInstanceId: payload.widgetInstanceId,
      application: payload.app,
      deploy: !!payload.deploy,
    };
    try {
      const response = await axios({
        method: "post",
        url: `/api/appbuilder/configuration`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
          dbname: envID,
        },
        data: body,
      });
      return response.data;
    } catch (err) {
      console.log("Error in update config schema service:", err);
      return err;
    }
  };
}

const inst = new ConfigService();
export default inst;
