/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-useless-computed-key */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import VfDialog from "../vf/VfDialog";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  appName: {
    fontWeight: "bold",
  },
  appDialogContainer: {
    textAlign: "center",
  },
  appDialogItem: {
    display: "flex",
    justifyContent: "center",
  },
  dialogConfirmAction: {
    justifyContent: "center",
  },
  p16: {
    padding: 16,
  },
});

const DeleteAppErrorDialog = ({ application, open, onButtonConfirm, envs }) => {
  const classes = useStyles();
  return (
    <VfDialog
      openDialog={open}
      buttonConfirmAction={onButtonConfirm}
      maxWidth="xs"
      buttonConfirmText="Ok"
      classDialogActions={classes.dialogConfirmAction}
    >
      <Title application={application} />
      <Grid container direction="row" className={classes.appDialogContainer}>
        <Grid
          className={`${classes.appDialogItem} ${classes.p16}`}
          item
          xs={12}
        >
          <List>
            {envs.map(({ title, type }) => {
              return (
                <ListItem key={title + type}>
                  <ListItemIcon>
                    <VfSvgIcon icon={type} viewBox={56} />
                  </ListItemIcon>
                  <ListItemText>{title}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid className={classes.p16} item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </VfDialog>
  );
};

const Title = ({ application }) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" className={classes.appDialogContainer}>
      <Grid item xs={12}>
        <Typography variant="h5" display="inline" className={classes.appName}>
          {`${application.title} `}
        </Typography>
        <Typography
          variant="h5"
          display="inline"
          className={classes.appName}
          color="secondary"
        >
          {`v${application.version}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" component="h3">
          cannot be deleted as it is currently deployed in the following
          environments:
        </Typography>
      </Grid>
      <Grid className={classes.p16} item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default DeleteAppErrorDialog;
