const SET_ENV = "SET_ENV";
const CLEAR_ENV = "CLEAR_ENV";
const ENVS_REQUEST = "ENVS_REQUEST";
const ENVS_REQUEST_SUCCESS = "ENVS_REQUEST_SUCCESS";
const ENVS_REQUEST_FAIL = "ENVS_REQUEST_FAIL";
const URLS_RESOURCES_REQUEST = "URLS_RESOURCES_REQUEST";
const URLS_RESOURCES_REQUEST_SUCCESS = "URLS_RESOURCES_REQUEST_SUCCESS";
const URLS_RESOURCES_REQUEST_FAIL = "URLS_RESOURCES_REQUEST_FAIL";
const CLEAR_URLS_RESOURCES = "CLEAR_URLS_RESOURCES";

const fetchEnvsByLocale = payload => ({
  type: ENVS_REQUEST,
  payload,
});

const setEnv = payload => ({
  type: SET_ENV,
  payload,
});

const clearEnv = () => ({
  type: CLEAR_ENV,
});

const fetchEnvURLsAndResources = payload => ({
  type: URLS_RESOURCES_REQUEST,
  payload,
});

const clearEnvURLs = () => ({
  type: CLEAR_URLS_RESOURCES,
});

export {
  SET_ENV,
  CLEAR_ENV,
  ENVS_REQUEST,
  ENVS_REQUEST_SUCCESS,
  ENVS_REQUEST_FAIL,
  URLS_RESOURCES_REQUEST,
  URLS_RESOURCES_REQUEST_SUCCESS,
  URLS_RESOURCES_REQUEST_FAIL,
  CLEAR_URLS_RESOURCES,
  fetchEnvsByLocale,
  setEnv,
  clearEnv,
  fetchEnvURLsAndResources,
  clearEnvURLs,
};
