import {
  CONFIG_LIST_APP_REQUEST,
  CONFIG_LIST_APP_REQUEST_SUCCESS,
  CONFIG_LIST_APP_REQUEST_FAIL,
  CONFIG_LIST_WIDGET_REQUEST,
  CONFIG_LIST_WIDGET_REQUEST_SUCCESS,
  CONFIG_LIST_WIDGET_REQUEST_FAIL,
  SET_CONFIG_APP,
  CLEAR_CONFIG_APP,
  SET_CONFIG_WIDGET,
  CLEAR_CONFIG_WIDGETS_LIST,
  CLEAR_CONFIG_WIDGET,
} from "actions/configurationActions";

const initialState = {
  currentApp: null,
  currentWidget: null,
  widgetList: [],
  appList: [],
  error: null,
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case SET_CONFIG_APP:
      return {
        ...state,
        currentApp: payload,
      };
    case CLEAR_CONFIG_APP:
      return {
        ...state,
        currentApp: null,
      };
    case CONFIG_LIST_APP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONFIG_LIST_APP_REQUEST_SUCCESS:
      return {
        ...state,
        appList: payload,
        loading: false,
      };
    case CONFIG_LIST_APP_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        ...error,
      };
    case CONFIG_LIST_WIDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONFIG_LIST_WIDGET_REQUEST_SUCCESS:
      return {
        ...state,
        widgetList: payload,
        loading: false,
      };
    case CONFIG_LIST_WIDGET_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        ...error,
      };
    case SET_CONFIG_WIDGET:
      return {
        ...state,
        currentWidget: payload,
      };
    case CLEAR_CONFIG_WIDGET:
      return {
        ...state,
        current: null,
      };
    case CLEAR_CONFIG_WIDGETS_LIST:
      return {
        ...state,
        widgetList: [],
      };
    default:
      return state;
  }
};
