/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { Grid, Typography, Chip } from "@material-ui/core";

import VfDialogFull from "../vf/VfDialogFull";
import Translate from "./Translations";
import VfSvgIcon from "../icons/VfSvgIcon";

export default function DialogTranslationsTranslate({
  setDialogWidget,
  dialogWidget,
  selectedWidget,
  saveTranslations,
  access_token,
  id_token,
  translations,
  translationsChanged,
  clearTranslations,
  isWidgetTranslationLoading,
  widgetTranslationDialogStatus
}) {

  useEffect(() => {
    if(widgetTranslationDialogStatus == "done"){
      setDialogWidget(false)
    }
  }, [widgetTranslationDialogStatus])

  return (
    <VfDialogFull
      /* eslint-disable */
      appBar={
        <Grid container spacing={3} alignItems="center" wrap="nowrap">
          <Grid item xs>
            <Grid container spacing={1}>
              <Grid item xs="auto">
                <Typography variant="body1" component="h5">
                  {selectedWidget.description}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Typography color="secondary" variant="body1" component="span">
                  {selectedWidget.version}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs="auto">
            <Chip
              /* eslint-disable */
              icon={
                <VfSvgIcon
                  className="MuiChip-leftIcon"
                  icon={selectedWidget.envTitle?.toLowerCase()}
                  fontSize="small"
                />
              }
              /* eslint-enable */
              label={selectedWidget.envTitle}
            />
          </Grid>
        </Grid>
      }
      /* eslint-enable */
      buttonConfirmText={isWidgetTranslationLoading ? "Saving..." : "Save"}
      buttonConfirmDisabled={isWidgetTranslationLoading}
      buttonConfirmAction={() => {}}
      saveAction={() => {
        saveTranslations({
          // eslint-disable-next-line no-underscore-dangle
          widget: selectedWidget._id,
          envID: selectedWidget.envId,
          access_token,
          id_token,
          data: translations.map(l => ({ language: l.language, data: l.data })),
        })
      }}
      buttonCloseAction={() => {
        clearTranslations();
        setDialogWidget(false);
      }}
      openDialog={dialogWidget}
      showConfirmDialog={translationsChanged}
      hasValidations={true}
    >
      <Translate />
    </VfDialogFull>
  );
}
