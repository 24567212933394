/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React from "react";

import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import tenantTypes from "enums/tenantTypes";
import {
  deleteUnmanagedApp,
  undeployApplication,
} from "../../../actions/applicationsActions";
import { undeploySpainApplication } from "../../../actions/spainIntegrationActions";

const UndeployMenuItem = ({
  app,
  env,
  onClick,
  auth,
  undeployApp,
  removeUnmanagedApp,
  tenantType,
  undeploySpainApp,
  coreAppJob
}) => {
  const { ctry } = auth;

  const onUndeploy = () => {
    const payload =
      (app?.isDeployedToKubernetes || app?.isDeployedToS3 || tenantType === tenantTypes.SPAIN)
        ? {
          locale: ctry.toLowerCase(),
          body: {
            environmentId: env.id,
            application: app,
            coreAppJob
          },
        }
        : {
          locale: ctry.toLowerCase(),
          envID: env.id,
          applicationId: app._id,
          globalApplicationId: app.application,
        };
    if (tenantType === tenantTypes.SPAIN) {
      undeploySpainApp(payload);
    } else if (app?.isDeployedToKubernetes || app?.isDeployedToS3) {
      undeployApp(payload);
    } else {
      removeUnmanagedApp(payload);
    }

    onClick();
  };

  return <MenuItem onClick={onUndeploy} data-testid="undeploy_btn">Undeploy</MenuItem>
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    tenantType: state.tenants?.current?.type,
  };
};

const mapDispatchToProps = dispatch => ({
  undeployApp: payload => dispatch(undeployApplication(payload)),
  removeUnmanagedApp: payload => dispatch(deleteUnmanagedApp(payload)),
  undeploySpainApp: payload => dispatch(undeploySpainApplication(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UndeployMenuItem);
