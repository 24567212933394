/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
export const initialState = {
  name: "",
  tags: [],
  files: [],
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "setApiInfo":
      return {
        ...state,
        ...payload,
      };
    case "setName":
      return {
        ...state,
        name: payload,
      };
    case "setTags":
      return {
        ...state,
        tags: payload,
      };
    case "addFile":
      return {
        ...state,
        files: [
          ...state.files.filter(file => {
            return !(
              file.version === payload.version && file.env === payload.env
            );
          }),
          payload,
        ],
      };
    case "removeFile":
      return {
        ...state,
        files: state.files.filter(
          file => file.fileId !== payload.fileId || file.env !== payload.envId
        ),
      };
    default:
      return state;
  }
};
