/* eslint-disable camelcase */
import axios from "axios";
import getCookie from "utils/getCookie";

class SpainIntegrationService {
  fetchWorkbenchApplicationsList = async () => {
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/spainIntegration/fetchWorkbenchApplications",
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
        },
      });
      return data;
    } catch (err) {
      console.log("Error in workbench applications schema service:", err);
      return { err };
    }
  };

  deploySpainApplication = async ({ body = {} }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/spainIntegration/build`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("Error in deploy application service:", err);
      return err;
    }
  };

  undeploySpainApplication = async ({ access_token, id_token, body = {} }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/spainIntegration/undeploy`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("Error in undeploy application service:", err);
      return err;
    }
  };

  fetchDeploymentData = async ({ body = {} }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/spainIntegration/fetchDeploymentData`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
        },
        params: body,
      });
      return data;
    } catch (err) {
      console.log("Error in deploy application service:", err);
      return err;
    }
  };

  fetchProjectsList = async ({ body = {} }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/spainIntegration/projectsList`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
        },
        params: body,
      });
      return data;
    } catch (err) {
      console.log("Error in deploy application service:", err);
      return err;
    }
  };

  createPackage = async ({ body = {} }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/spainIntegration/createProject`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("Error in deploy application service:", err);
      return err;
    }
  };
}

const inst = new SpainIntegrationService();
export default inst;
