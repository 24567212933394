/* eslint-disable camelcase */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";

import { setWidget } from "actions/widgetsActions";
import { setTranslationWidget } from "actions/translationsActions";

import {
  fetchConfigSchema,
  fetchConfigData,
  clearConfigData,
  clearConfigSchema,
} from "actions/configFormActions";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import theme from "AppTheme";
import WidgetsHistory from "./WidgetsHistory";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {},
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
});

const WidgetsItemGrid = ({
  error,
  warning,
  info,
  title,
  image,
  label,
  componentId,
  application,
  setWdgt,
  getConfigSchema,
  getConfigData,
  isSaved,
  auth,
  envID,
  deployChanges,
  translation,
  widget,
  setTranslationWdgt,
  version,
  widgetId,
}) => {
  const { locale, env, touchpoint } = useParams();
  const { access_token, id_token } = auth;
  const [anchorEl, setAnchorEl] = useState(null);

  const deployClick = () => {
    deployChanges(componentId);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDialogNotes, setOpenDialogNotes] = React.useState(false);
  const [openDialogHistory, setOpenDialogHistory] = React.useState(false);
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenDialogNotes = () => {
    setOpenDialogNotes(true);
  };

  const handleCloseDialogNotes = () => {
    setOpenDialogNotes(false);
  };

  const handleOpenDialogHistory = () => {
    setOpenDialogHistory(!openDialogHistory);
  };

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-widget": true,
        "MuiCard-grid": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-error": error,
        "MuiCard-outlined-warning": warning,
        "MuiCard-outlined-info": info,
        "MuiCard-outlined-success": !!isSaved,
        "MuiCard-rounded": true,
        animate: true,
      })}
    >
      <CardActionArea
        component={Link}
        to={
          translation
            ? `/${locale}/translations/${env}/${widget}/${version}`
            : `/${locale}/env/${env}/${touchpoint}/${application}/${componentId}/form`
        }
        onClick={() => {
          if (!translation) {
            setWdgt(componentId || "global");
            getConfigSchema({
              widget: componentId || "global",
              access_token,
              id_token,
              envID,
            });
            getConfigData({
              technology: touchpoint,
              application,
              widget: componentId || "global",
              stageType: "runtime",
              envID,
              access_token,
              id_token,
            });
          } else {
            setTranslationWdgt(widgetId);
          }
        }}
      >
        <CardMedia className="MuiCardMedia-flex" title={title}>
          {image}
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="body1" component="h5">
            {title}
          </Typography>

          <Typography
            {...(label.length ? { gutterBottom: true } : {})}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            Last edit: 3mins ago - Sara Sanders
          </Typography>
          {!translation && (
            <Typography className="label" variant="body2">
              {label}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      {!translation && (
        <CardActions disableSpacing>
          <IconButton
            aria-label="add to favorites"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <VfSvgIcon icon="more" viewBox={24} />
          </IconButton>
          <Menu
            id="simple-menu"
            elevation={0}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            getContentAnchorEl={null}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>VIEW INFO</MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                handleOpenDialogNotes();
              }}
            >
              SEE NOTES
            </MenuItem>
            <MenuItem onClick={() => deployClick(componentId)}>
              DEPLOY TO DEVELOPMENT
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                handleOpenDialogHistory();
              }}
            >
              HISTORY
            </MenuItem>
          </Menu>
        </CardActions>
      )}
      {!translation && (
        <Dialog
          fullScreen={fullScreen}
          open={openDialogNotes}
          onClose={handleClose}
          aria-labelledby={`responsive-dialog-title-${componentId}`}
        >
          <DialogTitle
            id={`responsive-dialog-title-${componentId}`}
            disableTypography
            className={classes.modalTitle}
          >
            <Typography variant="h5" component="h3">
              Notes
            </Typography>
            <Divider
              className={classes.modalDivider}
              flexItem
              orientation="vertical"
            />
            <IconButton aria-label="close" onClick={handleCloseDialogNotes}>
              <VfSvgIcon icon="close" viewBox={24} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {!translation && (
        <Dialog
          fullScreen={fullScreen}
          open={openDialogHistory}
          onClose={handleOpenDialogHistory}
          maxWidth="md"
          aria-labelledby={`responsive-dialog-title-${componentId}`}
        >
          <DialogTitle
            id={`responsive-dialog-title-${componentId}`}
            disableTypography
            className={classes.modalTitle}
          >
            <Typography variant="h5" component="h3">
              {componentId
                ? `${componentId[0].toUpperCase()}${componentId.slice(
                    1
                  )} configuration’s history`
                : "Global configuration’s history"}
            </Typography>
            <Divider
              className={classes.modalDivider}
              flexItem
              orientation="vertical"
            />
            <IconButton aria-label="close" onClick={handleOpenDialogHistory}>
              <VfSvgIcon icon="close" viewBox={24} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <WidgetsHistory />
          </DialogContent>
        </Dialog>
      )}
    </Card>
  );
};

const mapStateToProps = state => ({
  auth: state.authentication,
  envID: state.envs.id,
});

const mapDispatchToProps = dispatch => ({
  setWdgt: widget => dispatch(setWidget(widget)),
  setTranslationWdgt: widget => dispatch(setTranslationWidget(widget)),
  getConfigSchema: params => dispatch(fetchConfigSchema(params)),
  getConfigData: opts => dispatch(fetchConfigData(opts)),
  deleteConfigSchema: () => dispatch(clearConfigSchema()),
  deleteConfigData: () => dispatch(clearConfigData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsItemGrid);
