import React from "react";
import { connect } from "react-redux";
import Editor from "components/editor/Editor";

const FormContainer = ({ applications, widgets }) => {
  return <Editor applications={applications} widgets={widgets} />;
};

const mapStateToProps = state => {
  return {
    applications: state.apps.list,
    widgets: state.widgets.list,
  };
};

export default connect(mapStateToProps, null)(FormContainer);
