/* eslint-disable camelcase */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { setTranslationWidget } from "actions/translationsActions";
import { setWidget } from "actions/widgetsActions";
import { fetchConfigHistory } from "actions/historyActions";
import {
  fetchConfigSchema,
  fetchConfigData,
  clearConfigData,
  clearConfigSchema,
} from "actions/configFormActions";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "AppTheme";
import WidgetsHistory from "./WidgetsHistory";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {},
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
});

const WidgetsItem = ({
  error,
  warning,
  info,
  title,
  image,
  label,
  auth,
  envID,
  componentId,
  application,
  setWdgt,
  getConfigSchema,
  fetchHistory,
  getConfigData,
  isSaved,
  deployChanges,
  translation,
  widget,
  version,
  widgetId,
  setTranslationWdgt,
}) => {
  const { locale, env, touchpoint } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const { access_token, id_token } = auth;

  const deployClick = () => {
    deployChanges(componentId);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openDialogNotes, setOpenDialogNotes] = useState(false);
  const [openDialogHistory, setOpenDialogHistory] = useState(false);
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenDialogNotes = () => {
    setOpenDialogNotes(!openDialogNotes);
  };

  const handleOpenDialogHistory = () => {
    setOpenDialogHistory(!openDialogHistory);
  };

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-widget": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-error": error,
        "MuiCard-outlined-warning": warning,
        "MuiCard-outlined-info": info,
        "MuiCard-outlined-success": !!isSaved,
        "MuiCard-rounded": true,
        animate: true,
      })}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <CardActionArea
            component={Link}
            to={
              translation
                ? `/${locale}/translations/${env}/${widget}/${version}`
                : `/${locale}/env/${env}/${touchpoint}/${application}/${componentId ||
                    "global"}/form`
            }
            onClick={() => {
              if (!translation) {
                setWdgt(componentId || "global");
                getConfigSchema({
                  widget: componentId || "global",
                  access_token,
                  id_token,
                  envID,
                });
                getConfigData({
                  technology: touchpoint,
                  application,
                  widget: componentId || "global",
                  stageType: "runtime",
                  envID,
                  id_token,
                  access_token,
                });
              } else setTranslationWdgt(widgetId);
            }}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs="auto">
                <Box mx={2}>
                  <CardMedia className="MuiCardMedia-flex" title={title}>
                    {image}
                  </CardMedia>
                </Box>
              </Grid>
              <Grid item xs>
                <CardContent>
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item xs={12} sm="auto">
                      <Typography variant="body1" component="h5">
                        {`${title} `}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Last edit: 3mins ago - Sara Sanders
                      </Typography>
                    </Grid>
                    {!translation && (
                      <Grid item xs={12} sm="auto">
                        <Typography className="label" variant="body2">
                          {label}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Grid>
              {label && <Grid item xs="auto" />}
            </Grid>
          </CardActionArea>
        </Grid>
        {!translation && (
          <Grid item xs="auto">
            <CardActions disableSpacing>
              <IconButton
                aria-label="add to favorites"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <VfSvgIcon icon="more" viewBox={24} />
              </IconButton>
              <Menu
                id="simple-menu"
                elevation={0}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>VIEW INFO</MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleOpenDialogNotes();
                  }}
                >
                  SEE NOTES
                </MenuItem>
                <MenuItem onClick={() => deployClick(componentId)}>
                  DEPLOY TO DEVELOPMENT
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleOpenDialogHistory();
                    fetchHistory({
                      id_token,
                      access_token,
                      envID,
                      touchpoint,
                      application,
                      componentId,
                    });
                  }}
                >
                  HISTORY
                </MenuItem>
              </Menu>
            </CardActions>
          </Grid>
        )}
      </Grid>
      {!translation && (
        <Dialog
          fullScreen={fullScreen}
          open={openDialogNotes}
          onClose={handleClose}
          aria-labelledby={`responsive-dialog-title-${componentId}`}
        >
          <DialogTitle
            id={`responsive-dialog-title-${componentId}`}
            disableTypography
            className={classes.modalTitle}
          >
            <Typography variant="h5" component="h3">
              Notes
            </Typography>
            <Divider
              className={classes.modalDivider}
              flexItem
              orientation="vertical"
            />
            <IconButton aria-label="close" onClick={handleOpenDialogNotes}>
              <VfSvgIcon icon="close" viewBox={24} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {!translation && (
        <Dialog
          fullScreen={fullScreen}
          open={openDialogHistory}
          onClose={handleOpenDialogHistory}
          maxWidth="md"
          aria-labelledby={`responsive-dialog-title-${title}`}
        >
          <DialogTitle
            id={`responsive-dialog-title-${title}`}
            disableTypography
            className={classes.modalTitle}
          >
            <Typography variant="h5" component="h3">
              {title
                ? `${title} configuration’s history`
                : "Global configuration’s history"}
            </Typography>
            <Divider
              className={classes.modalDivider}
              flexItem
              orientation="vertical"
            />
            <IconButton aria-label="close" onClick={handleOpenDialogHistory}>
              <VfSvgIcon icon="close" viewBox={24} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <WidgetsHistory />
          </DialogContent>
        </Dialog>
      )}
    </Card>
  );
};

const mapStateToProps = state => ({
  auth: state.authentication,
  envID: state.envs.id,
});

const mapDispatchToProps = dispatch => ({
  setWdgt: widget => dispatch(setWidget(widget)),
  setTranslationWdgt: widget => dispatch(setTranslationWidget(widget)),
  getConfigSchema: params => dispatch(fetchConfigSchema(params)),
  getConfigData: params => dispatch(fetchConfigData(params)),
  deleteConfigSchema: () => dispatch(clearConfigSchema()),
  deleteConfigData: () => dispatch(clearConfigData()),
  fetchHistory: params => dispatch(fetchConfigHistory(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsItem);
