/* eslint-disable no-useless-computed-key */
import React from "react";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";

import noImage from "assets/images/Illustration.svg";

const theme = createMuiTheme();

const useStyles = makeStyles({
  root: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ["& .MuiCardMedia-root"]: {
      height: theme.spacing(19),
      maxWidth: theme.spacing(38),
      width: "100%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(11),
      },
    },
    ["& .MuiCardContent-root"]: {
      maxWidth: theme.spacing(48),
      textAlign: "center",
    },
  },
});

const EditorFormNoData = ({ text, minHeight = 18.5 }) => {
  const classes = useStyles();

  return (
    <Card
      className={classnames(classes.root, "MuiPaper-transparent")}
      elevation={0}
      style={{ minHeight: `calc(100vh - ${theme.spacing(minHeight)}px)` }}
    >
      <CardMedia image={noImage} />
      {text && (
        <CardContent>
          <Typography variant="h4" component="h2">
            {text}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default EditorFormNoData;
