import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Grid, TextField, Collapse, MenuItem } from "@material-ui/core";
import classNames from "classnames";
// import uuidv4 from "uuid/v4";

import ApiField from "./ApiField";

import { findOperationById } from "./ElementsUtils";

const ServiceTaskLeftPart = ({
  selectedEffect,
  setSelectedEffect,
  modalTab,
}) => {
  const apis = useSelector(
    state => state.applications.current.appInfo.apis,
    shallowEqual
  );
  const effectTypes = [
    { name: "Select", value: "select" },
    { name: "Select Javascript", value: "select-js" },
    { name: "Call Api", value: "call-api" },
    { name: "Call Javascript", value: "call-js" },
  ];
  return (
    <Collapse in={modalTab === "general"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              },
            }}
            onChange={e => {
              setSelectedEffect({
                ...selectedEffect,
                effect: e.target.value,
              });
            }}
            label="Select Effect"
            value={selectedEffect.effect}
            variant="outlined"
            color="primary"
            fullWidth
            InputProps={{
              disableUnderline: true,
              className: classNames("MuiFilledInput-disableUnderline"),
            }}
          >
            {effectTypes.map(({ name, value }) => (
              <MenuItem key={name.trim().toLowerCase()} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {selectedEffect.effect === "call-api" && (
          <>
            <Grid item xs={12}>
              <ApiField
                apis={apis}
                selectedEffect={selectedEffect}
                setSelectedEffect={setSelectedEffect}
              />
            </Grid>
            {!!selectedEffect?.operations.length && (
              <Grid item xs={12}>
                <TextField
                  select
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    },
                  }}
                  onChange={e => {
                    const oper = findOperationById(
                      selectedEffect.fileData,
                      e.target.value
                    );
                    setSelectedEffect({
                      ...selectedEffect,
                      operation: e.target.value,
                      parameters: oper.parameters,
                    });
                  }}
                  label="Select operation"
                  value={selectedEffect.operation}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    className: classNames("MuiFilledInput-disableUnderline"),
                  }}
                >
                  {selectedEffect?.operations.map(({ operation }) => (
                    <MenuItem key={operation} value={operation}>
                      {operation}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Collapse>
  );
};

export default ServiceTaskLeftPart;
