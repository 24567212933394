const globalReduxSchema = {
  title: "State",
  type: "object",
  properties: {
    authentication: {
      title: "authentication",
      type: "object",
      properties: {
        access_token: {
          title: "access_token",
          type: "string",
        },
        id_token: {
          title: "id_token",
          type: "string",
        },
        ctry: {
          title: "ctry",
          type: "string",
        },
      },
    },
    config: {
      title: "config",
      type: "object",
      properties: {
        data: {
          title: "data",
          type: "object",
          properties: {
            global: {
              title: "global",
              type: "object",
              properties: {
                app: {
                  type: "string",
                  title: "App name",
                  default: "Web1",
                  readOnly: true,
                },
                versionName: {
                  type: "string",
                  title: "Version name",
                  default: "1.0.0",
                  readOnly: true,
                },
                versionCode: {
                  type: "integer",
                  title: "Version code",
                  default: 1,
                  readOnly: true,
                },
                tags: {
                  type: "string",
                  title: "Tags",
                },
                TTL: {
                  title: "TTL",
                  type: "integer",
                  default: 600,
                  minimum: 0,
                  maximum: 3600,
                },
                defaultAppLanguage: {
                  title: "Default App Language",
                  type: "string",
                  default: "en",
                },
                isPushable: {
                  title: "Is Pushable",
                  type: "boolean",
                  default: false,
                },
                dateFormat: {
                  type: "string",
                  title: "Date Format",
                  default: "DD/MM/YYYY",
                  enum: [
                    "MM/DD/YYYY",
                    "MM/DD/YY",
                    "DD/MM/YYYY",
                    "DD/MM/YY",
                    "MM-DD-YYYY",
                    "MM-DD-YY",
                    "DD-MM-YYYY",
                    "DD-MM-YY",
                    "M/D/YYYY",
                    "M/D/YY",
                    "D/M/YYYY",
                    "D/M/YY",
                    "M-D-YYYY",
                    "M-D-YY",
                    "D-M-YYYY",
                    "D-M-YY",
                    "YYYY-MM-DD",
                    "YYYY-DD-MM",
                    "YYYY-M-D",
                    "YYYY-D-M",
                    "MMM D, YYYY",
                    "MMMM D, YYYY",
                    "MMM DD, YYYY",
                    "MMMM DD, YYYY",
                    "mmmm d, yy",
                    "mmm dd, yy",
                  ],
                  enumNames: [
                    "mm/dd/yyyy ex: 02/16/2023 or 02/09/2023",
                    "mm/dd/yy ex: 02/16/23 or 02/09/23",
                    "dd/mm/yyyy ex: 16/02/2023 or 09/02/2023",
                    "dd/mm/yy ex: 16/02/23 or 09/02/23",
                    "mm-dd-yyyy ex: 02-16-2023 or 02-09-2023",
                    "mm-dd-yy ex: 02-16-23 or 02-09-23",
                    "dd-mm-yyyy ex: 16-02-2023 or 09-02-2023",
                    "dd-mm-yy ex: 16-02-23 or 09-02-23",
                    "m/d/yyyy ex: 2/16/2023 or 2/9/2023",
                    "m/d/yy ex: 2/16/23 or 2/9/23",
                    "d/m/yyyy ex: 16/2/2023 or 9/2/2023",
                    "d/m/yy ex: 16/2/23 or 9/2/23",
                    "m-d-yyyy ex: 2-16-2023 or 2-9-2023",
                    "m-d-yy ex: 2-16-23 or 2-9-23",
                    "d-m-yyyy ex: 16-2-2023 or 9-2-2023",
                    "d-m-yy ex: 16-2-23 or 9-2-23",
                    "yyyy-mm-dd ex: 2023-02-16 or 2023-02-09",
                    "yyyy-dd-mm ex: 2023-16-02 or 2023-09-02",
                    "yyyy-m-d ex: 2023-2-16 or 2023-2-9",
                    "yyyy-d-m ex: 2023-16-2 or 2023-9-2",
                    "mmm d, yyyy ex: Feb 9, 2023",
                    "mmmm d, yyyy ex: February 9, 2023",
                    "mmm dd, yyyy ex: Feb 09, 2023",
                    "mmmm dd, yyyy ex: February 09, 2023",
                    "mmmm d, yy ex: February 9, 23",
                    "mmm dd, yy ex: Feb 09, 23",
                  ],
                },
                priceDecimalSeparator: {
                  type: "string",
                  title: "Price Decimal Separator",
                  default: ".",
                  enum: [".", ","],
                  enumNames: ["Point or .", "Comma or ,"],
                },
                priceThousandsSeparator: {
                  type: "string",
                  title: "Price Thousands Separator",
                  default: ",",
                  enum: [".", ","],
                  enumNames: ["Point or .", "Comma or ,"],
                },
                hideHeader: {
                  title: "Hide Header",
                  type: "boolean",
                  default: false,
                },
                smapiConfig: {
                  type: "array",
                  title: "SMAPI Config",
                  items: {
                    type: "object",
                    properties: {
                      smapiIsEnabled: {
                        title: "Smapi Is Enabled",
                        type: "boolean",
                        default: false,
                      },
                      smapiEnvironment: {
                        title: "Smapi Environment",
                        type: "string",
                        default: "GCP_TEST",
                      },
                      smapiCookiesEnabled: {
                        title: "Smapi Cookies Enabled",
                        type: "boolean",
                        default: false,
                      },
                      smapiVerbose: {
                        title: "Smapi Verbose",
                        type: "boolean",
                        default: false,
                      },
                      smapiNetworkingMonitoringEnabled: {
                        title: "Smapi Networking Monitoring Enabled",
                        type: "boolean",
                        default: false,
                      },
                      smapiSpaTrackingOptions: {
                        title: "Smapi Spa Tracking Options",
                        type: "object",
                        properties: {
                          enabled: {
                            title: "Enabled",
                            type: "boolean",
                            default: true,
                          },
                          replaceState: {
                            title: "Replace State",
                            type: "boolean",
                            default: false,
                          },
                        },
                      },
                      smapiHeaders: {
                        title: "Smapi Headers",
                        type: "object",
                        properties: {
                          vfTraceSourceHeader: {
                            title: "x-vf-trace-source",
                            type: "string",
                            default: "js:com.vodafone.lib.sec.smapiweb",
                          },
                          vfTraceSourceVersionHeader: {
                            title: "x-vf-trace-source-version",
                            type: "string",
                            default: "smapi-web-test",
                          },
                        },
                      },
                    },
                  },
                },
                tealiumConfig: {
                  title: "Tealium Config",
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      tealiumIsEnabled: {
                        title: "tealium Is Enabled Flag",
                        type: "boolean",
                        description: "Set tealium to be used.",
                        default: true,
                      },
                      pageMountIsEnabled: {
                        title: "page mount Enabled Flag",
                        type: "boolean",
                        description: "Set page mount action to be used.",
                        default: true,
                      },
                      windowUtagIsEnabled: {
                        title: "window utag Enabled Flags",
                        type: "boolean",
                        default: false,
                      },
                      tealiumSdk: {
                        title: "Tealium Sdk",
                        type: "object",
                        properties: {
                          sdkPath: {
                            title: "Tealium Sdk Path",
                            type: "string",
                            description: "Tealium Sdk Path",
                          },
                          mappingParameters: {
                            title: "Mapping Parameters",
                            type: "object",
                            properties: {
                              urlParams: {
                                title: "URL Params",
                                type: "object",
                                properties: {
                                  profileName: {
                                    title: "profile name",
                                    type: "string",
                                  },
                                  environment: {
                                    title: "environment",
                                    type: "string",
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      taggingSheetUrl: {
                        title: "Tagging Sheet Url",
                        type: "string",
                        description: "Tagging Sheet Url",
                        default:
                          "https://af-coreapp-stage-gr-media.s3.amazonaws.com/297b6792-8b0b-48b6-95cf-b377bf4725d8.json",
                      },
                      taggingSheetJson: {
                        type: "array",
                        title: "Tagging sheet json data",
                        items: {
                          type: "object",
                          properties: {
                            page_channel: {
                              title: "page channel value",
                              type: "string",
                              default: "Retail 10",
                            },
                            tagging_id: {
                              title: "event tagging id",
                              type: "string",
                            },
                          },
                        },
                        default: [],
                      },
                    },
                  },
                },
                config_acl: {
                  type: "array",
                  title: "ACL",
                  items: {
                    type: "object",
                    title: "ACL roles",
                    properties: {
                      role: {
                        type: "string",
                        enum: ["ADMIN", "DEVOPS"],
                      },
                      accessLevels: {
                        type: "array",
                        items: {
                          type: "string",
                          enum: ["CREATE", "READ", "UPDATE", "DELETE"],
                        },
                      },
                    },
                  },
                },
                localMarketAPIs: {
                  type: "string",
                  title: "localMarketAPIs",
                  description: "APIs that needs/expect an authorization header would be sent from the core-app.",
                },
                screenLockTimeout: {
                  type: "string",
                  title: "Screen Lock Timeout",
                  default: 600,
                  minimum: 60,
                  maximum: 3600,
                },
                readMe: {
                  type: "string",
                  title: "Read me",
                },
                contactEmail: {
                  type: "string",
                  title: "Contact email",
                },
                refDoc: {
                  type: "string",
                  title: "Reference documentation",
                },
              },
            },
            widgets: { title: "widgets", type: "object" },
          },
        },
      },
    },
  },
};

const EventSchema = {
  title: "Event Schema",
  type: "object",
  properties: {
    instanceId: {
      title: "instanceId",
      type: "string",
    },
    type: {
      title: "type",
      type: "string",
    },
    payload: {
      title: "payload",
      type: "object",
      properties: {},
    },
  },
};

const CallApiSchema = {
  title: "Call Api Schema",
  type: "object",
  properties: {
    ok: {
      title: "ok",
      type: "boolean",
    },
    status: {
      title: "status",
      type: "integer",
    },
    headers: {
      title: "headers",
      type: "object",
      properties: {
        "content-type": {
          title: "content-type",
          type: "string",
        },
      },
    },
    body: {
      title: "body",
      type: "object",
      properties: {},
    },
  },
};

export { globalReduxSchema, EventSchema, CallApiSchema };
