/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
  Menu,
  MenuItem,
  Fade,
} from "@material-ui/core";
import theme from "../../AppTheme";
import { ReactComponent as MoreIcon } from "../../assets/images/ic-more.svg";
import { ReactComponent as HideIcon } from "../../assets/images/ic-hide.svg";
import { ReactComponent as ShowIcon } from "../../assets/images/ic-show.svg";
import VfDialogConfirm from "../vf/VfDialogConfirm";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiCardActions-root": {
      padding: 0,
    },
  },
});

const CardMenuListItem = ({ title, hidden }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogConfirm, setDialogConfirm] = React.useState(false);
  const handleDialogConfirm = val => {
    setDialogConfirm(val);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Card
      elevation={0}
      className={classNames(classes.card, {
        "MuiCard-grey": true,
        "MuiCard-rounded": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-grey": true,
      })}
    >
      <CardActionArea>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              {hidden && <VfSvgIcon icon="hide" viewBox={24} />}
              {!hidden && <VfSvgIcon icon="show" viewBox={24} />}
            </Grid>
            <Grid item>
              <Typography variant="body1">{title}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <IconButton onClick={handleClick}>
          <VfSvgIcon icon="more" viewBox={24} />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={0}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            HIDE FROM MENU
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleDialogConfirm(true);
            }}
          >
            REMOVE FROM MENU
          </MenuItem>
        </Menu>
        <VfDialogConfirm
          openDialog={dialogConfirm}
          maxWidth="xs"
          buttonCancelText="Cancel"
          buttonCancelAction={() => handleDialogConfirm(false)}
          buttonConfirmText="Remove"
          buttonConfirmAction={() => handleDialogConfirm(false)}
        >
          <Typography variant="body1" align="center">
            Are you sure you want to remove from menu
            <br />
            <strong>{title}</strong>
          </Typography>
        </VfDialogConfirm>
      </CardActions>
    </Card>
  );
};

export default CardMenuListItem;
