/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
  updateConfigData as updtConfigData,
  setSelectedTreeItem as settingSelectedTreeItem,
  setConfigTree as settingConfigTree,
} from "actions/configFormActions";
import { deleteEnvFromTenant } from "actions/tenantActions";

import HasPermission from "components/has-permissions/HasPermissions";
import EditorFormOrderList from "./EditorFormOrderList";
import EditorFormNoData from "./EditorFormNoData";
import AddItemForm from "./AddItemForm";
import ConfigForm from "./ConfigForm";

const ConfigurationForm = ({
  configForm,
  updateConfigData,
  setSelectedTreeItem,
  setConfigTree,
  isTenant,
  isGlobal,
  envID,
  currentTenant,
  deleteTenantEnv,
  maximumRequestBodySize,
}) => {
  const { widget } = useParams();
  const { selectedTreeItem, tree } = configForm;

  const [addItem, setAddItem] = useState(false);
  const [newItemSchema, setItemSchema] = useState({});
  const [newItemData, setItemData] = useState({});

  const onAddItem = () => setAddItem(!addItem);
  const onSetItemSchema = schema => setItemSchema(schema);
  const onSetItemData = data => setItemData(data);

  useEffect(() => {
    if (tree && !selectedTreeItem) {
      setSelectedTreeItem({
        schema: tree.__schema__,
        id: tree.id,
        name: tree.name,
        isArray: tree.showArray,
        childrenSchema: tree.childrenSchema,
      });
    }
  }, [configForm]);

  if (addItem) {
    return (
      <HasPermission
        permissionKey={
          isTenant ? "edit_tenant" : `edit_configurations_env_${envID}`
        }
        isGlobal={isGlobal}
      >
        <AddItemForm
          isTenant={isTenant}
          currentTenant={currentTenant}
          newItemSchema={newItemSchema}
          newItemData={newItemData}
          configForm={configForm}
          widget={widget}
          updateConfigData={updateConfigData}
          setConfigTree={setConfigTree}
          setAddItem={setAddItem}
        />
      </HasPermission>
    );
  }

  if (
    configForm.selectedTreeItem &&
    configForm.selectedTreeItem.schema &&
    configForm.data
  ) {
    return (
      <ConfigForm
        isGlobal={isGlobal}
        isTenant={isTenant}
        currentTenant={currentTenant}
        envID={envID}
        configForm={configForm}
        updateConfigData={updateConfigData}
        maximumRequestBodySize={maximumRequestBodySize}
      />
    );
  }

  if (configForm.selectedTreeItem && configForm.selectedTreeItem.isArray) {
    return (
      <EditorFormOrderList
        deleteTenantEnv={deleteTenantEnv}
        envID={envID}
        configForm={configForm}
        widget={widget}
        setConfigTree={setConfigTree}
        updateConfigData={updateConfigData}
        onAddItem={onAddItem}
        onSetItemSchema={onSetItemSchema}
        onSetItemData={onSetItemData}
        isGlobal={isGlobal}
        isTenant={isTenant}
        currentTenant={currentTenant}
      />
    );
  }

  return (
    <EditorFormNoData text="Please make a selection from the menu on the left" />
  );
};

const mapStateToProps = state => {
  return {
    configForm: state.configForm,
    envID: state.configuration?.currentApp?.envId,
    maximumRequestBodySize: state.settings.maximumRequestBodySize,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateConfigData: data => dispatch(updtConfigData(data)),
    setSelectedTreeItem: data => dispatch(settingSelectedTreeItem(data)),
    setConfigTree: data => dispatch(settingConfigTree(data)),
    deleteTenantEnv: data => dispatch(deleteEnvFromTenant(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationForm);
