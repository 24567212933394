import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { shallowEqual, useSelector } from "react-redux";
import AppsDetails from "./AppsDetails";
import AppsList from "./AppsList";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
  },
  rootNoPadding: {
    minHeight: "100vh",
    paddingTop: "0px",
    "& .MuiBox-root": {
      paddingTop: "0px",
    },
  },
});

const Apps = () => {
  const styles = useStyles();
  const show = useSelector(state => state.applications.show, shallowEqual);
  const showEdit = useSelector(
    state => state.applications.showEdit,
    shallowEqual
  );
  const className = show || showEdit ? styles.rootNoPadding : styles.root;

  return (
    <main className={className}>
      <AppsDetails />
      <AppsList />
    </main>
  );
};

export default Apps;
