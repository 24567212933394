/* eslint-disable camelcase */
import axios from "axios";
import getCookie from "utils/getCookie";

class EnvsService {
  fetchEnvsByLocale = async ({ locale, }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/environments/${locale}`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch envs service:", err);
      return err;
    }
  };

  fetchURLsAndResourcesPerEnv = async ({
    localeID,
    environmentID,
    access_token,
    id_token,
  }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/environments/${localeID}/${environmentID}/urls-resources`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch envs service:", err);
      return err;
    }
  };

  fetchS3URLsAndResourcesPerEnv = async ({
    localeID,
    environmentID,
    access_token,
    id_token,
  }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/environments/${localeID}/${environmentID}/s3-urls-resources`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
          
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch s3 envs service:", err);
      return err;
    }
  };
}

const inst = new EnvsService();
export default inst;
