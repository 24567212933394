/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-useless-computed-key */
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Dialog, DialogContent, DialogContentText, useMediaQuery, DialogActions, Snackbar } from "@material-ui/core";
import SpainIntegrationService from "services/SpainIntegrationService";
import tenantTypes from "enums/tenantTypes";

import {
  fetchApps,
  setAppsView,
  setShowApp,
  setApp,
  clearApp,
  setAppEnv,
  clearAppEnv,
  setShowEditApp,
  deployApplication,
  undeployApplication,
  fetchPages,
  deleteUnmanagedApp,
  deleteApp,
  clearDeleteAppError,
  showSnackbar,
} from "actions/applicationsActions";
import { fetchEnvURLsAndResources, clearEnvURLs } from "actions/envsActions";
import { fetchS3EnvURLs,clearS3EnvURLs } from "actions/s3EnvsActions";
import SearchBar from "components/searchbar/SearchBar";
import DisplayType from "components/displaytype/DisplayType";
import Fab from "@material-ui/core/Fab";

import HasPermission from "components/has-permissions/HasPermissions";
import theme from "AppTheme";

import Loader from "../loader/Loader";
import CardAppList from "./CardAppList";
import CardAppsGrid from "./CardAppsGrid";
import AppsDialog from "./AppsDialog";
import AppsDialogEdit from "./AppDialogEdit/AppsDialogEdit";
import VfSvgIcon from "../icons/VfSvgIcon";
import DeleteAppErrorDialog from "./DeleteAppErrorDialog";
import VfAlert from "../vf/VfAlert";
import { groupBy } from "lodash";

const useStyles = makeStyles({
  root: {
    ["& .MuiBox-searchBar"]: {
      position: "absolute",
      left: 0,
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  fab: {
    position: "absolute",
    right: theme.spacing(3),
    top: -theme.spacing(6),
    transform: "translateY(-50%)",
  },
  container: {
    position: "relative",
  },
  appName: {
    fontWeight: "bold",
  },
  appDialogContainer: {
    textAlign: "center",
  },
  appDialogItem: {
    padding: 24,
  },
  dialogConfirmAction: {
    justifyContent: "center",
  },
});

const THRESHOLD = 3;

const AppsList = ({
  currentEnv,
  applications,
  auth,
  environments,
  touchpoints,
  setApplicationsView,
  getApplications,
  showApp,
  setApplication,
  clearApplication,
  setApplicationEnv,
  clearApplicationEnv,
  showCreateApp,
  showEdit,
  deployApp,
  deleteApplication,
  undeployApp,
  getAppPages,
  removeUnmanagedApp,
  getEnvURLsAndResources,
  getS3EnvURLs,
  clearEnvironmentURLs,
  clearS3EnvironmentURLs,
  envURLs,
  clearDeleteAppErrorMsg,
  envResourcesLoading,
  snackBar,
  dispatchShowSnackBar,
  tenantType,
}) => {
  const defaultThresholdPerEnv = {};
  environments.forEach(x => {
    defaultThresholdPerEnv[x.type] = THRESHOLD;
  });
  const [thresholdPerEnv, setThresholdPerEnv] = useState(defaultThresholdPerEnv);
  const [workbenchFailMessage, setWorkbenchFailMessage] = useState("Could not retrieve Workbench Applications list.");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currDeletedAppError, setCurrDeletedAppError] = useState(null);
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");
  
  const resetSearchForm = useCallback(() => {
    setSearchValue("");
  }, []);
  
  const handleChangeGrid = view => {
    setApplicationsView(view);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    showApp(false);
  }, [showApp]);

  const { view, loading, list, show, error } = applications;
  const [showWorkbenchFail, setShowWorkbenchFail] = useState(false);
  const [workbenchApplicationList, setWorkbenchApplicationList] = useState([]);

  useEffect(() => {
    if (error && error.status === 403 && error.type === "DeleteApp") {
      const { applicationId } = error;

      const founded = list.find(item => {
        const [env] = Object.values(item);
        return env.isGlobal;
      });
      const [globalEnv] = Object.values(founded);
      const application = globalEnv.data.find(app => app._id === applicationId);
      setCurrDeletedAppError(application);
      setOpenDeleteDialog(true);
    } else {
      setOpenDeleteDialog(false);
    }
  }, [error, list]);

  const onShowMore = value => {
    const copyThresholdPerEnv = { ...thresholdPerEnv };
    copyThresholdPerEnv[value] = 1000000;
    setThresholdPerEnv(copyThresholdPerEnv);
  };

  const handleButtonConfirmAction = () => {
    setOpenDeleteDialog(false);
    clearDeleteAppErrorMsg();
  };

  function getAppsPerEnv(env){
    const data = env.data.filter(x => {
      return touchpoints.includes(x.touchpoint);
    });
    if(data){
      const groupedDataByTitle = groupBy(data, "title")
      return Object.keys(groupedDataByTitle).length - 3;
    }
    return data.length - 3;
  }
  const getAppsCount = useCallback((env) => getAppsPerEnv(env), [list.length]);

  const { access_token, id_token } = auth;

  return (
    <Box pt={8} pb={4} className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box py={0.25} className="MuiBox-searchOverlay" position="relative">
              <DisplayType grid={view} handleGridView={handleChangeGrid} />
              <SearchBar
                value={searchValue}
                setSearchValue={setSearchValue}
                callback={getApplications}
                callbackParams={{
                  access_token,
                  id_token,
                }}
                btnId="searchApp_btn"
                searchbarId="searchApp_bar"
              />
            </Box>
          </Grid>
          {(list.length && !loading) || false ? (
            list
              .filter(envs => {
                if (currentEnv) return Object.keys(envs)[0] === currentEnv;
                return envs;
              })
              .map(envs => {
                const [type, env] = Object.entries(envs)[0];
                return (
                  !!env.data.filter(x => {
                    return touchpoints.includes(x.touchpoint);
                  }).length && (
                    <Fragment key={env.title}>
                      <Grid item container xs={12} spacing={1} alignItems="center" data-testid={`${env.title}_env`}>
                        <Grid item xs="auto">
                          <VfSvgIcon icon={type} viewBox={56} />
                        </Grid>
                        <Grid item xs="auto">
                          <Typography component="h5" variant="body1">
                            {env.title}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>{
                          (() => {
                            
                           const filteredApps = env.data
                              .filter(x => {
                                return touchpoints.includes(x.touchpoint);
                            })
                            const groupedAppsByTitle = groupBy( filteredApps, "title")
                            env.length = Object.keys(groupedAppsByTitle).length;
                            let thresholdLevel = thresholdPerEnv[type]
                            const groupedApps = {}
                            for( const item in groupedAppsByTitle){
                                if(thresholdLevel > 0){
                                  groupedApps[item] = groupedAppsByTitle[item]
                                  thresholdLevel--;
                                }
                            }
                            
                            if (view === "grid") return filteredApps.map(app =>
                              <Grid key={app._id + env._id} item xs={12} sm={6} md={4}>
                                <CardAppsGrid
                                  auth={auth}
                                  app={app}
                                  environments={environments}
                                  env={env}
                                  envType={type}
                                  showApp={showApp}
                                  setApplication={setApplication}
                                  setApplicationEnv={setApplicationEnv}
                                  deployApp={deployApp}
                                  deleteApp={deleteApplication}
                                  getAppPages={getAppPages}
                                  moreActions
                                  getEnvURLsAndResources={getEnvURLsAndResources}
                                  getS3EnvURLs={getS3EnvURLs}
                                  clearEnvironmentURLs={clearEnvironmentURLs}
                                  clearS3EnvironmentURLs={clearS3EnvironmentURLs}
                                  envURLs={envURLs}
                                  envResourcesLoading={envResourcesLoading}
                                  resetSearch={resetSearch}
                                />
                              </Grid>)

                            return Object.keys(groupedApps).map(key =>
                            (<Grid key={'list' + key + env._id
                            } item xs={12} >
                              <CardAppList
                                apps={groupedApps[key]}
                                envType={type}
                                auth={auth}
                                app={groupedApps[key][0]}
                                environments={environments}
                                env={env}
                                showApp={showApp}
                                setApplication={setApplication}
                                setApplicationEnv={setApplicationEnv}
                                deployApp={deployApp}
                                deleteApp={deleteApplication}
                                getAppPages={getAppPages}
                                moreActions
                                getEnvURLsAndResources={getEnvURLsAndResources}
                                getS3EnvURLs={getS3EnvURLs}
                                clearEnvironmentURLs={clearEnvironmentURLs}
                                clearS3EnvironmentURLs={clearS3EnvironmentURLs}
                                envURLs={envURLs}
                                envResourcesLoading={envResourcesLoading}
                                resetSearchForm={resetSearchForm}
                                dispatchShowSnackBar={dispatchShowSnackBar}
                              />
                            </Grid>)
                            )
                          })()
                        }
                        </Grid>
                        {env.length > THRESHOLD && (<Box mb={4}>
                          {
                            env.data.filter(x => {
                            return touchpoints.includes(x.touchpoint);
                          }).length -
                            3 >
                            0 &&
                            thresholdPerEnv[type] <= THRESHOLD && (
                              <Button onClick={() => onShowMore(type)} data-testid="viewMore_btn">
                                <Typography
                                  style={{ textDecoration: "underline" }}
                                  component="span"
                                  variant="body2"
                                >
                                  {`View
                                    ${getAppsCount(env)}
                                    more`}
                                </Typography>
                              </Button>
                            )}
                        </Box>)}
                      </Grid>
                    </Fragment>
                  )
                );
              })
          ) : (
            <Grid item xs={12}>
              <Loader size={theme.spacing(12)} />
            </Grid>
          )}
        </Grid>
        <HasPermission permissionKey="create_applications" >
          <Fab
            color="secondary"
            className={classes.fab}
            onClick={() => {
              if (tenantType === tenantTypes.SPAIN) {
                SpainIntegrationService.fetchWorkbenchApplicationsList({
                  access_token,
                  id_token,
                })
                  .then(res => {
                    if (res?.data?.error) {
                      if (res.data.error.message) setWorkbenchFailMessage(res.data.error.message);
                      setShowWorkbenchFail(true);
                    } else {
                      setWorkbenchApplicationList(res.data);
                      showCreateApp(true);
                    }
                  })
                  .catch(err => {
                    if (err.message) setWorkbenchFailMessage(err.message);
                    setShowWorkbenchFail(true);
                  });
              } else {
                showCreateApp(true);
              }
            }}
            data-testid="create_app_btn"
          >
            <VfSvgIcon icon="plus" viewBox={24} />
          </Fab>
        </HasPermission>
      </Container>
      {
        showEdit && (
          <AppsDialogEdit
            loading={loading}
            show={showEdit}
            setApplication={setApplication}
            showCreateApp={showCreateApp}
            applications={applications}
            workbenchApplicationList={workbenchApplicationList}
          />
        )
      }
      {
        showWorkbenchFail && (
          <Dialog fullScreen={fullScreen} open={showWorkbenchFail} maxWidth="xs">
            <DialogContent className={classes.modalContent}>
              <DialogContentText gutterBottom>{workbenchFailMessage}</DialogContentText>
            </DialogContent>
            <DialogActions className={classes.modalActions}>
              <Button color="secondary" variant="contained" onClick={() => setShowWorkbenchFail(false)}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
      {
        show && (
          <AppsDialog
            showApp={showApp}
            showCreateApp={showCreateApp}
            clearApplication={clearApplication}
            clearApplicationEnv={clearApplicationEnv}
          />
        )
      }

      {
        error && error.type === "DeleteApp" && openDeleteDialog && (
          <DeleteAppErrorDialog
            application={currDeletedAppError}
            open={openDeleteDialog}
            envs={error.data}
            onButtonConfirm={handleButtonConfirmAction}
          />
        )
      }
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBar.show}
        onClose={() => dispatchShowSnackBar({ ...snackBar, show: false })}
      >
        <VfAlert severity={snackBar.severity} message={snackBar.message} />
      </Snackbar>
    </Box >
  );
};

const mapStateToProps = state => {
  return {
    currentEnv: state.envs.current,
    applications: state.applications,
    auth: state.authentication,
    touchpoints: state.touchpoints.list,
    environments: state.envs.list,
    showEdit: state.applications.showEdit,
    envURLs: state.envs.envUrls,
    envResourcesLoading: state.envs.loading,
    snackBar: state.applications.snackBar,
    tenantType: state.tenants.current.type,
  };
};

const mapDispatchToProps = dispatch => ({
  setApplicationsView: payload => dispatch(setAppsView(payload)),
  showApp: payload => dispatch(setShowApp(payload)),
  showCreateApp: payload => dispatch(setShowEditApp(payload)),
  setApplication: payload => dispatch(setApp(payload)),
  clearApplication: () => dispatch(clearApp()),
  setApplicationEnv: payload => dispatch(setAppEnv(payload)),
  clearApplicationEnv: () => dispatch(clearAppEnv()),
  getApplications: params => dispatch(fetchApps(params)),
  deployApp: payload => dispatch(deployApplication(payload)),
  undeployApp: payload => dispatch(undeployApplication(payload)),
  getAppPages: payload => dispatch(fetchPages(payload)),
  removeUnmanagedApp: payload => dispatch(deleteUnmanagedApp(payload)),
  deleteApplication: payload => dispatch(deleteApp(payload)),
  getEnvURLsAndResources: payload => dispatch(fetchEnvURLsAndResources(payload)),
  getS3EnvURLs: payload => dispatch(fetchS3EnvURLs(payload)),
  clearEnvironmentURLs: () => dispatch(clearEnvURLs()),
  clearS3EnvironmentURLs: () => dispatch(clearS3EnvURLs()),
  clearDeleteAppErrorMsg: () => dispatch(clearDeleteAppError()),
  dispatchShowSnackBar: payload => dispatch(showSnackbar(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppsList);
