/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import classNames from "classnames";
import dot from "dot-object";

import { fetchApis } from "actions/apisActions";
import { fetchPackages } from "actions/packagesActions";

import VfSvgIcon from "../icons/VfSvgIcon";
import VfAlert from "../vf/VfAlert";
import theme from "../../AppTheme";
import CardListItem from "./CardListItem";
import SearchBar from "../searchbar/SearchBar";

const AppsSidebarList = ({
  dictionary,
  selector,
  sidebarItem,
  setSidebarItem,
  setDialogFlag,
  setTheId,
  handleRemoveItem,
  onOpenAddDialog,
  isLogicApiPckSnipSelected,
}) => {
  const [snackBar, setSnackBar] = useState({
    show: false,
    message: "",
    severity: 0,
  });

  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();
  const getApis = params => dispatch(fetchApis(params));
  const getPackages = params => dispatch(fetchPackages(params));

  const { access_token, id_token } = useSelector(
    state => state.authentication,
    shallowEqual
  );

  useEffect(() => {
    if (sidebarItem === "apiList") {
      getApis({ access_token, id_token });
    } else if (sidebarItem === "packagesList") {
      getPackages({ access_token, id_token });
    }
  }, []);

  const items = useSelector(state => dot.pick(selector, state)) ?? [];
  const appBarHeight = theme.spacing(43);

  const useStylesNewItem = makeStyles({
    root: {
      padding: theme.spacing(3),
      minHeight: `calc(100vh - ${appBarHeight}px)`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiCardMedia-root": {
        height: theme.spacing(9),
        maxWidth: theme.spacing(38),
        width: "100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        opacity: 0.5,
        [theme.breakpoints.down("xs")]: {
          height: theme.spacing(11),
        },
      },
      "& .MuiCardContent-root": {
        maxWidth: theme.spacing(48),
        textAlign: "center",
      },
      "& .MuiCardActions-root": {
        padding: theme.spacing(2),
      },
    },
  });

  const classesNewLogic = useStylesNewItem();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box
          mt={3}
          pl={3}
          pr={8}
          className="MuiBox-searchOverlay"
          position="relative"
        >
          {items.length > 0 && (
            <Grid container justify="flex-end">
              <Grid item xs="auto">
                <IconButton onClick={onOpenAddDialog}>
                  <VfSvgIcon icon="plus" viewBox={24} />
                </IconButton>
              </Grid>
              <Grid item xs="auto">
                <SearchBar
                  callback={() => {}}
                  callbackParams={{}}
                  setSearchText={setSearchText}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
      {items.length > 0 && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box py={3} px={3}>
          <Grid container spacing={3}>
            {items.length === 0 && (
              <Card
                square
                className={classNames(classesNewLogic.root)}
                elevation={0}
              >
                <CardContent>
                  <Typography className="bold" variant="h5" component="h2">
                    {dictionary.addFirst}
                  </Typography>
                  <Typography variant="body1" component="p">
                    {dictionary.addFirstSub}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onOpenAddDialog}
                  >
                    {dictionary.add}
                  </Button>
                </CardActions>
              </Card>
            )}
            {items
              .filter(p => {
                if (searchText)
                  return p.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase());
                return p;
              })
              .map((s, i) => (
                <Grid
                  item
                  xs={12}
                  key={`menu-item-${
                    sidebarItem === "apiList" || sidebarItem === "pacakgesList"
                      ? s._id
                      : s.id || i
                  }`}
                >
                  <CardListItem
                    isLogicApiPckSnipSelected={isLogicApiPckSnipSelected}
                    sidebarItem={sidebarItem}
                    item={s}
                    dictionary={dictionary}
                    setSidebarItem={setSidebarItem}
                    setDialogFlag={setDialogFlag}
                    setTheId={setTheId}
                    handleRemoveItem={handleRemoveItem}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBar.show}
        onClose={() => setSnackBar({ ...snackBar, show: false })}
      >
        <VfAlert severity={snackBar.severity} message={snackBar.message} />
      </Snackbar>
    </Grid>
  );
};

export default AppsSidebarList;
