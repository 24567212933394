export default (error) => {
  // Check axios error handling https://axios-http.com/docs/handling_errors
  const errorMsg =
    error.response?.data ||
    error.request ||
    error.message ||
    error.toJson();

  throw new Error(JSON.stringify(errorMsg));
};
