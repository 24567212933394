import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";

import { clearApp } from "actions/appsActions";
import { clearWidgetsList } from "actions/widgetsActions";
import { clearTranslationWidgetList } from "actions/translationsActions";
import VfSvgIcon from "../icons/VfSvgIcon";

const WidgetsDetails = ({
  title,
  image,
  clearApplication,
  clearWdgtsList,
  clearTranslationList,
  translation,
}) => {
  const history = useHistory();
  const { locale, env, touchpoint } = useParams();
  return (
    <Box py={4} bgcolor="common.white">
      <Container maxWidth="md">
        <Card elevation={0} className="MuiCard-details animate">
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <CardContent>
                <Grid container spacing={3} direction="row" alignItems="center">
                  <Grid item xs={false}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        if (translation) {
                          history.push(`/${locale}/translations`);
                          clearTranslationList();
                        } else {
                          history.push(`/${locale}/env/${env}/${touchpoint}`);
                          clearApplication();
                          clearWdgtsList();
                        }
                      }}
                    >
                      <VfSvgIcon icon="arrowLeft" viewBox={24} />
                    </IconButton>
                  </Grid>
                  <Grid item sm>
                    <Typography variant="h4" component="h1" color="textPrimary">
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      component="p"
                      color="textSecondary"
                    >
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
            <Grid item sm="auto">
              <CardMedia image={image} title="Kiosk" />
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Box>
  );
};

const mapDispatchToProps = dispatch => ({
  clearApplication: () => dispatch(clearApp()),
  clearWdgtsList: () => dispatch(clearWidgetsList()),
  clearTranslationList: () => dispatch(clearTranslationWidgetList()),
});

export default connect(null, mapDispatchToProps)(WidgetsDetails);
