import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {
  setSelectedTreeItem as settingSelectedTreeItem,
  updateTranslationData as updTranslationData,
} from "actions/translationsActions";
import HasPermission from "components/has-permissions/HasPermissions";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import { get, set, unset, isEmpty } from "lodash";
import { Delete, Close } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {},
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TranslationsKeys = ({
  selectedTreeItem,
  setSelectedTreeItem,
  translations,
  updateTranslationData,
  envID,
  locales,
  widgetDescription,
}) => {
  const classes = useStyles();
  const hiddenImportRef = React.createRef();
  const initialState = {
    key: "",
  };
  translations.forEach(t => {
    initialState[t.language] = "";
  });
  const [formData, setFormData] = React.useState(initialState);
  const [openModal, setOpenModal] = React.useState(false);
  /* eslint-disable no-nested-ternary */
  const path =
    selectedTreeItem && selectedTreeItem.path
      ? selectedTreeItem.path === "$."
        ? "data"
        : `data.${selectedTreeItem.path}`.replace("$.", "")
      : "";
  const [translated, setTranslated] = React.useState(
    get(
      translations.find(l => l.language === "en"),
      path,
      {}
    )
  );

  const languageCodeToProperWordMap = {};
  locales.forEach(localeObject => {
    languageCodeToProperWordMap[localeObject.code] = localeObject.language;
  });

  const exportTranslations = () => {
    const filename = "translations.json";
    const pureData = {};
    translations.forEach(lang => {
      pureData[lang.language] = lang.data;
    });
    const jsonStr = JSON.stringify(pureData);

    let element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(jsonStr));
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const importTranslations = () => {
    hiddenImportRef.current.click();
  };

  const handleImport = event => {
    const reader = new FileReader();
    reader.readAsText(event.target.files[0]);
    reader.onload = event => {
      const importedTranslationData = JSON.parse(event.target.result);
      const mutatedTranslations = translations.map(t => {
        set(t, "data", importedTranslationData[t.language]);
        return t;
      });
      updateTranslationData(mutatedTranslations);
    };
  };

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => {
    setFormData(initialState);
    setOpenModal(false);
  };
  const handleModalSubmit = e => {
    addTranslationItem(formData);
    setFormData(initialState);
    handleModalClose();
  };
  const setValue = (k, v) => {
    translations.map(t => {
      if (t.language !== "en") return t;
      set(t, `${path}.${k}`, v);
      setTranslated(get(t, path, {}));
      return t;
    });
    updateTranslationData(translations);
    setSelectedTreeItem({
      ...selectedTreeItem,
    });
  };

  const addTranslationItem = formData => {
    if (!formData.key) {
      return;
    }
    translations.forEach(t => {
      set(t, `${path}.${formData.key}`, formData[t.language]);
      setTranslated(get(t, path, {}));
      return t;
    });
    updateTranslationData(translations);
    setSelectedTreeItem({
      ...selectedTreeItem,
    });
  };

  const removeTranslationItem = key => {
    let returnToRoot = false;
    translations.forEach(t => {
      unset(t, `${path}.${key}`);

      if (isEmpty(get(t, path, {})) && path !== "data") {
        unset(t, `${path}`);
        returnToRoot = true;
      }
      setTranslated(get(t, path, {}));

      return t;
    });

    updateTranslationData(translations);

    if (returnToRoot) {
      setSelectedTreeItem({
        ...selectedTreeItem,
        id: "$.",
        name: widgetDescription,
        path: "$.",
        schema: translations.find(l => l.language === "en").data,
      });
    } else {
      setSelectedTreeItem({
        ...selectedTreeItem,
      });
    }
  };

  React.useEffect(() => {
    translations[0].data? setTranslated(
      get(
        translations.find(l => l.language === "en"),
        path,
        {}
      )
    ):null;
  });

  if (!translated) return null;

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button variant="contained" color="secondary" size="small" onClick={handleModalOpen} data-testid="add_btn">
              Add
            </Button>
          </Grid>
          <Grid item>
            <input
              ref={hiddenImportRef}
              accept="*.json"
              type="file"
              onChange={handleImport}
              style={{ display: "none" }}
              id="translations-hidden-input"
            />
            <Button variant="outlined" color="secondary" size="small" onClick={importTranslations} data-testid="import_btn">
              Import
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="secondary" size="small" onClick={exportTranslations} data-testid="export_btn">
              Export
            </Button>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Typography variant="h5" component="h5">
            Original
          </Typography>
        </Grid>
        {Object.keys(translated).map(
          k =>
            typeof translated[k] !== "object" && (
              <>
                <Grid key={k} item xs={11}>
                  <HasPermission permissionKey={`edit_translations_env_${envID}`} disabled>
                    <TextField
                      label={k}
                      variant="outlined"
                      value={translated && translated[k]}
                      onChange={e => setValue(k, e.target.value)}
                      rowsMax="3"
                      rows="1"
                      multiline
                      fullWidth
                      data-testid={`textfeild_container${k}`}
                      inputProps = {{"data-testid":`inputFeild_${(translated && translated[k]).replace(/\s/g, "")}`}}
                    />
                  </HasPermission>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      removeTranslationItem(k);
                    }}
                    data-testid="delete_btn"
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </>
            )
        )}
        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container justify="flex-end">
              <Grid item>
                <IconButton onClick={handleModalClose} data-testid="close_modal_btn">
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <form>
                <Typography variant="h5" component="h5">
                  Key
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  To create a new group enter "group.key" in the key field.
                </Typography>
                <TextField
                  error={!formData.key}
                  helperText={!formData.key ? "A key is required" : ""}
                  id="modal-key"
                  label="value"
                  variant="outlined"
                  fullWidth
                  name="modal-key"
                  size="small"
                  focused
                  value={formData.key}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      key: e.target.value,
                    })
                  }
                  margin="dense"
                  inputProps = {{"data-testid":`inputFeild_akeyFeild`}}

                />
                {translations.map(t => {
                  return (
                    <Grid>
                      <Typography variant="h5" component="h5">
                        {languageCodeToProperWordMap[t.language]}
                      </Typography>
                      <TextField
                        id="modal-translation"
                        size="small"
                        label="Translation"
                        fullWidth
                        variant="outlined"
                        name="modal-translation"
                        value={formData[t.language]}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            [t.language]: e.target.value,
                          })
                        }
                        margin="dense"
                        inputProps = {{"data-testid":`inputFeild_translation_${languageCodeToProperWordMap[t.language]}`}}
                      />
                    </Grid>
                  );
                })}
                <Grid container justify="flex-end">
                  <Box pt={2}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="secondary"
                      size="medium"
                      onClick={handleModalSubmit}
                      data-testid="addTranslation_btn"
                    >
                      Add
                    </Button>
                  </Box>
                </Grid>
              </form>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    translations: state.translations.translations,
    selectedTreeItem: state.translations.selectedTreeItem,
  };
};

const mapDispatchToProps = dispatch => ({
  setSelectedTreeItem: data => dispatch(settingSelectedTreeItem(data)),
  updateTranslationData: data => dispatch(updTranslationData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranslationsKeys);
