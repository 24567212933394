/* eslint-disable no-underscore-dangle */
import {
  GET_ROLES_REQUEST,
  GET_ROLES_REQUEST_SUCCESS,
  GET_ROLES_REQUEST_FAIL,
  SHOW_ROLE,
  SET_ROLE,
  CLEAR_ROLE,
} from "actions/rolesActions";
import {
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_REQUEST_SUCCESS,
  GET_PERMISSIONS_REQUEST_FAIL,
} from "actions/permissionsActions";

const initialState = {
  loading: false,
  show: false,
  current: null,
  list: [],
  permissions: [],
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case SET_ROLE:
      return {
        ...state,
        current: payload,
      };
    case CLEAR_ROLE:
      return {
        ...state,
        current: null,
      };
    case SHOW_ROLE:
      return {
        ...state,
        show: !state.show,
      };
    case GET_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLES_REQUEST_SUCCESS:
      return {
        ...state,
        list: payload,
        loading: false,
      };
    case GET_ROLES_REQUEST_FAIL:
      return {
        ...state,
        ...error,
        loading: false,
      };
    case GET_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PERMISSIONS_REQUEST_SUCCESS:
      return {
        ...state,
        permissions: payload,
        loading: false,
      };
    case GET_PERMISSIONS_REQUEST_FAIL:
      return {
        ...state,
        ...error,
        loading: false,
      };
    default:
      return state;
  }
};
