import React from "react";
import { MenuItem, Grid, TextField } from "@material-ui/core";

const StartEventElement = ({ handleInputChange, element }) => {
  return (
    <>
      <Grid item xs={12}>
        <TextField
          select
          name="selectedWidget"
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={handleInputChange}
          label="Select Widget"
          value={element.selectedWidget || ""}
          variant="outlined"
          color="primary"
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
        >
          {element?.widgetList.map(a => {
            return (
              <MenuItem key={a.name} value={a.name}>
                {a.description}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          name="actionType"
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={handleInputChange}
          label="Select Action"
          value={element.actionType || ""}
          variant="outlined"
          color="primary"
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
        >
          {element.selectedWidget &&
            element?.actions[element.selectedWidget].map(a => {
              const [key] = Object.keys(a);
              return (
                <MenuItem
                  key={key}
                  value={
                    element.selectedWidget === "Global"
                      ? key
                      : `@${element.selectedWidget}/${key}`
                  }
                >
                  {element.selectedWidget === "Global"
                    ? key
                    : `@${element.selectedWidget}/${key}`}
                </MenuItem>
              );
            })}
        </TextField>
      </Grid>
    </>
  );
};

export default StartEventElement;
