/* eslint-disable camelcase */
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import theme from "AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiCard-details": {
      position: "relative",
    },
    "& .MuiChip-root": {
      "&.MuiChip-selected": {
        backgroundColor: "#007C92",
        color: theme.palette.common.white,
        "& .MuiSvgIcon-root": {
          "& path": {
            fill: theme.palette.common.white,
          },
          "& rect": {
            fill: theme.palette.common.white,
          },
          "& circle": {
            fill: theme.palette.common.white,
          },
          "& polygon": {
            fill: theme.palette.common.white,
          },
        },
      },
      "&.MuiChip-clickable": {
        "&:focus": {
          backgroundColor: undefined,
        },
      },
    },
  },
});

const Heading = ({ title, description, image, backUrl, children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box py={3} bgcolor="common.white" className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <Card elevation={0} className="MuiCard-details animate">
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <CardContent  data-testid={`${title}_container`}>
                <Grid container spacing={1} direction="row" alignItems="center" >
                  <Grid item xs={false}>
                    <IconButton
                      onClick={() => {
                        history.push(backUrl);
                      }}
                      data-testid={`backFrom${(title).replace(/\s/g, "")}_btn`}
                    >
                      <VfSvgIcon icon="arrowLeft" viewBox={24} />
                    </IconButton>
                  </Grid>
                  <Grid item sm>
                    <Typography variant="h4" component="h1" color="textPrimary"  data-testid={`app_${title}`}>
                      {title}
                    </Typography>
                  </Grid>
                  {description && (
                    <Grid item sm={12}>
                      <Typography
                        variant="body1"
                        component="p"
                        color="textPrimary"
                      >
                        {description}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Grid>
            {image && (
              <Grid item xs="auto">
                <CardMedia image={image} title={title} />
              </Grid>
            )}
            {children && (
              <Grid item xs={12}>
                {children}
              </Grid>
            )}
          </Grid>
        </Card>
      </Container>
    </Box>
  );
};

export default Heading;
