/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React from "react";
import classNames from "classnames";
import { Card, CardContent, Typography } from "@material-ui/core";
import CardWidgetImage from "../widgets/CardWidgetImage";

const CardWidgetPopover = ({ widget, showImage }) => {
  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-grey": true,
        "MuiCard-widgetPopover": true,
        "MuiCard-rounded": true,
      })}
    >
      {showImage && <CardWidgetImage widget={widget} />}
      <CardContent>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {widget.description}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {widget.keywords ? widget.keywords.join(", ") : ""}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {widget.author}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardWidgetPopover;
