/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import {
  Grid,
  List,
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import theme from "../../AppTheme";
import VfDialog from "../vf/VfDialog";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  icon: {
    fontSize: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  list: {
    margin: theme.spacing(0, -4),
  },
  listItem: {
    padding: theme.spacing(2, 4),
  },
});

export default function SelectEnvsDialog({
  show,
  setShow,
  envs,
  selectedEnv,
  dispatch,
  swaggerFile,
  setSwaggerFile,
  apiInfo,
}) {
  const classes = useStyles();

  const [selected, setSelected] = useState([selectedEnv._id]);

  const handleChange = e => {
    let values = [];
    if (e.target.checked) {
      values = [...selected, e.target.value];
    } else {
      values = selected.filter(env => env !== e.target.value);
    }
    setSelected(values);
  };

  const onConfirmActionClick = async () => {
    const jsonContent = JSON.parse(swaggerFile);

    const fileId = `${jsonContent.info.title}-v${jsonContent.info.version}`;
    const { version } = jsonContent.info;

    selected.forEach(envId => {
      const swggrFile = {
        fileId,
        env: envId,
        fileData: swaggerFile,
        version,
        unsaved: true,
      };

      const found = apiInfo.files.find(
        file => file.fileId === fileId && file.env === envId
      );

      if (!found) {
        dispatch({
          type: "addFile",
          payload: swggrFile,
        });
      }
    });
  };

  return (
    <VfDialog
      title={JSON.parse(swaggerFile).info.title}
      openDialog={show}
      buttonConfirmText="Add swagger"
      buttonCloseAction={() => {
        setShow(false);
      }}
      buttonConfirmAction={() => {
        setShow(false);
        onConfirmActionClick();
        setSwaggerFile(null);
      }}
      buttonConfirmDisabled={!selected.length}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2">Select environments</Typography>
        </Grid>
        <Grid item xs={12}>
          <List className={classes.list}>
            {envs
              .sort((a, b) => a.order - b.order)
              .map(
                env =>
                  !env.isGlobal && (
                    <ListItem
                      divider
                      key={env._id}
                      className={classes.listItem}
                    >
                      <VfSvgIcon
                        className={classes.icon}
                        icon={env.title.toLowerCase()}
                        viewBox={24}
                        fontSize="large"
                      />
                      <ListItemText primary={env.title} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          color="primary"
                          edge="end"
                          onChange={handleChange}
                          name={env.title}
                          value={env._id}
                          checked={selected.includes(env._id)}
                          inputProps = {{"data-testid": `inputFeild_${(env.titlee).replace(/\s/g, "")}`}}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
              )}
          </List>
        </Grid>
      </Grid>
    </VfDialog>
  );
}
