import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useRef, useState } from "react";

const MenuButton = ({ items, disabled, onClick, onClose }) => {
  const attachTo = useRef(null);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(items[0]);

  useEffect(() => {
    setOpen(false);
  }, [selected]);

  return (
    <>
      <ButtonGroup variant="contained" color="secondary" ref={attachTo}>
        <Button onClick={() => onClick(selected.id)} disabled={disabled}>
          {selected.label}
        </Button>

        <Button
          size="small"
          aria-haspopup="true"
          onClick={() => setOpen(true)}
          disabled={disabled}
        >
          <ArrowDropDownIcon fontSize="small" />
        </Button>
      </ButtonGroup>
      <Menu
        id="simple-menu"
        anchorEl={attachTo.current}
        keepMounted
        open={open}
        onClose={() => {
          setOpen(false);
          onClose();
        }}
      >
        {items.map(item => (
          <MenuItem
            key={item.id}
            onClick={() => {
              setOpen(false);
              setSelected(item);
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButton;
