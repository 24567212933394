import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Slide,
  Box,
  Fade,
  Toolbar,
  AppBar,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import theme from "../../AppTheme";
import VfDialogConfirm from "./VfDialogConfirm";

const useStyles = makeStyles({
  dialogContent: {
    padding: 0,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Fade ref={ref} {...props}>
      <Box height="70vh" position="absolute" left={0} bottom={0} right={0}>
        <Slide direction="up" ref={ref} {...props} />
      </Box>
    </Fade>
  );
});

const VfDialogDrawer = ({
  appBar,
  openDialog,
  children,
  title,
  buttonConfirmAction,
  buttonConfirmText,
  buttonConfirmDisabled,
  buttonCloseAction,
  showConfirmDialog,
  saveAction,
  hasValidations,
  buttonConfirmGroup,
}) => {
  const classes = useStyles();
  const [dialogConfirm, setDialogConfirm] = React.useState(false);
  const handleDialogConfirm = val => {
    setDialogConfirm(val);
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      TransitionComponent={Transition}
      transitionDuration={theme.transitions.duration.complex}
      scroll="paper"
    >
      <AppBar position="relative" color="inherit" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Grid container alignItems="center" wrap="nowrap" spacing={3}>
            {appBar ? (
              <Grid item xs>
                {appBar}
              </Grid>
            ) : (
              <Grid item xs>
                <Typography>{title}</Typography>
              </Grid>
            )}
            <Grid item xs="auto">
              <Button
                autoFocus
                size="small"
                color="default"
                variant="outlined"
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  showConfirmDialog
                    ? handleDialogConfirm(true)
                    : buttonCloseAction();
                }}
              >
                Cancel
              </Button>
              <VfDialogConfirm
                openDialog={dialogConfirm}
                maxWidth="xs"
                buttonCancelText="No"
                buttonCancelAction={() => handleDialogConfirm(false)}
                buttonConfirmText="Yes"
                buttonConfirmAction={() => {
                  handleDialogConfirm(false);
                  buttonCloseAction();
                }}
              >
                <Typography variant="body1" align="center">
                  Are you sure you want to discard your changes?
                </Typography>
              </VfDialogConfirm>
            </Grid>
            {buttonConfirmText && buttonConfirmAction && (
              <Grid item xs="auto">
                <Button
                  autoFocus
                  size="small"
                  color="secondary"
                  variant="contained"
                  disabled={buttonConfirmDisabled}
                  onClick={() => {
                    if (saveAction) saveAction();
                    if (!hasValidations) buttonCloseAction();
                    buttonConfirmAction();
                  }}
                >
                  {buttonConfirmText}
                </Button>
              </Grid>
            )}
            {buttonConfirmGroup}
          </Grid>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default VfDialogDrawer;
