/* eslint-disable no-useless-computed-key */
import React from "react";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import CardActionArea from "@material-ui/core/CardActionArea";
import VfSvgIcon from "../../icons/VfSvgIcon";

const CardSelectorItems = ({
  title,
  value,
  imageIcon,
  selected,
  disabled,
  onSelect,
}) => {
  return (
    <Card
      className={classNames({
        "MuiCard-selection": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-rounded": true,
        "MuiCard-selected": selected,
        "MuiCard-disabled": disabled,
      })}
      elevation={0}
    >
      <CardActionArea onClick={() => onSelect(value)}>
        <CardContent>
          <VfSvgIcon icon={imageIcon} viewBox="56" fontSize="large" />
          <Typography variant="body2" component="h5">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardSelectorItems;
