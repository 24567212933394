/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { cloneDeep } from "lodash";

import ArrayListItem from "./ArrayListItem";
import VfSvgIcon from "../icons/VfSvgIcon";

// const jp = require("jsonpath");

const ArrayList = ({ items, onAddElement, onRemoveElement }) => {
  return (
    <>
      <Grid container spacing={3}>
        {items &&
          items.length > 0 &&
          items.map(({ key }, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={key} item xs={12}>
                <ArrayListItem index={index} remove={onRemoveElement} />
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<VfSvgIcon icon="add" viewBox={24} />}
            onClick={onAddElement}
          >
            Add Element
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ArrayList;
