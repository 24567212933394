import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";

const ArrayListItem = ({ index, remove }) => {
  return (
    <Card
      className="MuiCard-shadow MuiCard-white MuiCard-ordering MuiCard-shadow animate"
      elevation={0}
    >
      <Grid container alignItems="center" wrap="nowrap" spacing={3}>
        <Grid item xs>
          <CardActionArea disableRipple disableTouchRipple focusRipple={false}>
            <CardHeader
              title={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography variant="h5" component="h3">
                  {`Item ${index}`}
                </Typography>
              }
            />
          </CardActionArea>
        </Grid>
        <Grid item>
          <CardActions className="animate">
            <IconButton onClick={() => remove(index)}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ArrayListItem;
