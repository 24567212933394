const SET_TOUCHPOINT = "SET_TOUCHPOINT";
const SET_TOUCHPOINTS_LIST = "SET_TOUCHPOINTS_LIST";
const CLEAR_TOUCHPOINT = "CLEAR_TOUCHPOINT";

const setTouchpoint = payload => ({
  type: SET_TOUCHPOINT,
  payload,
});
const setTouchpointsList = payload => ({
  type: SET_TOUCHPOINTS_LIST,
  payload,
});

const clearTouchpoint = () => ({
  type: CLEAR_TOUCHPOINT,
});

export {
  SET_TOUCHPOINT,
  SET_TOUCHPOINTS_LIST,
  CLEAR_TOUCHPOINT,
  setTouchpoint,
  setTouchpointsList,
  clearTouchpoint,
};
