/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import VfDialog from "../vf/VfDialog";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  version: {
    color: theme.palette.secondary.main,
  },
  notAvailable: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default function AddApiDialog({
  dialogApi,
  setDialogApi,
  handleAddNewApi,
  apis,
}) {
  const classes = useStyles();
  const [selectedApi, setSelectedApi] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState("");

  return (
    <VfDialog
      title="Add API"
      openDialog={dialogApi}
      buttonConfirmText="Add API"
      buttonCloseAction={() => setDialogApi(false)}
      buttonConfirmAction={() => {
        handleAddNewApi({ ...selectedApi, selectedVersion });
        setDialogApi(false);
      }}
      buttonConfirmDisabled={!selectedVersion}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            select
            SelectProps={{
              renderValue: () => {
                return selectedApi.name;
              },
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              },
            }}
            onChange={e => {
              setSelectedApi(e.target.value);
            }}
            label="Select API"
            value={selectedApi?.name}
            variant="filled"
            color="secondary"
            fullWidth
            InputProps={{
              disableUnderline: true,
              className: classNames("MuiFilledInput-disableUnderline"),
            }}
          >
            {apis?.map(a => (
              <MenuItem key={a._id} value={a}>
                <ListItemText primary={a.name} secondary={a.tags.join(" ")} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {selectedApi && (
          <Grid item xs={12}>
            <TextField
              select
              SelectProps={{
                MenuProps: {
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                },
              }}
              onChange={e => setSelectedVersion(e.target.value)}
              label="Select Version"
              value={selectedVersion}
              variant="filled"
              color="secondary"
              fullWidth
              InputProps={{
                disableUnderline: true,
                className: classNames("MuiFilledInput-disableUnderline"),
              }}
            >
              {selectedApi.versions.map(version => (
                <MenuItem
                  key={version.name}
                  value={version.name}
                  disabled={!version.available}
                  className={classes.version}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography component="div" variant="h5">
                        {`v${version.name}`}
                      </Typography>
                    </Grid>
                    {!version.available && (
                      <Grid item xs={6} className={classes.notAvailable}>
                        <Typography component="div" variant="body2">
                          Not added in all environments
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
      </Grid>
    </VfDialog>
  );
}
