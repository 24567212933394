const CONFIG_LIST_APP_REQUEST = "CONFIG_LIST_APP_REQUEST";
const CONFIG_LIST_APP_REQUEST_SUCCESS = "CONFIG_LIST_APP_REQUEST_SUCCESS";
const CONFIG_LIST_APP_REQUEST_FAIL = "CONFIG_LIST_APP_REQUEST_FAIL";
const SET_APP = "SET_APP";
const CLEAR_APP = "CLEAR_APP";
const CLEAR_APPS_LIST = "CLEAR_APPS_LIST";

const fetchConfigListApp = payload => ({
  type: CONFIG_LIST_APP_REQUEST,
  payload,
});

const setApp = payload => ({
  type: SET_APP,
  payload,
});

const clearApp = () => ({
  type: CLEAR_APP,
});

const clearAppsList = () => ({
  type: CLEAR_APPS_LIST,
});

export {
  CONFIG_LIST_APP_REQUEST,
  CONFIG_LIST_APP_REQUEST_SUCCESS,
  CONFIG_LIST_APP_REQUEST_FAIL,
  SET_APP,
  CLEAR_APP,
  CLEAR_APPS_LIST,
  fetchConfigListApp,
  setApp,
  clearApp,
  clearAppsList,
};
