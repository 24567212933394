/* eslint-disable no-underscore-dangle */
import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import compareVersions from "compare-versions";

import CardWidgetVersion from "../apps/CardWidgetVersion";
import VfDialogFull from "../vf/VfDialogFull";
import CardWidgetList from "../widgets/CardWidgetList";
import Loader from "../loader/Loader";
import theme from "../../AppTheme";

export default function DialogTranslationsVersions({
  envs,
  selectedWidget,
  widgetsVersions,
  dialogVersion,
  setDialogVersion,
  setDialogWidget,
  clearWidgetVersions,
  clearWidgetDetails,
  setTranslationWdgt,
  widgetDetails,
  handleMoreInfoClick,
  handlePromoteClick,
  versionsLoading,
  application
}) {
  return (
    <VfDialogFull openDialog={dialogVersion}>
      <Box py={6}>
        <Container maxWidth="md">
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <CardWidgetList
                showEnv
                envTitle={selectedWidget.envTitle}
                envType={
                  envs.find(e =>
                    selectedWidget ? e._id === selectedWidget.envId : null
                  )?.type
                }
                widget={selectedWidget}
                backAction={() => {
                  clearWidgetVersions();
                  setDialogVersion(false);
                }}
              />
            </Grid>
            {!versionsLoading ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {widgetsVersions
                    .sort((a, b) => {
                      return compareVersions(a.version, b.version);
                    })
                    .reverse()
                    .map(widgetVer => {
                      return (
                        <Grid key={widgetVer.version} item xs={6} sm={4}>
                          <CardWidgetVersion
                            envs={envs}
                            sourceEnvId={selectedWidget.envId}
                            handlePromoteClick={handlePromoteClick}
                            widget={widgetDetails}
                            widgetFromTranslation={widgetVer}
                            setWidget={() => {
                              setTranslationWdgt({
                                ...selectedWidget,
                                ...widgetVer,
                              });
                              setDialogWidget(true);
                            }}
                            setWidgetVersionToApplication={() => {}}
                            appInfo={null}
                            handleMoreInfoClick={handleMoreInfoClick}
                            clearWidgetDetails={clearWidgetDetails}
                            moreActions
                            application={application}

                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Loader size={theme.spacing(12)} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </VfDialogFull>
  );
}
