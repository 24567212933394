import uuidv4 from "uuid/v4";
/* eslint-disable no-underscore-dangle */
const gridColToLayoutCol = col => {
  if (col.__component !== "GridItem") {
    return { err: "Top component in column is not GridItem" };
  }

  const layoutCol = {
    id: col.__props.id ? col.__props.id : uuidv4(),
    xs: col.__props.xs,
    sm: col.__props.sm,
    md: col.__props.md,
    widget: null,
  };

  if (
    col.__props.children &&
    col.__props.children.length > 0 &&
    col.__props.children[0].__component !== "CardHeader"
  ) {
    layoutCol.widget = {
      name: col.__props.children[0].__component,
      images: [{ default: col.__props.children[0].__props.__image }],
      imageFromProps: col.__props.children[0].__props.imageFromProps,
      instanceId: col.__props.children[0].__props.__instanceId,
    };
  }

  return layoutCol;
};

const gridRowToLayoutRow = row => {
  if (row.__component !== "Grid") {
    return { err: "Top component in row is not Grid" };
  }

  return {
    id: row.__props.id ? row.__props.id : uuidv4(),
    spacing: row.__props.spacing,
    justify: row.__props.justify,
    alignItems: row.__props.alignItems,
    direction: row.__props.direction,
    cols: row.__props.children.map(gridColToLayoutCol),
  };
};

const isGrid = page => {
  const { djr } = page;
  // eslint-disable-next-line no-underscore-dangle
  if (djr.__component !== "Fragment") return false;
  const children = djr.__props?.children;
  if (!children) return true;
  const notGrid = children.find(c => c.__component !== "Grid");
  if (notGrid) return false;
  const grandChildren = children.flatMap(c => c.__props?.children);
  const notGridItem = grandChildren.find(c => c.__component !== "GridItem");
  if (notGridItem) return false;
  return true;
};

const djrToLayout = grid => {
  if (!isGrid({ djr: grid })) {
    return null;
  }

  return { rows: grid.__props.children.map(gridRowToLayoutRow) };
};

const htmlTags = [
  "a",
  "abbr",
  "acronym",
  "address",
  "applet",
  "area",
  "article",
  "aside",
  "audio",
  "b",
  "base",
  "basefont",
  "bdi",
  "bdo",
  "bgsound",
  "big",
  "blink",
  "blockquote",
  "body",
  "br",
  "button",
  "canvas",
  "caption",
  "center",
  "cite",
  "code",
  "col",
  "colgroup",
  "content",
  "data",
  "datalist",
  "dd",
  "decorator",
  "del",
  "details",
  "dfn",
  "dir",
  "div",
  "dl",
  "dt",
  "element",
  "em",
  "embed",
  "fieldset",
  "figcaption",
  "figure",
  "font",
  "footer",
  "form",
  "frame",
  "frameset",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "head",
  "header",
  "hgroup",
  "hr",
  "html",
  "i",
  "iframe",
  "img",
  "input",
  "ins",
  "isindex",
  "kbd",
  "keygen",
  "label",
  "legend",
  "li",
  "link",
  "listing",
  "main",
  "map",
  "mark",
  "marquee",
  "menu",
  "menuitem",
  "meta",
  "meter",
  "nav",
  "nobr",
  "noframes",
  "noscript",
  "object",
  "ol",
  "optgroup",
  "option",
  "output",
  "p",
  "param",
  "plaintext",
  "pre",
  "progress",
  "q",
  "rp",
  "rt",
  "ruby",
  "s",
  "samp",
  "script",
  "section",
  "select",
  "shadow",
  "small",
  "source",
  "spacer",
  "span",
  "strike",
  "strong",
  "style",
  "sub",
  "summary",
  "sup",
  "table",
  "tbody",
  "td",
  "template",
  "textarea",
  "tfoot",
  "th",
  "thead",
  "time",
  "title",
  "tr",
  "track",
  "tt",
  "u",
  "ul",
  "var",
  "video",
  "wbr",
  "xmp",
];

export { djrToLayout, isGrid, htmlTags };
