/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";

import {
  ENVS_REQUEST,
  ENVS_REQUEST_SUCCESS,
  ENVS_REQUEST_FAIL,
  URLS_RESOURCES_REQUEST,
  URLS_RESOURCES_REQUEST_SUCCESS,
  URLS_RESOURCES_REQUEST_FAIL,
} from "actions/envsActions";
import {
  S3_URLS_RESOURCES_REQUEST,
  S3_URLS_RESOURCES_REQUEST_SUCCESS,
  S3_URLS_RESOURCES_REQUEST_FAIL,
} from "actions/s3EnvsActions";
import EnvsService from "services/EnvsService";

function* envsWorker({ payload }) {
  try {
    const data = yield call(EnvsService.fetchEnvsByLocale, payload);
    yield put({
      type: ENVS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: ENVS_REQUEST_FAIL, error });
  }
}

function* envsWatcher() {
  yield takeLatest(ENVS_REQUEST, envsWorker);
}

function* envURLsAndResourcesWorker({ payload }) {
  try {
    const { data } = yield call(
      EnvsService.fetchURLsAndResourcesPerEnv,
      payload
    );
    yield put({
      type: URLS_RESOURCES_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: URLS_RESOURCES_REQUEST_FAIL, error });
  }
}

function* envURLsAndResourcesWatcher() {
  yield takeLatest(URLS_RESOURCES_REQUEST, envURLsAndResourcesWorker);
}

function* envS3URLsWorker({ payload }) {
  try {
    const { data } = yield call(
      EnvsService.fetchS3URLsAndResourcesPerEnv,
      payload
    );
    yield put({
      type: S3_URLS_RESOURCES_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: S3_URLS_RESOURCES_REQUEST_FAIL, error });
  }
}

function* envS3URLsWatcher() {
  yield takeLatest(S3_URLS_RESOURCES_REQUEST, envS3URLsWorker);
}

const envsSaga = [envsWatcher(), envURLsAndResourcesWatcher(), envS3URLsWatcher()];

export default envsSaga;
