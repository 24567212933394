/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import classNames from "classnames";
import React, { useState } from "react";
import SpainIntegrationService from "services/SpainIntegrationService";
import theme from "AppTheme";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  useMediaQuery,
  Button,
  FormControl,
  TextField,
  MenuItem,
  Typography,
  Grid,
  DialogActions,
} from "@material-ui/core";
import AppDialogFullHeader from "../common/AppDialogFullHeader";

const WorkbenchDevMenuItem = ({ application, onClick, auth }) => {
  const { access_token, id_token } = auth;
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [show, setShow] = useState(false);
  const [showFail, setShowFail] = useState(false);
  const [deploymentPath, setDeploymentPath] = useState({});
  const [deploymentPathList, setDeploymentPathList] = useState([]);
  const [delivery, setDelivery] = useState({});
  const [deliveryList, setDeliveryList] = useState([]);
  const [project, setProject] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [message, setMessage] = useState("Could not retrieve the necessary data for this action.");
  const clearValues = () => {
    setDeploymentPath({});
    setDeploymentPathList([]);
    setDelivery({});
    setDeliveryList([]);
    setProject({});
    setProjectList([]);
  };
  const onCancel = () => {
    clearValues();
    setShow(false);
  };

  const onComplete = () => {
    SpainIntegrationService.createPackage({
      access_token,
      id_token,
      body: {
        appId: application.application,
        appName: application.title,
        appVersion: application.version,
        technology: application.touchpoint,
        dockerImageTag: application.dockerImageTag,
        dockerImagePath: application.dockerImagePath,
        workbenchAppId: application.workbenchApplication._id,
        deploymentPathId: deploymentPath.id,
        projectId: project.id,
      },
    })
      .then(({ data, error }) => {
        if (error) {
          console.log(error);
          if (error.message) setMessage(error.message);
          setShow(false);
          setShowFail(true);
        }
        console.log(data);
      })
      .catch(error => {
        console.log(error);
        setShow(false);
        setShowFail(true);
      });
    clearValues();
    setShow(false);
  };

  const fetchProjectsList = deliveryData => {
    SpainIntegrationService.fetchProjectsList({
      access_token,
      id_token,
      body: { delivery: deliveryData },
    })
      .then(({ data, error }) => {
        if (error) {
          setShow(false);
          setShowFail(true);
        } else {
          setProjectList(data);
        }
      })
      .catch(() => {
        setShow(false);
        setShowFail(true);
      });
  };
  const ActionButtons = () => {
    return (
      <>
        <Grid item xs="auto">
          <Button autoFocus color="default" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs="auto">
          <Button
            autoFocus
            color="secondary"
            variant="contained"
            disabled={!(!!delivery?.id && !!deploymentPath?.id && !!project.id)}
            onClick={onComplete}
          >
            Create
          </Button>
        </Grid>
      </>
    );
  };
  return application.status === "Deployed" ? (
    <>
      <MenuItem
        onClick={() => {
          onClick();
          SpainIntegrationService.fetchDeploymentData({
            access_token,
            id_token,
            body: {
              application: application.application,
              workbenchAppId: application.workbenchApplication._id,
            },
          })
            .then(({ data, error }) => {
              if (error) {
                setShow(false);
                setShowFail(true);
              } else {
                setDeploymentPathList(data.deploymentPaths);
                setDeliveryList(data.deliveryList);
                setShow(true);
              }
            })
            .catch(() => {
              setShow(false);
              setShowFail(true);
            });
        }}
      >
        CREATE PACKAGE
      </MenuItem>
      <Dialog fullScreen={fullScreen} open={show} maxWidth="md">
        <AppDialogFullHeader includeLogo application={application} buttons={<ActionButtons setShow={setShow} />} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Select Deployment Path
              </Typography>
              <FormControl fullWidth>
                <TextField
                  select
                  SelectProps={{
                    renderValue: () => {
                      return deploymentPath?.name;
                    },
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    },
                  }}
                  onChange={e => {
                    setDeploymentPath(e.target.value);
                  }}
                  label="Deployment Path"
                  value={deploymentPath?.name}
                  variant="filled"
                  color="primary"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    className: classNames("MuiFilledInput-disableUnderline"),
                  }}
                >
                  {deploymentPathList &&
                    deploymentPathList.map(a => (
                      <MenuItem key={a.id} value={a}>
                        <Typography component="div" variant="h5">
                          {a?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="body2" color="textSecondary">
                  Select Delivery
                </Typography>
                <TextField
                  select
                  SelectProps={{
                    renderValue: () => {
                      return delivery?.name;
                    },
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    },
                  }}
                  onChange={e => {
                    setDelivery(e.target.value);
                    fetchProjectsList(e.target.value.id);
                  }}
                  label="Delivery"
                  value={delivery?.name}
                  variant="filled"
                  color="primary"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    className: classNames("MuiFilledInput-disableUnderline"),
                  }}
                >
                  {deliveryList &&
                    deliveryList.map(a => (
                      <MenuItem key={a.id} value={a}>
                        <Typography component="div" variant="h5">
                          {a?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Select Project
              </Typography>
              <FormControl fullWidth>
                <TextField
                  select
                  SelectProps={{
                    renderValue: () => {
                      return project?.name;
                    },
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    },
                  }}
                  onChange={e => {
                    setProject(e.target.value);
                  }}
                  label="Project"
                  value={project?.name}
                  variant="filled"
                  color="primary"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    className: classNames("MuiFilledInput-disableUnderline"),
                  }}
                >
                  {projectList &&
                    projectList.map(a => (
                      <MenuItem key={a.id} value={a}>
                        <Typography component="div" variant="h5">
                          {a?.name}
                        </Typography>
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={showFail} maxWidth="xs">
        <DialogContent>
          <DialogContentText gutterBottom>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" onClick={() => setShowFail(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <></>
  );
};

export default WorkbenchDevMenuItem;
