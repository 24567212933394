import jp from "jsonpath";
import { filter, includes } from "lodash";
import { globalReduxSchema } from "../components/bpmn-custom-components/schema";
import {
  constructConfigActionSchema,
  constructWidgetsReduxSlices,
  schemaToTreeForBpmn,
} from "../components/bpmn-custom-components/ElementsUtils";

const defaultValidatorResponse = { isValid: true, messages: [] };

const removeDuplicateMessages = messageArray => {
  const uniques = new Set(messageArray);
  return [...uniques];
};

const hasDuplicates = (djr, pages, currentPageId) => {
  const response = { ...defaultValidatorResponse };
  const appInstanceIds = [
    ...pages.filter(({ _id }) => _id !== currentPageId),
    { _id: currentPageId, data: djr },
  ].flatMap(({ data }) => jp.query(data, "$..__instanceId"));

  const duplicates = filter(appInstanceIds, (value, index, iteratee) =>
    includes(iteratee, value, index + 1)
  ).map(duplicate => `Error: Duplicated instance ids: ${duplicate}`);

  if (duplicates.length > 0) {
    response.isValid = false;
    response.messages = removeDuplicateMessages(duplicates);
  }

  return response;
};

const errorsForExpression = (schema, functions) => {
  const tree = schemaToTreeForBpmn(globalReduxSchema, "$");
  const pathError = (currTree, item, segments) => {
    if (segments.length === 0) return [];
    if (!currTree.children || currTree.children.length === 0) return []; // We allow this because the schema might not be complete
    const [segment, ...rest] = segments;
    const found = currTree.children.find(s => s.name === segment);
    if (!found) {
      const trimmed = segment.trim();
      const foundTrimmed = currTree.children.find(s => s.name === trimmed);
      if (foundTrimmed) {
        return [`Spaces are not allowed around ${trimmed}`];
      }
      return [`Could not find ${segment} in ${item}`];
    }
    return pathError(found, item, rest);
  };
  const ie = item => {
    if (item.startsWith("!")) {
      return [];
    }
    const segments = item.split(".");
    return pathError(tree, item, segments);
  };
  const e = expression => {
    const argsAndFunc = expression.split("|");
    if (argsAndFunc.length === 2) {
      const items = argsAndFunc[0].split(",");
      const errorsForItems = items.flatMap(ie);
      const fn = argsAndFunc[1];
      if (!functions[fn]) {
        if (functions[fn.trim()]) {
          return [`Space is not allowed around ${fn}`, ...errorsForItems];
        }

        return [`Inexistent function ${fn}`, ...errorsForItems];
      }
      return errorsForItems;
    }

    if (argsAndFunc.length === 1 && expression.startsWith("!")) {
      return [];
    }

    if (argsAndFunc.length > 2) {
      return [`Cannot have more than one pipe symbol in ${expression}`];
    }

    const items = argsAndFunc[0].split(",");
    const errorsForItems = items.flatMap(ie);
    if (items.length > 1) {
      return [
        `Missing function for expression ${expression}`,
        ...errorsForItems,
      ];
    }
    return errorsForItems;
  };

  return e;
};

const invalidStatePaths = (appInfo, djr, fatWidgets, appWidgets) => {
  const response = { ...defaultValidatorResponse };
  const stateExpressions = [
    ...jp.query(djr, "$..__mapStateToProps"),
    ...jp.query(djr, "$..__render"),
  ];

  globalReduxSchema.properties = {
    ...globalReduxSchema.properties,
    ...constructWidgetsReduxSlices(fatWidgets, appWidgets),
  };

  // I've left the below in as per Ioannis example but as far as i can tell this doesnt seem to add anything
  // the content of globalReduxSchema.properties.config.properties.data.properties.widgets.properties
  // is already set by line 212. Might want reviewing to see if it can be removed?
  globalReduxSchema.properties.config.properties.data.properties.widgets.properties = constructConfigActionSchema(
    fatWidgets,
    appWidgets
  );

  const functions = Object.fromEntries([
    ["not", 1],
    ["toString", 1],
    ...(appInfo.snippets || []).map(s => [`functions.${s.data?.name}`, 1]),
    ...(appInfo.packages || []).flatMap(p =>
      p.declarations.map(d => [
        `${p?.name.replace(/-/g, "_").toLowerCase()}.${d?.name}`,
        1,
      ])
    ),
  ]);

  const createErrors = errorsForExpression(globalReduxSchema, functions);
  const invalidPaths = stateExpressions.flatMap(createErrors);

  if (invalidPaths.length > 0) {
    response.isValid = false;
    response.messages = removeDuplicateMessages(invalidPaths);
  }

  return response;
};

const invalidActionProps = (appInfo, djr) => {
  const response = { ...defaultValidatorResponse };
  const functionExpressions = [...jp.query(djr, "$..__props.*.function")];

  const functions = Object.fromEntries([
    ["not", 1],
    ["toString", 1],
    ...(appInfo.snippets || []).map(s => [`functions.${s.data?.name}`, 1]),
    ...(appInfo.packages || []).flatMap(p =>
      p.declarations.map(d => [
        `${p?.name.replace(/-/g, "_").toLowerCase()}.${d?.name}`,
        1,
      ])
    ),
  ]);

  const invalidFunctions = functionExpressions.flatMap(e => {
    if (typeof e === "string" && functions[e]) return [];
    return [`Function ${e} does not exist`];
  });

  if (invalidFunctions.length > 0) {
    response.isValid = false;
    response.messages = removeDuplicateMessages(invalidFunctions);
  }

  return response;
};

export { hasDuplicates, invalidStatePaths, invalidActionProps };
