const CONFIG_LIST_WIDGET_REQUEST = "CONFIG_LIST_WIDGET_REQUEST";
const CONFIG_LIST_WIDGET_REQUEST_SUCCESS = "CONFIG_LIST_WIDGET_REQUEST_SUCCESS";
const CONFIG_LIST_WIDGET_REQUEST_FAIL = "CONFIG_LIST_WIDGET_REQUEST_FAIL";
const CONFIG_RESET_WIDGET_REQUEST = "CONFIG_RESET_WIDGET_REQUEST";
const CONFIG_RESET_WIDGET_REQUEST_SUCCESS =
  "CONFIG_RESET_WIDGET_REQUEST_SUCCESS";
const CONFIG_RESET_WIDGET_REQUEST_FAIL = "CONFIG_RESET_WIDGET_REQUEST_FAIL";
const CONFIG_DEPLOY_WIDGET_REQUEST = "CONFIG_DEPLOY_WIDGET_REQUEST";
const CONFIG_DEPLOY_WIDGET_REQUEST_SUCCESS =
  "CONFIG_DEPLOY_WIGET_REQUEST_SUCCESS";
const CONFIG_DEPLOY_WIDGET_REQUEST_FAIL = "CONFIG_DEPLOY_WIDGET_REQUEST_FAIL";
const SET_WIDGET = "SET_WIDGET";
const CLEAR_WIDGET = "CLEAR_WIDGET";
const CLEAR_WIDGETS_LIST = "CLEAR_WIDGETS_LIST";

const fetchConfigListWidget = payload => ({
  type: CONFIG_LIST_WIDGET_REQUEST,
  payload,
});

const resetConfigListWidget = payload => ({
  type: CONFIG_RESET_WIDGET_REQUEST,
  payload,
});

const deployConfigListWidget = payload => ({
  type: CONFIG_DEPLOY_WIDGET_REQUEST,
  payload,
});

const setWidget = payload => ({
  type: SET_WIDGET,
  payload,
});

const clearWidget = () => ({
  type: CLEAR_WIDGET,
});

const clearWidgetsList = () => ({
  type: CLEAR_WIDGETS_LIST,
});

export {
  CONFIG_LIST_WIDGET_REQUEST,
  CONFIG_LIST_WIDGET_REQUEST_SUCCESS,
  CONFIG_LIST_WIDGET_REQUEST_FAIL,
  CONFIG_RESET_WIDGET_REQUEST,
  CONFIG_RESET_WIDGET_REQUEST_SUCCESS,
  CONFIG_RESET_WIDGET_REQUEST_FAIL,
  CONFIG_DEPLOY_WIDGET_REQUEST,
  CONFIG_DEPLOY_WIDGET_REQUEST_SUCCESS,
  CONFIG_DEPLOY_WIDGET_REQUEST_FAIL,
  SET_WIDGET,
  CLEAR_WIDGET,
  CLEAR_WIDGETS_LIST,
  fetchConfigListWidget,
  resetConfigListWidget,
  deployConfigListWidget,
  setWidget,
  clearWidget,
  clearWidgetsList,
};
