import React from "react";
import { connect } from "react-redux";

import Breadcrumb from "components/breadcrumb/Breadcrumb";
import EnvironmentsDetails from "./EnvironmentsDetails";

const Environments = ({ envs }) => {
  return (
    <main>
      <Breadcrumb />
      <EnvironmentsDetails environments={envs} title="Select an environment" />
    </main>
  );
};

const mapStateToProps = state => {
  return {
    envs: state.envs.list,
  };
};

export default connect(mapStateToProps)(Environments);
