import {
  CONFIG_HISTORY_REQUEST_SUCCESS,
  CONFIG_HISTORY_REQUEST_FAIL,
  CLEAR_CONFIG_HISTORY,
} from "actions/historyActions";

const initialState = {
  history: {},
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case CONFIG_HISTORY_REQUEST_SUCCESS:
      return {
        ...state,
        history: payload,
      };
    case CONFIG_HISTORY_REQUEST_FAIL:
      return {
        ...state,
        ...error,
      };
    case CLEAR_CONFIG_HISTORY:
      return {
        ...state,
        history: {},
      };
    default:
      return state;
  }
};
