/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import { is } from "bpmn-js/lib/util/ModelUtil";

const { parseScript } = require("shift-parser");
const codegen = require("shift-codegen").default;
const jp = require("jsonpath");
const traverse = require("traverse");
const uuid = require("uuid");
// const { isEmpty } = require("lodash");

const objectMatching = (obj, objPat) => {
  if (typeof obj === "object") {
    if (typeof objPat === "object") {
      for (const x of Object.getOwnPropertyNames(objPat)) {
        if (!Object.prototype.hasOwnProperty.call(obj, x) || !objectMatching(obj[x], objPat[x])) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  if (Array.isArray(obj)) {
    if (Array.isArray(objPat)) {
      for (let i = 0; i < objPat.length; i += 1) {
        if (!objectMatching(obj[i], objPat[i])) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  if (!(objPat === null) && !(objPat === undefined) && objPat === obj) {
    return true;
  }
  return false;
};

const itemToField = expr => {
  if (
    objectMatching(expr, {
      // case of "path"
      type: "CallExpression",
      callee: {
        type: "IdentifierExpression",
        name: "val",
      },
      arguments: [
        {
          type: "LiteralStringExpression",
        },
        {
          type: "IdentifierExpression",
          name: "context",
        },
      ],
    })
  ) {
    return {
      key: uuid(),
      path: expr.arguments[0].value,
    };
  }
  if (
    objectMatching(expr, {
      // case of "literal"
      type: "CallExpression",
      callee: {
        type: "IdentifierExpression",
        name: "val",
      },
      arguments: [
        {
          type: "LiteralNullExpression",
        },
        {
          type: "LiteralStringExpression",
        },
      ],
    })
  ) {
    return {
      key: uuid(),
      literal: expr.arguments[1].value,
    };
  }
  if (
    objectMatching(expr, {
      // case of "js"
      type: "CallExpression",
      callee: {
        type: "IdentifierExpression",
        name: "val",
      },
      arguments: [
        {},
        {
          type: "LiteralNullExpression",
        },
      ],
    })
  ) {
    return {
      key: uuid(),
      code: codegen(expr.arguments[0]),
    };
  }
  // shouldn't reach here!
  return {};
};

const astToTree = expr => {
  if (expr.type === "ObjectExpression") {
    const entries = expr.properties.map(p => [p.name.value, astToTree(p.expression)]);
    return {
      children: Object.fromEntries(entries),
    };
  }
  if (expr.type === "ArrayExpression") {
    return {
      elements: expr.elements.map(astToTree).map(a => ({
        key: uuid(),
        ...a,
      })),
    };
  }
  if (
    objectMatching(expr, {
      type: "CallExpression",
      callee: {
        type: "IdentifierExpression",
        name: "field",
      },
      arguments: [
        {},
        {
          type: "ArrayExpression",
        },
      ],
    })
  ) {
    const fname = expr.arguments[0].type === "LiteralStringExpression" ? expr.arguments[0].value : null;
    const fields = expr.arguments[1].elements.map(itemToField);
    return {
      function: fname,
      values: fields,
    };
  }
};

const destructure = text => {
  try {
    const ast = parseScript(text);
    for (const st of ast.statements) {
      if (
        st.type === "ExpressionStatement" &&
        st.expression.type === "CallExpression" &&
        st.expression.callee.type === "IdentifierExpression"
      ) {
        const effect = st.expression.callee.name;
        if (effect === "call") {
          const argumentExpressions = [...st.expression.arguments];
          argumentExpressions.splice(0, 1);
          const calleeType = st.expression.arguments[0].type;
          if (
            calleeType === "StaticMemberExpression" &&
            st.expression.arguments[0].object.type === "IdentifierExpression" &&
            argumentExpressions.length === 1
          ) {
            try {
              const api = st.expression.arguments[0].object.name;
              const operation = st.expression.arguments[0].property;
              return {
                effect,
                api,
                operation,
                arguments: astToTree(argumentExpressions[0]),
              };
            } catch (e) {
              return {
                effect,
                callExpression: codegen(st.expression),
              };
            }
          }
          return {
            effect,
            callExpression: codegen(st.expression),
          };
        }
        if (effect === "select") {
          if (
            st.expression.arguments.length === 1 &&
            st.expression.arguments[0].type === "ArrowExpression" &&
            st.expression.arguments[0].params.type === "FormalParameters" &&
            st.expression.arguments[0].params.items.length === 1 &&
            st.expression.arguments[0].body.type === "CallExpression" &&
            st.expression.arguments[0].body.callee.type === "StaticMemberExpression" &&
            st.expression.arguments[0].body.callee.object.name === "dot" &&
            st.expression.arguments[0].body.callee.property === "pick" &&
            st.expression.arguments[0].body.arguments.length === 2 &&
            st.expression.arguments[0].body.arguments[0].type === "LiteralStringExpression" &&
            st.expression.arguments[0].body.arguments[1].type === "IdentifierExpression" &&
            st.expression.arguments[0].params.items[0].name === st.expression.arguments[0].body.arguments[1].name
          )
            return {
              effect,
              pickExpression: st.expression.arguments[0].body.arguments[0].value,
            };
          return {
            effect,
            selectExpression: codegen(st.expression),
          };
        }
        if (effect === "put") {
          const argument = st.expression.arguments[0];
          if (argument.type === "ObjectExpression") {
            const { properties } = argument;
            const typeProperty = properties.find(
              p =>
                p.type === "DataProperty" &&
                p.name.type === "StaticPropertyName" &&
                p.name.value === "type" &&
                p.expression.type === "LiteralStringExpression"
            );
            const instanceIdProperty = properties.find(
              p =>
                p.type === "DataProperty" &&
                p.name.type === "StaticPropertyName" &&
                p.name.value === "instanceId" &&
                p.expression.type === "CallExpression"
            );
            const payloadProperty = properties.find(
              p => p.type === "DataProperty" && p.name.type === "StaticPropertyName" && p.name.value === "payload"
            );

            if (typeProperty && payloadProperty) {
              return {
                effect: "put",
                type: typeProperty.expression.value,
                instanceId: instanceIdProperty ? astToTree(instanceIdProperty.expression) : null,
                arguments: astToTree(payloadProperty.expression),
              };
            }
            return {
              effect: "put",
              putExpression: codegen(argument),
            };
          }
          return null;
        }
        return null;
      }
      return null;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const findOperationById = (spec, operationId) => {
  const path = `$..*[?(@.operationId == "${operationId}")]`;
  const results = jp.query(spec, path);
  if (results.length) {
    return results[0];
  }
  return {};
};

const codeOrExpressionValidation = (text, parentElement) => {
  const jsText = is(parentElement, "bpmn:ScriptTask") ? text : `x = (${text})`;
  try {
    // eslint-disable-next-line no-unused-vars
    const ast = parseScript(jsText);

    return { outcome: true };
  } catch (ex) {
    return { outcome: false, reason: `${ex}` };
  }
};

const findSelectedWidget = element => {
  if (element.businessObject.get("custom:actionType")) {
    if (element.businessObject.get("custom:actionType").charAt(0) === "@") {
      return element.businessObject
        .get("custom:actionType")
        .split("@")
        .pop()
        .split("/")[0];
    }
    return "Global";
  }
  if (
    is(element, "bpmn:EndEvent") &&
    !!element?.businessObject.get("eventDefinitions")?.length &&
    element.businessObject.get("custom:effect")
  ) {
    if (element.businessObject.get("custom:effect").charAt(11) === "@") {
      return element.businessObject
        .get("custom:effect")
        .split("@")
        .pop()
        .split("/")[0];
    }
    return "Global";
  }
  return "";
};

const schemaToTreeForBpmn = (schema, id) => {
  const obj = {};
  const cache = {};
  const shadow = {};
  const title = schema.title || "schema";

  shadow[title] = {};

  traverse(schema)
    .paths()
    .map(function(path) {
      let prev;
      return path.reduce(function(acc, key) {
        if (prev && prev === "properties") {
          acc.push(key);
        }
        prev = key;
        return acc;
      }, []);
    })
    .filter(function(path) {
      const unique = path.join(".");
      if (!cache[unique] && path.length) {
        cache[unique] = path;
        return true;
      }
      return false;
    })
    .forEach(function(path) {
      // console.log(path);
      traverse(shadow[title]).set(path, {});
    });

  traverse(shadow).forEach(function() {
    const path = this.path.slice(0);
    const child = path.pop();
    let deep = obj;
    let node;
    let parent;
    const path1 = this.path.slice(1, this.path.length - 1);

    const jpPath = ["$['properties']"];

    path1.forEach((p, i) => {
      jpPath.push(`['${p}']`);
      if (i < path1.length - 1) jpPath.push("['properties']");
    });
    const query = jp.query(schema, jpPath.join(""));
    const isArray = !!(query.length && query[0].type === "array");

    const idPath = [id, ...this.path.slice(1)];
    if (isArray) {
      idPath[idPath.length - 2] = `${idPath[idPath.length - 2]}[0]`;
    }

    while (path.length) {
      node = path.shift();
      // eslint-disable-next-line prefer-destructuring
      parent = deep.children.filter(e => e.name === node)[0];
      if (!parent) {
        parent = {
          id: idPath.join("."),
          name: node,
          children: [],
        };
        deep.children.push(parent);
      }
      deep = parent;
    }
    if (child) {
      if (!deep.children) {
        deep.children = [];
      }
      deep.children.push({
        id: idPath.join("."),
        name: child,
      });
    }
  });
  return obj.children[0];
};

const findChildSchema = (schema, ids) => {
  const temp = ["$", "properties"];
  ids.forEach(p => {
    temp.push(p);
    temp.push("properties");
  });

  const pathExpression = jp.stringify(temp);
  return jp.query(schema, pathExpression)[0];
};

const constructConfigActionSchema = (widgetList, appWidgets) => {
  const widgetsConfigSchema = {};
  widgetList.forEach(({ name, jsonSchema }) => {
    if (name !== "Global") {
      const instanceIds = appWidgets.filter(a => a.name === name).flatMap(({ instances }) => instances);
      widgetsConfigSchema[`${name}`] = { properties: {} };
      if (jsonSchema)
        instanceIds.forEach(({ uuid: id }) => {
          widgetsConfigSchema[`${name}`].properties[`${id}`] = JSON.parse(jsonSchema);
        });
    }
  });
  return widgetsConfigSchema;
};

const constructWidgetsReduxSlices = (widgetList, appWidgets) => {
  const widgetsConfigSchema = {};
  widgetList.forEach(({ name, reduxSchema }) => {
    if (name !== "Global") {
      const instanceIds = appWidgets.filter(a => a.name === name).flatMap(({ instances }) => instances);
      widgetsConfigSchema[`${name}`] = { properties: {} };
      if (reduxSchema)
        instanceIds.forEach(({ uuid: id }) => {
          widgetsConfigSchema[`${name}`].properties[`${id}`] = JSON.parse(reduxSchema);
        });
    }
  });
  return widgetsConfigSchema;
};

const stringToExpressionExpr = text => {
  const ast = parseScript(text);
  return ast.statements[0].expression;
};

const fieldToExpr = fld => {
  if (fld.literal) {
    return {
      type: "CallExpression",
      callee: {
        type: "IdentifierExpression",
        name: "val",
      },
      arguments: [
        {
          type: "LiteralNullExpression",
        },
        {
          type: "LiteralStringExpression",
          value: fld.literal,
        },
      ],
    };
  }
  if (fld.path) {
    return {
      type: "CallExpression",
      callee: {
        type: "IdentifierExpression",
        name: "val",
      },
      arguments: [
        {
          type: "LiteralStringExpression",
          value: fld.path,
        },
        {
          type: "IdentifierExpression",
          name: "context",
        },
      ],
    };
  }
  if (fld.code) {
    return {
      type: "CallExpression",
      callee: {
        type: "IdentifierExpression",
        name: "val",
      },
      arguments: [
        stringToExpressionExpr(fld.code),
        {
          type: "LiteralNullExpression",
        },
      ],
    };
  }
  // should never reach here
  return {
    type: "LiteralNullExpression",
  };
};

const treeToExpr = tree => {
  if (tree.children) {
    return {
      // todo
      type: "ObjectExpression",
      properties: Object.entries(tree.children).map(([name, child]) => ({
        type: "DataProperty",
        name: {
          type: "StaticPropertyName",
          value: name,
        },
        expression: treeToExpr(child),
      })),
    };
  }
  if (tree.elements) {
    return {
      type: "ArrayExpression",
      elements: tree.elements.map(treeToExpr),
    };
  }
  if (tree.values) {
    const fn = tree.function
      ? {
          type: "LiteralStringExpression",
          value: tree.function,
        }
      : {
          type: "LiteralNullExpression",
        };
    return {
      type: "CallExpression",
      callee: {
        type: "IdentifierExpression",
        name: "field",
      },
      arguments: [
        fn,
        {
          type: "ArrayExpression",
          elements: tree.values.map(fieldToExpr),
        },
      ],
    };
  }
  // should never reach here
  return {
    type: "LiteralNullExpression",
  };
};

const structure = eff => {
  switch (eff.effect) {
    case "call":
      if (eff.api) {
        return codegen({
          type: "ExpressionStatement",
          expression: {
            type: "CallExpression",
            callee: {
              type: "IdentifierExpression",
              name: "call",
            },
            arguments: [
              {
                type: "StaticMemberExpression",
                object: {
                  type: "IdentifierExpression",
                  name: eff.api,
                },
                property: eff.operation,
              },
              treeToExpr(eff.arguments),
            ],
          },
        });
      }
      return eff.callExpression;

    case "put":
      if (eff.putExpression) return eff.putExpression;
      return codegen({
        type: "ExpressionStatement",
        expression: {
          type: "CallExpression",
          callee: {
            type: "IdentifierExpression",
            name: "put",
          },
          arguments: [
            // treeToExpr(eff.arguments)
            {
              type: "ObjectExpression",
              properties: [
                {
                  type: "DataProperty",
                  name: {
                    type: "StaticPropertyName",
                    value: "type",
                  },
                  expression: {
                    type: "LiteralStringExpression",
                    value: eff.type,
                  },
                },
                {
                  type: "DataProperty",
                  name: {
                    type: "StaticPropertyName",
                    value: "instanceId",
                  },
                  expression: eff.instanceId
                    ? treeToExpr(eff.instanceId)
                    : {
                        type: "LiteralNullExpression",
                      },
                },
                {
                  type: "DataProperty",
                  name: {
                    type: "StaticPropertyName",
                    value: "payload",
                  },
                  expression: treeToExpr(eff.arguments),
                },
              ],
            },
          ],
        },
      });
    case "select":
      return "";
    default:
      return "";
  }
};

const structureEffect = ({
  effect,
  api,
  // parameters,
  operation,
  selectExpression,
  pickExpression,
  callExpression,
  putExpression,
  type,
  arguments: args,
}) => {
  if (effect === "put") {
    return structure({
      effect: "put",
      type,
      instanceId: args.children.instanceId,
      arguments: args.children.payload,
    });
  }
  if (effect === "put-js") {
    return `put(${putExpression})`;
  }
  if (effect === "select") {
    return `select(state=>dot.pick("${pickExpression.substring(6)}",state))`;
  }
  if (effect === "select-js") {
    return `select(${selectExpression})`;
  }
  if (effect === "call-api") {
    return structure({ effect: "call", api, operation, arguments: args });
  }
  if (effect === "call-js") {
    return `call(${callExpression})`;
  }
  return null;
};

export {
  destructure,
  structure,
  structureEffect,
  findOperationById,
  codeOrExpressionValidation,
  findSelectedWidget,
  schemaToTreeForBpmn,
  findChildSchema,
  constructConfigActionSchema,
  constructWidgetsReduxSlices,
};
