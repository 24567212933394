const hasPermission = (permissions, permissionName, enable) => {
  if (enable) {
    return !!permissions.find(p => p === permissionName);
  }
  return true;
};

const hasPermissions = (permissions, permissionNames, enable) => {
  return enable && permissionNames.length > 0
    ? permissionNames.every(name => permissions.find(p => p === name))
    : true;
};

export { hasPermission, hasPermissions };
