/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { clearConfigData, clearConfigSchema } from "actions/configFormActions";
import {
  fetchConfigListWidget,
  resetConfigListWidget,
  deployConfigListWidget,
  clearWidget,
} from "actions/widgetsActions";
import WidgetsComponent from "components/widgets/Widgets";

const Widgets = ({
  configForm,
  getConfigListWidget,
  resetConfigChanges,
  deployConfigChanges,
  deleteConfigSchema,
  deleteConfigData,
  clearWdgt,
  auth,
  envID,
  currentApp,
  currentWidget,
  applications,
  widgets,
}) => {
  const history = useHistory();
  const { locale, env, touchpoint, app } = useParams();
  const { schema, data } = configForm;
  const { access_token, id_token } = auth;

  const deployChanges = async componentId => {
    await deployConfigChanges({
      envID,
      access_token,
      touchpoint,
      application: app,
      componentId,
      id_token,
    });
    getConfigListWidget({
      envID,
      access_token,
      touchpoint,
      application: app,
      id_token,
    });
    history.push(`/${locale}/env/${env}/${touchpoint}/${app}`);
  };
  const resetChanges = async () => {
    await resetConfigChanges({
      envID,
      touchpoint,
      application: app,
      id_token,
      access_token,
    });
    getConfigListWidget({
      envID,
      access_token,
      touchpoint,
      application: app,
      id_token,
    });
    history.push(`/${locale}/env/${env}/${touchpoint}/${app}`);
  };

  useEffect(() => {
    if (schema) deleteConfigSchema();
    if (data) deleteConfigData();
    if (currentWidget) clearWdgt();
    if (!currentApp && !schema && !data)
      getConfigListWidget({
        envID,
        access_token,
        touchpoint,
        application: app,
        id_token,
      });
  });
  return (
    <WidgetsComponent
      resetChanges={resetChanges}
      deployChanges={deployChanges}
      applicationList={applications}
      widgetList={widgets}
    />
  );
};

const mapStateToProps = state => {
  return {
    configForm: state.configForm,
    currentApp: state.apps.current,
    currentWidget: state.widgets.current,
    applications: state.apps.list,
    widgets: state.widgets.list,
    auth: state.authentication,
    envID: state.envs.id,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteConfigSchema: () => dispatch(clearConfigSchema()),
  deleteConfigData: () => dispatch(clearConfigData()),
  getConfigListWidget: payload => dispatch(fetchConfigListWidget(payload)),
  resetConfigChanges: payload => dispatch(resetConfigListWidget(payload)),
  deployConfigChanges: payload => dispatch(deployConfigListWidget(payload)),
  clearWdgt: () => dispatch(clearWidget()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Widgets);
