import React from "react";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { Container } from "@material-ui/core";
import classNames from "classnames";

import logo from "assets/images/logo.svg";
import CmnLoginLogout from "./LoginLogoutBtn";
import AuthMessage from "./AuthMessage";
import useStyles from "./LoginTheme";

const Login = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <main className={classNames("login", classes.root)}>
      <Container maxWidth="xs">
        <Card
          elevation={0}
          className={classNames(classes.card, {
            "MuiCard-login": true,
            animate: true,
          })}
        >
          <CardMedia className={classes.media} image={logo} title="Vodafone" />
          <CardContent className={classes.content}>
            <Typography component="h1" variant="h3">
              Welcome to the R10 App Builder
            </Typography>
            <Typography component="h2" variant="h5">
              Click below to sign in
            </Typography>
          </CardContent>
          <CardContent className={classes.button}>
            <CmnLoginLogout greeting="" history={history} />
          </CardContent>
        </Card>
        <AuthMessage />
      </Container>
    </main>
  );
};

export default Login;
