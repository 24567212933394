import React, { useState } from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import classNames from "classnames";
import VfSvgIcon from "../icons/VfSvgIcon";

const FilterGroupAppTypes = ({ handleFilters }) => {
  const [filterList] = useState([
    { name: "mobile", icon: "mobile" },
    { name: "tablet", icon: "tablet" },
    { name: "desktop", icon: "desktop" },
    { name: "kiosk", icon: "kiosk" },
  ]);
  const [filterActive, setFilterActive] = useState([]);
  const handleFilterActive = filter => {
    let newFilters = [...filterActive];
    if (filterActive.includes(filter.name)) {
      newFilters = newFilters.filter(item => item !== filter.name);
      setFilterActive(newFilters);
    } else {
      newFilters.push(filter.name);
      setFilterActive(newFilters);
    }
  };
  return (
    <ButtonGroup
      variant="contained"
      color="default"
      size="small"
      aria-label="contained primary button group"
    >
      {filterList.map(item => {
        return (
          <Button
            data-name="appType"
            data-value={item.name}
            {...(filterActive.includes(item.name)
              ? {
                  "data-remove": true,
                }
              : {})}
            key={item.name}
            onClick={e => {
              handleFilters(e);
              handleFilterActive(item);
            }}
            color={filterActive.includes(item.name) && "primary"}
          >
            <VfSvgIcon
              className={classNames({
                "MuiSvgIcon-white": filterActive.includes(item.name),
              })}
              icon={item.icon}
              viewBox={24}
            />
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default FilterGroupAppTypes;
