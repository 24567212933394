/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Box, Grid, IconButton, SvgIcon, Typography } from "@material-ui/core";
import theme from "AppTheme";
import CardMenuListItem from "./CardMenuListItem";
import { ReactComponent as PlusIcon } from "../../assets/images/ic-plus.svg";

const useStyles = makeStyles({
  box: {
    minHeight: theme.spacing(7),
    display: "flex",
    alignItems: "center",
  },
});

const AppsMenus = () => {
  const classes = useStyles();
  const [menuItems, setMenuItems] = useState([
    {
      title: "Home Page",
      home: true,
      hidden: false,
    },
    {
      title: "Other Page 1",
      home: false,
      hidden: false,
    },
    {
      title: "Other Page 2",
      home: false,
      hidden: false,
    },
    {
      title: "Hidden Page",
      home: false,
      hidden: true,
    },
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">
                <strong>Menu</strong>
                &nbsp;&nbsp;
                <Typography variant="body2" component="span">
                  Drag & drop pages from the list on the left
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {menuItems.map((menu, index) => {
            return (
              <Grid item xs={12} key={menu.title}>
                <CardMenuListItem title={menu.title} hidden={menu.hidden} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppsMenus;
