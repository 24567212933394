/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "AppTheme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import VfDialog from "../../../vf/VfDialog";
import VfSvgIcon from "../../../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {},
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  appName: {
    fontWeight: "bold",
  },
  appDialogContainer: {
    textAlign: "center",
  },
  dialogConfirmAction: {
    justifyContent: "center",
  },
});

const NoResourcesDialog = ({ environment, isOpen, onConfirm }) => {
  const styles = useStyles();

  return (
    <VfDialog
      title={
        <Grid container spacing={1} alignItems="center" justify="space-between">
          <Grid item xs="auto">
            <VfSvgIcon icon="apps" />
          </Grid>
          <Grid item xs="auto">
            <VfSvgIcon icon="arrowRight" />
          </Grid>
          <Grid item xs="auto">
            <Typography component="span" variant="body1">
              {"Deploy to "}
            </Typography>
            <Typography
              component="span"
              variant="body1"
              className={classNames({
                "MuiTypography-bold": true,
                [`MuiTypography-${environment.title.toLowerCase()}`]: true,
              })}
            >
              {environment.title}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <VfSvgIcon icon="arrowRight" />
          </Grid>
          <Grid item xs="auto">
            <VfSvgIcon icon={environment.title?.toLowerCase()} />
          </Grid>
        </Grid>
      }
      maxWidth="xs"
      openDialog={isOpen}
      buttonSecondaryText={null}
      buttonSecondaryAction={null}
      buttonConfirmText="Ok"
      buttonConfirmDisabled={false}
      buttonConfirmAction={onConfirm}
      classDialogTitle={styles.dialogEnvsTitle}
      classDialogActions={styles.dialogConfirmAction}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid className={styles.appDialogContainer}>
            <Grid item xs="auto">
              <Typography className="MuiTypography-bold">
                Not enough resources available.
              </Typography>
              <Typography>
                Please, undeploy an app to free up resources.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </VfDialog>
  );
};

export default NoResourcesDialog;
