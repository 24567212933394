/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";

import {
  APIS_REQUEST,
  APIS_REQUEST_SUCCESS,
  APIS_REQUEST_FAIL,
  SAVE_API_REQUEST,
  SAVE_API_REQUEST_SUCCESS,
  SAVE_API_REQUEST_FAIL,
  SHOW_CREATE_API_DIALOG,
  API_DETAILS_REQUEST,
  API_DETAILS_REQUEST_SUCCESS,
  API_DETAILS_REQUEST_FAIL,
  DELETE_API_REQUEST,
  DELETE_API_REQUEST_SUCCESS,
  DELETE_API_REQUEST_FAIL,
  DELETE_API_IS_USED_POP_UP_TOGGLE,
  DELETE_VERSION_API_REQUEST,
  DELETE_VERSION_API_REQUEST_SUCCESS,
  DELETE_VERSION_API_REQUEST_FAIL,
} from "actions/apisActions";
import ApisService from "services/ApisService";

function* apisWorker({ payload }) {
  try {
    const data = yield call(ApisService.fetchApis, payload);
    yield put({
      type: APIS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: APIS_REQUEST_FAIL, error });
  }
}

function* apisWatcher() {
  yield takeLatest(APIS_REQUEST, apisWorker);
}

function* saveApiWorker({ payload }) {
  try {
    const data = yield call(ApisService.saveApi, payload);
    yield put({
      type: SAVE_API_REQUEST_SUCCESS,
      payload: { _id: data._id, name: data.name, tags: data.tags },
    });
    yield put({
      type: APIS_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
    yield put({
      type: SHOW_CREATE_API_DIALOG,
      payload: false,
    });
  } catch (error) {
    yield put({ type: SAVE_API_REQUEST_FAIL, error });
  }
}

function* saveApiWatcher() {
  yield takeLatest(SAVE_API_REQUEST, saveApiWorker);
}

function* deleteApiWorker({ payload }) {
  try {
    const { data, failData } = yield call(ApisService.deleteApi, payload);
    if (failData?.used) {
      yield put({ type: DELETE_API_REQUEST_FAIL });
      yield put({
        type: DELETE_API_IS_USED_POP_UP_TOGGLE,
        payload: {
          usedBy: failData.appsList.join(", "),
          deleteVersion: false,
        },
      });
    } else {
      yield put({
        type: DELETE_API_REQUEST_SUCCESS,
        payload: data?._id,
      });
    }
  } catch (error) {
    yield put({ type: DELETE_API_REQUEST_FAIL, error });
  }
}

function* deleteApiWatcher() {
  yield takeLatest(DELETE_API_REQUEST, deleteApiWorker);
}

function* getApiDetailsWorker({ payload }) {
  try {
    const data = yield call(ApisService.fetchApiDetails, payload);
    yield put({
      type: API_DETAILS_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: SHOW_CREATE_API_DIALOG,
      payload: true,
    });
  } catch (error) {
    yield put({ type: API_DETAILS_REQUEST_FAIL, error });
  }
}

function* getApiDetailsWatcher() {
  yield takeLatest(API_DETAILS_REQUEST, getApiDetailsWorker);
}

function* deleteVersionApiWorker({ payload }) {
  try {
    const { data, failData } = yield call(
      ApisService.deleteVersionApi,
      payload
    );
    if (failData?.used) {
      yield put({ type: DELETE_VERSION_API_REQUEST_FAIL });
      yield put({
        type: DELETE_API_IS_USED_POP_UP_TOGGLE,
        payload: {
          usedBy: failData.appsList.join(", "),
          deleteVersion: true,
        },
      });
    } else {
      yield put({
        type: DELETE_VERSION_API_REQUEST_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({ type: DELETE_VERSION_API_REQUEST_FAIL, error });
  }
}

function* deleteVersionApiWatcher() {
  yield takeLatest(DELETE_VERSION_API_REQUEST, deleteVersionApiWorker);
}

const apisSaga = [
  apisWatcher(),
  saveApiWatcher(),
  deleteApiWatcher(),
  getApiDetailsWatcher(),
  deleteVersionApiWatcher(),
];

export default apisSaga;
