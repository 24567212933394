import React from "react";
import { Grid } from "@material-ui/core";
import uuidv4 from "uuid/v4";
import GridColTemplateBuilder from "./GridColTemplateBuilder";

const GridRowTemplateBuilder = ({ grid }) => {
  const renderRow = row => {
    const id = uuidv4();
    return (
      <Grid item xs={12} id={id} key={`row-${id}`}>
        <GridColTemplateBuilder columns={row.columns} />
      </Grid>
    );
  };

  return (
    <Grid container spacing={1}>
      {grid.rows.map((row, i) => renderRow(row, i))}
    </Grid>
  );
};

export default GridRowTemplateBuilder;
