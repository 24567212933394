const CONFIG_HISTORY_REQUEST = "CONFIG_HISTORY_REQUEST";
const CONFIG_HISTORY_REQUEST_SUCCESS = "CONFIG_HISTORY_REQUEST_SUCCESS";
const CONFIG_HISTORY_REQUEST_FAIL = "CONFIG_HISTORY_REQUEST_FAIL";
const CLEAR_CONFIG_HISTORY = "CLEAR_CONFIG_HISTORYT";

const fetchConfigHistory = payload => ({
  type: CONFIG_HISTORY_REQUEST,
  payload,
});
const clearConfigHistory = () => ({
  type: CLEAR_CONFIG_HISTORY,
});

export {
  CONFIG_HISTORY_REQUEST,
  CONFIG_HISTORY_REQUEST_SUCCESS,
  CONFIG_HISTORY_REQUEST_FAIL,
  CLEAR_CONFIG_HISTORY,
  fetchConfigHistory,
  clearConfigHistory,
};
