/* eslint-disable camelcase */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Lottie from "react-lottie";
import theme from "AppTheme";
import loaderData from "../../assets/animations/vodafone_loader.json";

const useStyles = makeStyles({
  loader: {
    minHeight: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: theme.spacing(12),
    },
  },
});

const Loader = ({ size, height }) => {
  const classes = useStyles();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderData,
  };
  return (
    <Box className={classes.loader} style={{ minHeight: height }}>
      <Lottie options={defaultOptions} height={size} width={size} />
    </Box>
  );
};

export default Loader;
