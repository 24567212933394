/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React from "react";
import classNames from "classnames";
import image from "assets/images/bck.jpg";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Avatar
} from "@material-ui/core";
import AppActionsMenu from "./AppActionMenu/AppActionMenu";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfTooltip from "../vf/VfTooltip";
import { DEPLOYMENT_FLOWS } from "../../utils/constants";
import AWS_S3 from "assets/images/aws-s3.svg";
import Kubernetes from "assets/images/kubernetes.svg";

const CardAppsGrid = ({
  app,
  showApp,
  setApplication,
  env,
  setApplicationEnv,
  auth,
  getAppPages,
  moreActions,
  isConfiguratorRoute = false,
  clickAction,
  resetSearch
}) => {
  const { access_token, id_token } = auth;

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-application": true,
        "MuiCard-grid": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-error": false,
        "MuiCard-outlined-warning": false,
        "MuiCard-outlined-info": false,
        "MuiCard-outlined-success": false,
        "MuiCard-rounded": true,
        animate: true,
      })}
    >
      <CardActionArea
        onClick={() => {
          if (clickAction) return clickAction();
          showApp(true);
          setApplication({ ...app, isDeployed: !!(env && !env.isGlobal) });
          setApplicationEnv(env);
          getAppPages({
            access_token,
            id_token,
            application: app._id,
            envId: env.id,
          });
        }}
      >
        <CardMedia
          className="CardMedia-imagePreview"
          image={app.imageBase64 ? app.imageBase64 : image}
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {app.iconBase64 && <CardMedia image={app.iconBase64} />}
              {!app.iconBase64 && <VfSvgIcon icon="vodafone" viewBox={24} />}
            </Grid>
            {app.status && (
              <Grid item xs={12}>
                <Typography variant="body2" component="div">
                  {app.status}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs="auto">
                  <Typography variant="body1" component="h5">
                    {app.title}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="body1" component="h5" color="secondary">
                    {app.version}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs="auto">
                  <VfSvgIcon
                    icon={app.appType}
                    viewBox={24}
                    className="MuiSvgIcon-fontSizeXSmall"
                  />
                </Grid>
                <Grid item xs="auto">
                  <VfSvgIcon
                    icon={app.platform}
                    viewBox={24}
                    className="MuiSvgIcon-fontSizeXSmall"
                  />
                </Grid>
                {app.hasAuth && (
                  <Grid item xs="auto">
                    <VfTooltip title={app.authProvider}>
                      <VfSvgIcon
                        icon="lock"
                        viewBox={24}
                        className="MuiSvgIcon-fontSizeXSmall"
                      />
                    </VfTooltip>
                  </Grid>
                )}
                <Grid item xs="auto">
                  <Typography variant="body2" color="textSecondary">
                    {app.theming === "MVA10" ? "WS10" : app.theming}
                  </Typography>
                </Grid>
                {!!(env && !env.isGlobal && !isConfiguratorRoute) && <Grid item xs="auto">
                  <Typography variant="body2" color="textSecondary">
                    <Avatar
                      alt={app.deploymentFlow === DEPLOYMENT_FLOWS.S3 ? "AWS S3 logo" : "Kubernetes logo"}
                      src={app.deploymentFlow === DEPLOYMENT_FLOWS.S3 ? AWS_S3 : Kubernetes}
                      style={{ width: 16, height: 16 }}
                    />
                  </Typography>
                </Grid>}
                {app.unmanaged && (
                  <Grid item xs="auto">
                    <Typography variant="body2" color="secondary">
                      Unmanaged
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    {app.tags ? app.tags.join(", ") : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>

      {moreActions && <AppActionsMenu resetSearch={resetSearch} app={app} env={env} />}
    </Card>
  );
};

export default CardAppsGrid;
