/* eslint-disable camelcase */
/* eslint-disable-next-line consistent-return */
/* eslint-disable-next-line no-underscore-dangle */
import axios from "axios";
import { handleErrorResponse } from "./ErrorHandling";
import { fillPagesMicroFrontendApps } from "../utils/micro-fe-apps-utils";
import getCookie from "utils/getCookie";

class AppsService {
  fetchApps = async ({ access_token, id_token, searchText }) => {
    const params = {
      searchText,
    };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/applications`,
        params,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch applications service:", err);
      return err;
    }
  };

  fetchAppBuilderLanguages = async ({ access_token, id_token }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/translation/languages`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch languages service:", err);
      return err;
    }
  };

  postApplicationData = async ({
    access_token,
    id_token,
    data: payloadData,
  }) => {
    const body = {
      data: payloadData,
    };
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/applications`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("Error in create application service:", err);
      return err;
    }
  };

  validateName = async ({
    access_token,
    id_token,
    title,
    appType,
    platform,
    id,
  }) => {
    const params = {
      title,
      appType,
      platform,
      id,
    };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/applications/validation`,
        params,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in create application service:", err);
      return err;
    }
  };

  deployApplication = async ({ access_token, id_token, body = {} }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/applications/deploy`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("Error in deploy application service:", err);
      return err;
    }
  };

  undeployApplication = async ({ access_token, id_token, body = {} }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/applications/undeploy`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("Error in undeploy application service:", err);
      return err;
    }
  };

  updateDeployedAppSecurityGroups = async ({
    applicationId,
    securityGroups,
    access_token,
    id_token,
  }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/applications/${applicationId}/securityGroups`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
        data: {
          securityGroups,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in updating secturityGroups:", err);
      return err;
    }
  };

  fetchPages = async ({ access_token, id_token, application, envId }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/applications/${application}/env/${envId}`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch pages service:", err);
      return err;
    }
  };

  // eslint-disable-next-line consistent-return
  savePages = async ({ access_token, id_token, pages, application, envId }) => {
    // fill attritute page[:].microFrontendApps
    fillPagesMicroFrontendApps(pages);

    const body = {
      pages,
      application,
      envId,
    };
    try {
      const { data } = await axios({
        method: "post",
        url: application.isDeployed
          ? `/api/appbuilder/pages/env/${envId}`
          : `/api/appbuilder/pages`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("Error in create page service:", err);
      handleErrorResponse(err, "Something went wrong! App could not be saved.");
    }
  };

  // eslint-disable-next-line consistent-return
  // savePage = async ({ access_token, id_token, page, application, envID }) => {
  //   const body = application.isDeployed
  //     ? {
  //         pages: [page],
  //         application,
  //         envID,
  //       }
  //     : {
  //         page,
  //         application,
  //       };
  //   try {
  //     const { data } = await axios({
  //       method: "post",
  //       url: application.isDeployed
  //         ? `/api/appbuilder/pages/env/${envID}`
  //         : `/api/appbuilder/pages`,
  //       headers: {
  //         "content-type": "application/json",
  //         'x-csrf-token': getCookie('x-csrf-token'),
  //         ,
  //       },
  //       data: body,
  //     });
  //     return data;
  //   } catch (err) {
  //     console.log("Error in create page service:", err);
  //     handleErrorResponse(err, "Something went wrong! App could not be saved.");
  //   }
  // };

  deletePage = async ({ access_token, id_token, pageId, appInfo }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `/api/appbuilder/pages/${pageId}`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
        data: {
          application: appInfo,
        },
      });
      return data;
    } catch (err) {
      console.log("Error in delete page service:", err);
      return err;
    }
  };

  // eslint-disable-next-line consistent-return
  saveAppVersion = async ({ access_token, id_token, pages, application }) => {
    const body = {
      pages,
      application,
    };
    try {
      const { data } = await axios({
        method: "post",
        // eslint-disable-next-line no-underscore-dangle
        url: `/api/appbuilder/applications/${application._id}/versions/${application.version}`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
        data: body,
      });
      return data;
    } catch (err) {
      console.log("Error in create app version service:", err);
      handleErrorResponse(
        err,
        "Something went wrong! Version could not be created."
      );
    }
  };

  getAppLatestVersion = async ({
    access_token,
    id_token,
    applicationId,
    sourceAppId,
    // eslint-disable-next-line consistent-return
  }) => {
    try {
      const { data } = await axios({
        method: "get",
        // eslint-disable-next-line no-underscore-dangle
        url: `/api/appbuilder/applications/${applicationId}/versions`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
        params: { sourceAppId },
      });
      return data;
    } catch (err) {
      console.log("Error in get app latest version service:", err);
      handleErrorResponse(
        err,
        "Something went wrong! Could not get latest version."
      );
    }
  };

  setHomepage = async ({ access_token, id_token, applicationId, pageId }) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/pages/${applicationId}/${pageId}/homepage`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in set homepage service:", err);
      return err;
    }
  };

  deleteUnmanagedApp = async ({
    access_token,
    id_token,
    envID,
    applicationId,
    globalApplicationId,
  }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/applications/${envID}/${applicationId}/${globalApplicationId}`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in delete page service:", err);
      return err;
    }
  };

  deleteApp = async ({ access_token, id_token, applicationId }) => {
    try {
      const { data } = await axios({
        method: "delete",
        url: `/api/appbuilder/application/delete/${applicationId}`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in delete Application service:", err);
      throw err;
    }
  };

  resolvePublicPackage = async (access_token, id_token, payload) => {
    // disabled for now, as we are not using this, but we might need it in future
    return;
    try {
      const { data } = await axios({
        method: "post",
        // eslint-disable-next-line no-underscore-dangle
        url: `/api/appbuilder/applications/resolve`,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
        data: payload,
      });

      return data;
    } catch (err) {
      console.log("Error in delete Application service:", err);
      throw err;
    }
  };
}

const inst = new AppsService();
export default inst;
