import React from "react";

import { ListItem, Box, Typography, Link, IconButton } from "@material-ui/core";
import { DEPLOYMENT_FLOWS } from "../../../utils/constants";
import VfSvgIcon from "../../icons/VfSvgIcon";

const JenkinsConsoleMenuItem = ({ application, onClick, coreAppJob }) => {
  const {deploymentFlow}= application
  return (
    <ListItem  data-testid="OpenJenkinsConsole_container">
      <Box display="flex" alignItems="center">
        <Typography variant="body2" color="secondary" onClick={onClick}>
          <Link
            href={
              `https://jenkins-af.javelin.vodafone.com/job/R10-AppFramework-PWA-BuildApp${deploymentFlow=== DEPLOYMENT_FLOWS.S3?'-S3':''}/job/${coreAppJob}/${application.buildNumber}/console` ||
              "#"
            }
            rel="noreferrer"
            target="_blank"
            variant="body2"
            color="secondary"
            data-testid="OpenJenkinsConsole_btn"
          >
            Open Jenkins Console
          </Link>
        </Typography>
        <Link
          href={
            `https://jenkins-af.javelin.vodafone.com/job/R10-AppFramework-PWA-BuildApp$${deploymentFlow=== DEPLOYMENT_FLOWS.S3?'-S3':''}/job/${coreAppJob}/${application.buildNumber}/console` ||
            "#"
          }
          rel="noreferrer"
          target="_blank"
        >
          <IconButton color="secondary" size="small" onClick={onClick}>
            <VfSvgIcon fontSize="small" icon="externalLink" viewBox="24" />
          </IconButton>
        </Link>
      </Box>
    </ListItem>
  );
};

export default JenkinsConsoleMenuItem;
