/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { memo, useState } from "react";
import { Grid, TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";

const tags = ["tag 1", "tag 2", "tag 3"];

function AppsPageAdd({ setPageValues, page, application }) {
  const [isTitleHasError, setTitleError] = useState(false);
  const { appInfo } = application;

  const onTagsChange = (event, values) => {
    setPageValues({ ...page, tags: values });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === "title") {
      setTitleError(!value?.match("^[a-z0-9-]+$"));
    }
    setPageValues({ ...page, [name]: value });
  };

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setPageValues({ ...page, [name]: checked });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          error={isTitleHasError}
          name="title"
          label="Name"
          fullWidth
          variant="outlined"
          helperText="There must be only small letters and numbers in the title."
          onChange={handleInputChange}
          value={page.title}
          data-testid="pageNameFeild"
          inputProps={{ "data-testid": "pageNameInput" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="documentTitle"
          label="Document Title"
          fullWidth
          variant="outlined"
          onChange={handleInputChange}
          value={page.documentTitle}
          data-testid="documentTitleFeild"
          inputProps={{ "data-testid": "documentTitleInput" }}
        />
      </Grid>
      {appInfo.hasAuth && (
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="primary" name="private" onChange={handleCheckboxChange} checked={page.private} />}
            label="Authenticated"
            labelPlacement="end"
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Switch
              name="isMicroFrontend"
              color="primary"
              checked={page.isMicroFrontend}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label="Is Micro Frontend Page"
          labelPlacement="end"
          data-testid="isMicroFrontend_feild"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Switch
              name="isGenericPage"
              color="primary"
              checked={page.isGenericPage}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label="Is Master Page"
          labelPlacement="end"
          data-testid="isGenericPage_feild"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Switch
              name="isSharedPage"
              color="primary"
              checked={page.isSharedPage}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label="Is Shared Page"
          labelPlacement="end"
          data-testid="isSharedPage_feild"
        />
      </Grid>
      {page.isMicroFrontend && (
        <Grid item xs={12}>
          <TextField
            name="baseRoute"
            label="* /baseRoute"
            fullWidth
            variant="outlined"
            onChange={handleInputChange}
            value={page.baseRoute}
            helperText="The URL path for this page, should be unique and follow the pattern /[a-zA-Z0-9]+ Ex: '/example'"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          name="description"
          label="Description (Optional)"
          fullWidth
          variant="outlined"
          onChange={handleInputChange}
          value={page.description}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          freeSolo
          id="tags-outlined"
          onChange={onTagsChange}
          options={tags}
          value={page.tags}
          filterSelectedOptions
          renderInput={params => <TextField {...params} fullWidth variant="outlined" label="Tags (Optional)" />}
        />
      </Grid>
    </Grid>
  );
}

export default memo(AppsPageAdd);
