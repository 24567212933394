/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import theme from "AppTheme";

import {
  setShowTenantDialog,
  tenantValidions,
  deleteTenant,
} from "actions/tenantActions";
import HasPermission from "components/has-permissions/HasPermissions";
import Loader from "../loader/Loader";
import Heading from "../headings/Heading";
import CardTenant from "./CardTenant";
import DialogAddTenant from "./DialogAddTenant";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfAlert from "../vf/VfAlert";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
  },
  loader: {
    minHeight: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: 100,
    },
  },
  fab: {
    position: "absolute",
    right: theme.spacing(3),
    top: -theme.spacing(7),
    transform: "translateY(-50%)",
  },
  container: {
    position: "relative",
  },
});

const Tenants = () => {
  const classes = useStyles();

  const tenants = useSelector(state => state.tenants.list, shallowEqual);
  const loading = useSelector(state => state.tenants.loading, shallowEqual);
  const showDialog = useSelector(state => state.tenants.show, shallowEqual);
  const error = useSelector(state => state.tenants.error);
  const { access_token, id_token } = useSelector(
    state => state.authentication,
    shallowEqual
  );

  const dispatch = useDispatch();
  const callTenantValidations = parmas => dispatch(tenantValidions(parmas));
  const showTenantDialog = () => dispatch(setShowTenantDialog());
  const dispatchDeleteTenant = parmas => dispatch(deleteTenant(parmas));

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (error && error.message) {
      setOpenSnackbar(true);
    }
  }, [error]);

  const checkTenant = body => {
    callTenantValidations({ access_token, id_token, body });
  };

  const deleteLocalMarket = id => {
    dispatchDeleteTenant({ access_token, id_token, id });
  };

  return (
    <main className={classes.root}>
      <Heading title="Tenants" backUrl="/landing" />
      <Box pt={8} pb={4}>
        <Container className={classes.container} maxWidth="md">
          <Grid container spacing={2}>
            {!loading || false ? (
              tenants.map(({ _id, ...tenant }) => (
                <Grid item xs={12} key={_id}>
                  <CardTenant
                    deleteLocalMarket={deleteLocalMarket}
                    checkTenant={checkTenant}
                    tenant={tenant}
                    id={_id}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Loader size={theme.spacing(12)} />
              </Grid>
            )}
          </Grid>
          <HasPermission permissionKey="create_tenant">
            <Fab
              color="secondary"
              className={classes.fab}
              onClick={() => {
                showTenantDialog();
              }}
              data-testid="addTenant_btn"
            >
              <VfSvgIcon icon="plus" viewBox={24} />
            </Fab>
          </HasPermission>
        </Container>
      </Box>
      {showDialog && (
        <DialogAddTenant
          showDialog={showDialog}
          setShowDialog={showTenantDialog}
        />
      )}      
      {openSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
        >
          <VfAlert severity={0} message={error?.message} />
        </Snackbar>
      )}      
    </main>
  );
};

export default Tenants;
