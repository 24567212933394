import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AppEditCode from "components/apps/AppEditCode";
import { updateConfigData } from "actions/configFormActions";

const EditorFormJson = ({ configForm, dispatchUpdateConfigData }) => {
  const [json, setJson] = useState(JSON.stringify(configForm?.data, null, 2));

  useEffect(() => {
    // If invalid JSON ignore and don't save it
    try {
      dispatchUpdateConfigData(JSON.parse(json));
    } catch (err) {}
  }, [json]);

  return <AppEditCode appBarHeight={0} toolBarHeight={0} djr={json} setDjr={setJson} />;
};

const mapStateToProps = state => ({
  configForm: state.configForm,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdateConfigData: data => dispatch(updateConfigData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorFormJson);
