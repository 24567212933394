import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Signin from "pages/signin/Signin";
import FormContainer from "pages/configurationForm/FormContainer";
import OauthCode from "pages/azureRedirection/OauthCode";
import Liveness from "services/Liveness";
import Environments from "pages/environments/Environments";
import Touchpoints from "pages/touchpoints/Touchpoints";
import Aplications from "pages/applications/Apps";
import Apps from "pages/apps/Apps";
import Landing from "pages/landing/Landing";
import Widgets from "pages/widgets/Widgets";
// import TranslationEnvs from "pages/translation/TranslationEnvs";
import TranslationWidgetList from "pages/translation/TranslationWidgets";
import Translations from "pages/translations/Translations";
import RolesPage from "pages/roles/Roles";
import MediaPage from "pages/media/Media";
import TenantPage from "pages/tenant/Tenant";
import NotFoundPage from "pages/notFound/NotFound";
import Logout from "components/singout/Logout";
import Configurator from "../pages/configurator/Configurator";
import Apis from "../pages/apis/Apis";
import PrivateRoute from "./PrivateRoute";

const AppRouter = () => {
  return (
    <Switch>
      <Route path="/" exact render={() => <Signin />} />
      <Route exact path="/oauthcode" render={() => <OauthCode />} />
      <Route path="/logout" exact render={() => <Logout />} />
      <PrivateRoute
        path="/tenant"
        component={TenantPage}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale"
        component={props => <Redirect to={`/${props.locale}/landing`} />}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/env"
        component={Environments}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/configurator"
        component={Configurator}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/translations"
        component={Translations}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/translations/:env"
        component={TranslationWidgetList}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/translations/:env/:widget/:version"
        component={Translations}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/env/:env"
        component={Touchpoints}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/env/:env/:touchpoint"
        component={Aplications}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/env/:env/:touchpoint/:app"
        component={Widgets}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/env/:env/:touchpoint/:app/:widget/form"
        component={FormContainer}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/apps/"
        component={Apps}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/roles/"
        component={RolesPage}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/media/"
        component={MediaPage}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/landing/"
        component={Landing}
        exact
        liveness={Liveness}
      />
      <PrivateRoute
        path="/:locale/apis/"
        component={Apis}
        exact
        liveness={Liveness}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default AppRouter;
