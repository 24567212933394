/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Grid,
  Checkbox,
  Typography,
  Snackbar,
} from "@material-ui/core";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  resetMedia as clearMedia,
  clearMediaError,
  fetchMedia,
  filterFetchMedia,
  uploadMedia,
  updateMedia,
  deleteMedia,
  uploadMediaError,
  clearSuccessMessage,
} from "actions/mediaActions";
import VfAlert from "../vf/VfAlert";
import VfSvgIcon from "../icons/VfSvgIcon";
import Heading from "../headings/Heading";
import theme from "../../AppTheme";

import MediaBox from "./MediaBox";
import VfDialogConfirm from "../vf/VfDialogConfirm";
import { ReactComponent as imagePlaceholder } from "../../assets/images/ic-image@56.svg";
import { ReactComponent as videoPlaceholder } from "../../assets/images/ic-video@56.svg";
import { ReactComponent as audioPlaceholder } from "../../assets/images/ic-audio@56.svg";
import { ReactComponent as filePlaceholder } from "../../assets/images/ic-read-me@56.svg";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
  },
  searchWrapper: {
    minHeight: theme.spacing(6) + 2,
  },
});

const initialFilterTypes = [
  { type: "image", icon: "image", placeholder: imagePlaceholder },
  { type: "video", icon: "video", placeholder: videoPlaceholder },
  { type: "audio", icon: "audio", placeholder: audioPlaceholder },
  { type: "other", icon: "readme", placeholder: filePlaceholder },
];

const PaperMedia = ({
  auth,
  media,
  loading,
  scrollLoading,
  page,
  limit,
  hasMore,
  resetMedia,
  clearError,
  getMedia,
  getFilterMedia,
  postMedia,
  putMedia,
  uploadMediaError,
  eraseMedia,
  error,
  maximumRequestBodySize,
  successMessage,
  clearSuccessMessage
}) => {
  const classes = useStyles();
  const { locale } = useParams();
  const [bulkSelect, setBulkSelect] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [bulkItems, setBulkItems] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterTypes] = useState(initialFilterTypes);
  const [searchText, setSearchText] = useState("");
  const [snackBar, setSnackBar] = useState({
    show: false,
    message: "",
    severity: 0,
  });

  const { access_token, id_token } = auth;

  useEffect(() => {
    getFilterMedia({ access_token, id_token, page, limit });

    return () => {
      resetMedia();
    };
  }, []);

  useEffect(() => {
    if(successMessage) {
      setSnackBar(s => ({
        ...s,
        severity: 3,
        show:true,
        message: successMessage
      }))
    }else {
        setSnackBar(s => ({ ...s, show: false, message: null }));
    }
  },[successMessage])

  useEffect(() => {
    if (error) {
      setSnackBar(s => ({
        ...s,
        severity: 0,
        show: true,
        message: error,
      }));
    } else {
      setSnackBar(s => ({ ...s, show: false, message: null }));
    }
  }, [error]);

  const handleFilters = type => {
    const newFilters = [...filters];
    const isOnFilters = newFilters.findIndex(element => element === type);
    if (isOnFilters >= 0) {
      newFilters.splice(isOnFilters, 1);
    } else {
      newFilters.push(type);
    }
    setFilters(newFilters);

    getFilterMedia({
      access_token,
      id_token,
      page: 0,
      limit,
      filters: newFilters.join(),
      searchText,
    });
  };

  const handleBulkSelect = () => {
    setBulkItems([]);
    setBulkSelect(!bulkSelect);
  };

  const handleBulkItems = item => {
    const newBulkItems = [...bulkItems];
    const isOnBulkItems = newBulkItems.findIndex(
      element => element.title === item.title
    );
    if (isOnBulkItems >= 0) {
      newBulkItems.splice(isOnBulkItems, 1);
    } else {
      newBulkItems.push(item);
    }
    setBulkItems(newBulkItems);
    setFilesToDelete(newBulkItems.map(file => file.key));
  };
  const mediaBoxProps = {
    classes,
    getFilterMedia,
    access_token,
    uploadMediaError,
    id_token,
    limit,
    filters: filters.join(),
    loading,
    getMedia,
    page,
    hasMore,
    bulkSelect,
    postMedia,
    media,
    filterTypes,
    bulkItems,
    handleBulkItems,
    putMedia,
    eraseMedia,
    scrollLoading,
    searchText,
    setSearchText,
    maximumRequestBodySize,
  };
  return (
    <main className={classes.root}>
      <Heading title="Media library" backUrl={`/${locale}/landing`} >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs="auto">
            <ButtonGroup
              variant="contained"
              color="default"
              size="small"
              aria-label="contained primary button group"
            >
              {filterTypes.map(filter => {
                return (
                  <Button
                    key={filter.type}
                    onClick={() => {
                      handleFilters(filter.type);
                    }}
                    color={filters.includes(filter.type) && "primary"}
                  >
                    <VfSvgIcon
                      className={classNames({
                        "MuiSvgIcon-white": filters.includes(filter.type),
                      })}
                      icon={filter.icon}
                      viewBox={24}
                    />
                  </Button>
                );
              })}
            </ButtonGroup>
          </Grid>
          <Grid item xs="auto">
            <Button
              onClick={() => handleBulkSelect()}
              variant="contained"
              size="small"
              {...(bulkSelect ? { color: "primary" } : {})}
              data-testid="bulkSelect_btn"
            >
              Bulk select
              <Checkbox
                size="small"
                color="primary"
                checked={bulkSelect}
                onChange={() => handleBulkSelect()}
              />
            </Button>
          </Grid>
          {bulkSelect && (
            <Grid item xs>
              <Grid container justify="flex-end">
                <Grid item xs="auto">
                  <Button
                    onClick={() => setDeleteDialog(true)}
                    size="small"
                    disabled={!bulkItems.length && true}
                    data-testid="deleteSelected_btn"
                  >
                    Delete selected
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Heading>
      <MediaBox {...mediaBoxProps} />
      <VfDialogConfirm
        buttonConfirmAction={() => {
          setDeleteDialog(false);
          eraseMedia({
            access_token,
            id_token,
            keys: filesToDelete,
            filters,
            searchText,
          });
        }}
        openDialog={deleteDialog}
        buttonCancelAction={() => setDeleteDialog(false)}
        buttonCancelText="Cancel"
        buttonConfirmText="Delete"
      >
        <Typography variant="body1" align="center">
          Are you sure you want to delete
          <br />
          <strong>
            {bulkItems.length}
            &nbsp;Items
          </strong>
          <br />
          permanently?
        </Typography>
      </VfDialogConfirm>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBar.show}
        onClose={() => {
          clearError();
          clearSuccessMessage();
        }}
      >
        <VfAlert severity={snackBar.severity} message={snackBar.message } />
      </Snackbar>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    media: state.media.list,
    page: state.media.page,
    limit: state.media.limit,
    hasMore: state.media.hasMore,
    loading: state.media.loading,
    scrollLoading: state.media.scrollLoading,
    error: state.media.error,
    successMessage: state.media.successMessage,
    maximumRequestBodySize: state.settings.maximumRequestBodySize,
  };
};

const mapDispatchToProps = dispatch => ({
  resetMedia: () => dispatch(clearMedia()),
  clearError: () => dispatch(clearMediaError()),
  clearSuccessMessage: () => dispatch(clearSuccessMessage()),
  getMedia: payload => dispatch(fetchMedia(payload)),
  getFilterMedia: payload => dispatch(filterFetchMedia(payload)),
  postMedia: payload => dispatch(uploadMedia(payload)),
  putMedia: payload => dispatch(updateMedia(payload)),
  eraseMedia: payload => dispatch(deleteMedia(payload)),
  uploadMediaError: error => dispatch(uploadMediaError(error))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaperMedia);
