import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import VfSvgIcon from "../icons/VfSvgIcon";

const DisplayType = ({ grid, handleGridView }) => {
  return (
    <Box className="MuiBox-displayType">
      <Grid container>
        <Grid item>
          <IconButton
            {...(grid === "list" ? { color: "secondary" } : {})}
            onClick={() => handleGridView("list")}
          >
            <VfSvgIcon icon="list" viewBox={24} />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            {...(grid === "grid" ? { color: "secondary" } : {})}
            onClick={() => handleGridView("grid")}
          >
            <VfSvgIcon icon="grid" viewBox={24} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DisplayType;
