/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchTranslationWidgetList } from "actions/translationsActions";
import WidgetsComponent from "components/widgets/Widgets";

const Widgets = ({ fetchWidgetList, auth, envID }) => {
  const { access_token, id_token } = auth;

  useEffect(() => {
    fetchWidgetList({ envID, access_token, id_token });
  });

  return <WidgetsComponent translation />;
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    envID: state.envs.id,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchWidgetList: params => dispatch(fetchTranslationWidgetList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Widgets);
