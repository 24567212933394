/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Card, CardActionArea } from "@material-ui/core";
import theme from "AppTheme";

import { addRow } from "utils/template-utils";
import {
  setShowAddTemplate as setShowAddTemplateAction,
  setCurrentPage as setCurrentPageAction,
  showSideBar,
  savePage,
} from "actions/applicationsActions";

import store from "../../store/configureStore";

const useStyles = makeStyles({
  root: {
    backgroundColor: "transparent",
    position: "relative",
    "&:hover": {
      "& .MuiCardActionArea-root": {
        "& .MuiBox-placeholder": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    "& .MuiCardActionArea-root": {
      "& .MuiBox-placeholder": {
        backgroundColor: theme.palette.grey[400],
        transition: theme.transitions.create(["background-color"], {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
  },
  cardActionArea: {
    "&.MuiCardActionArea-sm": {
      padding: theme.spacing(1.5),
    },
    "&.MuiCardActionArea-xs": {
      padding: theme.spacing(3),
    },
  },
});

const CardWidgetPlaceholder = ({
  application,
  setCurrentPage,
  setShowAddTemplate,
  cols,
  template,
  templateFunc,
  children,
  handleShowOverlay,
  size,
  dispatchshowSideBar,
  auth,
  environment,
  dispatchSavePage,
}) => {
  const classes = useStyles();

  const { currentPage } = application;

  const getChangedPages = changedPages => {
    return (
      Object.values(changedPages)
        // eslint-disable-next-line no-shadow
        .filter(page => !!page)
        // eslint-disable-next-line no-shadow
        .map(page => ({
          ...page.pageInfo,
          _id:
            page.pageInfo._id.indexOf("-") >= 0 ? undefined : page.pageInfo._id,
          data: page.djr,
        }))
    );
  };

  const { access_token, id_token } = auth;

  const handleSave = () => {
    const {
      applications: { current: app },
    } = store.getState();
    dispatchSavePage({
      access_token,
      id_token,
      pages: getChangedPages(app.changedPages),
      application: app.appInfo,
      envId: environment.id,
    });
  };

  return (
    <Card square elevation={0} className={classNames(classes.root)}>
      <CardActionArea
        className={classNames(classes.cardActionArea, {
          "MuiCardActionArea-xs": size === "xs",
          "MuiCardActionArea-sm": size === "sm",
        })}
        onClick={() => {
          if (handleShowOverlay) {
            handleShowOverlay(false);
          }
          if (template) {
            setCurrentPage(templateFunc(template));
            handleSave();
            setShowAddTemplate(true);
          } else {
            const { djr } = addRow(cols, currentPage);
            setCurrentPage({ ...currentPage, rows: [], djr });
          }
          dispatchshowSideBar(true);
        }}
      >
        {children}
      </CardActionArea>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    application: state.applications.current,
    auth: state.authentication,
    environment: state.applications.env,
  };
};

const mapDispatchToProps = dispatch => ({
  setCurrentPage: payload => dispatch(setCurrentPageAction(payload)),
  setShowAddTemplate: payload => dispatch(setShowAddTemplateAction(payload)),
  dispatchshowSideBar: payload => dispatch(showSideBar(payload)),
  dispatchSavePage: payload => dispatch(savePage(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardWidgetPlaceholder);
