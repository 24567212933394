import React, { useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useDrop } from "react-dnd";
import classNames from "classnames";
import uuidv4 from "uuid/v4";
import { Button, ButtonGroup, Menu, MenuItem } from "@material-ui/core";
import {
  clearSelectedWidget,
  setCurrentPage as setCurrentPageAction,
  showSnackbar,
} from "actions/applicationsActions";
import { pasteWidget } from "utils/djsl-utils";
import Ajv, { ValidationError } from "ajv";

import theme from "./AppTheme";
import VfSvgIcon from "./components/icons/VfSvgIcon";
import djrSchema from "./djr-schema.json";

const useStyles = makeStyles({
  root: {
    borderStyle: "dashed",
    borderColor: theme.palette.grey[500],
    borderWidth: 2,
    position: "relative",
    margin: 1,
    transition: theme.transitions.create(["border-color"], {
      duration: theme.transitions.duration.standard,
    }),
    "& > div": {
      flexBasis: "100% !important",
      maxWidth: "100%",
    },
  },
  selected: {
    borderStyle: "solid",
    outline: "none",
  },
  padded: {
    padding: theme.spacing(2),
  },
  inline: {
    display: "inline-block",
  },
  mouseOver: {
    borderColor: theme.palette.primary.main,
  },
  dragOver: {
    borderColor: theme.palette.warning.main,
    borderStyle: "solid",
  },
  widgetActions: {
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.standard,
    }),
  },
  widgetActionsHover: {
    opacity: 1,
  },
  widgetActionsButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
});

const AtomicComponentWrapper = ({
  children,
  label,
  onDrop,
  parentComponent,
  onClick,
  onDelete,
  onEdit,
  selected,
  hasPadding,
  isInline,
  component,
  componentProps,
  application,
  parent,
  setCurrentPage,
  showSnackBar,
  componentJSON,
  ...props
}) => {
  const styles = useStyles();
  const { currentPage } = application;

  const childJSON = useMemo(() => componentJSON?.__props?.children?.[0] || {}, [
    [componentJSON],
  ]);

  const previewMode = useSelector(
    state => state.applications.current.showSidebar,
    shallowEqual
  );

  const isDeployed = useSelector(
    state => state.applications.current.appInfo.isDeployed,
    shallowEqual
  );

  const dispatch = useDispatch();
  const clearWidgetProps = () => dispatch(clearSelectedWidget());

  const [mouseOver, setMouseOver] = useState(false);
  const isSelected = selected === parentComponent;

  const handleWidgetDrop = useCallback((item, name) => {
    if (name) {
      const instanceId = uuidv4();
      onDrop({
        name,
        parent: parentComponent,
        instanceId,
      });
    }
  }, []);

  const nonPropagatingEvent = (e, eventCallback) => {
    e.stopPropagation();
    e.preventDefault();
    return eventCallback();
  };

  const [{ isOverCurrent }, drop] = useDrop({
    accept: "widget",
    drop: ({ name }) => {
      handleWidgetDrop(props, name);
    },
    collect: monitor => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  const [anchorEl, setAnchorEl] = useState(null);

  let muiGridBreakpoints = children?.props?.md
    ? `MuiGrid-grid-md-${children.props.md}`
    : "";
  muiGridBreakpoints += children?.props?.sm
    ? ` MuiGrid-grid-sm-${children.props.sm}`
    : "";
  muiGridBreakpoints += children?.props?.xs
    ? ` MuiGrid-grid-xs-${children.props.xs}`
    : "";

  const ajv = new Ajv({
    useDefaults: true,
    allErrors: true,
    jsonPointers: true,
  });
  const validateDjr = ajv.compile(djrSchema);

  const copyColumn = async () => {
    return navigator.clipboard.writeText(JSON.stringify(childJSON)).then(
      () =>
        showSnackBar({
          show: true,
          message: "Column successfully copied",
          severity: 3,
        }),
      () =>
        showSnackBar({
          show: true,
          message: "Clipboard write failed",
          severity: 0,
        })
    );
  };

  const pasteColumn = async () => {
    const snackBarMsg = {
      show: true,
      message: "Widget was added successfully",
      severity: 3,
    };
    let content;

    try {
      content = await navigator.clipboard.readText();
      content = JSON.parse(content);

      if (!validateDjr(content)) {
        throw new ValidationError();
      }
      const instanceId = uuidv4();
      const { djr } = pasteWidget(
        childJSON.__props.id,
        currentPage,
        instanceId,
        content
      );

      setCurrentPage({ ...currentPage, djr });
    } catch (err) {
      snackBarMsg.severity = 0;

      if (err instanceof DOMException) {
        snackBarMsg.message =
          "Browser doesn't have the permission to write to your clipboard";
      } else if (err instanceof SyntaxError || err instanceof ValidationError) {
        snackBarMsg.message = "This is not a valid schema!";
      } else {
        snackBarMsg.message = "Some error occurred";
      }
    }

    showSnackBar(snackBarMsg);
  };

  return !previewMode ? (
    <>{children}</>
  ) : (
    /* eslint-disable */
    <div
      {...props}
      tabIndex={0}
      ref={drop}
      className={classNames(styles.root, muiGridBreakpoints, {
        [styles.selected]: isSelected,
        [styles.padded]: true,
        [styles.inline]: isInline,
        [styles.mouseOver]: mouseOver && !isSelected,
        [styles.dragOver]: isOverCurrent,
      })}
      onMouseOver={event => {
        nonPropagatingEvent(event, () => {
          setMouseOver(true);
        });
      }}
      onMouseOut={event =>
        nonPropagatingEvent(event, () => {
          setMouseOver(false);
        })
      }
      component-id={parentComponent}
    >
      {children}
      <ButtonGroup
        variant="contained"
        color="default"
        size="small"
        aria-label="contained primary button group"
        className={classNames({
          [styles.widgetActions]: true,
          [styles.widgetActionsHover]: mouseOver,
        })}
      >
        <Button
          disableRipple
          disableTouchRipple
          className={styles.widgetActionsButton}
        >
          {label}
        </Button>
        <Button onClick={event => setAnchorEl(event.currentTarget)}>
          <VfSvgIcon icon="more" viewBox={24} />
        </Button>
      </ButtonGroup>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() =>
            onEdit({ parent: parentComponent, widget: component.selectedWdgt })
          }
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            copyColumn();
          }}
        >
          Copy
        </MenuItem>
        <MenuItem
          onClick={() => {
            pasteColumn();
          }}
        >
          Paste
        </MenuItem>
        {!isDeployed && (
          <MenuItem
            onClick={() => {
              clearWidgetProps();
              onDelete(parentComponent);
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    application: state.applications.current,
    parent: state.applications.parent,
  };
};

const mapDispatchToProps = dispatch => ({
  setCurrentPage: payload => dispatch(setCurrentPageAction(payload)),
  showSnackBar: payload => dispatch(showSnackbar(payload)),
  setCurrentPage: payload => dispatch(setCurrentPageAction(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AtomicComponentWrapper);
