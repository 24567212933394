import React from "react";
import { connect } from "react-redux";

const HasMultiPermissions = ({
  userPermissions,
  children,
  permissionKeys,
  disabled,
  type, // can be 'AT_LEAST_ONE', 'ALL'
}) => {
  const { enablePermissions, permissions } = userPermissions;
  const hasPermission = (uPermissions, permissionName, enable) => {
    if (enable) {
      return !!uPermissions.find(p => p === permissionName);
    }
    return true;
  };

  const hasAllPermissions = (uPermissions, permissionNames, enable) => {
    const notAllowedActionsLength = permissionNames
      .map(permissionKey => hasPermission(uPermissions, permissionKey, enable))
      .filter(value => !value).length;

    if (type === "AT_LEAST_ONE") {
      return notAllowedActionsLength < permissionNames.length;
    }
    return notAllowedActionsLength === 0;
  };

  const customChildren = disabled
    ? React.cloneElement(children, {
        disabled: !hasAllPermissions(
          permissions,
          permissionKeys,
          enablePermissions
        ),
      })
    : children;

  return (
    <>
      {hasAllPermissions(permissions, permissionKeys, enablePermissions) ||
      disabled
        ? customChildren
        : null}
    </>
  );
};

const mapStateToProps = state => ({
  userPermissions: state.userPermissions,
});

export default connect(mapStateToProps, null)(HasMultiPermissions);
