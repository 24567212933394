import {
  SET_TOUCHPOINT,
  SET_TOUCHPOINTS_LIST,
  CLEAR_TOUCHPOINT,
} from "actions/touchpointsActions";

const initialState = {
  current: "",
  list: ["ios", "android", "web", "kiosk"],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TOUCHPOINT:
      return {
        ...state,
        current: payload,
      };
    case SET_TOUCHPOINTS_LIST:
      return {
        ...state,
        list: payload,
      };
    case CLEAR_TOUCHPOINT:
      return {
        ...state,
        current: "",
      };
    default:
      return state;
  }
};
