import inherits from "inherits";

import ReplaceMenuProvider from "bpmn-js/lib/features/popup-menu/ReplaceMenuProvider";

export default function CustomReplaceMenuProvider() {}
// eslint-disable-next-line no-underscore-dangle
const _getEntries = ReplaceMenuProvider.prototype.getEntries;
ReplaceMenuProvider.prototype.getEntries = function(element) {
  const entries = _getEntries.apply(this, [element]);
  return entries.filter(
    elem =>
      elem.label === "Script Task" ||
      elem.label === "Service Task" ||
      elem.label === "Message Start Event" ||
      elem.label === "End Event" ||
      elem.label === "Conditional Flow" ||
      elem.label === "Default Flow" ||
      elem.label === "Message End Event" ||
      elem.label === "Start Event" ||
      elem.label === "Sequence Flow"
  );
};
inherits(CustomReplaceMenuProvider, ReplaceMenuProvider);
