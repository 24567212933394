/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Divider, Grid, Toolbar } from "@material-ui/core";

import CardMedia from "@material-ui/core/CardMedia";
import theme from "../../../AppTheme";
import VfSvgIcon from "../../icons/VfSvgIcon";
import AppSummary from "./AppSummary";

const appBarHeight = theme.spacing(13);

const useStyles = makeStyles({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    paddingRight: "0 !important",
  },
  toolBar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minHeight: appBarHeight,
    maxHeight: appBarHeight,
  },
  gridDivider: {
    alignSelf: "stretch",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  gridDividerExpand: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    alignSelf: "stretch",
  },
});

const AppDialogFullHeader = ({
  application,
  includeLogo,
  onEditApp,
  buttons,
  children,
}) => {
  const classes = useStyles();

  const AppLogo = () => {
    if (!includeLogo) {
      return null;
    }
    return (
      <>
        <Grid item xs="auto">
          {/* eslint-disable */}
          {application.iconBase64 && (
            <CardMedia image={application.iconBase64} />
          )}
          {!application.iconBase64 && (
            <VfSvgIcon icon="vodafone" viewBox={24} />
          )}
          {/* eslint-enable */}
        </Grid>
        <Grid item xs="auto" className={classes.gridDivider}>
          <Divider orientation="vertical" />
        </Grid>
      </>
    );
  };

  return (
    <AppBar color="inherit" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs>
            <Grid container spacing={3} alignItems="center">
              <AppLogo />

              <AppSummary
                appType={application.appType}
                appPlatform={application.platform}
                appTitle={application.title}
                appTheme={application.theming}
                appTags={application.tags}
                unmanaged={application.unmanaged}
                hasAuth={application.hasAuth}
                onEditApp={onEditApp}
              />
            </Grid>
          </Grid>
          {children}
          <Grid item xs="auto" className={classes.gridDividerExpand}>
            <Divider orientation="vertical" />
          </Grid>
          {buttons}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AppDialogFullHeader;
