import React, { useState, useEffect } from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";

import { Typography } from "@material-ui/core";
import schemaToTreeBpmn from "utils/schema-to-tree-bpmn";
import VfSvgIcon from "../icons/VfSvgIcon";

const jp = require("jsonpath");

const ApiParamsTree = ({
  schema,
  name,
  isPutEffect,
  args,
  handleClickNode,
  parentExpanded,
  handleSetExpanded,
}) => {
  const [treeData, setTreeData] = useState([]);
  const [expanded, setExpanded] = useState(parentExpanded);
  useEffect(() => {
    const tree = schemaToTreeBpmn(
      name,
      isPutEffect ? "$.children" : `$.children.${name}.children`,
      schema,
      args
    );
    setTreeData([tree]);
  }, [schema, args]);
  const renderTree = node => {
    return (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        label={<Typography variant="body2">{node.name}</Typography>}
        onClick={() => {
          handleClickNode(node);
        }}
      >
        {Array.isArray(node.children) && jp.query(args, node.id).length
          ? node.children.map(n => renderTree(n))
          : null}
      </TreeItem>
    );
  };
  return (
    <TreeView
      defaultCollapseIcon={<VfSvgIcon icon="chevronDown" fontSize="small" />}
      defaultExpandIcon={<VfSvgIcon icon="chevronRight" fontSize="small" />}
      expanded={expanded}
      onNodeToggle={(e, newValue) => {
        handleSetExpanded(newValue);
        setExpanded(newValue);
      }}
    >
      {Array.isArray(treeData) ? treeData.map(node => renderTree(node)) : null}
    </TreeView>
  );
};

export default ApiParamsTree;
