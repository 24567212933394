/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import theme from "AppTheme";
import { DialogActions, FormControl, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import VfSvgIcon from "../../icons/VfSvgIcon";
import { updateNexusWidget } from "../../../actions/nexusWidgetsActions";

const useStyles = makeStyles({
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalContent: {
    minHeight: "20vh",
  },
  gridDivider: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    alignSelf: "stretch",
  },
  dialogActions: {
    paddingBottom: theme.spacing(4),
    justifyContent: "center",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      "& .MuiButton-root": {
        width: "100%",
      },
      "& > :not(:first-child)": {
        marginLeft: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
});

const UpdateWidgetDialog = ({
  isOpen,
  widget,
  onCancel,
  localeId,
  updateWidget,
}) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const [version, updateVersion] = useState("");
  const [packageName, updatePackageName] = useState(widget.name);

  return !isOpen ? null : (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      open={isOpen}
      maxWidth="xs"
    >
      <DialogTitle disableTypography className={classes.modalTitle}>
        <Grid container alignItems="center" justify="space-between" spacing={1}>
          <Grid item xs="auto">
            <VfSvgIcon icon="vodafone" viewBox={24} />
          </Grid>
          <Grid item xs="auto" className={classes.gridDivider}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs>
            <Grid container>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    {packageName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Grid container spacing={3} justify="center" alignContent="center">
          <Grid item xs={12}>
            <Typography variant="body1">
              Please enter package name and version
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                name="widgetVersion"
                variant="outlined"
                label="Version"
                type="text"
                onChange={e => updateVersion(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                name="packageName"
                variant="outlined"
                label="Package name"
                type="text"
                defaultValue={widget.name}
                onChange={e => updatePackageName(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!version || !packageName}
          onClick={() => {
            updateWidget({
              localeId,
              widgetName: packageName,
              widgetVersion: version,
            });
            onCancel();
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    localeId: (state.envs?.list || [])[0]?.locale,
  };
};

const mapDispatchToProps = dispatch => ({
  updateWidget: params => dispatch(updateNexusWidget(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateWidgetDialog);
