import {
  S3_SET_ENV,
  S3_CLEAR_ENV,
  S3_ENVS_REQUEST_SUCCESS,
  S3_ENVS_REQUEST_FAIL,
  S3_URLS_RESOURCES_REQUEST,
  S3_URLS_RESOURCES_REQUEST_SUCCESS,
  S3_URLS_RESOURCES_REQUEST_FAIL,
  S3_CLEAR_URLS_RESOURCES,
} from "actions/s3EnvsActions";

const initialState = {
  current: "",
  id: "",
  list: [],
  error: null,
  s3EnvUrls: [],
  loading: false,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case S3_SET_ENV:
      return {
        ...state,
        current: payload.type,
        id: payload.id,
      };
    case S3_CLEAR_ENV:
      return {
        ...state,
        current: "",
        id: "",
      };
    case S3_ENVS_REQUEST_SUCCESS:
      return {
        ...state,
        list: payload.data,
        coreAppJob: payload.coreAppJob,
      };
    case S3_ENVS_REQUEST_FAIL:
      return {
        ...state,
        ...error,
      };
    case S3_URLS_RESOURCES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case S3_URLS_RESOURCES_REQUEST_SUCCESS:
      return {
        ...state,
        s3EnvUrls: payload,
        loading: false,
      };
    case S3_URLS_RESOURCES_REQUEST_FAIL:
      return {
        ...state,
        ...error,
        loading: false,
      };
    case S3_CLEAR_URLS_RESOURCES:
      return {
        ...state,
        s3EnvUrls: [],
      };
    default:
      return state;
  }
};
