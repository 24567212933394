/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import { Grid, MenuItem, TextField } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const DeployResources = ({
  selectedUrl,
  selectedRam,
  selectedCpu,
  urlList,
  ramList,
  cpuList,
  onSelectUrl,
  onSelectRam,
  onSelectCpu,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={e => onSelectUrl(e.target.value)}
          label="Select url"
          value={selectedUrl || ""}
          variant="filled"
          color="secondary"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classNames("MuiFilledInput-disableUnderline"),
          }}
        >
          {urlList.map(
            url =>
              url.available && (
                <MenuItem key={url._id} value={url._id}>
                  {url.value}
                </MenuItem>
              )
          )}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={e => onSelectCpu(e.target.value)}
          label="Select CPU"
          value={selectedCpu || ""}
          variant="filled"
          color="secondary"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classNames("MuiFilledInput-disableUnderline"),
          }}
        >
          {cpuList.map(cpu => (
            <MenuItem key={cpu} value={cpu}>
              {`${cpu} m`}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          }}
          onChange={e => onSelectRam(e.target.value)}
          label="Select RAM"
          value={selectedRam || ""}
          variant="filled"
          color="secondary"
          fullWidth
          InputProps={{
            disableUnderline: true,
            className: classNames("MuiFilledInput-disableUnderline"),
          }}
        >
          {ramList.map(ram => (
            <MenuItem key={ram} value={ram}>
              {`${ram} mi`}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default React.memo(DeployResources);
