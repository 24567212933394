import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import theme from "AppTheme";
import {
  setShowRole,
  setRole,
  clearRole,
  deleteRole,
} from "actions/rolesActions";
import Loader from "../loader/Loader";
import Heading from "../headings/Heading";
import CardRole from "./CardRole";
import RolesDialog from "./RolesDialog";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
  },
  loader: {
    minHeight: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: 100,
    },
  },
  fab: {
    position: "absolute",
    right: theme.spacing(3),
    top: -theme.spacing(7),
    transform: "translateY(-50%)",
  },
  container: {
    position: "relative",
  },
});

const Roles = ({
  roles,
  showRole,
  setCurrentRole,
  clearCurrentRole,
  deleteRoleById,
  auth,
}) => {
  // const [showDialog, setShowDialog] = useState(false);
  const { locale } = useParams();
  const classes = useStyles();

  const { loading, list, show } = roles;
  return (
    <main className={classes.root}>
      <Heading title="Roles & Permissions" backUrl={`/${locale}/landing`} />
      <Box pt={8} pb={4}>
        <Container className={classes.container} maxWidth="md">
          <Grid container spacing={2}>
            {!loading || false ? (
              list.map(({ _id, name, securityGroups, permissions }) => (
                <Grid item xs={12} key={name}>
                  <CardRole
                    name={name}
                    securityGroups={securityGroups}
                    permissions={permissions}
                    showRole={showRole}
                    setCurrentRole={setCurrentRole}
                    id={_id}
                    deleteRole={deleteRoleById}
                    auth={auth}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Loader size={theme.spacing(12)} />
              </Grid>
            )}
          </Grid>
          <Fab
            color="secondary"
            className={classes.fab}
            onClick={() => showRole()}
          >
            <VfSvgIcon icon="plus" viewBox={24} />
          </Fab>
        </Container>
      </Box>
      {show && (
        <RolesDialog
          show={show}
          showDialog={showRole}
          clearCurrentRole={clearCurrentRole}
        />
      )}
    </main>
  );
};

const mapStateToProps = state => {
  return {
    roles: state.roles,
    auth: state.authentication,
  };
};

const mapDispatchToProps = dispatch => ({
  showRole: () => dispatch(setShowRole()),
  setCurrentRole: payload => dispatch(setRole(payload)),
  clearCurrentRole: () => dispatch(clearRole()),
  deleteRoleById: payload => dispatch(deleteRole(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
