import React from "react";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import theme from "../../AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    padding: 0,
    borderRadius: "5px",
    overflow: "hidden",
    whiteSpace: "pre-wrap",
    marginTop: theme.spacing(2),
    "&.MuiAlert-outlinedError": {
      color: theme.palette.text.primary,
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.common.white,
      "& .MuiAlert-icon": {
        backgroundColor: theme.palette.error.main,
      },
    },
    "&.MuiAlert-outlinedSuccess": {
      color: theme.palette.text.primary,
      borderColor: theme.palette.success.main,
      backgroundColor: theme.palette.common.white,
      "& .MuiAlert-icon": {
        backgroundColor: theme.palette.success.main,
      },
    },
    "&.MuiAlert-outlinedWarning": {
      color: theme.palette.text.primary,
      borderColor: theme.palette.warning.main,
      backgroundColor: theme.palette.common.white,
      "& .MuiAlert-icon": {
        backgroundColor: theme.palette.warning.main,
      },
    },
    "&.MuiAlert-outlinedInfo": {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
      "& .MuiAlert-icon": {
        backgroundColor: theme.palette.info.main,
      },
    },
    "& .MuiAlert-icon": {
      opacity: 1,
      color: theme.palette.common.white,
      width: theme.spacing(7),
      alignItems: "center",
      justifyContent: "center",
      margin: 0,
    },
    "& .MuiAlert-message": {
      "&": theme.typography.body1,
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    "& .MuiAlertTitle-root": {
      "&": theme.typography.h5,
    },
  },
});

const VfAlert = ({ severity, message ,testid}) => {
  const classes = useStyles();

  const severityMap = ["error", "warning", "info", "success"];
  const iconMap = ["warning", "warning", "success", "success"];

  return (
    <Alert
      className={classNames(classes.root)}
      variant="outlined"
      severity={severityMap[severity]}
      icon={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <VfSvgIcon className="MuiSvgIcon-white" icon={iconMap[severity]} />
      }
      data-testid={`${testid}_${severityMap[severity]}`}
    >
      {message}
    </Alert>
  );
};

export default VfAlert;
