import React from "react";
import { Box, Grid } from "@material-ui/core";

import SelectTreeViewField from "./SelectTreeViewField";
import ApiParameterWithExpansion from "./ApiParameterWithExpansion";

import Ace from "./ace";

const ServiceTaskRightPart = ({
  selectedEffect,
  setSelectedEffect,
  element,
  modeler,
}) => {
  return (
    <>
      {!!selectedEffect?.parameters?.length && (
        <Box p={3}>
          <Grid container spacing={3}>
            {!!selectedEffect?.parameters?.length &&
              selectedEffect.effect === "call-api" &&
              selectedEffect?.parameters.map(param => (
                <Grid item xs={12} key={param.name}>
                  <ApiParameterWithExpansion
                    param={param}
                    setSelectedEffect={setSelectedEffect}
                    selectedEffect={selectedEffect}
                    element={element}
                    modeler={modeler}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
      {selectedEffect.effect === "call-js" && (
        <Ace
          isForExpression
          value={selectedEffect}
          prop="callExpression"
          aid="master-editor"
          setValue={setSelectedEffect}
        />
      )}
      {selectedEffect.effect === "select-js" && (
        <Ace
          isForExpression
          prop="selectExpression"
          value={selectedEffect}
          aid="master-editor"
          setValue={setSelectedEffect}
        />
      )}
      {selectedEffect.effect === "select" && (
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectTreeViewField
                element={element}
                selectedEffect={selectedEffect}
                setSelectedEffect={setSelectedEffect}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ServiceTaskRightPart;
