const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
const GET_ROLES_REQUEST_SUCCESS = "GET_ROLES_REQUEST_SUCCESS";
const GET_ROLES_REQUEST_FAIL = "GET_ROLES_REQUEST_FAIL";
const SAVE_ROLE_REQUEST = "SAVE_ROLE_REQUEST";
const SAVE_ROLE_REQUEST_SUCCESS = "SAVE_ROLE_REQUEST_SUCCESS";
const SAVE_ROLE_REQUEST_FAIL = "SAVE_ROLE_REQUEST_FAIL";
const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
const DELETE_ROLE_REQUEST_SUCCESS = "DELETE_ROLE_REQUEST_SUCCESS";
const DELETE_ROLE_REQUEST_FAIL = "DELETE_ROLE_REQUEST_FAIL";
const SHOW_ROLE = "SHOW_ROLE";
const SET_ROLE = "SET_ROLE";
const CLEAR_ROLE = "CLEAR_ROLE";

const fetchRoles = payload => ({
  type: GET_ROLES_REQUEST,
  payload,
});

const saveRole = payload => ({
  type: SAVE_ROLE_REQUEST,
  payload,
});

const deleteRole = payload => ({
  type: DELETE_ROLE_REQUEST,
  payload,
});

const setShowRole = () => ({
  type: SHOW_ROLE,
});

const setRole = payload => ({
  type: SET_ROLE,
  payload,
});

const clearRole = () => ({
  type: CLEAR_ROLE,
});

export {
  GET_ROLES_REQUEST,
  GET_ROLES_REQUEST_SUCCESS,
  GET_ROLES_REQUEST_FAIL,
  SAVE_ROLE_REQUEST,
  SAVE_ROLE_REQUEST_SUCCESS,
  SAVE_ROLE_REQUEST_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_REQUEST_SUCCESS,
  DELETE_ROLE_REQUEST_FAIL,
  SET_ROLE,
  CLEAR_ROLE,
  SHOW_ROLE,
  fetchRoles,
  saveRole,
  deleteRole,
  setShowRole,
  setRole,
  clearRole,
};
