/* eslint-disable camelcase */
import React, { Fragment, forwardRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Collapse from "@material-ui/core/Collapse";

import { saveRole } from "actions/rolesActions";
import theme from "AppTheme";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";

import CardPlain from "./CardPlain";
import VfSvgIcon from "../icons/VfSvgIcon";

const dialogAppBarPadding = theme.spacing(3);

const useStyles = makeStyles({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    paddingRight: "0 !important",
  },
  toolBar: {
    paddingTop: dialogAppBarPadding,
    paddingBottom: dialogAppBarPadding,
  },
  gridDivider: {
    marginTop: -dialogAppBarPadding,
    marginBottom: -dialogAppBarPadding,
    alignSelf: "stretch",
  },
  gridContainer: {
    minHeight: "100%",
  },
  dialogContent: {
    padding: 0,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.grey[100],
  },
  cardCheckContent: {
    padding: 0,
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RolesDialog = ({ show, showDialog, currentRole, clearCurrentRole, allPermissions, auth, saveCurrRole }) => {
  const classes = useStyles();
  const { access_token, id_token } = auth;

  const [role, setRoleValues] = useState({
    name: "",
    permissions: [],
    securityGroups: [],
  });

  const [hasName, setHasName] = useState(true);
  const [hasSGroup, setHasSGroup] = useState(true);

  const checkHasName = () => {
    setHasName(!!role.name);
  };

  const checkHasSGroup = () => {
    setHasSGroup(!!role.securityGroups.length);
  };

  useEffect(() => {
    if (currentRole) {
      setRoleValues(r => ({ ...r, ...currentRole }));
    }
  }, [currentRole]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setRoleValues({ ...role, [name]: value });
  };

  const onSecurityGroupsChange = (event, values) => {
    setRoleValues({ ...role, securityGroups: values });
  };

  const handleToggle = ({ name, description, key }) => () => {
    const currentIndex = role.permissions.findIndex(x => x.key === key);
    const newChecked = [...role.permissions];

    if (currentIndex === -1) {
      newChecked.push({ name, description, key });
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setRoleValues({ ...role, permissions: newChecked });
  };
  const [tabValue, setTabValue] = useState("general");
  const handleChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  const isTabActive = value => {
    return value === tabValue;
  };

  return (
    <main>
      <Dialog
        fullScreen
        open={show}
        onClose={() => {
          showDialog(false);
        }}
        TransitionComponent={Transition}
        scroll="paper"
      >
        <AppBar color="inherit" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>
                <Grid container spacing={1} alignItems="center" justify="space-between">
                  <Grid item>
                    <Typography variant="body1" component="h5">
                      {role.name ? role.name : "Role Name"}
                    </Typography>
                    <Typography variant="body2" component="h5">
                      {role.permissions.length > 0
                        ? `${
                            role.permissions.length > 3
                              ? `${role.permissions
                                  .slice(0, 3)
                                  .map(e => e.name)
                                  .join(", ")} +${role.permissions.length - 3}`
                              : role.permissions.map(e => e.name).join(", ")
                          }`
                        : "Role Permissions"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{`${role.securityGroups.length} Groups`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs="auto" className={classes.gridDivider}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs="auto">
                <Button
                  autoFocus
                  color="default"
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    clearCurrentRole();
                    showDialog(false);
                  }}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    checkHasName();
                    checkHasSGroup();
                    if (role.name && role.securityGroups.length) saveCurrRole({ access_token, id_token, data: role });
                  }}
                >
                  {currentRole ? "Save" : "Add Role"}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogContent}>
          <Container maxWidth="md">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CardPlain title="Enter role name">
                  <FormControl fullWidth>
                    <TextField
                      name="name"
                      variant="outlined"
                      label="Name"
                      type="text"
                      value={role.name}
                      onChange={e => {
                        handleInputChange(e);
                      }}
                      {...(!hasName && !role.name
                        ? {
                            error: true,
                            helperText: "Please provide a Role Name.",
                          }
                        : {})}
                    />
                  </FormControl>
                </CardPlain>
              </Grid>
              <Grid item xs={12}>
                <CardPlain title="Add security groups">
                  <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      options={[]}
                      freeSolo
                      name="groups"
                      closeIcon={<VfSvgIcon icon="close" fontSize="small" />}
                      onChange={onSecurityGroupsChange}
                      value={role.securityGroups}
                      /* eslint-disable */
                      // renderTags={(tagValue, getTagProps) =>
                      //   role.securityGroups.map((option, index) => (
                      //     <Chip
                      //       label={option}
                      //       deleteIcon={<VfSvgIcon icon="icon" viewBox={24} />}
                      //       onDelete
                      //       {...getTagProps({ index })}
                      //     />
                      //   ))
                      // }
                      /* eslint-enable */
                      renderInput={params => (
                        <TextField
                          {...params}
                          {...(!hasSGroup && !role.securityGroups.length
                            ? {
                                error: true,
                                helperText: "Please provide a Security Group.",
                              }
                            : {})}
                          variant="outlined"
                          label="Groups"
                          placeholder="Group..."
                        />
                      )}
                    />
                  </FormControl>
                </CardPlain>
              </Grid>
              <Grid item xs={12}>
                <CardPlain title="Select permissions" isList>
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTabValue}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="GENERAL" value="general" />
                    <Tab label="APPS" value="apps" />
                    <Tab label="CONFIGURATOR" value="configuration" />
                    <Tab label="TRANSLATOR" value="translation" />
                    <Tab label="TENANT" value="tenant" />
                  </Tabs>
                  {Object.keys(allPermissions).map(tab => {
                    return (
                      <Collapse in={isTabActive(tab)} key={tab}>
                        <List disablePadding>
                          {allPermissions[tab].map(({ name, description, key }) => (
                            <Fragment key={key + role.name}>
                              <Divider />
                              <ListItem
                                role={undefined}
                                dense
                                button
                                onClick={handleToggle({
                                  name,
                                  description,
                                  key,
                                })}
                              >
                                <ListItemText
                                  id={`checkbox-list-secondary-label-${key}`}
                                  /* eslint-disable */
                                  primary={<Typography variant="body1">{name}</Typography>}
                                  /* eslint-enable */
                                  secondary={description}
                                />
                                <ListItemIcon>
                                  <Checkbox
                                    edge="end"
                                    color="primary"
                                    checked={role.permissions.some(e => e.key === key)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": `checkbox-list-secondary-label-${key}`,
                                    }}
                                  />
                                </ListItemIcon>
                              </ListItem>
                            </Fragment>
                          ))}
                        </List>
                      </Collapse>
                    );
                  })}
                </CardPlain>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
      </Dialog>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    currentRole: state.roles.current,
    allPermissions: state.roles.permissions,
    auth: state.authentication,
  };
};

const mapDispatchToProps = dispatch => ({
  saveCurrRole: payload => dispatch(saveRole(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesDialog);
