/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  SvgIcon,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { range } from "lodash";
import theme from "AppTheme";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { ReactComponent as MoveIcon } from "../../assets/images/ic-move.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/ic-more.svg";
import GridWidgetTemplateBuilder from "./GridWidgetTemplateBuilder";

const useStyles = makeStyles({
  root: {
    height: "calc(100% + 24px)",
    position: "relative",
    overflow: "hidden",
    ["&.Mui-hover"]: {
      ["& .MuiBox-root"]: {
        opacity: 1,
      },
    },
    ["&.Mui-selected"]: {
      ["& .MuiBox-root"]: {
        opacity: 1,
        borderColor: theme.palette.primary.main,
        ["& .MuiAppBar-root"]: {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
  overlay: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.text.primary,
    pointerEvents: "none",
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.standard,
    }),
  },
  appbar: {
    pointerEvents: "all",
    backgroundColor: fade(theme.palette.text.primary, 0.9),
  },
  toolbar: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  hide: {
    height: 0,
    opacity: 0,
    paddingTop: 0,
    paddingBottom: 0,
    transition: theme.transitions.create(
      ["height", "opacity", "padding-top", "padding-bottom"],
      {
        duration: 2000,
      }
    ),
  },
});
const gridSizeMap = {
  xs: {
    height: theme.spacing(3),
  },
  sm: {
    height: theme.spacing(7),
  },
};
const gridColumnsMap = {
  1: {
    xs: 12,
    sm: 12,
    md: 12,
  },
  2: {
    xs: 12,
    sm: 6,
    md: 6,
  },
  3: {
    xs: 12,
    sm: 4,
    md: 4,
  },
  4: {
    xs: 12,
    sm: 6,
    md: 3,
  },
  6: {
    xs: 12,
    sm: 6,
    md: 2,
  },
};

const GridColTemplateBuilder = ({ columns, size }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hover, setHover] = useState(null);
  const [selected, setSelected] = useState(null);
  const [hideRow, setHideRow] = useState(true);

  const handleHover = value => {
    setHover(value);
  };

  const renderColPlaceholder = (col, index) => {
    return (
      <Grid
        key={index}
        item
        xs={gridColumnsMap[columns].xs}
        sm={gridColumnsMap[columns].sm}
        md={gridColumnsMap[columns].md}
      >
        <GridWidgetTemplateBuilder size={size} />
      </Grid>
    );
  };
  return (
    <Collapse in={hideRow} timeout={theme.transitions.duration.complex}>
      <Grid
        container
        spacing={1}
        className={classNames(classes.root, {
          "Mui-hover": hover,
          "Mui-selected": selected,
        })}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        {Array.from(Array(columns), (e, i) => renderColPlaceholder(e, i))}
      </Grid>
    </Collapse>
  );
};

export default GridColTemplateBuilder;
