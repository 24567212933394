/* eslint-disable camelcase */
import axios from "axios";
import getCookie from "utils/getCookie";

class PackagesService {
  fetchPackages = async ({ searchText }) => {
    const params = {
      searchText,
    };
    try {
      const { data } = await axios({
        method: "get",
        url: `/api/appbuilder/packages`,
        params,
        headers: {
          "content-type": "application/json",
          'x-csrf-token': getCookie('x-csrf-token'),
        },
      });
      return data;
    } catch (err) {
      console.log("Error in fetch packages service:", err);
      throw err;
    }
  };
}
const inst = new PackagesService();
export default inst;
