import React from "react";
import VfDialogConfirm from "../vf/VfDialogConfirm";

export default function DeleteAppDialog({
  isOpen,
  onCancel,
  onDelete,
  children,
}) {
  const handleClose = () => {
    onCancel(false);
  };

  const handleDelete = () => {
    onDelete();
    onCancel(false);
  };

  return (
    <VfDialogConfirm
      openDialog={isOpen}
      buttonConfirmAction={handleDelete}
      buttonCancelAction={handleClose}
      buttonConfirmText="Delete"
      buttonCancelText="Cancel"
    >
      {children}
    </VfDialogConfirm>
  );
}
