/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect, useSelector, shallowEqual } from "react-redux";
import { debounce } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  createApplication,
  validateAppName,
} from "actions/applicationsActions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "@material-ui/core/StepConnector";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "AppTheme";
import IconButton from "@material-ui/core/IconButton";

import Loader from "../../loader/Loader";
import VfSvgIcon from "../../icons/VfSvgIcon";
import CardSelector from "../common/CardSelector";
import FreeTextSelector from "../common/FreeTextSelector";
import AppDialogFilesSelector from "./AppDialogFilesSelector";
import AppDialogDetailsSelector from "./AppDialogDetailsSelector";
import AppDialogFullHeader from "../common/AppDialogFullHeader";
import { NO_AUTH, AZURE_AD, GDI_SAML, NO_PROTOCOL, AZURE_AUTH2_PROTOCOL, GDI_SAML_PROTOCOL, } from "../../../utils/authentication.enum";
import AppDialogConfigOptions from "./AppDialogConfigOptions";

const useStyles = makeStyles({
  modalContent: {
    minHeight: "50vh",
  },
  gridDivider: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    alignSelf: "stretch",
  },
  gridContainer: {
    minHeight: "100%",
  },
  dialogContent: {
    padding: 0,
    backgroundColor: theme.palette.grey[100],
  },
  stepFirst: {
    marginTop: -theme.spacing(3),
    "& .MuiStepConnector-line": {
      minHeight: theme.spacing(5),
    },
  },
  stepLast: {
    marginBottom: -theme.spacing(3),
    "& .MuiStepConnector-line": {
      minHeight: theme.spacing(5),
    },
  },
});
const APP_TYPE_STEP = 1;
const APP_PLATFORM_STEP = 2;
const APP_DETAILS_STEP = 3;
const APP_AUTH_STEP = 4;
const APP_SECURITY_GROUPS_STEP = 5;
const APP_TOKEN_STORE = 6;
const CONFIGURATIONS_STEP = 7;
const APP_TAGS_STEP = 8;
const APP_THEME_STEP = 9;
const APP_ASSETS_STEP = 10;
const APP_FINAL_STEP = -1;

const defaultAppValues = {
  version: "1.0.0",
  title: "",
  touchpoint: "web",
  description: "",
  bgColor: "",
  tags: [],
  imageBase64: "",
  iconBase64: "",
  platform: "",
  theming: "",
  appType: "",
  widgets: [],
  unmanaged: false,
  hasAuth: false,
  authProvider: "",
  authProtocol:"",
  storeToken: "",
  securityGroups: [],
  externalConfig: false,
  externalConfigUrl: ""
};

const steps = [
  { id: APP_TYPE_STEP, name: "Type", order: 1 },
  { id: APP_PLATFORM_STEP, name: "Platform", order: 2 },
  { id: APP_DETAILS_STEP, name: "Details", order: 3 },
  { id: APP_AUTH_STEP, name: "Authentication", order: 4 },
  { id: APP_SECURITY_GROUPS_STEP, name: "Security Groups", order: 5 },
  { id: APP_TOKEN_STORE, name: "Store Token", order: 6 },
  { id: CONFIGURATIONS_STEP, name: "Configurations", order: 7 },
  { id: APP_THEME_STEP, name: "Theme", order: 8 },
  { id: APP_TAGS_STEP, name: "Tags", order: 9 },
  { id: APP_ASSETS_STEP, name: "Assets", order: 10 },
  { id: APP_FINAL_STEP, order: 10, system: true },
];

const AppsDialogEdit = ({
  show,
  showCreateApp,
  createApp,
  auth,
  loading,
  validateName,
  applications,
  workbenchApplicationList,
}) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { access_token, id_token } = auth;
  const {
    listType,
    listPlatform,
    listTheme,
    hasValidName,
    current,
    authProviders,
    storeTokenOptions
  } = applications;

  const initStateProp = prop => {
    if (!current || !current.appInfo) {
      return null;
    }
    return current.appInfo[prop];
  };

  const [securityGroupsApplicable, setSecurityGroupsApplicable] = useState(
    false
  );
  const [appId] = useState(initStateProp("_id") || "");
  const [appType, setAppType] = useState(initStateProp("appType") || "");
  const [platform, setPlatform] = useState(initStateProp("platform") || "");
  const [authProvider, setAuthProvider] = useState(
    initStateProp("authProvider") || ""
  );
  const [authProtocol, setAuthProtocol] = useState(
    initStateProp("authProtocol") || ""
  );
  const [storeToken, setStoreToken] = useState(initStateProp("storeToken") || "");
  const [workbenchApplication, setWorkbenchApplication] = useState(
    initStateProp("workbenchApplication" || undefined)
  );

  const [tags, setTags] = useState(initStateProp("tags") || []);
  const [suggestedTags, setSuggestedTags] = useState({});

  const [securityGroups, setSecurityGroups] = useState(
    initStateProp("securityGroups") || []
  );
  const [title, setTitle] = useState(initStateProp("title") || "");

  const [description, setDescription] = useState(
    initStateProp("description") || ""
  );
  const [bgColor, setBgColor] = useState(
    initStateProp("bgColor") || "#f4f4f4"
  );
  const [unmanaged, setUnmanaged] = useState(
    initStateProp("unmanaged") || false
  );
  const [theming, setTheming] = useState(initStateProp("theming") || "");
  const [iconBase64, setIconBase64] = useState(
    initStateProp("iconBase64") || ""
  );
  const [imageBase64, setImageBase64] = useState(
    initStateProp("imageBase64") || ""
  );

  const tenantType = useSelector(
    state => state.tenants.current.type,
    shallowEqual
  );
  const [activeStep, setActiveStep] = useState(steps[0]);

  const [tmpTitle, setTmpTitle] = useState(title);
  const [tmpUnmanaged, setTmpUnmanaged] = useState(unmanaged);
  const [externalConfig, setExternalConfig] = useState(initStateProp("externalConfig") || false);
  const [externalConfigUrl, setExternalConfigUrl] = useState(initStateProp("externalConfigUrl") || "");
  const validateAppTitle = appTitle => {
    if (!appTitle) {
      return;
    }
    validateName({
      id: appId,
      title: appTitle,
      appType,
      platform,
      access_token,
      id_token,
    });
  };

  const getTagSuggestions = (type, appTitle) => {
    const suggestions = {};
    if (appType) {
      suggestions.appType = type;
    }
    if (appTitle) {
      suggestions.title = appTitle;
    }
    if (appType && appTitle) {
      suggestions.appTitleType = `${appTitle}-${appType}`;
    }
    return suggestions;
  };

  useEffect(() => {
    if (!!authProvider && authProvider !== NO_AUTH) {
      setSecurityGroupsApplicable(true);
    } else {
      setSecurityGroupsApplicable(false);
      setSecurityGroups([]);
    }
    switch (authProvider) {
      case NO_AUTH:
        setAuthProtocol(NO_PROTOCOL);
        break;
      case AZURE_AD:
        setAuthProtocol(AZURE_AUTH2_PROTOCOL);
        break;
      case GDI_SAML:
        setAuthProtocol(GDI_SAML_PROTOCOL);
        break;
      default:
        break;
    }
  }, [authProvider]);

  const onStepClicked = step => () => {
    if (!appId) {
      if (step.order >= activeStep.order) {
        return;
      }
    }
    setActiveStep(step);
  };

  const handleComplete = nextStepId => {
    setActiveStep(steps.find(step => step.id === nextStepId));
  };

  const isStepExpanded = stepId => {
    return stepId === activeStep.id;
  };

  const stepIcon = step => {
    if (activeStep.id === step.id) {
      return "circleSelected";
    }
    if (appId) {
      return "circleDone";
    }
    return step.order > activeStep.order ? "circle" : "circleDone";
  };

  const onInputChange = useRef(
    debounce((val, type, appPlatform, id) => {
      if (!val) {
        return;
      }
      validateName({
        title: val,
        appType: type,
        platform: appPlatform,
        id,
        access_token,
        id_token,
      });
    }, 500)
  ).current;

  const onCompleteDetails = useCallback(
    (appTitle, appDescription, appBgColor, appUnmanaged, workbenchApp) => {
      setTitle(appTitle);
      setWorkbenchApplication(workbenchApp);
      setDescription(appDescription);
      setBgColor(appBgColor);
      setUnmanaged(appUnmanaged);
      setTmpUnmanaged("");
      setSuggestedTags(suggested => ({
        ...suggested,
        ...getTagSuggestions(appType, appTitle),
      }));
      handleComplete(APP_AUTH_STEP);
    },
    [appType]
  );

  const onTitleChange = useCallback(
    value => {
      setTmpTitle(value);
      onInputChange(value, appType, platform, appId);
    },
    [appType, platform, appId]
  );

  useEffect(() => {
    if (activeStep.id === APP_DETAILS_STEP) {
      validateAppTitle(tmpTitle);
    }
  }, [activeStep]);
  const onUnmanagedChanged = useCallback(value => setTmpUnmanaged(value), []);

  const onSubmit = () => {
    createApp({
      data: {
        ...defaultAppValues,
        ...(current && current.appInfo ? current.appInfo : {}),
        appType,
        platform,
        title,
        description,
        bgColor,
        theming,
        authProvider,
        authProtocol,
        tags,
        securityGroups,
        iconBase64,
        imageBase64,
        unmanaged,
        workbenchApplication,
        hasAuth: !!authProvider && authProvider !== NO_AUTH,
        storeToken,
        externalConfig,
        externalConfigUrl
      },
      access_token,
      id_token,
    });
  };

  const renderStep = step => {
    if (!appId) {
      if (step.order > activeStep.order) {
        step.disabled = true;
      } else {
        step.disabled = false;
      }
    } else {
      step.disabled = false;
    }

    if (step.id === APP_SECURITY_GROUPS_STEP && !securityGroupsApplicable) {
      return null;
    }
    return (
      <Step key={step.name}>
        <StepButton
          onClick={onStepClicked(step)}
          completed={step.completed}
          disabled={step.disabled}
          icon={<VfSvgIcon icon={stepIcon(step)} viewBox={24} />}
        >
          <Typography component="span" variant="body1">
            {step.name}
          </Typography>
        </StepButton>
      </Step>
    );
  };

  const ActionButton = () => {
    return (
      <IconButton onClick={() => showCreateApp(false)}>
        <VfSvgIcon icon="close" viewBox={24} />
      </IconButton>
    );
  };

  const FinalButton = () => {
    return activeStep.id === APP_FINAL_STEP ? (
      <Button variant="contained" color="secondary" onClick={onSubmit}>
        {appId ? "Save" : "Add App"}
      </Button>
    ) : (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => handleComplete(APP_FINAL_STEP)}
      >
        {!imageBase64 && !iconBase64 ? "Skip" : "Next"}
      </Button>
    );
  };

  const SecurityGroupsCollapse = () => {
    if (!securityGroupsApplicable) {
      return null;
    }
    return (
      <Collapse in={isStepExpanded(APP_SECURITY_GROUPS_STEP)}>
        <FreeTextSelector
          name="securityGroups"
          label="Security groups"
          placeholder="Group..."
          title="Add security groups"
          values={securityGroups}
          options={securityGroups}
          onChange={(e, value) => setSecurityGroups(value)}
          onComplete={() => handleComplete(APP_TOKEN_STORE)}
          optional={false}
        />
      </Collapse>
    );
  };

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      open={show}
      maxWidth="md"
    >
      <AppDialogFullHeader
        application={{
          platform,
          title: tmpTitle || title,
          appType,
          theming,
          unmanaged: tmpUnmanaged || unmanaged,
          hasAuth: !!authProvider && authProvider !== NO_AUTH,
          tags,
          
        }}
        buttons={<ActionButton />}
      />
      <DialogContent className={classes.modalContent}>
        {loading ? (
          <Grid item xs={12}>
            <Loader size={theme.spacing(12)} />
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep.id} orientation="vertical">
                <StepConnector className={classes.stepFirst} />
                {steps
                  .filter(step => !step.system)
                  .map(step => step.id === APP_TOKEN_STORE ? (authProvider !== NO_AUTH && renderStep(step) || null) : renderStep(step))}
                <StepConnector className={classes.stepLast} />
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              <Collapse in={isStepExpanded(APP_TYPE_STEP)}>
                <CardSelector
                  title="Select app type"
                  items={listType}
                  selected={appType}
                  editable={!appId}
                  onSelect={value => {
                    setAppType(value);
                    handleComplete(APP_PLATFORM_STEP);
                  }}
                  onConfirm={() => handleComplete(APP_PLATFORM_STEP)}
                />
              </Collapse>
              <Collapse in={isStepExpanded(APP_PLATFORM_STEP)}>
                <CardSelector
                  title="Select platform"
                  items={listPlatform}
                  selected={platform}
                  editable={!appId}
                  onSelect={value => {
                    setPlatform(value);
                    handleComplete(APP_DETAILS_STEP);
                  }}
                  onConfirm={() => handleComplete(APP_DETAILS_STEP)}
                />
              </Collapse>
              <Collapse in={isStepExpanded(APP_DETAILS_STEP)}>
                <AppDialogDetailsSelector
                  isValidName={hasValidName}
                  title={title}
                  description={description}
                  bgColor={bgColor}
                  unmanaged={unmanaged}
                  onTitleChange={onTitleChange}
                  workbenchApplicationList={workbenchApplicationList}
                  workbenchApplicationInitial={workbenchApplication}
                  onUnmanagedChanged={onUnmanagedChanged}
                  onConfirm={onCompleteDetails}
                  tenantType={tenantType}
                />
              </Collapse>
              <Collapse in={isStepExpanded(APP_AUTH_STEP)}>
                <CardSelector
                  title="Select type of authentication"
                  items={authProviders}
                  selected={authProvider}
                  editable={!appId}
                  onSelect={value => {
                    setAuthProvider(value);
                    handleComplete(
                      value === NO_AUTH
                        ? CONFIGURATIONS_STEP
                        : APP_SECURITY_GROUPS_STEP
                    );
                  }}
                  onConfirm={() => {
                    handleComplete(
                      authProvider === NO_AUTH
                        ? CONFIGURATIONS_STEP
                        : APP_SECURITY_GROUPS_STEP
                    );
                  }}
                />
              </Collapse>
              <Collapse in={isStepExpanded(APP_TOKEN_STORE)}>
                <CardSelector
                  title="Select where to store token"
                  items={storeTokenOptions}
                  selected={storeToken}
                  editable={!appId}
                  onSelect={value => {
                    setStoreToken(value);
                    handleComplete(CONFIGURATIONS_STEP);
                  }}
                  onConfirm={() => {
                    handleComplete(CONFIGURATIONS_STEP);
                  }}
                />
              </Collapse>
              <SecurityGroupsCollapse />
              <Collapse in={isStepExpanded(CONFIGURATIONS_STEP)}>
                <AppDialogConfigOptions
                  externalConfigUrl={externalConfigUrl}
                  setExternalConfigUrl={setExternalConfigUrl}
                  externalConfig={externalConfig}
                  setExternalConfig={setExternalConfig}
                  onNext={() => {
                    handleComplete(APP_THEME_STEP);
                  }}
                />
              </Collapse>
              <Collapse in={isStepExpanded(APP_THEME_STEP)}>
                <CardSelector
                  title="Select theme"
                  items={listTheme}
                  selected={theming}
                  editable={!appId}
                  onSelect={value => {
                    setTheming(value);
                    handleComplete(APP_TAGS_STEP);
                  }}
                  onConfirm={() => handleComplete(APP_TAGS_STEP)}
                />
              </Collapse>
              <Collapse in={isStepExpanded(APP_TAGS_STEP)} data-testid="tags_container">
                <FreeTextSelector
                  name="tags"
                  label="Tags"
                  placeholder="Tag..."
                  title="Add Tags (Optional)"
                  values={tags}
                  options={Object.values(suggestedTags)}
                  onChange={(e, value) => setTags(value)}
                  onComplete={() => handleComplete(APP_ASSETS_STEP)}
                  optional
                 
                />
              </Collapse>
              <Collapse
                in={
                  isStepExpanded(APP_ASSETS_STEP) ||
                  isStepExpanded(APP_FINAL_STEP)
                }
              >
                <Grid container spacing={3}>
                  <AppDialogFilesSelector
                    iconBase64={iconBase64}
                    imageBase64={imageBase64}
                    onIconSelected={value => setIconBase64(value)}
                    onImageSelected={value => setImageBase64(value)}
                  />
                  <Grid item xs={12}>
                    <Grid container spacing={3} justify="flex-end">
                      <Grid item xs="auto">
                        <FinalButton />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
  };
};

const mapDispatchToProps = dispatch => ({
  createApp: payload => dispatch(createApplication(payload)),
  validateName: payload => dispatch(validateAppName(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppsDialogEdit);
