/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { cloneDeep } from "lodash";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
  Menu,
  MenuItem,
  Fade,
} from "@material-ui/core";
import VfDialogConfirm from "../vf/VfDialogConfirm";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiCardActions-root": {
      padding: 0,
    },
  },
});

const CardListItem = ({
  item,
  dictionary,
  setTheId,
  setSidebarItem,
  setDialogFlag,
  handleRemoveItem,
  sidebarItem,
  isLogicApiPckSnipSelected,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const id =
    sidebarItem === "apiList" || sidebarItem === "packagesList"
      ? item._id
      : item.id;
  const isPackage = sidebarItem === "packagesList";

  return (
    <Card
      elevation={0}
      className={classNames(classes.card, {
        "MuiCard-selection": true,
        "MuiCard-outlined": true,
        "MuiCard-white": true,
        "MuiCard-rounded": true,
        "MuiCard-selected": isLogicApiPckSnipSelected(id),
      })}
    >
      <CardActionArea onClick={() => setTheId(id)}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Typography variant="body1" component="h5">
                {item.name}
              </Typography>
            </Grid>
            {isPackage && (
              <Grid item xs="auto">
                <Typography variant="body1" component="span" color="secondary">
                  {`v${item?.version}`}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
          <VfSvgIcon icon="more" viewBox={24} />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={0}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          TransitionComponent={Fade}
        >
          {dictionary.edit && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setTheId(id);
                setDialogFlag(true);
              }}
            >
              {dictionary.edit}
            </MenuItem>
          )}
          {dictionary.design && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setTheId(id);
                setSidebarItem();
              }}
            >
              {dictionary.design}
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setTheId(id);
              setDialogConfirm(true);
            }}
          >
            {dictionary.delete}
          </MenuItem>
        </Menu>
        <VfDialogConfirm
          openDialog={dialogConfirm}
          maxWidth="xs"
          buttonCancelText="Cancel"
          buttonCancelAction={() => setDialogConfirm(false)}
          buttonConfirmText="Delete"
          buttonConfirmAction={() => {
            setDialogConfirm(false);
            handleRemoveItem(id);
          }}
        >
          <Typography variant="body1" align="center">
            Are you sure you want to delete
            <br />
            <strong>{item.name}</strong>
            <br />
            permanently?
          </Typography>
        </VfDialogConfirm>
      </CardActions>
    </Card>
  );
};

export default CardListItem;
