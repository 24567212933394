/* eslint-disable react/jsx-fragments */
/* eslint-disable camelcase */
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";

import HasPermission from "components/has-permissions/HasPermissions";
import theme from "AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  card: {
    height: "100%",
    border: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
    "& .MuiButtonBase-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      transition: theme.transitions.create(["background-color"], {
        duration: theme.transitions.duration.complex,
      }),
      "& .MuiCardActionArea-focusHighlight": {
        backgroundColor: theme.palette.grey[100],
      },
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
        "& .MuiSvgIcon-root": {
          top: -theme.spacing(2),
        },
        "& .MuiTypography-root": {
          bottom: -theme.spacing(2),
        },
      },
    },
    "& .MuiSvgIcon-root": {
      width: theme.spacing(12),
      height: theme.spacing(12),
      position: "relative",
      top: 0,
      transition: theme.transitions.create(["top"], {
        duration: theme.transitions.duration.complex,
      }),
    },
    "& .MuiTypography-root": {
      marginTop: theme.spacing(1.2),
      position: "relative",
      bottom: 0,
      transition: theme.transitions.create(["bottom"], {
        duration: theme.transitions.duration.complex,
      }),
    },
  },
  cardActionsArea: {},
});

const CardLanding = ({
  permissionKey,
  getApplications,
  title,
  icon,
  historyUrl,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card className={classes.card} elevation={0}>
      <HasPermission permissionKey={permissionKey} disabled>
        <CardActionArea
          className={classes.cardActionsArea}
          onClick={() => {
            history.push(historyUrl);
            getApplications();
          }}
        >
          <VfSvgIcon icon={icon} viewBox={56} />
          <Typography variant="h4" component="h4">
            {title}
          </Typography>
        </CardActionArea>
      </HasPermission>
    </Card>
  );
};

export default CardLanding;
