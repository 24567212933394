import { all } from "redux-saga/effects";

import settingsSaga from "./settingsSaga";
import configFormSaga from "./configFormSaga";
import historySaga from "./historySaga";
import translationsSaga from "./translationsSaga";
import envsSaga from "./envsSaga";
import nexusWidgetsSaga from "./nexusWidgetsSaga";
import applicationsSaga from "./applicationsSaga";
import rolesSaga from "./rolesSaga";
import permissionsSaga from "./permissionsSaga";
import mediaSaga from "./mediaSaga";
import tenantSaga from "./tenantSaga";
import ConfigSaga from "./configurationSaga";
import ApisSaga from "./apisSaga";
import PackagesSaga from "./packagesSaga";
import compareVersionsSaga from "./compareVersions";
import spainIntegrationSaga from "./spainIntegrationSaga";

export default function* rootSaga() {
  yield all([
    ...settingsSaga,
    ...configFormSaga,
    ...historySaga,
    ...translationsSaga,
    ...envsSaga,
    ...nexusWidgetsSaga,
    ...applicationsSaga,
    ...rolesSaga,
    ...permissionsSaga,
    ...mediaSaga,
    ...tenantSaga,
    ...ConfigSaga,
    ...ApisSaga,
    ...PackagesSaga,
    ...compareVersionsSaga,
    ...spainIntegrationSaga,
  ]);
}
