/* eslint-disable camelcase */
import { Box, Container, Grid, Typography } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroller";
import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchBar from "../searchbar/SearchBar";
import CardUpload from "./CardUpload";
import CardMedia from "./CardMedia";
import Loader from "../loader/Loader";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  box: {
    overflowX: "hidden",
    overflowY: "scroll",
    marginRight: "-1em",
  },
});

const MediaBox = ({
  getFilterMedia,
  access_token,
  id_token,
  limit,
  filters,
  loading,
  getMedia,
  page,
  hasMore,
  bulkSelect,
  postMedia,
  media,
  filterTypes,
  bulkItems,
  handleBulkItems,
  putMedia,
  eraseMedia,
  scrollLoading,
  allwaysOpen,
  hasFocus,
  searchText,
  setSearchText,
  inModal = false,
  selected,
  setSelected,
  maximumRequestBodySize,
  uploadMediaError
}) => {
  const classes = useStyles();
  const refItem = useRef();
  const [itemHeight, setItemHeight] = useState(0);
  useEffect(() => {
    let refWidth = refItem.current ? refItem.current.offsetWidth : 0;
    const items = inModal ? 3 : 4;
    refWidth = ((refWidth - 48) / items) * 0.56 + 24;
    console.log("width", refWidth);
    setItemHeight(refWidth);
  }, [refItem.current]);
  return (
    <Box pt={inModal ? 0 : 6} pb={inModal ? 0 : 4}>
      <Container ref={refItem} maxWidth="md" disableGutters={inModal}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box py={0.25} className="MuiBox-searchOverlay" position="relative">
              <SearchBar
                callback={getFilterMedia}
                callbackParams={{
                  access_token,
                  id_token,
                  page: 0,
                  limit,
                  filters,
                }}
                allwaysOpen={allwaysOpen}
                hasFocus={hasFocus}
                setSearchText={setSearchText}
                btnId="searchMedia_icon"
                searchbarId="searchMediaFeild"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {!loading ? (
              <Box
                // TO BE FIXED!!!
                className={classes.box}
                style={loading ? {} : { height: 2.5 * itemHeight }}
              >
                <InfiniteScroll
                  className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2"
                  useWindow={false}
                  pageStart={0}
                  initialLoad={false}
                  loadMore={() => {
                    getMedia({
                      access_token,
                      id_token,
                      page,
                      limit,
                      filters,
                      searchText,
                    });
                  }}
                  hasMore={hasMore}
                  threshold={10}
                >
                  {!bulkSelect && (
                    <Grid item xs={6} sm={4} md={inModal ? 4 : 3}>
                      <CardUpload
                        uploadMediaError={uploadMediaError}
                        media={media}
                        postMedia={postMedia}
                        filters={filters}
                        access_token={access_token}
                        id_token={id_token}
                        searchText={searchText}
                      />
                    </Grid>
                  )}
                  {media.map(item => {
                    return (
                      <Grid
                        key={item.key}
                        item
                        xs={6}
                        sm={4}
                        md={inModal ? 4 : 3}
                      >
                        <CardMedia
                          item={item}
                          filterTypes={filterTypes}
                          isBulkSelected={bulkSelect}
                          isForBulkSelect={inModal || bulkSelect}
                          isSelected={
                            inModal
                              ? item.key === selected.key
                              : bulkItems.length &&
                                bulkItems.some(
                                  bulkItem => bulkItem.title === item.title
                                )
                          }
                          inModal={inModal}
                          setSelected={setSelected}
                          clickSelected={handleBulkItems}
                          putMedia={putMedia}
                          eraseMedia={eraseMedia}
                          filters={filters}
                          access_token={access_token}
                          id_token={id_token}
                          searchText={searchText}
                          data-testid="card_media"
                        />
                      </Grid>
                    );
                  })}
                </InfiniteScroll>
                {scrollLoading && (
                  <Loader size={theme.spacing(6)} height="120px" />
                )}
                <Typography variant="body2" color="secondary">
                  {`*The maximum size for file is ${maximumRequestBodySize.toUpperCase()}`}
                </Typography>
              </Box>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Loader
                    size={theme.spacing(12)}
                    height={inModal ? "25vh" : false}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MediaBox;
