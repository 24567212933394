/* eslint-disable camelcase */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ReactDiffViewer from "react-diff-viewer";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiExpansionPanel-root": {
      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
      "&.Mui-expanded": {
        margin: 0,
      },
      "&:before": {
        left: 8,
        top: 0,
        bottom: 0,
        right: "auto",
        backgroundColor: theme.palette.grey[200],
        opacity: 1,
        width: 1,
        height: "100%",
        display: "block !important",
        transform: "translateX(-.5px)",
      },
    },
    "& .MuiExpansionPanelSummary-root": {
      padding: 0,
      "&.Mui-disabled": {
        opacity: 1,
        "& .MuiExpansionPanelSummary-expandIcon": {
          opacity: 0,
        },
      },
    },
    "& .MuiExpansionPanelSummary-content": {
      alignItems: "center",
    },
    "& .MuiExpansionPanelDetails-root": {
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(1),
      paddingRight: 0,
      paddingBottom: theme.spacing(1),
    },
    "& .MuiSvgIcon-root": {
      display: "block",
    },
  },
  name: {
    fontWeight: "bold",
    flexGrow: 0,
    flexShrink: 0,
    marginRight: theme.spacing(1.5),
  },
  status: {
    flexGrow: 0,
    flexShrink: 0,
    marginRight: theme.spacing(1.5),
    color: theme.palette.grey[600],
  },
  date: {
    flexGrow: 0,
    flexShrink: 0,
    marginLeft: "auto",
    color: theme.palette.grey[600],
  },
  environment: {
    color: theme.palette.grey[600],
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1.5),
  },
  dif: {
    maxHeight: "50vh",
    overflowY: "auto",
    width: "100%",
  },
  bullet: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(3),
    borderRadius: theme.spacing(1),
    borderWidth: 4,
    borderStyle: "solid",
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.grey["200"],
    flexGrow: 0,
    flexShrink: 0,
  },
}));

const WidgetsHistory = ({ history }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-extra-boolean-cast
  return !!Object.keys(history).length ? (
    <Box className={classes.root}>
      {history.records.map(
        ({
          data_before: dataBefore,
          data_after: dataAfter,
          _id,
          save_type,
          user,
          date,
        }) => {
          const fDate = new Date(date);
          return (
            save_type !== "save" && (
              <ExpansionPanel
                key={_id}
                disabled={save_type === "promote"}
                elevation={0}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <Box className={classes.bullet} />
                  <Avatar
                    className={classes.avatar}
                    alt="Cindy Baker"
                    src="/assets/images/avatar.png"
                  />
                  <Typography
                    className={classes.name}
                    component="div"
                    variant="body1"
                  >
                    {user.name}
                  </Typography>
                  {save_type === "deploy" && (
                    <Typography
                      className={classes.status}
                      component="div"
                      variant="body1"
                    >
                      updated widget:
                    </Typography>
                  )}

                  {save_type === "promote" && (
                    <Typography
                      className={classes.status}
                      component="div"
                      variant="body1"
                    >
                      promoted widget:
                    </Typography>
                  )}
                  {save_type === "promote" && (
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <VfSvgIcon icon="dev" viewBox={56} />
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classes.environment}
                          component="div"
                          variant="body2"
                        >
                          Development
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ArrowForwardIcon fontSize="small" />
                      </Grid>
                      <Grid item>
                        <VfSvgIcon icon="prod" viewBox={56} />
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classes.environment}
                          component="div"
                          variant="body2"
                        >
                          Testing
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <Typography
                    className={classes.date}
                    component="div"
                    variant="body2"
                  >
                    {fDate.toLocaleString()}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Box className={classes.dif}>
                    <ReactDiffViewer
                      oldValue={JSON.stringify(dataBefore, null, 1)}
                      newValue={JSON.stringify(dataAfter, null, 1)}
                      leftTitle="Before update"
                      rightTitle="After update"
                      showDiffOnly
                      extraLinesSurroundingDiff={0}
                      splitView
                    />
                  </Box>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          );
        }
      )}
    </Box>
  ) : null;
};

const mapStateToProps = state => {
  return {
    history: state.configHistory.history,
  };
};

export default connect(mapStateToProps, null)(WidgetsHistory);
