import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import VfSvgIcon from "../../icons/VfSvgIcon";

const MenuIconButton = ({ items, disabled, onClick, onClose, className }) => {
  const attachTo = useRef(null);

  const [open, setOpen] = useState(false);
  const [selected] = useState(items[0]);

  useEffect(() => {
    setOpen(false);
  }, [selected]);

  return (
    <>
      <IconButton
        ref={attachTo}
        title="More actions"
        size="small"
        className={className || ""}
        onClick={() => setOpen(true)}
        disabled={disabled}
      >
        <VfSvgIcon icon="more" viewBox={24} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={attachTo.current}
        keepMounted
        open={open}
        onClose={() => {
          setOpen(false);
          onClose();
        }}
      >
        {items.map(item => (
          <MenuItem
            key={item.id}
            onClick={() => {
              setOpen(false);
              onClick(item);
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuIconButton;
