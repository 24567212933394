/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import { cloneDeep } from "lodash";
import theme from "AppTheme";
import {
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Popover,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@material-ui/core";
import uuidv4 from "uuid/v4";
import {
  setShowAddTemplate as setShowAddTemplateAction,
  setCurrentPage as setCurrentPageAction,
  showSideBar,
  savePage,
} from "actions/applicationsActions";
import store from "../../store/configureStore";

import { render } from "renderer";
import CardWidgetPopover from "./CardWidgetPopover";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfDialog from "../vf/VfDialog";




const useStyles = makeStyles({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  listItemAction: {
    right: theme.spacing(3),
    top: theme.spacing(2),
    transform: "none",
  },
});

const CardWidgetVersion = ({
  appInfo,
  setApplication,
  widget,
  setWidget,
  selected,
  moreActions,
  handleMoreInfoClick,
  clearWidgetDetails,
  widgetFromTranslation,
  handlePromoteClick,
  sourceEnvId,
  envs,
  setCurrentAppPage,
  application,
  auth,
  environment,
  dispatchSavePage,
}) => {
  const {currentPage} = application;
  const classes = useStyles();
  const [openDialogNotes, setOpenDialogNotes] = useState(false);
  const componentId = 1;
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickMenu = event => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleOpenDialogNotes = event => {
    if (!appInfo && openDialogNotes) clearWidgetDetails();
    setOpenDialogNotes(!openDialogNotes);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!appInfo && (anchorEl || openDialogNotes))
      handleMoreInfoClick(widgetFromTranslation._id);
  }, [anchorEl, openDialogNotes]);

  const handleClose = () => {
    if (!appInfo) clearWidgetDetails();
    setAnchorEl(null);
  };
  const { access_token, id_token } = auth;

  const open = Boolean(anchorEl);
 

  const getChangedPages = changedPages => {
    return (
      Object.values(changedPages)
        .filter(page => !!page)
        .map(page => ({
          ...page.pageInfo,
          _id:
            page.pageInfo._id.indexOf("-") >= 0 ? undefined : page.pageInfo._id,
          data: page.djr,
        }))
    );
  };

const setWidgetVersionToApplication = async () => {
 
  // Clone the appInfo object
  const clonedAppInfo = await cloneDeep(appInfo);

  // Find the index of the widget with the matching name
  const found = clonedAppInfo.widgets.findIndex(w => w.name === widget.name);
  if (found === -1) return;

  // Update the _id and version of the matched widget
  clonedAppInfo.widgets[found]._id = widget._id;
  clonedAppInfo.widgets[found].version = widget.version;

  // Create an array of instances with old and new UUIDs
  const instancesObj = clonedAppInfo.widgets[found].instances.map(inst => ({
    oldInstanceId: inst.uuid,
    newInstanceId: uuidv4(),
  }));

  // Generate new instances with new UUIDs
  const newInstances = clonedAppInfo.widgets[found].instances.map(inst => ({
    ...inst,
    uuid: uuidv4(),
  }));

  // Update the instances array in the clonedAppInfo
  clonedAppInfo.widgets[found].instances = newInstances;

  // Update the corresponding new instance UUIDs in instancesObj
  instancesObj.forEach((instance, index) => {
    instance.newInstanceId = newInstances[index].uuid;
  });


  const OldPageswithOldInstance = getChangedPages(application.changedPages)
  // Update the pages with the new instance UUIDs
  const newPagesWithNewInstances = OldPageswithOldInstance.map(async page => {
    const updatedPage = { ...page };

    const traverse = async obj => {
      if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
          if (key === '__instanceId') {
            const matchingInstance = instancesObj.find(inst => inst.oldInstanceId === obj[key]);
            if (matchingInstance) {
              obj[key] = matchingInstance.newInstanceId;
            }
          } else {
            await traverse(obj[key]);
          }
        }
      }
    };

    await traverse(updatedPage.data);
    return updatedPage;
  });

  // Wait for all updated pages
  const updatedPages = await Promise.all(newPagesWithNewInstances);

  // Update the application with the clonedAppInfo
  setApplication(clonedAppInfo);

  // Dispatch the updated pages to be saved
  await dispatchSavePage({
    access_token,
    id_token,
    pages: updatedPages,
    application: clonedAppInfo, // Use the clonedAppInfo here instead of application.appInfo
    envId: environment.id,
  });
};
   


  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-primary": selected,
        "Mui-selected": selected,
        "MuiCard-widgetList": true,
        "MuiCard-rounded": true,
        "MuiCard-shadowHover": true,
      })}
    >
      <CardActionArea
        onClick={() => {
          if (setWidget) {
            setWidget(widget);
          }
          if (appInfo) {
            setWidgetVersionToApplication();
          }
          if (setCurrentAppPage) {
            setCurrentAppPage(currentPage);
          }
        }}
      >
        <CardContent data-testid="widget_version">
          <Typography variant="body2" color="secondary">
            {`v${appInfo ?widget.version: widgetFromTranslation.version}`}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {widget.published}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing>
        <IconButton title="View more info" size="small" onClick={handleClick} data-testid="info_btn">
          <VfSvgIcon icon="info" viewBox={24} />
        </IconButton>
        {moreActions ? (
          <>
            <IconButton
              title="View readme file"
              size="small"
              onClick={handleClickMenu}
              data-testid="viewMore_btn"
            >
              <VfSvgIcon icon="more" />
            </IconButton>
            <Menu
              id="simple-menu"
              elevation={0}
              anchorEl={anchorElMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(anchorElMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                onClick={() => {
                  handleOpenDialogNotes();
                  handleCloseMenu();
                }}
                data-testid="seeNotes_btn"
              >
                See notes
              </MenuItem>
              {envs
                .filter(({ _id, isGlobal }) => !isGlobal && _id !== sourceEnvId)
                .sort((a, b) => (a.order < b.order ? -1 : 1))
                .map(({ title, _id }) => (
                  <MenuItem
                    key={title}
                    onClick={() => {
                      handlePromoteClick(
                        widgetFromTranslation._id,
                        sourceEnvId,
                        _id
                      );
                      handleCloseMenu();
                    }}
                    className={`MuiListItem-${title.toLowerCase()}`}
                    data-testid={`Promoteto_${title}_btn`}
                  >
                    <ListItemText secondary={`Promote to ${title}`} />
                  </MenuItem>
                ))}
            </Menu>
          </>
        ) : (
          <IconButton
            title="View readme file"
            size="small"
            onClick={() => {
              handleOpenDialogNotes();
            }}
          >
            <VfSvgIcon icon="readme" />
          </IconButton>
        )}
      </CardActions>
      {widget && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <CardWidgetPopover widget={widget} showImage />
        </Popover>
      )}
      {widget && (
        <VfDialog
          openDialog={openDialogNotes}
          buttonCloseAction={handleOpenDialogNotes}
          title="Readme file"
          maxWidth="md"
          classDialogContent="MuiDialogContent-git"
        >
          <ReactMarkdown source={widget.readme} />
        </VfDialog>
      )}
    </Card>
  );
};


const mapStateToProps = state => {
  return {
    application: state.applications.current,
    auth: state.authentication,
    environment: state.applications.env,
  };
};

const mapDispatchToProps = dispatch => ({
 
  dispatchSavePage: payload => dispatch(savePage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardWidgetVersion);
