/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
/* eslint-disable-next-line react/no-array-index-key */
import React, { useState } from "react";

import {
  CardActions,
  IconButton,
  Menu,
  ListItemText,
  MenuItem,
  Link,
  Typography,
  Grid,
  Divider,
  DialogContent,
  Dialog,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import tenantTypes from "enums/tenantTypes";
import VfSvgIcon from "../../icons/VfSvgIcon";
import DeleteAppMenuItem from "./DeleteMenuItem";
import NotesMenuItem from "./NotesMenuItem";
import UndeployAppMenuItem from "./UndeployMenuItem";
import DeployAppMenuItem from "./Deployment/DeployMenuItem";
import VisitAppMenuItem from "./VisitAppMenuItem";
import JenkinsConsoleMenuItem from "./JenkinsConsoleMenuItem";
import SecurityGroupsMenuItem from "./SecurityGroups/SecurityGroupsMenuItem";
import CompareVersionsMenuItem from "../CompareVersions/CompareVersions";
import WorkbenchDevMenuItem from "./WorkbenchDevMenuItem";
import theme from "../../../AppTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppDialogFullHeader from "../common/AppDialogFullHeader";
import FilterMenuEnvironments from "../../filters/FilterMenuEnvironments";
import axios from "axios";
import getCookie from "../../../utils/getCookie";
import { showSnackbar } from "actions/applicationsActions";

const useStyles = makeStyles({
  modalContent: {
    minHeight: "50vh",
  },
  alignRight: {
    textAlign: "right",
  },
  buttonText: {
    backgroundColor: "none",
    fontSize: theme.typography.caption.fontSize,
  },
  gridDividerExpand: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    alignSelf: "stretch",
  },
});

const AppActionMenu = ({
  app,
  env,
  environments,
  tenantType,
  auth,
  envType,
  defaultCoreAppJob,
  resetSearchForm,
  apps,
  showSnackBar,
}) => {
  const styles = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [targetEnvToClone, setTargetEnvToClone] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [originEnv, setOriginEnv] = useState(null);
  const [isCloneModalOpened, setIsCloneModalOpened] = useState(false);
  const onMenuPressed = (anchor, originEnvironment) => {
    setAnchorEl(anchor);
    setOriginEnv(originEnvironment);
  };
  const onMenuItemPressed = () => {
    setAnchorEl(null);
  };
  const stateEnvironments =
    tenantType === tenantTypes.SPAIN
      ? environments.filter(item => item.type === "dev" || item.type === "global")
      : environments;

  const coreAppJob = stateEnvironments.find(e => e._id === env.id)?.deploymentInformation?.coreAppJob;

  const cloneConfigHandler = async () => {
    try {
      await axios({
        url: `/api/appbuilder/configuration/cloneConfigurations/${env.id}/${targetEnvToClone}/${app.application}`,
        headers: {
          "content-type": "application/json",
          "x-csrf-token": getCookie("x-csrf-token"),
          dbname: "",
        },
      });
      setIsCloneModalOpened(false);
      setTargetEnvToClone("");
      showSnackBar({
        show: true,
        message: "Configurations cloned successfully",
        severity: 3,
      });
    } catch (e) {
      console.log(e);
      showSnackBar({
        show: true,
        message: "Something went wrong",
        severity: 0,
      });
    }
  };

  return (
    <Grid item xs="auto">
      <CardActions disableSpacing>
        <IconButton
          aria-label="add to favorites"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={e => onMenuPressed(e.target, env)}
          dtat-testid="AppVersion_ActionMenuBtn"
        >
          <VfSvgIcon icon="more" viewBox={24} />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={0}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onMenuItemPressed}
          data-testid={anchorEl === null ? "closedMenuActionParent" : "openeddMenuActionParent"}
        >
          {env &&
            !env.isGlobal &&
            (app.isDeployedToKubernetes || app.isDeployedToS3) &&
            app.status === "Deployed" &&
            tenantType !== tenantTypes.SPAIN && [
              <VisitAppMenuItem
                key={`app-actions-menu-list-${app._id}`}
                application={app}
                environment={env}
                onClick={onMenuItemPressed}
                tenantType={tenantType}
                envType={envType}
              />,
              <Divider key={`app-actions-menu-divider-${app._id}`} />,
            ]}
          {tenantType === tenantTypes.SPAIN &&
            app.status === "Deployed" && [
              <MenuItem data-testid="openDeployedApp_btn">
                <Typography variant="body2" color="secondary" onClick={onMenuItemPressed}>
                  <Link href={app.workbenchUrl} rel="noreferrer" target="_blank" variant="body2" color="secondary">
                    {`Open ${app.title}`}
                  </Link>
                </Typography>
              </MenuItem>,
              envType === "dev" && (
                <WorkbenchDevMenuItem application={app} environment={env} auth={auth} onClick={onMenuItemPressed} />
              ),
              <Divider key={`app-actions-menu-divider-${app._id}`} />,
            ]}
          <NotesMenuItem onClick={onMenuItemPressed} />
          {env && env.isGlobal && <CompareVersionsMenuItem onClick={onMenuItemPressed} app={app} />}
          {env && env.isGlobal && (
            <SecurityGroupsMenuItem application={app} environment={env} onClick={onMenuItemPressed} />
          )}
          {env &&
            env.isGlobal &&
            stateEnvironments
              .filter(environment => !environment.isGlobal)
              .sort((a, b) => a.order - b.order)
              .map(environment => {
                return (
                  <DeployAppMenuItem
                    key={`global-${app._id}-${environment._id}`}
                    application={app}
                    environment={environment}
                    originEnvironment={originEnv}
                    onPress={onMenuItemPressed}
                  />
                );
              })}
          {env &&
            !env.isGlobal &&
            stateEnvironments
              .filter(
                environment =>
                  !environment.isGlobal &&
                  environment._id !== env._id &&
                  environment.order > env.order &&
                  app.status === "Deployed"
              )
              .sort((a, b) => a.order - b.order)
              .map(environment => {
                return (
                  <DeployAppMenuItem
                    key={`deployed-${app._id}-${environment._id}`}
                    application={app}
                    environment={environment}
                    originEnvironment={originEnv}
                    onPress={onMenuItemPressed}
                  />
                );
              })}

          {env && !env.isGlobal && app.buildNumber && (
            <MenuItem
              className={`MuiListItem-${env.title.toLowerCase()}`}
              onClick={() => {
                onMenuItemPressed();
                setIsCloneModalOpened(true);
                setTargetEnvToClone("");
              }}
              data-testid={`PromoteTo${env.title}_btn`}
            >
              <ListItemText secondary={`Clone configs`} />
            </MenuItem>
          )}

          {isCloneModalOpened ? (
            <Dialog fullScreen={fullScreen} open={isCloneModalOpened} maxWidth="md">
              <AppDialogFullHeader
                application={app}
                includeLogo
                buttons={
                  <Grid item xs="auto">
                    <Button
                      autoFocus
                      color="default"
                      variant="outlined"
                      onClick={() => {
                        setIsCloneModalOpened(false);
                        setTargetEnvToClone("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                }
              >
                <FilterMenuEnvironments
                  envs={{ list: [env] }}
                  activeEnv={env ? env.title : ""}
                  clearEnvironment={() => {}}
                  setEnvironment={() => {}}
                  skipAllOption
                  isDisabled
                />
              </AppDialogFullHeader>
              <DialogContent className={styles.modalContent}>
                <Grid item xs={12}>
                  <TextField
                    select
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                      },
                    }}
                    onChange={e => {
                      setTargetEnvToClone(e.target.value);
                    }}
                    label="Select Environment to clone from"
                    value={targetEnvToClone}
                    variant="filled"
                    color="secondary"
                    fullWidth
                  >
                    {env &&
                      !env.isGlobal &&
                      apps?.list
                        .filter(environment => {
                          const envName = Object.keys(environment)[0];
                          if (!environment[envName].isGlobal && environment[envName].id !== env.id) {
                            return environment[envName].data.some(applicationData => {
                              return applicationData.application === app.application;
                            });
                          }
                          return false;
                        })
                        .map(environment => {
                          const envName = Object.keys(environment)[0];
                          return environment[envName];
                        })
                        .sort((a, b) => a.order - b.order)
                        .map(environment => {
                          return (
                            <MenuItem key={environment.id} value={environment.id}>
                              {environment.title}
                            </MenuItem>
                          );
                        })}
                  </TextField>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    autoFocus
                    color="secondary"
                    variant="contained"
                    disabled={!targetEnvToClone}
                    onClick={cloneConfigHandler}
                    style={{ marginTop: 20 }}
                  >
                    Clone
                  </Button>
                </Grid>
              </DialogContent>
            </Dialog>
          ) : null}

          {env &&
            !env.isGlobal &&
            app.buildNumber && [
              <JenkinsConsoleMenuItem
                key={`jenkins-console-menu-list-${app._id}`}
                application={app}
                coreAppJob={coreAppJob || defaultCoreAppJob}
                onClick={onMenuItemPressed}
              />,
              <Divider key={`jenkins-console-actions-menu-divider-${app._id}`} />,
            ]}

          {env && env.isGlobal && (
            <DeleteAppMenuItem application={app} onClick={onMenuItemPressed} resetSearchForm={resetSearchForm} />
          )}

          {env && !env.isGlobal && app.status !== "Pending" && app.status !== "Building" && (
            <UndeployAppMenuItem env={env} app={app} coreAppJob={coreAppJob} onClick={onMenuItemPressed} />
          )}
        </Menu>
      </CardActions>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    environments: state.envs.list || [],
    tenantType: state.tenants?.current?.type,
    auth: state.authentication,
    defaultCoreAppJob: state.envs.defaultCoreAppJob || "master",
    apps: state.applications,
  };
};

const mapDispatchToProps = dispatch => ({
  showSnackBar: payload => dispatch(showSnackbar(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppActionMenu);
