/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { withTheme } from "react-jsonschema-form";
import { cloneDeep } from "lodash";

import schemaToTree from "utils/schema-to-tree";
import FormTheme from "components/custom-mui-components/FormTheme";
import createInputForRole from "components/input/customInputForPropertyRole";

const jp = require("jsonpath");

const Form = withTheme(FormTheme);

const AddItemForm = ({
  newItemSchema,
  newItemData,
  configForm,
  widget,
  updateConfigData,
  setConfigTree,
  setAddItem,
  currentTenant,
  isTenant,
}) => {
  const scalarSchema = cloneDeep(newItemSchema);
  if (scalarSchema.properties) {
    Object.entries(scalarSchema.properties).forEach(entry => {
      const [key, value] = entry;
      if (value.type === "array" || value.type === "object")
        delete scalarSchema.properties[key];
    });
  }

  const uiSchemaEntries = Object.entries(scalarSchema.properties)
    // eslint-disable-next-line no-unused-vars,no-underscore-dangle
    .filter(([_, prop]) => prop.type === "string" && prop.__role)
    .map(([field, prop]) => [
      field,
      {
        // eslint-disable-next-line no-underscore-dangle
        "ui:widget": createInputForRole(prop.__role),
      },
    ]);
  const uiSchema = Object.fromEntries(uiSchemaEntries);

  if (
    isTenant &&
    configForm.selectedTreeItem.id.includes("environments") &&
    configForm.selectedTreeItem.id.match(/\./g)?.length === 1
  ) {
    const items = jp.query(configForm.data, configForm.selectedTreeItem.id)[0];
    const types = items.map(({ type }) => type);
    const difference = scalarSchema.properties.type.enum.filter(
      x => !types.includes(x)
    );
    scalarSchema.properties.type.enum = difference;
    if (currentTenant) scalarSchema.properties.type.readOnly = false;
  }

  const addMissingProperties = (obj, path, val) => {
    const keys = path.split(".");
    const lastKey = keys.pop();
    // eslint-disable-next-line no-shadow
    const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
    lastObj[lastKey] = val;
  };

  return (
    <Form
      schema={scalarSchema}
      uiSchema={uiSchema}
      formData={newItemData}
      showErrorList={false}
      liveValidate
      onSubmit={formData => {
        const { schema } = configForm;
        const cloneData = cloneDeep(configForm.data);
        if (!jp.query(cloneData, configForm.selectedTreeItem.id).length) {
          addMissingProperties(
            cloneData,
            configForm.selectedTreeItem.id.substring(2),
            []
          );
        }
        jp.apply(cloneData, configForm.selectedTreeItem.id, value => {
          return [...value, formData.formData];
        });
        updateConfigData(cloneData);
        const treeData = schemaToTree(widget, "$", schema, cloneData);
        treeData.name = configForm.tree.name;
        setConfigTree(treeData);
        setAddItem(false);
      }}
    >
      <Box pt={2}>
        <Grid container alignItems="center" justify="space-between" spacing={3}>
          <Grid item xs="auto">
            <Button variant="outlined" onClick={() => setAddItem(false)} data-testid="cancel_btn">
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button type="submit" variant="outlined" color="secondary" data-testid="saveItem_btn">
              Save Item
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};

export default AddItemForm;
