/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";

import {
  PACKAGES_REQUEST,
  PACKAGES_REQUEST_SUCCESS,
  PACKAGES_REQUEST_FAIL,
} from "actions/packagesActions";
import PackagesService from "services/PackagesService";

function* packagesWorker({ payload }) {
  try {
    const data = yield call(PackagesService.fetchPackages, payload);
    yield put({
      type: PACKAGES_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: PACKAGES_REQUEST_FAIL, error });
  }
}

function* packagesWatcher() {
  yield takeLatest(PACKAGES_REQUEST, packagesWorker);
}

const packagesSaga = [packagesWatcher()];

export default packagesSaga;
