/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import {
  setConfigTree as settingConfigTree,
  clearConfigTree as clearingConfigTree,
  clearConfigFormFieldSet,
} from "actions/configFormActions";
import { fetchTenantSchema, setCurrentTenant } from "actions/tenantActions";
import schemaToTree from "utils/schema-to-tree";
import EditorFormTree from "./EditorFormTree";
import EditorFormTabs from "./EditorFormTabs";

const theme = createMuiTheme();

const useStyles = makeStyles({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(18.5)}px)`,
  },
});

const EditorForm = ({
  isGlobal,
  configForm,
  currentWidget,
  setConfigTree,
  clearConfigTree,
  isTenant,
  getTenantSchema,
  clearConfigForm,
  setTenantData,
  currentTenant,
  auth,
}) => {  
  const classes = useStyles();
  const { schema, data, tree } = configForm;
  const { access_token, id_token } = auth;

  useEffect(() => {
    if (isTenant && (typeof schema === "undefined" || !schema)) {
      getTenantSchema({ access_token, id_token });
    }
    if (isTenant && (typeof data === "undefined" || !data)) {
      setTenantData({
        locale: {
          mediaCredentials: {},
        },
        environments: [],
      });
    }
    if (schema && data) {
      if (currentTenant) {
        schema.properties.locale.properties.code.readOnly = true;
        schema.properties.environments.items.properties.type.readOnly = true;
      }
      const treeData = schemaToTree(
        // eslint-disable-next-line no-nested-ternary
        isTenant
          ? "Tenant"
          : !currentWidget.componentId
          ? "global"
          : currentWidget.description,
        "$",
        schema,
        data
      );
      if (!tree) setConfigTree(treeData);
    }

    if (tree && (!schema || !data)) {
      clearConfigTree();
    }
  }, [configForm]);

  useEffect(() => {
    return () => {
      clearConfigForm();
    };
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={4} lg={3}>
        {tree && <EditorFormTree isTenant={isTenant} data={[tree]} />}
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <EditorFormTabs
          isGlobal={isGlobal}
          isTenant={isTenant}
          currentTenant={currentTenant}
          form={configForm}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    configForm: state.configForm,
    currentWidget: state.configuration.currentWidget,
  };
};

const mapDispatchToProps = dispatch => ({
  setConfigTree: data => dispatch(settingConfigTree(data)),
  clearConfigTree: () => dispatch(clearingConfigTree()),
  getTenantSchema: params => dispatch(fetchTenantSchema(params)),
  clearConfigForm: () => dispatch(clearConfigFormFieldSet()),
  setTenantData: params => dispatch(setCurrentTenant(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorForm);
