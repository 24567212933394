import React from "react";
import { Box } from "@material-ui/core";
import theme from "AppTheme";

const GridWidgetTemplateBuilder = ({ size = "sm" }) => {
  const height = size === "sm" ? theme.spacing(3) : theme.spacing(7);
  return (
    <>
      <Box
        className="MuiBox-placeholder"
        style={{
          width: "100%",
          height,
        }}
      />
    </>
  );
};

export default GridWidgetTemplateBuilder;
