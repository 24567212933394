/* eslint-disable camelcase */
/* eslint-disable no-useless-computed-key */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { fetchConfigListApp } from "actions/appsActions";
import { setListGridView } from "actions/listGridViewActions";
import SearchBar from "components/searchbar/SearchBar";
import DisplayType from "components/displaytype/DisplayType";
import ApplicationsItem from "./ApplicationsItem";
import ApplicationsItemGrid from "./ApplicationsItemGrid";
import "./Applications.scss";

const useStyles = makeStyles({
  root: {
    ["& .MuiBox-searchBar"]: {
      position: "absolute",
      left: 0,
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
});

const Applications = ({
  auth,
  apps,
  envID,
  getConfigListApp,
  setListGridViewApp,
  listGridView,
}) => {
  const { access_token, id_token } = auth;
  const { touchpoint } = useParams();
  const classes = useStyles();

  const handleChangeGrid = view => {
    setListGridViewApp({ appView: view });
  };

  useEffect(() => {
    getConfigListApp({ touchpoint, access_token, id_token, envID });
  }, [getConfigListApp, touchpoint, id_token, access_token]);

  return (
    <Box py={4} className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box py={0.25} className="MuiBox-searchOverlay" position="relative">
              <DisplayType
                grid={listGridView}
                handleGridView={handleChangeGrid}
              />
              <SearchBar
                callback={getConfigListApp}
                callbackParams={{
                  envID,
                  touchpoint,
                  access_token,
                  id_token,
                }}
              />
            </Box>
          </Grid>
          {apps.map(({ application, data, isSaved }) => {
            return listGridView === "grid" ? (
              <Grid key={application} item xs={12} sm={6} md={4}>
                <ApplicationsItemGrid
                  application={application}
                  data={data}
                  isSaved={isSaved}
                />
              </Grid>
            ) : (
              <Grid key={application} item xs={12}>
                <ApplicationsItem
                  application={application}
                  data={data}
                  isSaved={isSaved}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    apps: state.apps.list,
    envID: state.envs.id,
    listGridView: state.listGridView.appView,
  };
};

const mapDispatchToProps = dispatch => ({
  getConfigListApp: params => dispatch(fetchConfigListApp(params)),
  setListGridViewApp: payload => dispatch(setListGridView(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
