import React, { useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Typography,
} from "@material-ui/core";
import theme from "../../AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(4),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  dialogContent: ({ maxHeight }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      maxHeight: maxHeight || "50vh",
    },
  }),
  dialogActions: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(3),
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VfDialog = ({
  buttonConfirmAction,
  buttonConfirmText,
  buttonConfirmDisabled,
  buttonCloseAction,
  buttonSecondaryText,
  buttonSecondaryAction,
  buttonSecondaryDisabled,
  openDialog,
  classDialogTitle,
  classDialogContent,
  classDialogActions,
  maxWidth,
  children,
  title,
  maxHeight,
  scrollable = true,
  buttonConfirmColor = "secondary",
  testId
}) => {
  const classes = useStyles({ maxHeight });
  const [scroll] = useState("paper");
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      open={openDialog}
      maxWidth={maxWidth}
      onBackdropClick={buttonCloseAction}
      {...(scrollable ? { scroll } : {})}
    >
      {title && (
        <DialogTitle
          disableTypography
          className={classNames(classDialogTitle, classes.modalTitle)}
        >
          {typeof title === "string" ? (
            <>
              <Typography variant="h5" component="h3">
                {title}
              </Typography>
              <Divider
                className={classes.modalDivider}
                flexItem
                orientation="vertical"
              />
              <IconButton aria-label="close" onClick={buttonCloseAction} data-testid="close_media_btn">
                <VfSvgIcon icon="close" viewBox={24} />
              </IconButton>
            </>
          ) : (
            title
          )}
        </DialogTitle>
      )}
      <DialogContent
        className={classNames(classDialogContent, classes.dialogContent)}
      >
        {children}
      </DialogContent>
      {buttonConfirmAction && (
        <DialogActions
          className={classNames(classDialogActions, classes.dialogActions)}
        >
          {buttonSecondaryText && buttonSecondaryAction && (
            <Button
              disabled={buttonSecondaryDisabled}
              variant="outlined"
              onClick={() => {
                buttonSecondaryAction();
              }}
              data-testid="secondary_btn"
            >
              {buttonSecondaryText}
            </Button>
          )}
          <Button
            data-testid="confirm_btn"
            disabled={buttonConfirmDisabled}
            variant="contained"
            color={buttonConfirmColor}
            onClick={() => {
              buttonConfirmAction();
            }}
          >
            {buttonConfirmText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default VfDialog;
