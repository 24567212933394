import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import VfSvgIcon from "../../icons/VfSvgIcon";

const AppDialogFilesSelector = ({
  iconBase64,
  imageBase64,
  onIconSelected,
  onImageSelected,
}) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs="auto">
                  <Typography variant="body1">App icon</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography component="span" variant="body2">
                    Square (min 512x512px)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card
                elevation={0}
                className={classNames({
                  "MuiCard-dropzone": true,
                  "MuiCard-white": true,
                  "MuiCard-outlined": true,
                  "MuiCard-rounded": true,
                  // "MuiCard-disabled": dragItem,
                })}
              >
                <Dropzone
                  multiple={false}
                  // onDropAccepted={handleDragItem}
                  onDrop={acceptedFiles => {
                    acceptedFiles.forEach(file => {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onabort = () =>
                        console.log("file reading was aborted");
                      reader.onerror = () =>
                        console.log("file reading has failed");
                      reader.onload = () => {
                        // Do whatever you want with the file contents
                        const binaryStr = reader.result;
                        onIconSelected(binaryStr);
                      };
                    });
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      {iconBase64.length ? (
                        <CardMedia image={iconBase64} />
                      ) : (
                        <CardContent>
                          <input {...getInputProps()} />
                          <VfSvgIcon
                            icon="upload"
                            viewBox={56}
                            fontSize="large"
                          />
                          <Typography variant="body2">
                            Drag & drop a file here or click
                          </Typography>
                        </CardContent>
                      )}
                    </div>
                  )}
                </Dropzone>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Upload featured image (Optional)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card
                elevation={0}
                className={classNames({
                  "MuiCard-dropzone": true,
                  "MuiCard-white": true,
                  "MuiCard-outlined": true,
                  "MuiCard-rounded": true,
                  // "MuiCard-disabled": dragItem,
                })}
              >
                <Dropzone
                  multiple={false}
                  // onDropAccepted={handleDragItem}
                  onDrop={acceptedFiles => {
                    acceptedFiles.forEach(file => {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onabort = () =>
                        console.log("file reading was aborted");
                      reader.onerror = () =>
                        console.log("file reading has failed");
                      reader.onload = () => {
                        // Do whatever you want with the file contents
                        const binaryStr = reader.result;
                        onImageSelected(binaryStr);
                      };
                    });
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      {imageBase64.length ? (
                        <CardMedia image={imageBase64} />
                      ) : (
                        <CardContent>
                          <input {...getInputProps()} />
                          <VfSvgIcon
                            icon="upload"
                            viewBox={56}
                            fontSize="large"
                          />
                          <Typography variant="body2">
                            Drag & drop a file here or click
                          </Typography>
                        </CardContent>
                      )}
                    </div>
                  )}
                </Dropzone>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppDialogFilesSelector;
