/* eslint-disable camelcase */
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import CardHeader from "@material-ui/core/CardHeader";

const useStyles = makeStyles({
  root: {},
});

const CardPlain = ({ title, isList, children }) => {
  const classes = useStyles();
  return (
    <Card
      elevation={0}
      className={classNames(classes.root, {
        "MuiCard-form": true,
        "MuiCard-list": isList,
        "MuiCard-white": true,
        "MuiCard-outlined": false,
        "MuiCard-rounded": true,
      })}
    >
      <CardHeader
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Typography variant="body1" component="h5">
            {title}
          </Typography>
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardPlain;
