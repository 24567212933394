/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "AppTheme";
import FilterMenuEnvironments from "../../../filters/FilterMenuEnvironments";
import AppDialogFullHeader from "../../common/AppDialogFullHeader";
import HasMultiPermissions from "../../../has-permissions/HasMultiPermissions";
import SecurityGroupsSelector from "./SecurityGroupsSelector";

const useStyles = makeStyles({
  modalContent: {
    minHeight: "50vh",
  },
  alignRight: {
    textAlign: "right",
  },
  buttonText: {
    backgroundColor: "none",
    fontSize: theme.typography.caption.fontSize,
  },
  gridDividerExpand: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    alignSelf: "stretch",
  },
});

const SecurityGroupsDialog = ({
  isOpen,
  app,
  defaultSecurityGroups,
  securityGroupsPerEnvironment,
  environments,
  selectedEnvironment,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getSecurityGroups = (env, groupsPerEnv, defaultGroups) => {
    const selectedEnvironmentId = env._id;
    if (
      !groupsPerEnv ||
      !groupsPerEnv[selectedEnvironmentId] ||
      !groupsPerEnv[selectedEnvironmentId].length
    ) {
      return defaultGroups;
    }
    return groupsPerEnv[selectedEnvironmentId];
  };

  const [securityGroups, setSecurityGroups] = useState(
    securityGroupsPerEnvironment || {}
  );

  const [environment, setEnvironment] = useState(
    selectedEnvironment && selectedEnvironment._id
      ? selectedEnvironment
      : environments[0]
  );

  const actionDisabled = () =>
    !securityGroups ||
    !securityGroups[environment._id] ||
    !securityGroups[environment._id].length;

  const ActionButtons = () => {
    return (
      <>
        <Grid item xs="auto">
          <Button
            autoFocus
            color="default"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs="auto">
          <HasMultiPermissions
            permissionKeys={environments.map(
              env => `edit_security_groups_env_${env._id}`
            )}
            type="AT_LEAST_ONE"
            disabled
          >
            <Button
              autoFocus
              color="secondary"
              variant="contained"
              disabled={actionDisabled()}
              onClick={() => onSubmit(securityGroups)}
            >
              Save
            </Button>
          </HasMultiPermissions>
        </Grid>
      </>
    );
  };

  return (
    <Dialog fullScreen={fullScreen} open={isOpen} maxWidth="md">
      <AppDialogFullHeader
        application={app}
        includeLogo
        buttons={<ActionButtons />}
      >
        <FilterMenuEnvironments
          envs={{ list: environments }}
          activeEnv={environment ? environment.title : ""}
          clearEnvironment={() => {}}
          setEnvironment={env => {
            const foundEnv = environments.find(e => e._id === env.id);
            setEnvironment(foundEnv);
            setSecurityGroups(value => ({
              ...value,
              [foundEnv._id]: getSecurityGroups(
                foundEnv,
                securityGroups,
                defaultSecurityGroups
              ),
            }));
          }}
          skipAllOption
          isDisabled={!!selectedEnvironment}
        />
      </AppDialogFullHeader>
      <DialogContent className={classes.modalContent}>
        <SecurityGroupsSelector
          environment={environment}
          selectedSecurityGroups={
            securityGroups[environment._id] || defaultSecurityGroups
          }
          onSelectSecurityGroups={value => {
            setSecurityGroups(prev => ({
              ...prev,
              [environment._id]: value,
            }));
          }}
          onResetSecurityGroups={() => {
            setSecurityGroups(value => ({
              ...value,
              [environment._id]: defaultSecurityGroups,
            }));
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
export default SecurityGroupsDialog;
