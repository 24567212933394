import React from "react";
import { connect } from "react-redux";

// The functionality of this component is covered by HasMultiPermissions. To be replaced later.
const HasPermission = ({
  userPermissions,
  children,
  permissionKey,
  disabled,
  isGlobal
}) => {
  const { enablePermissions, permissions } = userPermissions;
  const hasPermission = (uPermissions, permissionName, enable,isGlobal) => {    
    if (enable && !isGlobal) {
      return !!uPermissions.find(p => p === permissionName);
    }
    return true;
  };

  const customChildren = disabled
    ? React.cloneElement(children, {
        disabled: !hasPermission(permissions, permissionKey, enablePermissions,isGlobal),
      })
    : children;

  return (
    <>
      {hasPermission(permissions, permissionKey, enablePermissions,isGlobal) || disabled
        ? customChildren
        : null}
    </>
  );
};

const mapStateToProps = state => ({
  userPermissions: state.userPermissions,
});

export default connect(mapStateToProps, null)(HasPermission);
