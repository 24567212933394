import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import {
  loggedOut,
  loggedOutMessage,
  clearLogoutMessage,
} from "actions/authenticationActions";

const Logout = ({ logout, addMessage, clearMessage }) => {
  const { search } = useLocation();
  useEffect(() => {
    if (search.includes("autoLogout")) {
      addMessage("Logged out due to inactivity.");
      setTimeout(() => {
        clearMessage();
      }, 5000);
    } else if (search.includes("sessionExpired")) {
      addMessage("Your session has expired");
      setTimeout(() => {
        clearMessage();
      }, 5000);
    } else logout();
  }, [search]);

  return <Redirect to="/" />;
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(loggedOut()),
  addMessage: payload => dispatch(loggedOutMessage(payload)),
  clearMessage: () => dispatch(clearLogoutMessage()),
});

export default connect(null, mapDispatchToProps)(Logout);
