/* eslint-disable camelcase */
import React from "react";
import { Card, CardContent, CardActionArea } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Dropzone, { useDropzone } from "react-dropzone";
import classNames from "classnames";
import VfSvgIcon from "../icons/VfSvgIcon";

const checkIfDuplicateFilesExists = (files,media,uploadMediaError) => {
  for (const file of files) {
    if(media.find(m => m.title === file.name)) {
      uploadMediaError(`${file.name}-already-exists`);
      return true;
    }
  }
  return false;
}

const checkIfAllFilesAreLessThanTenMb =(files,uploadMediaError) => {
  let totalSize = 0;
  files.forEach(file => {
    totalSize += file.size;
  })
  if(totalSize > 10485760) {
      uploadMediaError(`The Files you are trying to upload are more than 10MB`)  
      return false
  }
  return true;
}

const checkIfFilesAreAcceptableFormat = (files,uploadMediaError) => {
  const acceptedTypes = ['image/','video/','audio/','application/json'];
  const acceptedFormats = ['.doc','.docx','.xls','.xlsx','.ppt','pptx','.txt','.pdf'];
  for (const file of files) {
    const {type,name} = file;
    if(acceptedTypes.some(f => type.startsWith(f))) return true;
    if(acceptedFormats.some(f => name.endsWith(f))) return true;
  }
  uploadMediaError('The format you are trying to upload is not supported');
  return false;
}

const CardUpload = ({
  postMedia,
  uploadMediaError,
  access_token,
  id_token,
  filters,
  searchText,
  media
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, video/*, audio/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .pdf, application/json'
  });

  const renderCardActionArea = getRootProps && getInputProps ? (
    <CardActionArea {...getRootProps()} data-testid="inputContainer_upload">
      <CardContent >
        <input {...getInputProps()} type="file" onChange={(e) => {
          const data = new FormData();
          const files = e?.target?.files;
          if(!files) return;
          if(checkIfDuplicateFilesExists(files,media,uploadMediaError)) return;
          if(!checkIfAllFilesAreLessThanTenMb(files,uploadMediaError)) return;
          if(!checkIfFilesAreAcceptableFormat(files,uploadMediaError)) return;
          e.target?.files?.forEach(f =>  {
              data.append("files", f);
          });
          postMedia({
            access_token,
            id_token,
            payload: data,
            filters,
            searchText,
          });
        }} 
        data-testid="inputFeild_upload"
        />        
        <VfSvgIcon
          className="MuiSvgIcon-fontSizeXLarge"
          icon="upload"
          viewBox={56}
          fontSize="large"
        />
        <Typography variant="body2">Click or drop files here</Typography>
      </CardContent>
    </CardActionArea>
  ) : {}

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-dropzone": true,
        "MuiCard-media": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-rounded": true,
      })}
    >
      <Dropzone
        multiple
        onDrop={acceptedFiles => {
          const data = new FormData();
          acceptedFiles.forEach(m => {
            data.append("files", m);
          });
          postMedia({
            access_token,
            id_token,
            payload: data,
            filters,
            searchText,
          });
        }}
      >
        {() => renderCardActionArea}
      </Dropzone>
    </Card>
  );
};

export default CardUpload;
