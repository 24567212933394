import React, { useRef } from "react";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

const Ace = ({
  aid,
  value,
  setValue,
  // isForExpression,
  prop,
}) => {
  const inputEl = useRef(null);
  function onChange(newValue) {
    setValue({ ...value, [prop]: newValue });
  }
  return (
    <AceEditor
      style={{ height: "calc(70vh - 64px)", width: "100%" }}
      mode="javascript"
      theme="tomorrow"
      onChange={onChange}
      value={value[prop] || null}
      name={aid}
      ref={inputEl}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
      }}
    />
  );
};

export default Ace;
