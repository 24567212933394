/* eslint-disable no-underscore-dangle */
import { Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import HasPermission from "../../../has-permissions/HasPermissions";
import FreeTextSelector from "../../common/FreeTextSelector";
import theme from "../../../../AppTheme";

const useStyles = makeStyles({
  alignRight: {
    textAlign: "right",
  },
  gridDividerExpand: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    alignSelf: "stretch",
  },
  buttonText: {
    backgroundColor: "none",
    fontSize: theme.typography.caption.fontSize,
  },
});

const SecurityGroupsSelector = ({
  environment,
  selectedSecurityGroups,
  onSelectSecurityGroups,
  onResetSecurityGroups,
}) => {
  const styles = useStyles();
  return (
    <Grid container spacing={3} direction="row">
      <Grid item xs={4}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}> */}
          {/*  <SearchBar /> */}
          {/* </Grid> */}
          {/* <Grid item xs="auto" className={styles.gridDividerExpand}> */}
          {/*  <Divider orientation="horizontal" /> */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <Typography color="secondary">App</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs="auto" className={styles.gridDividerExpand}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item xs={7}>
        <Grid container alignItems="center" className={styles.alignRight}>
          <Grid item xs={12}>
            <HasPermission
              permissionKey={`edit_security_groups_env_${environment._id}`}
              disabled
            >
              <Button
                className={styles.buttonText}
                variant="text"
                onClick={() => onResetSecurityGroups()}
              >
                Reset to default
              </Button>
            </HasPermission>
          </Grid>
          <HasPermission
            permissionKey={`edit_security_groups_env_${environment._id}`}
            disabled
          >
            <FreeTextSelector
              name="securityGroups"
              label="Security groups"
              placeholder="Group..."
              values={selectedSecurityGroups}
              options={[]}
              onChange={(e, value) => onSelectSecurityGroups(value)}
              optional={false}
            />
          </HasPermission>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SecurityGroupsSelector;
