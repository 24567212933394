import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import HasPermission from "components/has-permissions/HasPermissions";
import { setEnv } from "actions/envsActions";

const EnvironmentsSelection = ({ image, title, type, id, setEnvironment }) => {
  const { locale } = useParams();
  const {
    location: { pathname },
  } = useHistory();
  const isTranslationPath =
    pathname.indexOf("translations") === -1 ? "env" : "translations";
  return (
    <Card
      elevation={0}
      className="MuiCard-environment MuiCard-outlined MuiCard-rounded animate"
      onClick={() => setEnvironment({ type, id })}
    >
      <HasPermission
        permissionKey={
          isTranslationPath === "translations"
            ? `view_translations_env_${id}`
            : `view_configurations_env_${id}`
        }
        disabled
      >
        <CardActionArea
          component={Link}
          to={`/${locale}/${isTranslationPath}/${type}`}
        >
          <CardMedia title={title}>{image}</CardMedia>
          <CardContent>
            <Typography variant="h5" component="h5">
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </HasPermission>
    </Card>
  );
};

const mapDispatchToProps = dispatch => ({
  setEnvironment: parmas => dispatch(setEnv(parmas)),
});

export default connect(null, mapDispatchToProps)(EnvironmentsSelection);
