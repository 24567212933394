/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import nodeName from "./form-tree-node-name";

const jp = require("jsonpath");

const schemaToTree = (name, id, schema, parentData) => {
  const tree = {
    name,
    id,
    children: [],
  };
  if (schema instanceof Array) {
    let i = 1;
    for (const itemSchema of schema) {
      let name = itemSchema.title || `Item ${i}`;
      let subTree = schemaToTree(
        name,
        `${id}[${i - 1}]`,
        itemSchema,
        parentData
      );
      tree.children.push(subTree);
      i++;
    }
  } else {
    let properties = schema.properties || schema.items;
    let scalarProperties = {};
    let scalarSchema = {};
    if (typeof properties === "undefined") {
      scalarSchema = schema;
    }
    for (let property in properties) {
      let propertySpec = properties[property];
      let name = propertySpec.title || property;
      let { type } = propertySpec;
      switch (type) {
        case "object":
          {
            let subTree = schemaToTree(
              name,
              `${id}.${property}`,
              propertySpec,
              parentData
            );
            tree.children.push(subTree);
          }
          break;

        case "array":
          {
            let arraySchema = propertySpec.items;
            let subTree;
            if (arraySchema instanceof Array) {
              subTree = schemaToTree(
                name,
                `${id}.${property}`,
                arraySchema,
                parentData
              );
            } else {
              let id1 = `${id}.${property}`;
              let data = jp.query(parentData, id1)[0];
              if (data) {
                subTree = {
                  // todo traverse actual data
                  name,
                  showArray: true,
                  id: id1,
                  childrenSchema: arraySchema,
                  children: data.map((e, i) =>
                    schemaToTree(
                      nodeName(e, i),
                      `${id1}[${i}]`,
                      arraySchema,
                      parentData
                    )
                  ),
                };
              } else {
                // subTree = null;
                subTree = {
                  name,
                  showArray: true,
                  id: id1,
                  childrenSchema: arraySchema,
                  children: [],
                };
              }
            }
            if (subTree) {
              tree.children.push(subTree);
            }
          }
          break;

        default:
          scalarProperties[property] = propertySpec;
          break;
      }
    }

    if (Object.entries(scalarProperties).length) {
      tree.__schema__ = {
        ...schema,
        type: "object",
        properties: scalarProperties,
      };
    }
    if (Object.entries(scalarSchema).length) {
      tree.__schema__ = {
        ...schema,
        ...scalarSchema,
      };
    }
  }

  return tree;
};

export default schemaToTree;
