import React from "react";

import Breadcrumb from "components/breadcrumb/Breadcrumb";
import TouchpointsDetails from "./TouchpointsDetails";
import "./Touchpoints.scss";

const Touchpoints = () => (
  <main>
    <Breadcrumb />
    <TouchpointsDetails title="Select a touchpoint" />
  </main>
);

export default Touchpoints;
