/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PermissionRoute from "./PermissionRoute";

const PrivateRoute = props => {
  const { component: Component, auth, ...rest } = props;
  return (
    <Route
      {...rest}
      component={() => {
        return auth ? (
          <PermissionRoute>
            <Component locale={auth.ctry} {...rest} />
          </PermissionRoute>
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.authentication,
});

export default connect(mapStateToProps)(PrivateRoute);
