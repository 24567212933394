/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import { Theme as MuiTheme } from "rjsf-material-ui";
import TextWidget from "./TextWidget";
import ObjectFieldTemplate from "./ObjectFieldTemplate";
import SelectWidget from "./SelectWidget";
import EmailWidget from "./EmailWidget";
import CheckboxWidget from "./CheckboxWidget";
import RangeWidget from "./RangeWidget";
import FreeTextSelectorWidget from "./FreeTextSelectorWidget";

const CustomFormTheme = {
  ...MuiTheme,
  ObjectFieldTemplate: props => {
    return ObjectFieldTemplate(props);
  },
  widgets: {
    ...MuiTheme.widgets,
    TextWidget: props => {
      return TextWidget(props);
    },
    SelectWidget: props => {
      return SelectWidget(props);
    },
    EmailWidget: props => {
      return EmailWidget(props);
    },
    CheckboxWidget: props => {
      return CheckboxWidget(props);
    },
    RangeWidget: props => {
      return RangeWidget(props);
    },
    FreeTextSelectorWidget: props => {
      return FreeTextSelectorWidget(props);
    },
  },
};

export default CustomFormTheme;
