/* eslint-disable no-param-reassign */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";

import { loggedIn, loginError } from "actions/authenticationActions";
import { userPermissions, userPermissionsError } from "actions/permissionsActions";
import { showTenantSelectionDialog } from "actions/applicationsActions";
import { fetchEnvsByLocale } from "actions/envsActions";
import { fetchS3EnvsByLocale } from "actions/s3EnvsActions";
import { fetchTenantBasicInfo } from "actions/tenantActions";

const OauthCode = ({
  location,
  history,
  dispatchloggedIn,
  dispatchloginError,
  dispatchUserPermissions,
  dispatchShowTenantSelectionDialog,
  getEnvsByLocale,
  getS3EnvsByLocale,
  getTenantBasicInfo,
}) => {
  const params = new URLSearchParams(location.search);

  const code = params.get("code");
  const error = params.get("error");

  if (code) {
    const data = {
      code,
    };

    fetch("/api/appbuilder/token", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(ret => ret.json())
      .then(json => {
        const decoded = jwt.decode(json.id_token);
        const payload = {};
        payload.name = decoded.name;
        payload.family_name = decoded.family_name;
        payload.given_name = decoded.given_name;
        payload.email = decoded.email;
        payload.original_country = decoded.original_country;
        payload.ctry = decoded.ctry;
        payload.enablePermissions = decoded.enablePermissions;
        payload.allowedMarkets = decoded.allowed_markets;
        payload.permissions = decoded.permissions;
        payload.selectedMarket = decoded.allowed_markets.find(m => m.code === json.ctry);
        payload.refresh_token = json.refresh_token;
        dispatchloggedIn(payload);
        const permissionsPayload = { enablePermissions: payload.enablePermissions, permissions: payload.permissions }
        dispatchUserPermissions(permissionsPayload);
        getEnvsByLocale({
          locale: payload.ctry.toLowerCase(),
        });
        getTenantBasicInfo({
          locale: payload.ctry.toLowerCase(),
        });
        history.push(`/${json.ctry}/landing`);
        if (payload.allowedMarkets.length > 1) dispatchShowTenantSelectionDialog(true);
      })
      .catch(e => {
        dispatchloginError({ error: e.message });
        history.push("/");
      });
  } else if (error) {
    console.log("Got error", error);
    history.push("/");
  }

  return null;
};

const mapDispatchToProps = dispatch => ({
  dispatchloggedIn: data => dispatch(loggedIn(data)),
  dispatchloginError: data => dispatch(loginError(data)),
  dispatchUserPermissions: data => dispatch(userPermissions(data)),
  dispatchShowTenantSelectionDialog: data => dispatch(showTenantSelectionDialog(data)),
  getEnvsByLocale: params => dispatch(fetchEnvsByLocale(params)),
  getS3EnvsByLocale: params => dispatch(fetchS3EnvsByLocale(params)),
  getTenantBasicInfo: params => dispatch(fetchTenantBasicInfo(params)),
});

export default connect(null, mapDispatchToProps)(withRouter(OauthCode));
