import axios from "axios";

class SettingsService {
  fetchSettings = async () => {
    try {
      const data = await axios({
        method: "get",
        url: "/api/appbuilder/settings",
        headers: {
          "content-type": "application/json",
        },
      });
      return data;
    } catch (err) {
      console.log("Error in settings service:", err);
      return err;
    }
  };
}

const inst = new SettingsService();
export default inst;
