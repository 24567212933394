import React from "react";

import Touchpoints from "components/touchpoints/Touchpoints";
import Applications from "components/applications/Applications";

const Apps = () => {
  return (
    <>
      <Touchpoints />
      <Applications />
    </>
  );
};

export default Apps;
