/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";

import {
  FETCH_APP_VERSIONS,
  FETCH_APP_VERSIONS_SUCCESS,
  FETCH_APP_VERSIONS_FAIL,
  FETCH_APP_VERSIONS_DETAILS,
  FETCH_APP_VERSIONS_DETAILS_SUCCESS,
  FETCH_APP_VERSIONS_DETAILS_FAIL,
} from "actions/compareAppVersionsActions";
import CompareVersionsService from "services/CompareAppVersions";

function* fetchVersionsWorker({ payload }) {
  try {
    const { data, status } = yield call(
      CompareVersionsService.fetchVersions,
      payload
    );

    if (status === 200) {
      yield put({
        type: FETCH_APP_VERSIONS_SUCCESS,
        payload: data,
      });

      yield put({
        type: FETCH_APP_VERSIONS_DETAILS,
        payload: {
          versionFrom: payload.app._id,
          versionTo: data[0]._id,
          id_token: payload.id_token,
          access_token: payload.access_token,
        },
      });
    }
  } catch ({ error }) {
    yield put({ type: FETCH_APP_VERSIONS_FAIL, error });
  }
}

function* fetchVersionsWatcher() {
  yield takeLatest(FETCH_APP_VERSIONS, fetchVersionsWorker);
}

function* fetchVersionsDetailsWorker({ payload }) {
  try {
    const { data, status } = yield call(
      CompareVersionsService.fetchVersionsDetails,
      payload
    );
    if (status === 200) {
      yield put({
        type: FETCH_APP_VERSIONS_DETAILS_SUCCESS,
        payload: data,
      });
    }
  } catch ({ error }) {
    yield put({ type: FETCH_APP_VERSIONS_DETAILS_FAIL, error });
  }
}

function* fetchVersionsDetailsWatcher() {
  yield takeLatest(FETCH_APP_VERSIONS_DETAILS, fetchVersionsDetailsWorker);
}

const compareVersionsSaga = [
  fetchVersionsWatcher(),
  fetchVersionsDetailsWatcher(),
];

export default compareVersionsSaga;
