/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from "uuid";

const ratios = [
  "1/12",
  "1/6",
  "1/4",
  "1/3",
  "5/12",
  "1/2",
  "7/12",
  "2/3",
  "3/4",
  "5/6",
  "11/12",
  "1/1",
];
const widthToRatio = w => {
  if (w <= 0) {
    return "1/12";
  }
  return ratios[Math.min(12, w) - 1];
};

const addDclWidths = o => {
  o.width = widthToRatio(o.md);
  o.smWidth = widthToRatio(o.sm);
  o.mdWidth = widthToRatio(o.md);
};

const gridColumnsMap = {
  1: {
    xs: 12,
    sm: 12,
    md: 12,
  },
  2: {
    xs: 12,
    sm: 6,
    md: 6,
  },
  3: {
    xs: 12,
    sm: 4,
    md: 4,
  },
  4: {
    xs: 12,
    sm: 6,
    md: 3,
  },
  6: {
    xs: 12,
    sm: 6,
    md: 2,
  },
};

for (const gridColumnsMapElement in gridColumnsMap) {
  addDclWidths(gridColumnsMap[gridColumnsMapElement]);
}

const layoutToDjr = layout => {
  const djr = {
    __component: "Fragment",
    __props: {
      children: layout.rows.map(row => ({
        __component: "Grid",
        __props: {
          id: row.id,
          spacing: row.spacing,
          children: row.cols.map(col => ({
            __component: "GridItem",
            __props: {
              ...gridColumnsMap[row.cols.length],
              id: col.id,
              children: [
                col.widget
                  ? {
                      __component: col.widget.name,
                    }
                  : {
                      __component: "CardHeader",
                      __props: {
                        id: uuidv4(),
                        title: "Blank",
                      },
                    },
              ],
            },
          })),
        },
      })),
    },
  };

  return djr;
};

export { gridColumnsMap, layoutToDjr };
