import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import VfSvgIcon from "../icons/VfSvgIcon";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiCardActions-root": {
      padding: 0,
      opacity: 0,
      transition: theme.transitions.create(["opacity"], {
        duration: theme.transitions.duration.standard,
      }),
    },
    "&:hover": {
      "& .MuiCardActions-root": {
        opacity: 1,
      },
    },
  },
});

const CardTranslationLanguageItem = ({ language, deleteAction }) => {
  const classes = useStyles();
  return (
    <Card
      elevation={0}
      className={classNames(classes.card, {
        "MuiCard-grey": true,
        "MuiCard-rounded": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-grey": true,
      })}
    >
      <CardContent>
        <Typography variant="body1">{language.language}</Typography>
      </CardContent>
      {language.code !== "en" && (
        <CardActions>
          <IconButton onClick={() => deleteAction(language.code)}>
            <VfSvgIcon icon="delete" />
          </IconButton>
        </CardActions>
      )}
    </Card>
  );
};

export default CardTranslationLanguageItem;
