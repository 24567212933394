/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Grid, TextField, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import VfDialog from "../vf/VfDialog";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  version: {
    color: theme.palette.secondary.main,
  },
  notAvailable: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default function SelectApiVersionDialog({
  editApiVersion,
  setEditApiVersion,
  handleEditApi,
  apiId,
}) {
  const classes = useStyles();
  const [api, setApi] = useState({});
  const [selectedVersion, setSelectedVersion] = useState("");

  const apis = useSelector(state => state.apis.list, shallowEqual);
  const appApis = useSelector(
    state => state.applications.current.appInfo.apis,
    shallowEqual
  );

  useEffect(() => {
    const found = apis.find(a => a._id === apiId);
    const selectedApi = appApis.find(a => a._id === apiId);
    setApi(found);
    setSelectedVersion((selectedApi || {}).selectedVersion);
  }, [apis, appApis]);

  return (
    <VfDialog
      title="Select Version"
      openDialog={editApiVersion}
      buttonConfirmText="Save"
      buttonCloseAction={() => setEditApiVersion(false)}
      buttonConfirmAction={() => {
        handleEditApi({ ...api, selectedVersion });
        setEditApiVersion(false);
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            disabled
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              },
            }}
            label="Select API"
            value={api?.name}
            variant="filled"
            color="secondary"
            fullWidth
            InputProps={{
              disableUnderline: true,
              className: classNames("MuiFilledInput-disableUnderline"),
            }}
          >
            <MenuItem key={api?._id} value={api?.name}>
              <Typography component="div" variant="h5">
                {api?.name}
              </Typography>
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              },
            }}
            onChange={e => setSelectedVersion(e.target.value)}
            label="Select Version"
            value={selectedVersion}
            variant="filled"
            color="secondary"
            fullWidth
            InputProps={{
              disableUnderline: true,
              className: classNames("MuiFilledInput-disableUnderline"),
            }}
          >
            {api?.versions?.map(version => (
              <MenuItem
                key={version.name}
                value={version.name}
                disabled={!version.available}
                className={classes.version}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography component="div" variant="h5">
                      {`v${version.name}`}
                    </Typography>
                  </Grid>
                  {!version.available && (
                    <Grid item xs={6} className={classes.notAvailable}>
                      <Typography component="div" variant="body2">
                        Not added in all environments
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </VfDialog>
  );
}
