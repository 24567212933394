import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@material-ui/core";
import theme from "../../../AppTheme";

const PackageTab = ({ packages }) => {
  return (
    <Table>
      <TableBody>
        {packages &&
          packages.map(pack => {
            return (
              <TableRow>
                <TableCell>
                  {pack?.versionFrom ? (
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">
                        {pack?.versionFrom?.name}
                      </Typography>
                      <Typography
                        color="secondary"
                        variant="body2"
                        style={{ marginLeft: theme.spacing(0.5) }}
                      >
                        {`v${pack?.versionFrom?.version}`}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="body2">Not Used</Typography>
                  )}
                </TableCell>
                <TableCell>
                  {pack?.versionTo ? (
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">
                        {pack?.versionTo?.name}
                      </Typography>
                      <Typography
                        color="secondary"
                        variant="body2"
                        style={{ marginLeft: theme.spacing(0.5) }}
                      >
                        {`v${pack?.versionTo?.version}`}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="body2">Not Used</Typography>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default PackageTab;
