/* eslint-disable no-underscore-dangle */
import {
  PACKAGES_REQUEST,
  PACKAGES_REQUEST_SUCCESS,
  PACKAGES_REQUEST_FAIL,
} from "actions/packagesActions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  show: false,
  currentApi: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case PACKAGES_REQUEST: {
      return { ...state, loading: true };
    }
    case PACKAGES_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: null, list: payload.data };
    }
    case PACKAGES_REQUEST_FAIL: {
      return { ...state, loading: false, error };
    }
    default:
      return state;
  }
};

export default reducer;
