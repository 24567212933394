import i18n from 'i18next';

import * as translationEN from './en/index';

i18n
  .init({
    resources: {
      en: translationEN,
    },
    defaultNS: 'common',
    lng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
