import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import { clearEnv } from "actions/envsActions";
import { clearTouchpoint } from "actions/touchpointsActions";
import { clearAppsList } from "actions/appsActions";
import VfSvgIcon from "../icons/VfSvgIcon";
import TouchpointsSelection from "./TouchpointsSelection";

const TouchpointsDetails = ({
  title,
  clearEnvironment,
  clearTchpoint,
  clearApplicationsList,
}) => {
  const history = useHistory();
  const { locale, touchpoint } = useParams();

  return (
    <Box py={4} bgcolor="common.white">
      <Container maxWidth="md">
        <Paper elevation={0} className="MuiCard-selection animate">
          <Grid container spacing={3} direction="row" alignItems="center">
            <Grid item xs={false}>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  history.push(`/${locale}/env`);
                  clearEnvironment();
                  clearTchpoint();
                  clearApplicationsList();
                }}
              >
                <VfSvgIcon icon="arrowLeft" viewBox={24} />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography variant="h4" component="h1" color="textPrimary">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                  <TouchpointsSelection
                    title="Mobile"
                    image={
                      <VfSvgIcon icon="mobile" viewBox={56} fontSize="large" />
                    }
                    selected={touchpoint === "mobile"}
                    type="mobile"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TouchpointsSelection
                    title="Tablet"
                    image={
                      <VfSvgIcon icon="tablet" viewBox={56} fontSize="large" />
                    }
                    selected={touchpoint === "tablet"}
                    type="tablet"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TouchpointsSelection
                    title="Web"
                    image={
                      <VfSvgIcon icon="desktop" viewBox={56} fontSize="large" />
                    }
                    selected={touchpoint === "web"}
                    type="web"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TouchpointsSelection
                    title="Kiosk"
                    image={
                      <VfSvgIcon icon="kiosk" viewBox={56} fontSize="large" />
                    }
                    selected={touchpoint === "kiosk"}
                    type="kiosk"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

const mapDispatchToProps = dispatch => ({
  clearEnvironment: () => dispatch(clearEnv()),
  clearTchpoint: () => dispatch(clearTouchpoint()),
  clearApplicationsList: () => dispatch(clearAppsList()),
});

export default connect(null, mapDispatchToProps)(TouchpointsDetails);
