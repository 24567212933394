import React, { useRef,memo,useState,useEffect } from "react";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import store from "store/configureStore";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import { debounce } from "lodash";
import { setCurrentPage } from "actions/applicationsActions";

const AppEditCode = ({ appBarHeight, toolBarHeight, djr, setDjr }) => {
  const inputEl = useRef(null);
  const [djrvalue, updateDjrValue] = useState(djr);
  
  useEffect(() => {
    updateDjrValue(djr)
  },[djr]);
  
  const debouncedOnChange = debounce(onChange, 800);

  function getDjr(str) {
    let data = false;
    try {
      data =  JSON.parse(str);
    } catch (e) {
      data = false;
    }
    return data;
  }

  function onChange(newDjr) {
    updateDjrValue(newDjr);
    setDjr(newDjr);
    const { applications } = store.getState();
    const currentPage =  applications?.current?.currentPage;

    const djr = getDjr(newDjr);
    if(djr && currentPage){
      store.dispatch(setCurrentPage({ ...currentPage, djr }))
    }
  }
  return (
    <AceEditor
      style={{
        height: `calc(100vh - ${appBarHeight}px - ${toolBarHeight}px)`,
        width: "100%",
      }}
      mode="json"
      theme="github"
      onChange={debouncedOnChange}
      value={djrvalue}
      name="json-editor"
      ref={inputEl}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
      }}
    />
  );
};

export default memo(AppEditCode);
