/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import uuidv4 from "uuid/v4";

import { uniqBy } from "lodash";
import VfDialog from "../vf/VfDialog";
import theme from "../../AppTheme";

const useStyles = makeStyles({
  version: {
    color: theme.palette.secondary.main,
  },
  notAvailable: {
    display: "flex",
    justifyContent: "flex-end",
  },
  radioBox: {
    padding: "0 0 15px 0",
  },
  inputField: {
    padding: "0 0 15px 0",
  },
});

export default function AddApiDialog({
  dialogPackage,
  setDialogPackage,
  handlePackageConfirm,
  packages,
  packageId,
}) {
  const appInfo = useSelector(
    state => state.applications.current.appInfo,
    shallowEqual
  );

  const packageToEdit = appInfo.packages.find(x => x._id === packageId);
  const classes = useStyles();
  const [packageType, setPackageType] = useState(
    packageToEdit ? packageToEdit.type : "Local"
  );
  const [selectedPackage, setSelectedPackage] = useState(packageToEdit || null);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [namePublic, setNamePublic] = useState(
    packageToEdit ? packageToEdit.name : null
  );
  const [versionPublic, setVersionPublic] = useState("");
  const [typesVersionPublic, setTypesVersionPublic] = useState("");
  return (
    <VfDialog
      title={packageId ? "Edit Package" : "Add Package"}
      openDialog={dialogPackage}
      buttonConfirmText={packageId ? "Edit Package" : "Add Package"}
      buttonCloseAction={() => setDialogPackage(false)}
      buttonConfirmAction={() => {
        handlePackageConfirm(
          packageType === "Local"
            ? Object.assign(selectedVersion, { type: "Local" })
            : {
                _id: uuidv4(),
                name: namePublic,
                version: versionPublic,
                typesVersion: typesVersionPublic,
                type: "Public",
              }
        );
        setDialogPackage(false);
      }}
      buttonConfirmDisabled={
        (!selectedVersion && packageType === "Local") ||
        (!namePublic && !versionPublic && packageType === "Public")
      }
    >
      <Grid container spacing={3}>
        {!packageId && (
          <Grid item xs={12}>
            <RadioGroup
              row
              className={classes.radioBox}
              aria-label="position"
              name="position"
              value={packageType}
              onChange={e => {
                setPackageType(e.target.value);
                setSelectedPackage({});
                setSelectedVersion("");
                setNamePublic("");
                setVersionPublic("");
              }}
            >
              <FormControlLabel
                value="Local"
                control={<Radio color="primary" />}
                label="Local"
              />
              <FormControlLabel
                disabled
                value="Public"
                control={<Radio color="primary" />}
                label="Public"
              />
            </RadioGroup>
          </Grid>
        )}
        {packageType === "Local" && (
          <>
            <Grid item xs={12}>
              <TextField
                select
                SelectProps={{
                  renderValue: () => {
                    return selectedPackage?.name;
                  },
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  },
                }}
                onChange={e => {
                  setSelectedPackage(e.target.value);
                }}
                label="Select Package"
                value={selectedPackage?.name}
                variant="filled"
                color="secondary"
                fullWidth
                disabled={!!packageId}
                InputProps={{
                  disableUnderline: true,
                  className: classNames("MuiFilledInput-disableUnderline"),
                }}
              >
                {uniqBy(
                  packages.filter(
                    x => !appInfo?.packages.find(y => x.name === y.name)
                  ),
                  "name"
                )?.map(a => (
                  <MenuItem key={a._id} value={a}>
                    <Typography component="div" variant="h5">
                      {a?.name}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {selectedPackage?.name && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                    },
                  }}
                  onChange={e => {
                    setSelectedVersion(e.target.value);
                  }}
                  label="Select Version"
                  value={selectedVersion}
                  variant="filled"
                  color="secondary"
                  InputProps={{
                    disableUnderline: true,
                    className: classNames("MuiFilledInput-disableUnderline"),
                  }}
                >
                  {packages
                    .filter(x => x?.name === selectedPackage?.name)
                    .map(item => (
                      <MenuItem
                        key={item?.version}
                        value={item}
                        className={classes.version}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Typography component="div" variant="h5">
                              {`v${item?.version}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
          </>
        )}

        {packageType === "Public" && (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={!!packageId}
                  name="name"
                  variant="outlined"
                  label="Enter Name"
                  type="text"
                  value={namePublic}
                  onChange={e => {
                    setNamePublic(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="VersionLocal"
                  variant="outlined"
                  label="Enter Version"
                  helperText="Blank for latest"
                  type="text"
                  value={versionPublic}
                  onChange={e => {
                    setVersionPublic(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="TypeVersionLocal"
                  variant="outlined"
                  label="Enter @types Version"
                  helperText="Blank for latest"
                  type="text"
                  value={typesVersionPublic}
                  onChange={e => {
                    setTypesVersionPublic(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </VfDialog>
  );
}
