/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_REQUEST_SUCCESS,
  GET_PERMISSIONS_REQUEST_FAIL,
} from "actions/permissionsActions";
import PermissionsService from "services/PermissionsService";

function* permissionsWorker({ payload }) {
  try {
    const data = yield call(PermissionsService.fetchPermissions, payload);
    yield put({
      type: GET_PERMISSIONS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: GET_PERMISSIONS_REQUEST_FAIL, error });
  }
}

function* permissionsWatcher() {
  yield takeLatest(GET_PERMISSIONS_REQUEST, permissionsWorker);
}

const permissionsSaga = [permissionsWatcher()];

export default permissionsSaga;
