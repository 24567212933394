/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  IconButton,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { omit, range } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import uuidv4 from "uuid/v4";
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

import { layoutToDjr } from "utils/layout-to-djr";
import GridRowTemplateBuilder from "./GridRowTemplateBuilder";
import CardWidgetPlaceholder from "./CardWidgetPlaceholder";
import CardGridStarter from "./CardGridStarter";

import theme from "../../AppTheme";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfTooltip from "../vf/VfTooltip";

const AppsTemplates = ({ currentPage, appBarHeight }) => {
  const useStyles = makeStyles({
    root: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      minHeight: `calc(100vh - ${appBarHeight}px)`,
      overflowX: "hidden",
      "& .slick-slider": {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        "& .slick-arrow": {
          position: "absolute",
          width: theme.spacing(6),
          height: theme.spacing(6),
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          "&:before": {
            display: "none",
          },
          "&.slick-disabled": {
            pointerEvents: "none",
            opacity: 0.5,
          },
        },
        "& .slick-prev": {
          left: 0,
        },
        "& .slick-next": {
          right: 0,
        },
      },
    },
    title: {
      marginTop: theme.spacing(2),
    },
  });
  const [devMode, setDevMode] = useState(false);
  const classes = useStyles();
  const template = {
    rows: [
      {
        columns: 1,
      },
    ],
  };
  const templates = [
    {
      title: "1:1:2:3 Column",
      rows: [
        {
          columns: 1,
        },
        {
          columns: 1,
        },
        {
          columns: 2,
        },
        {
          columns: 3,
        },
      ],
    },
    {
      title: "1:2:3 Column",
      rows: [
        {
          columns: 1,
        },
        {
          columns: 2,
        },
        {
          columns: 3,
        },
      ],
    },
    {
      title: "1:2:1 Column",
      rows: [
        {
          columns: 1,
        },
        {
          columns: 2,
        },
        {
          columns: 1,
        },
      ],
    },
    {
      title: "1:3:1 Column",
      rows: [
        {
          columns: 1,
        },
        {
          columns: 3,
        },
        {
          columns: 1,
        },
      ],
    },
    {
      title: "2:1:3 Column",
      rows: [
        {
          columns: 2,
        },
        {
          columns: 1,
        },
        {
          columns: 3,
        },
      ],
    },
    {
      title: "2:2:1 Column",
      rows: [
        {
          columns: 2,
        },
        {
          columns: 2,
        },
        {
          columns: 1,
        },
      ],
    },
    {
      title: "2:2:3 Column",
      rows: [
        {
          columns: 2,
        },
        {
          columns: 2,
        },
        {
          columns: 3,
        },
      ],
    },
    {
      title: "3:3:1 Column",
      rows: [
        {
          columns: 3,
        },
        {
          columns: 3,
        },
        {
          columns: 1,
        },
      ],
    },
    {
      title: "3:3:2 Column",
      rows: [
        {
          columns: 3,
        },
        {
          columns: 3,
        },
        {
          columns: 2,
        },
      ],
    },
    {
      title: "3:2:2 Column",
      rows: [
        {
          columns: 3,
        },
        {
          columns: 2,
        },
        {
          columns: 2,
        },
      ],
    },
  ];

  const templateDev = {
    rows: [
      {
        columns: 1,
      },
    ],
    djr: {
      __component: "Fragment",
      __props: {
        id: "root",
        children: [],
      },
    },
  };
  const templatesDev = [
    {
      title: "Header - Main - Footer",
      rows: [
        {
          columns: 1,
        },
        {
          columns: 1,
        },
        {
          columns: 1,
        },
      ],
      djr: {
        __component: "Fragment",
        __props: {
          id: "root",
          children: [
            {
              __component: "Fragment",
              __props: {
                id: uuidv4().replaceAll(`-`, `_`),
                children: [],
              },
            },
            {
              __component: "Fragment",
              __props: {
                id: uuidv4().replaceAll(`-`, `_`),
                children: [],
              },
            },
            {
              __component: "Fragment",
              __props: {
                id: uuidv4().replaceAll(`-`, `_`),
                children: [],
              },
            },
          ],
        },
      },
    },
  ];
  const buildTemplate = tmpl => {
    const newTemplate = {
      ...currentPage,
      spacing: 3,
      rows: tmpl?.rows?.map(row => ({
        id: uuidv4(),
        spacing: 3,
        columns: row.columns,
        cols: range(0, row.columns).map(() => ({
          id: uuidv4(),
          widget: null,
        })),
      })),
      devMode,
    };

    const djr = layoutToDjr(newTemplate);
    newTemplate.djr = devMode ? tmpl.djr : djr;

    return devMode ? omit(newTemplate, ["spacing", "rows"]) : newTemplate;
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: theme.transitions.duration.complex,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: (
      <IconButton style={classes.slickNext}>
        <VfSvgIcon icon="chevronRight" viewBox={24} fontSize="large" />
      </IconButton>
    ),
    prevArrow: (
      <IconButton style={classes.slickPrev}>
        <VfSvgIcon icon="chevronLeft" viewBox={24} fontSize="large" />
      </IconButton>
    ),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <Paper className={classes.root} square elevation={0}>
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="center" justify="flex-end">
              <Grid item xs={4}>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item xs="auto">
                    <Typography
                      className="bold"
                      component="h3"
                      variant="h5"
                      align="center"
                    >
                      Select a template
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justify="flex-end"
                >
                  <Grid item xs="auto">
                    <VfTooltip title="Advanced development mode.">
                      <FormControlLabel
                        /* eslint-disable */
                        control={
                          <Switch
                            name="devMode"
                            checked={devMode}
                            onChange={event => setDevMode(event.target.checked)}
                            color="primary"
                          />
                        }
                        /* eslint-enable */
                        label="No grid (dev) mode"
                        labelPlacement="start"
                      />
                    </VfTooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Slider {...settings}>
              {(devMode ? templatesDev : templates).map((temp, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <CardWidgetPlaceholder
                    // eslint-disable-next-line react/no-array-index-key
                    key={`grid-${i}`}
                    template={temp}
                    templateFunc={buildTemplate}
                    size="xs"
                  >
                    <GridRowTemplateBuilder size="xs" grid={temp} />
                    <Typography
                      className={classes.title}
                      variant="body2"
                      color="textSecondary"
                      align="center"
                    >
                      {temp.title}
                    </Typography>
                  </CardWidgetPlaceholder>
                );
              })}
            </Slider>
          </Grid>
          <Grid item xs={12}>
            <CardGridStarter
              templateFunc={buildTemplate}
              template={devMode ? templateDev : template}
            />
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default AppsTemplates;
