const CONFIG_LIST_APP_REQUEST = "CONFIG_LIST_APP_REQUEST";
const CONFIG_LIST_APP_REQUEST_SUCCESS = "CONFIG_LIST_APP_REQUEST_SUCCESS";
const CONFIG_LIST_APP_REQUEST_FAIL = "CONFIG_LIST_APP_REQUEST_FAIL";
const SET_CONFIG_APP = "SET_CONFIG_APP";
const CLEAR_CONFIG_APP = "CLEAR_CONFIG_APP";

const CONFIG_LIST_WIDGET_REQUEST = "CONFIG_LIST_WIDGET_REQUEST";
const CONFIG_LIST_WIDGET_REQUEST_SUCCESS = "CONFIG_LIST_WIDGET_REQUEST_SUCCESS";
const CONFIG_LIST_WIDGET_REQUEST_FAIL = "CONFIG_LIST_WIDGET_REQUEST_FAIL";
const SET_CONFIG_WIDGET = "SET_CONFIG_WIDGET";
const CLEAR_CONFIG_WIDGET = "CLEAR_CONFIG_WIDGET";
const CLEAR_CONFIG_WIDGETS_LIST = "CLEAR_CONFIG_WIDGETS_LIST";

const CONFIG_RESET_WIDGET_REQUEST = "CONFIG_RESET_WIDGET_REQUEST";
const CONFIG_RESET_WIDGET_REQUEST_SUCCESS =
  "CONFIG_RESET_WIDGET_REQUEST_SUCCESS";
const CONFIG_RESET_WIDGET_REQUEST_FAIL = "CONFIG_RESET_WIDGET_REQUEST_FAIL";
const CONFIG_DEPLOY_WIDGET_REQUEST = "CONFIG_DEPLOY_WIDGET_REQUEST";
const CONFIG_DEPLOY_WIDGET_REQUEST_SUCCESS =
  "CONFIG_DEPLOY_WIGET_REQUEST_SUCCESS";
const CONFIG_DEPLOY_WIDGET_REQUEST_FAIL = "CONFIG_DEPLOY_WIDGET_REQUEST_FAIL";
const CONFIG_DEPLOY_AND_FETCH_WIDGET = "CONFIG_DEPLOY_AND_FETCH_WIDGET";

const fetchConfigApps = payload => ({
  type: CONFIG_LIST_APP_REQUEST,
  payload,
});

const setConfigApp = payload => ({
  type: SET_CONFIG_APP,
  payload,
});

const clearConfigApp = () => ({
  type: CLEAR_CONFIG_APP,
});

const fetchConfigListWidget = payload => ({
  type: CONFIG_LIST_WIDGET_REQUEST,
  payload,
});

const setConfigWidget = payload => ({
  type: SET_CONFIG_WIDGET,
  payload,
});

const clearConfigWidget = () => ({
  type: CLEAR_CONFIG_WIDGET,
});

const clearConfigWidgetList = () => ({
  type: CLEAR_CONFIG_WIDGETS_LIST,
});

const resetConfigListWidget = payload => ({
  type: CONFIG_RESET_WIDGET_REQUEST,
  payload,
});

const deployConfigListWidget = payload => ({
  type: CONFIG_DEPLOY_WIDGET_REQUEST,
  payload,
});

const deployAndFetchWidgetConfig = payload => ({
  type: CONFIG_DEPLOY_AND_FETCH_WIDGET,
  payload,
});

export {
  CONFIG_LIST_APP_REQUEST,
  CONFIG_LIST_APP_REQUEST_SUCCESS,
  CONFIG_LIST_APP_REQUEST_FAIL,
  CONFIG_LIST_WIDGET_REQUEST,
  CONFIG_LIST_WIDGET_REQUEST_SUCCESS,
  CONFIG_LIST_WIDGET_REQUEST_FAIL,
  SET_CONFIG_APP,
  CLEAR_CONFIG_APP,
  SET_CONFIG_WIDGET,
  CLEAR_CONFIG_WIDGETS_LIST,
  CLEAR_CONFIG_WIDGET,
  CONFIG_RESET_WIDGET_REQUEST,
  CONFIG_RESET_WIDGET_REQUEST_SUCCESS,
  CONFIG_RESET_WIDGET_REQUEST_FAIL,
  CONFIG_DEPLOY_WIDGET_REQUEST,
  CONFIG_DEPLOY_WIDGET_REQUEST_SUCCESS,
  CONFIG_DEPLOY_WIDGET_REQUEST_FAIL,
  CONFIG_DEPLOY_AND_FETCH_WIDGET,
  fetchConfigApps,
  setConfigApp,
  clearConfigApp,
  fetchConfigListWidget,
  clearConfigWidgetList,
  clearConfigWidget,
  setConfigWidget,
  resetConfigListWidget,
  deployConfigListWidget,
  deployAndFetchWidgetConfig,
};
