const SETTINGS_REQUEST = "SETTINGS_REQUEST";
const SETTINGS_REQUEST_SUCCESS = "SETTINGS_REQUEST_SUCCESS";
const SETTINGS_REQUEST_FAIL = "SETTINGS_REQUEST_FAIL";

const fetchSettings = () => ({
  type: SETTINGS_REQUEST,
});

export {
  SETTINGS_REQUEST,
  SETTINGS_REQUEST_SUCCESS,
  SETTINGS_REQUEST_FAIL,
  fetchSettings,
};
