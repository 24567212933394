/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React, { useState } from "react";

import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { updateDeployedAppSecurityGroups } from "../../../../actions/applicationsActions";
import SecurityGroupsDialog from "./SecurityGroupsDialog";
import HasPermission from "../../../has-permissions/HasPermissions";

const SecurityGroupsMenuItem = ({
  application,
  environment,
  onClick,
  auth,
  applications,
  environments,
  updateDeployedApplication,
}) => {
  const getAppSecurityGroupsPerEnvironment = () => {
    if (!application.hasAuth) {
      return {};
    }

    const securityGroupsPerEnv = {};

    (applications || [])
      .map(app => {
        if (app.global) {
          return null;
        }

        return Object.values(app)[0];
      })
      .forEach(env => {
        if (env) {
          const appDeployedToEnv = (env.data || []).find(
            appInEnv =>
              appInEnv.application === application._id ||
              appInEnv.application === application.application
          );

          if (appDeployedToEnv) {
            securityGroupsPerEnv[env.id] = appDeployedToEnv.securityGroups;
          }
        }
      });

    return securityGroupsPerEnv;
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [securityGroupsPerEnvironment] = useState(
    getAppSecurityGroupsPerEnvironment()
  );

  const onUpdate = value => {
    updateDeployedApplication({
      applicationId: environment.isGlobal
        ? application._id
        : application.application,
      environmentId: environment._id,
      securityGroups: value,
      ...auth,
    });
  };

  const onCancel = () => setOpenDialog(false);

  const allowSecurityGroupsManagement = () => {
    return (
      application.hasAuth && Object.keys(securityGroupsPerEnvironment).length
    );
  };

  return !allowSecurityGroupsManagement() ? null : (
    <>
      <HasPermission permissionKey="view_security_groups">
        <MenuItem
          onClick={() => {
            onClick();
            setOpenDialog(true);
          }}
          data-testid="manageSecurityGroups_btn"
        >
          Manage Security Groups
        </MenuItem>
      </HasPermission>

      {openDialog && (
        <SecurityGroupsDialog
          isOpen={openDialog}
          app={application}
          defaultSecurityGroups={application.securityGroups}
          securityGroupsPerEnvironment={securityGroupsPerEnvironment || {}}
          environments={
            environments.filter(
              environ => !!securityGroupsPerEnvironment[environ._id]
            ) || []
          }
          selectedEnvironment={null}
          onCancel={onCancel}
          onSubmit={onUpdate}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    applications: state.applications.list || [],
    environments: state.envs.list || [],
  };
};

const mapDispatchToProps = dispatch => ({
  updateDeployedApplication: payload =>
    dispatch(updateDeployedAppSecurityGroups(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityGroupsMenuItem);
