/* eslint-disable no-underscore-dangle */
import {
  SET_APPLICATION,
  CLEAR_APPLICATION,
  SET_APPLICATION_ENVIRONMENT,
  CLEAR_APPLICATION_ENVIRONMENT,
  SHOW_APPLICATION,
  APPLICATIONS_REQUEST,
  APPLICATIONS_REQUEST_SUCCESS,
  APPLICATIONS_REQUEST_FAIL,
  SET_APPLICATIONS_VIEW,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_REQUEST_SUCCESS,
  CREATE_APPLICATION_REQUESTT_FAIL,
  SHOW_CREATE_APPLICATION_DIALOG,
  VALIDATE_NAME_REQUEST_SUCCESS,
  VALIDATE_NAME_REQUESTT_FAIL,
  SHOW_ADD_PAGE,
  SHOW_ADD_TEMPLATE,
  SET_CURRENT_PAGE,
  DJSL_DELETE_COMPONENT,
  ADD_PAGE,
  CLEAR_PAGE,
  CLEAR_PAGES,
  SAVE_PAGE_REQUEST_SUCCESS,
  SAVE_PAGE_REQUEST_FAIL,
  GET_PAGES_REQUEST_SUCCESS,
  GET_PAGES_REQUEST_FAIL,
  SHOW_APPLICATION_PAGES,
  SHOW_APPLICATION_SIDEBAR,
  SHOW_APPLICATION_CREATE_PAGE,
  SET_SELECTED_WIDGET,
  CLEAR_SELECTED_WIDGET,
  SET_SELECTED_WIDGET_VERSIONS,
  CLEAR_SELECTED_WIDGET_VERSIONS,
  SET_APPLICATION_HOMEPAGE_REQUEST,
  SET_APPLICATION_HOMEPAGE_REQUEST_SUCCESS,
  SET_APPLICATION_HOMEPAGE_REQUEST_FAIL,
  DELETE_PAGE_REQUEST_SUCCESS,
  DELETE_PAGE_REQUEST_FAIL,
  SHOW_SNACKBAR,
  GET_PAGES_REQUEST,
  SET_CURRENT_ROW_COL,
  CLEAR_CURRENT_ROW_COL,
  SET_APPLICATION_SECTION,
  SET_SIDEBAR_ITEM,
  DELETE_APP_REQUEST,
  DELETE_APP_REQUEST_SUCCESS,
  DELETE_APP_REQUEST_FAIL,
  CLEAR_DELETE_APP_ERROR,
  DJSL_ADD_COMPONENT,
  DJSL_EDIT_COMPONENT,
  ADD_SECURITY_DEFS_API,
  UPDATE_SNIPPET,
  DEPLOY_APPLICATION_REQUEST_SUCCESS,
  CLEAR_DEPLOYMENT_INFO,
  GET_APP_LATEST_VERSION_REQUEST,
  CLEAR_APP_LATEST_VERSION,
  RESOLVE_PUBLIC_PACKAGE_SUCCESS,
  RESOLVE_PUBLIC_PACKAGE_FAIL,
  SET_BPMN_OF_SAGA,
  SET_NAME_OF_SAGA,
  REMOVE_SAGA,
  ADD_NEW_SAGA,
  ADD_NEW_API,
  EDIT_API,
  REMOVE_API,
  ADD_NEW_PACKAGE,
  REMOVE_PACKAGE,
  ADD_NEW_SNIPPET,
  REMOVE_SNIPPET,
  SHOW_TENANT_SELECTION_DIALOG,
} from "actions/applicationsActions";

import { djslAddComponent, djslDeleteComponent } from "utils/djsl-utils";
import uuidv4 from "uuid/v4";
import { DCL, MVA10 } from "../utils/constants";
import {
  GET_APP_LATEST_VERSION_REQUEST_FAIL,
  GET_APP_LATEST_VERSION_REQUEST_SUCCESS,
} from "../actions/applicationsActions";

const initialState = {
  view: "list",
  loading: false,
  show: false,
  current: {
    appSection: "design",
    appSideBar: "widgetList",
    appInfo: null,
    showAddPage: false,
    showAddTemplate: false,
    showPages: false,
    showSidebar: false,
    showCreatePage: false,
    currentPage: {},
    changedPages: {},
    loading: false,
    pages: [],
    latestVersion: null,
  },
  selectedWidget: null,
  selectedWidgetVersions: null,
  selectedRow: null,
  selectedColumn: null,
  parent: null,
  list: [],
  error: null,
  env: null,
  showEdit: false,
  hasValidName: true,
  listType: [
    { title: "mobile", value: "mobile" },
    { title: "tablet", value: "tablet" },
    { title: "desktop", value: "desktop" },
    { title: "kiosk", value: "kiosk" },
  ],
  listPlatform: [
    { title: "ios", value: "ios" },
    { title: "android", value: "android" },
    { title: "windows", value: "windows" },
    { title: "web", value: "web" },
  ],
  listTheme: [
    { title: "WS10", value: MVA10 },
    { title: DCL, value: DCL },
  ],
  snackBar: {
    show: false,
    severity: 0,
    message: "",
  },
  authProviders: [
    { title: "No Authentication", value: "No Authentication" },
    { title: "Azure AD using OAuth2", value: "Azure AD using OAuth2" },
    { title: "GDI using SAML 2.0", value: "GDI using SAML 2.0" }
  ],
  storeTokenOptions: [
    { title: "Localstorage", value: "localstorage" },
    { title: "Cookies", value: "cookies" },
  ],
  showTenantSelectionDialog: false,
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case SET_APPLICATION:
      return {
        ...state,
        current: { ...state.current, appInfo: payload },
      };
    case SET_BPMN_OF_SAGA: {
      const { sagaId, bpmn } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            sagas: [
              ...state.current.appInfo.sagas.filter(s => s.id !== sagaId),
              {
                ...state.current.appInfo.sagas.find(s => s.id === sagaId),
                bpmn,
              },
            ],
          },
        },
      };
    }
    case SET_NAME_OF_SAGA: {
      const { sagaId, name } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            sagas: [
              ...state.current.appInfo.sagas.filter(s => s.id !== sagaId),
              {
                ...state.current.appInfo.sagas.find(s => s.id === sagaId),
                name,
              },
            ],
          },
        },
      };
    }
    case REMOVE_SAGA: {
      const { sagaId } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            sagas: [
              ...state.current.appInfo.sagas.filter(s => s.id !== sagaId),
            ],
          },
        },
      };
    }
    case ADD_NEW_SAGA: {
      const { name } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            sagas: [
              ...state.current.appInfo.sagas,
              {
                id: uuidv4(),
                name,
                bpmn: `<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
  <bpmn:process id="Process_1" isExecutable="false">
    <bpmn:startEvent id="StartEvent_1"/>
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>`,
              },
            ],
          },
        },
      };
    }
    case ADD_NEW_API: {
      const { api } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            apis: [...(state.current.appInfo.apis ?? []), api],
          },
        },
      };
    }
    case EDIT_API: {
      const { api } = payload;
      const apis = [...state.current.appInfo.apis];
      const foundIndex = apis.findIndex(x => x._id === api._id);
      apis[foundIndex] = api;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            apis,
          },
        },
      };
    }
    case REMOVE_API: {
      const { apiId } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            apis: [...state.current.appInfo.apis.filter(s => s._id !== apiId)],
          },
        },
      };
    }
    case ADD_NEW_PACKAGE: {
      const { pack } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            packages: [...(state.current.appInfo.packages ?? []), pack],
          },
        },
      };
    }
    case REMOVE_PACKAGE: {
      const { packageId } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            packages: [
              ...state.current.appInfo.packages.filter(
                s => s._id !== packageId
              ),
            ],
          },
        },
      };
    }
    case ADD_NEW_SNIPPET: {
      const { name } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            snippets: [
              ...(state.current.appInfo.snippets ?? []),
              { id: uuidv4(), name },
            ],
          },
        },
      };
    }
    case REMOVE_SNIPPET: {
      const { snippetId } = payload;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            snippets: [
              ...state.current.appInfo.snippets.filter(s => s.id !== snippetId),
            ],
          },
        },
      };
    }
    case CLEAR_APPLICATION:
      return {
        ...state,
        current: {
          appInfo: null,
          appSection: "design",
          appSideBar: "widgetList",
          showAddPage: false,
          showAddTemplate: false,
          showPages: false,
          showSidebar: false,
          showCreatePage: false,
          currentPage: {},
          changedPages: {},
          pages: [],
          latestVersion: null,
        },
        selectedRow: null,
        selectedColumn: null,
        parent: null,
        snackBar: {
          show: false,
          severity: 0,
          message: "",
        },
      };
    case SET_APPLICATION_ENVIRONMENT:
      return {
        ...state,
        env: payload,
      };
    case CLEAR_APPLICATION_ENVIRONMENT:
      return {
        ...state,
        env: null,
      };
    case SHOW_APPLICATION:
      return {
        ...state,
        show: payload,
      };
    case APPLICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case APPLICATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        list: payload,
        loading: false,
      };
    case APPLICATIONS_REQUEST_FAIL:
      return {
        ...state,
        error,
        loading: false,
      };
    case SET_APPLICATIONS_VIEW:
      return {
        ...state,
        view: payload,
      };
    case CREATE_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_APPLICATION_REQUEST_SUCCESS:
      return {
        ...state,
        current: { ...state.current, appInfo: payload },
        loading: false,
      };
    case CREATE_APPLICATION_REQUESTT_FAIL:
      return {
        ...state,
        error,
        loading: false,
      };
    case SHOW_CREATE_APPLICATION_DIALOG:
      return {
        ...state,
        showEdit: payload,
      };
    case VALIDATE_NAME_REQUEST_SUCCESS:
      return {
        ...state,
        hasValidName: payload,
      };
    case VALIDATE_NAME_REQUESTT_FAIL:
      return {
        ...state,
        error,
      };
    case SHOW_APPLICATION_PAGES:
      return {
        ...state,
        current: { ...state.current, showPages: payload },
      };
    case SHOW_APPLICATION_CREATE_PAGE:
      return {
        ...state,
        current: {
          ...state.current,
          showCreatePage: payload,
        },
      };
    case SHOW_APPLICATION_SIDEBAR:
      return {
        ...state,
        current: { ...state.current, showSidebar: payload },
      };
    case SHOW_ADD_PAGE:
      return {
        ...state,
        current: { ...state.current, showAddPage: payload },
      };
    case SHOW_ADD_TEMPLATE:
      return {
        ...state,
        current: {
          ...state.current,
          showAddTemplate: payload,
        },
      };
    case SET_CURRENT_PAGE:
      // eslint-disable-next-line no-case-declarations
      const isNewPage = !!(payload.pageInfo && !payload.pageInfo._id);

      // eslint-disable-next-line no-case-declarations
      const pageId = !payload.pageInfo
        ? null
        : payload.pageInfo._id || uuidv4();

      // eslint-disable-next-line no-case-declarations
      const currentPage = payload.pageInfo
        ? {
            ...payload,
            pageInfo: { ...payload.pageInfo, _id: pageId },
          }
        : payload;

      // eslint-disable-next-line no-case-declarations
      const appPages = [
        ...state.current.pages,
        { _id: pageId, ...payload.pageInfo, data: {} },
      ];

      return {
        ...state,
        current: {
          ...state.current,
          pages: isNewPage ? appPages : state.current.pages,
          currentPage,
          changedPages: payload.pageInfo
            ? {
                ...state.current.changedPages,
                [pageId]: {
                  ...payload,
                  pageInfo: { ...payload.pageInfo, _id: pageId },
                },
              }
            : state.current.changedPages,
        },
      };

    case DJSL_DELETE_COMPONENT:
      // eslint-disable-next-line no-case-declarations
      const appInfo = payload.appInfo ? payload.appInfo : state.current.appInfo;
      // eslint-disable-next-line no-case-declarations
      const pageAfterComponentDeletion = {
        ...state.current.currentPage,
        ...djslDeleteComponent(payload.id, state.current.currentPage),
      };
      return {
        ...state,
        current: {
          ...state.current,
          appInfo,
          currentPage: pageAfterComponentDeletion,
          changedPages: {
            ...state.current.changedPages,
            [pageAfterComponentDeletion.pageInfo
              ._id]: pageAfterComponentDeletion,
          },
        },
      };
    case ADD_PAGE:
      return {
        ...state,
        current: {
          ...state.current,
          pages: [...state.current.pages, state.current.currentPage],
          changedPages: {
            ...state.current.changedPages,
            [state.current.currentPage._id || uuidv4()]: state.current
              .currentPage,
          },
        },
      };
    case CLEAR_PAGE:
      return {
        ...state,
        pages: state.current.pages.filter(page => page.id !== payload.id),
        current: {
          ...state.current,
          pages: state.current.pages.filter(page => page.id !== payload.id),
          changedPages: {
            ...state.current.changedPages,
            [payload.id]: undefined,
          },
        },
      };
    case CLEAR_PAGES:
      return {
        ...state,
        pages: [],
        current: {
          ...state.current,
          pages: [],
          changedPages: {},
        },
      };
    case GET_PAGES_REQUEST:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true,
        },
      };
    case GET_PAGES_REQUEST_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          pages: payload.pages,
          loading: false,
        },
      };
    case GET_PAGES_REQUEST_FAIL:
      return {
        ...state,
        current: {
          ...state.current,
          loading: false,
        },
        error,
      };
    case SAVE_PAGE_REQUEST_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const pageAfterRequestSuccess = (payload.pages || []).find(
        page => page._id === state.current.currentPage.pageInfo._id
      );

      // eslint-disable-next-line no-case-declarations
      const currentPageAfterRequestSuccess = {};
      if (pageAfterRequestSuccess) {
        const { data, ...rest } = pageAfterRequestSuccess;
        currentPageAfterRequestSuccess.djr = data;
        currentPageAfterRequestSuccess.pageInfo = rest;
        currentPageAfterRequestSuccess.spacing = 3;
      } else {
        const { data, ...rest } = payload.pages[payload.pages.length - 1];
        currentPageAfterRequestSuccess.djr = data;
        currentPageAfterRequestSuccess.pageInfo = rest;
        currentPageAfterRequestSuccess.spacing = 3;
      }
      return {
        ...state,
        current: {
          ...state.current,
          ...payload,
          currentPage: currentPageAfterRequestSuccess,
          changedPages: {},
        },
      };
    case SAVE_PAGE_REQUEST_FAIL:
      return {
        ...state,
        error,
      };
    case SET_SELECTED_WIDGET:
      return {
        ...state,
        selectedWidget: payload,
        parent: payload.id || state.parent,
      };
    case CLEAR_SELECTED_WIDGET:
      return {
        ...state,
        selectedWidget: null,
        current: {
          ...state.current,
          appSideBar: state.current.appInfo?.isDeployed
            ? "widgetEditor"
            : "widgetList",
        },
      };
    case SET_SELECTED_WIDGET_VERSIONS:
      return {
        ...state,
        selectedWidgetVersions: payload,
      };
    case CLEAR_SELECTED_WIDGET_VERSIONS:
      return {
        ...state,
        selectedWidgetVersions: null,
      };
    case SET_APPLICATION_HOMEPAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_APPLICATION_HOMEPAGE_REQUEST_SUCCESS:
      return {
        ...state,
        current: { ...state.current, pages: payload },
        loading: false,
      };
    case SET_APPLICATION_HOMEPAGE_REQUEST_FAIL:
      return {
        ...state,
        error,
        loading: false,
      };
    case DELETE_PAGE_REQUEST_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          pages: state.current.pages.filter(p => p._id !== payload.id),
          changedPages: {
            ...state.current.changedPages,
            [payload.id]: undefined,
          },
        },
      };
    case DELETE_PAGE_REQUEST_FAIL:
      return {
        ...state,
        error,
      };
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackBar: payload,
      };
    case SET_CURRENT_ROW_COL:
      return {
        ...state,
        selectedRow: payload.row,
        selectedColumn: payload.col,
      };
    case CLEAR_CURRENT_ROW_COL:
      return {
        ...state,
        selectedRow: null,
        selectedColumn: null,
        parent: null,
      };
    case SET_APPLICATION_SECTION:
      return {
        ...state,
        current: { ...state.current, appSection: payload },
      };
    case SET_SIDEBAR_ITEM:
      return {
        ...state,
        current: { ...state.current, appSideBar: payload },
      };
    case DELETE_APP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_APP_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_APP_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error,
      };
    case CLEAR_DELETE_APP_ERROR:
      return {
        ...state,
        error: null,
      };
    case DJSL_ADD_COMPONENT:
      // eslint-disable-next-line no-case-declarations
      const pageAfterComponentAddition = {
        ...state.current.currentPage,
        djr: djslAddComponent(
          payload,
          state.current.currentPage.djr,
          state.current.currentPage.pageInfo._id
        ),
      };

      return {
        ...state,
        current: {
          ...state.current,
          appInfo: payload.appInfo ? payload.appInfo : state.current.appInfo,
          appSideBar: "widgetVersion",
          currentPage: pageAfterComponentAddition,
          changedPages: {
            ...state.current.changedPages,
            [pageAfterComponentAddition.pageInfo
              ._id]: pageAfterComponentAddition,
          },
        },
        selectedWidget: payload.selectedWdgt,
        selectedWidgetVersions: payload.widgetVersions,
      };
    case DJSL_EDIT_COMPONENT:
      return {
        ...state,
        selectedRow: -1,
        selectedColumn: -1,
        parent: payload.parent,
        current: {
          ...state.current,
          appSideBar: "widgetEditor",
        },
        selectedWidget: payload.widget,
      };
    case ADD_SECURITY_DEFS_API: {
      const index = state.current.appInfo.apis.findIndex(
        a => a._id === payload._id
      );
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            apis: state.current.appInfo.apis.map((api, i) => {
              if (i === index) return payload;
              return api;
            }),
          },
        },
        error: null,
      };
    }
    case DEPLOY_APPLICATION_REQUEST_SUCCESS:
      return {
        ...state,
        deployment: payload.deployment,
        error: null,
      };

    case CLEAR_DEPLOYMENT_INFO:
      return {
        ...state,
        deployment: null,
      };
    case GET_APP_LATEST_VERSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_APP_LATEST_VERSION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        current: { ...state.current, latestVersion: payload },
      };
    case GET_APP_LATEST_VERSION_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error,
      };
    case CLEAR_APP_LATEST_VERSION:
      return {
        ...state,
        loading: false,
        current: { ...state.current, latestVersion: null },
      };
    case UPDATE_SNIPPET: {
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            snippets: state.current.appInfo.snippets.map(snippet =>
              snippet.id === payload.id ? payload.snippet : snippet
            ),
          },
        },
      };
    }
    case RESOLVE_PUBLIC_PACKAGE_SUCCESS: {
      if (payload.appId !== state.current.appInfo._id) return state;
      return {
        ...state,
        current: {
          ...state.current,
          appInfo: {
            ...state.current.appInfo,
            packages: [...state.current.appInfo.packages, payload.pack],
          },
        },
      };
    }
    case RESOLVE_PUBLIC_PACKAGE_FAIL:
      return {
        ...state,
        error,
      };
    case SHOW_TENANT_SELECTION_DIALOG:
      return {
        ...state,
        showTenantSelectionDialog: payload,
      };
    default:
      return state;
  }
};
