/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  fetchConfigListWidget,
  setConfigWidget,
} from "actions/configurationActions";
import { fetchConfigHistory, clearConfigHistory } from "actions/historyActions";

import { fetchConfigSchema, fetchConfigData } from "actions/configFormActions";
import SearchBar from "../searchbar/SearchBar";
import Loader from "../loader/Loader";
import theme from "../../AppTheme";
import DisplayType from "../displaytype/DisplayType";
import CardWidgetList from "../widgets/CardWidgetList";
import CardWidgetGrid from "../widgets/CardWidgetGrid";

const useStyles = makeStyles({
  root: {
    "& .MuiBox-searchBar": {
      position: "absolute",
      left: 0,
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  searchWrapper: {
    minHeight: theme.spacing(6) + 2,
  },
});

const PaperConfiguratorAppsWidgets = ({
  setDialogWidget,
  envID,
  application,
  setSearchText,
}) => {
  const envs = useSelector(state => state.envs.list, shallowEqual);
  const widgetList = useSelector(
    state => state.configuration.widgetList,
    shallowEqual
  );
  const { access_token, id_token } = useSelector(
    state => state.authentication,
    shallowEqual
  );
  const loading = useSelector(
    state => state.configuration.loading,
    shallowEqual
  );

  const dispatch = useDispatch();
  const fetchWidgetList = parmas => dispatch(fetchConfigListWidget(parmas));
  const setCurrentWidget = parmas => dispatch(setConfigWidget(parmas));
  const getConfigSchema = parmas => dispatch(fetchConfigSchema(parmas));
  const getConfigData = parmas => dispatch(fetchConfigData(parmas));
  const fetchHistory = parmas => dispatch(fetchConfigHistory(parmas));
  const clearHistory = () => dispatch(clearConfigHistory());

  const classes = useStyles();
  const [grid, setGrid] = useState("list");

  const renderItems = ({ widget }) => {
    return grid === "list" ? (
      <Grid key={widget.name + widget.version} item xs={12}>
        <CardWidgetList
          envs={envs}
          sourceEnvId={envID}
          widget={widget}
          fetchHistory={() => {
            fetchHistory({
              access_token,
              id_token,
              application: widget.application,
              componentId: widget.componentId || "global",
              envID,
            });
          }}
          clearHistory={clearHistory}
          cardAction={() => {
            setCurrentWidget(widget);
            setDialogWidget(true);
            getConfigSchema({
              widget: widget.componentId || "global",
              access_token,
              id_token,
              envID,
            });
            getConfigData({
              application,
              widget: widget.componentId || "global",
              widgetInstanceId: widget.widgetInstanceId,
              envID,
              access_token,
              id_token,
            });
          }}
          isConfig
          moreActions
        />
      </Grid>
    ) : (
      <Grid key={widget.name + widget.version} item xs={12} sm={6} md={4}>
        <CardWidgetGrid
          envs={envs}
          sourceEnvId={envID}
          widget={widget}
          fetchHistory={() => {
            fetchHistory({
              access_token,
              id_token,
              application: widget.application,
              componentId: widget.componentId || "global",
              envID,
            });
          }}
          clearHistory={clearHistory}
          cardAction={() => {
            setCurrentWidget(widget);
            setDialogWidget(true);
            getConfigSchema({
              widget: widget.componentId || "global",
              access_token,
              id_token,
              envID,
            });
            getConfigData({
              application,
              widget: widget.componentId || "global",
              widgetInstanceId: widget.widgetInstanceId,
              stageType: "runtime",
              envID,
              access_token,
              id_token,
            });
          }}
          isConfig
          moreActions
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <Box className={classes.searchWrapper}>
          <Box py={0.25} className="MuiBox-searchOverlay" position="relative">
            <DisplayType grid={grid} handleGridView={setGrid} />
            <SearchBar
              callback={fetchWidgetList}
              callbackParams={{
                access_token,
                id_token,
                application,
                envID,
              }}
              setSearchText={setSearchText}
              btnId="configurationApp_btn"
              searchbarId="configurationApp_container"
            />
          </Box>
        </Box>
      </Grid>
      {!loading ? (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {widgetList?.map(widget => renderItems({ widget }))}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Loader size={theme.spacing(12)} />
        </Grid>
      )}
    </Grid>
  );
};

export default PaperConfiguratorAppsWidgets;
