/* eslint-disable no-nested-ternary */
import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import nodeName from "utils/form-tree-node-name";
import Tooltip from "@material-ui/core/Tooltip";

const WidgetFormOrderItem = ({ index, item, remove }) => {
  return (
    <Card
      className="MuiCard-shadow MuiCard-white MuiCard-ordering MuiCard-shadow animate"
      elevation={0}
    >
      <Grid container alignItems="center" wrap="nowrap" spacing={3}>
        <Grid item xs style={{ paddingRight: "0px" }}>
          <CardActionArea disableRipple disableTouchRipple focusRipple={false}>
            <Tooltip title={nodeName(item, index) || ""}>
              <CardHeader
                style={{ paddingRight: "0px" }}
                title={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Typography
                    variant="h5"
                    style={{ fontSize: "18px" }}
                    component="h3"
                  >
                    {nodeName(item, index)}
                  </Typography>
                }
              />
            </Tooltip>
          </CardActionArea>
        </Grid>
        <Grid item style={{ paddingLeft: "0px" }}>
          <CardActions className="animate">
            <IconButton onClick={() => remove(index)}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};

export default WidgetFormOrderItem;
