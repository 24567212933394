const APIS_REQUEST = "APIS_REQUEST";
const APIS_REQUEST_SUCCESS = "APIS_REQUEST_SUCCESS";
const APIS_REQUEST_FAIL = "APIS_REQUEST_FAIL";
const SHOW_CREATE_API_DIALOG = "SHOW_CREATE_API_DIALOG";
const SAVE_API_REQUEST = "SAVE_API_REQUEST";
const SAVE_API_REQUEST_SUCCESS = "SAVE_API_REQUEST_SUCCESS";
const SAVE_API_REQUEST_FAIL = "SAVE_API_REQUEST_FAIL";
const API_DETAILS_REQUEST = "API_DETAILS_REQUEST";
const API_DETAILS_REQUEST_SUCCESS = "API_DETAILS_REQUEST_SUCCESS";
const API_DETAILS_REQUEST_FAIL = "API_DETAILS_REQUEST_FAIL";
const CLEAR_API_DETAILS = "CLEAR_API_DETAILS";
const DELETE_API_REQUEST = "DELETE_API_REQUEST";
const DELETE_API_REQUEST_SUCCESS = "DELETE_API_REQUEST_SUCCESS";
const DELETE_API_REQUEST_FAIL = "DELETE_API_REQUEST_FAIL";
const DELETE_API_IS_USED_POP_UP_TOGGLE = "DELETE_API_IS_USED_POP_UP_TOGGLE";
const DELETE_VERSION_API_REQUEST = "DELETE_VERSION_API_REQUEST";
const DELETE_VERSION_API_REQUEST_SUCCESS = "DELETE_VERSION_API_REQUEST_SUCCESS";
const DELETE_VERSION_API_REQUEST_FAIL = "DELETE_VERSION_API_REQUEST_FAIL";

const fetchApis = payload => ({
  type: APIS_REQUEST,
  payload,
});

const showCreateApiDialog = payload => ({
  type: SHOW_CREATE_API_DIALOG,
  payload,
});

const saveApi = payload => ({
  type: SAVE_API_REQUEST,
  payload,
});

const fetchApiDetails = payload => ({
  type: API_DETAILS_REQUEST,
  payload,
});

const clearApiDetails = () => ({
  type: CLEAR_API_DETAILS,
});

const deleteApi = payload => ({
  type: DELETE_API_REQUEST,
  payload,
});

const toggleDeleteApiIsUsedPopUp = () => ({
  type: DELETE_API_IS_USED_POP_UP_TOGGLE,
});

const deleteVersionApi = payload => ({
  type: DELETE_VERSION_API_REQUEST,
  payload,
});

export {
  APIS_REQUEST,
  APIS_REQUEST_SUCCESS,
  APIS_REQUEST_FAIL,
  SHOW_CREATE_API_DIALOG,
  SAVE_API_REQUEST,
  SAVE_API_REQUEST_SUCCESS,
  SAVE_API_REQUEST_FAIL,
  API_DETAILS_REQUEST,
  API_DETAILS_REQUEST_SUCCESS,
  API_DETAILS_REQUEST_FAIL,
  CLEAR_API_DETAILS,
  DELETE_API_REQUEST,
  DELETE_API_REQUEST_SUCCESS,
  DELETE_API_REQUEST_FAIL,
  DELETE_API_IS_USED_POP_UP_TOGGLE,
  DELETE_VERSION_API_REQUEST,
  DELETE_VERSION_API_REQUEST_SUCCESS,
  DELETE_VERSION_API_REQUEST_FAIL,
  fetchApis,
  showCreateApiDialog,
  clearApiDetails,
  saveApi,
  fetchApiDetails,
  deleteApi,
  toggleDeleteApiIsUsedPopUp,
  deleteVersionApi,
};
