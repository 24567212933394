/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { ListItem, Box, Typography, Link, IconButton } from "@material-ui/core";
import VfSvgIcon from "../../icons/VfSvgIcon";
import { DEPLOYMENT_FLOWS } from "../../../utils/constants";

const VisitAppMenuItem = ({ application, environment,envType, onClick }) => {  
  const initialTenantValue = useSelector(state => state.tenants.current?.code, shallowEqual)
  const tenantValue = useSelector(state => state.tenants.current?.locale?.code, shallowEqual)
  const currentTenant = initialTenantValue || tenantValue;
  const { deploymentFlow, title, url: appUrl, s3Url: appS3Url } = application
  const isS3Flow = deploymentFlow === DEPLOYMENT_FLOWS.S3
  const targetUrls = isS3Flow ? environment.s3Urls : environment.urls
  const targetAppUrl = isS3Flow ? appS3Url : appUrl
  const targetUrl = targetUrls?.find(
    url => url._id.toString() === targetAppUrl
  )
  let targetUrlValue = targetUrl?.value
  targetUrlValue = (targetUrlValue || '').replace(/\/+$/, ''); // Remove trailing slashes

  targetUrlValue = targetUrlValue.match(/https?:\/\//g)?targetUrlValue: `https://${targetUrlValue}`

  if (targetUrlValue && isS3Flow && !targetUrl?.isRoot)
    targetUrlValue = `${targetUrlValue}/${currentTenant}/${envType}/${application.title}`

  return (
    <ListItem data-testid={`open${title}_container`}>
      <Box display="block">
        <Typography variant="body2">
          {targetUrlValue.substring(0, 25)}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="secondary" onClick={onClick} data-testid={`open${title}_btn`}>
            <Link
              href={targetUrlValue || "#"}
              rel="noreferrer"
              target="_blank"
              variant="body2"
              color="secondary"
            >
              {`Open ${title}`}
            </Link>
          </Typography>
          <Link
            href={targetUrlValue || "#"}
            rel="noreferrer"
            target="_blank"
          >
            <IconButton color="secondary" size="small" onClick={onClick}>
              <VfSvgIcon fontSize="small" icon="externalLink" viewBox="24" />
            </IconButton>
          </Link>
        </Box>
      </Box>
    </ListItem>
  );
};

export default VisitAppMenuItem;
