import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import VfSvgIcon from "../icons/VfSvgIcon";
import { MVA10 } from "../../utils/constants";

const FilterMenuThemes = ({ handleFilters }) => {
  const [anchorElThemes, setAnchorElThemes] = useState(null);
  const [filterActive, setFilterActive] = useState("All themes");

  const handleClickThemes = event => {
    setAnchorElThemes(event.currentTarget);
  };
  const handleCloseThemes = () => {
    setAnchorElThemes(null);
  };
  return (
    <>
      <Button
        variant="contained"
        size="small"
        endIcon={<VfSvgIcon icon="chevronDown" fontSize="small" />}
        onClick={handleClickThemes}
      >
        {filterActive}
      </Button>
      <Menu
        id="simple-menu"
        elevation={0}
        anchorEl={anchorElThemes}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorElThemes)}
        onClose={handleCloseThemes}
      >
        <MenuItem
          data-name="theme"
          data-value={null}
          onClick={e => {
            handleCloseThemes();
            handleFilters(e);
            setFilterActive("All themes");
          }}
          selected={filterActive === "All themes"}
        >
          All themes
        </MenuItem>
        <MenuItem
          selected={filterActive === "WS10"}
          data-name="theme"
          data-value={MVA10}
          onClick={e => {
            handleCloseThemes();
            handleFilters(e);
            setFilterActive("WS10");
          }}
        >
          WS10
        </MenuItem>
        <MenuItem
          selected={filterActive === "DCL"}
          data-name="theme"
          data-value="DCL"
          onClick={e => {
            handleCloseThemes();
            handleFilters(e);
            setFilterActive("DCL");
          }}
        >
          DCL
        </MenuItem>
      </Menu>
    </>
  );
};

export default FilterMenuThemes;
