import { call, put, takeLatest } from "redux-saga/effects";

import {
  CONFIG_HISTORY_REQUEST,
  CONFIG_HISTORY_REQUEST_SUCCESS,
  CONFIG_HISTORY_REQUEST_FAIL,
} from "actions/historyActions";
import historyService from "services/HistoryService";

function* configHistoryWorker({ payload }) {
  try {
    const { data } = yield call(historyService.fetchConfigHistory, payload);
    yield put({
      type: CONFIG_HISTORY_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: CONFIG_HISTORY_REQUEST_FAIL, error });
  }
}

function* configHistoryWatcher() {
  yield takeLatest(CONFIG_HISTORY_REQUEST, configHistoryWorker);
}

const appsSaga = [configHistoryWatcher()];

export default appsSaga;
