/* eslint-disable camelcase */
import React, { useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardMedia as MuiCardMedia,
  CardActionArea,
  CardContent,
  CardActions,
  SvgIcon,
  Divider,
  ListItem,
  Snackbar,
  FormControl,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isEqual } from "lodash";
import theme from "../../AppTheme";
import VfAlert from "../vf/VfAlert";
import VfDialog from "../vf/VfDialog";
import VfDialogConfirm from "../vf/VfDialogConfirm";
import VfDialogMedia from "../vf/VfDialogMedia";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  listItem: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&:focus": {
      outline: "none",
    },
  },
});

const CardMedia = ({
  item,
  filterTypes,
  isSelected,
  isBulkSelected,
  isForBulkSelect,
  clickSelected,
  putMedia,
  eraseMedia,
  filters,
  access_token,
  id_token,
  searchText,
  inModal,
  setSelected,
}) => {
  const classes = useStyles();
  const [mediaDialog, setMediaDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editDialogName, setEditDialogName] = useState(item.title);
  const [editDialogTags, setEditDialogTags] = useState(item.tags);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hover, setHover] = useState(false);
  const [snackBar, setSnackBar] = useState({
    show: false,
    message: "",
    severity: 3,
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Card
      elevation={0}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classNames({
        "MuiCard-media": true,
        "MuiCard-media-image": item.mediaType === "image",
        "MuiCard-media-video": item.mediaType === "video",
        "MuiCard-media-audio": item.mediaType === "audio",
        "MuiCard-media-file": item.mediaType === "other",
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-primary": isSelected,
        "Mui-selected": isSelected,
        "Mui-forSelect": isForBulkSelect,
        "MuiCard-rounded": true,
        "MuiCard-grid": true,
        "Mui-hover": hover,
      })}
      data-testid="card_media"
    >
      <CardActions disableSpacing>
        <IconButton
          className="MuiIconButton-colorWhite"
          color="secondary"
          aria-label="add to favorites"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <VfSvgIcon icon="more" viewBox={24} />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={0}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <ListItem className={classes.listItem}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography variant="body2">{item.title}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="secondary">
                  Copy link
                </Typography>
              </Grid>
              <Grid item>
                <CopyToClipboard text={item.url}>
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => {
                      handleClose();
                      setSnackBar({
                        ...snackBar,
                        show: true,
                        message: "Copied to clipboard",
                      });
                    }}
                  >
                    <VfSvgIcon fontSize="small" icon="copy" viewBox="24" />
                  </IconButton>
                </CopyToClipboard>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleClose();
              setEditDialog(true);
            }}
          >
            EDIT INFO
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setDeleteDialog(true);
            }}
          >
            DELETE FILE
          </MenuItem>
        </Menu>
      </CardActions>
      <CardActionArea
        onClick={() => {
          if (!isBulkSelected && !inModal && item.mediaType !== "other")
            setMediaDialog(true);
          if (isBulkSelected && !inModal) clickSelected(item);
          if (inModal) {
            setSelected(item);
          }
          if (!inModal && !isBulkSelected && item.mediaType === "other")
            setErrorDialog(true);
        }}
      >
        <MuiCardMedia image={item.url}>
          {item.mediaType !== "image" &&
            filterTypes
              .filter(filter => filter.type === item.mediaType)
              .map(filter => {
                return (
                  <SvgIcon
                    key={filter.placeholder}
                    className="MuiSvgIcon-fontSizeXLarge"
                    component={filter.placeholder}
                    viewBox="0 0 56 56"
                  />
                );
              })}
        </MuiCardMedia>
        <CardContent>
          <Box
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">{item.title}</Typography>
            {item.type === "video" && (
              <VfSvgIcon icon="video" fontSize="small" viewBox={24} />
            )}
          </Box>
        </CardContent>
      </CardActionArea>
      <VfDialogMedia
        openDialog={mediaDialog}
        item={item}
        maxWidth="md"
        closeAction={() => setMediaDialog(false)}
      />
      <VfDialogConfirm
        buttonConfirmAction={() => {
          setDeleteDialog(false);
          eraseMedia({
            access_token,
            id_token,
            keys: [item.key],
            filters,
            searchText,
          });
        }}
        openDialog={deleteDialog}
        buttonCancelAction={() => setDeleteDialog(false)}
        buttonCancelText="Cancel"
        buttonConfirmText="Delete"
      >
        <Typography variant="body1" align="center">
          Are you sure you want to delete
          <br />
          <strong>{item.title}</strong>
          <br />
          permanently?
        </Typography>
      </VfDialogConfirm>
      <VfDialog
        title="Edit info"
        openDialog={editDialog}
        buttonConfirmText="Save"
        buttonCloseAction={() => setEditDialog(false)}
        buttonConfirmAction={() => {
          setEditDialog(false);
          putMedia({
            access_token,
            id_token,
            key: item.key,
            title: editDialogName,
            tags: editDialogTags,
            filters,
            searchText,
          });
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={4}>
                <Card
                  className={classNames({
                    "MuiCard-media": true,
                    "MuiCard-media-thumb": true,
                    "MuiCard-outlined": true,
                    "MuiCard-rounded": true,
                  })}
                  elevation={0}
                >
                  <MuiCardMedia image={item.url} />
                </Card>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" gutterBottom>
                  512x512 • 7Kb
                </Typography>
                <Typography variant="body2">
                  {`Original file name: ${item.title}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                name="name"
                variant="outlined"
                label="Name"
                type="text"
                value={editDialogName}
                onChange={e => setEditDialogName(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              freeSolo
              id="tags-outlined"
              options={editDialogTags}
              value={editDialogTags}
              onChange={(event, values) => {
                setEditDialogTags(values);
              }}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label="Tags (Optional)"
                />
              )}
            />
          </Grid>
        </Grid>
      </VfDialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBar.show}
        onClose={() => setSnackBar({ ...snackBar, show: false, message: null })}
      >
        <VfAlert severity={snackBar.severity} message={snackBar.message} />
      </Snackbar>
      <VfDialogConfirm
        buttonConfirmAction={() => {
          setErrorDialog(false);
        }}
        openDialog={errorDialog}
        buttonConfirmText="OK"
        buttonCancelAction={() => {
          setErrorDialog(false);
        }}
      >
        <Typography variant="body1" align="center">
          No preview available
        </Typography>
      </VfDialogConfirm>
    </Card>
  );
};

export default React.memo(CardMedia, isEqual);
