import axios from "axios";

const logout = (store) => {
  const { settings } = store.getState();
  store.dispatch({ type: "LOGOUT" });
  window.location.href = `https://login.windows.net/${settings?.tenantId}/oauth2/v2.0/logout?post_logout_redirect_uri=${settings?.logoutRedirectUri}&state=sessionExpired`;
};

export default {
  setupInterceptors: store => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const originalRequest = error.config;
        if (error.response?.status === 401) {
          if (!originalRequest._retry) {
            originalRequest._retry = true;
            try {
              const auth = JSON.parse(localStorage.getItem("auth"));
              await axios.post("/api/appbuilder/token/refresh");

              store.dispatch({ type: "LOGGED_IN", payload: auth });
              return axios(originalRequest);
            } catch (_error) {
              logout(store);
              return Promise.reject(_error);
            }
          } else logout(store);
        }
        return Promise.reject(error);
      }
    );
  },
};
