/* eslint-disable camelcase */
/* eslint-disable no-useless-computed-key */
/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import SearchBar from "components/searchbar/SearchBar";
import DisplayType from "components/displaytype/DisplayType";
import { setListGridView } from "actions/listGridViewActions";
import { fetchConfigListWidget } from "actions/widgetsActions";
import { fetchTranslationWidgetList } from "actions/translationsActions";
import WidgetsItem from "./WidgetsItem";
import WidgetsItemGrid from "./WidgetsItemGrid";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    ["& .MuiBox-searchBar"]: {
      position: "absolute",
      left: 0,
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
});

const WidgetsItems = ({
  translationWidgets,
  configurationWidgets,
  deployChanges,
  setListGridViewWidget,
  listGridView,
  getConfigListWidget,
  auth,
  envID,
  translation,
  fetchWidgetList,
}) => {
  const classes = useStyles();
  const handleChangeGrid = view => {
    setListGridViewWidget({ widgetView: view });
  };
  const { touchpoint, app } = useParams();
  const { access_token, id_token } = auth;
  const widgets = translation ? translationWidgets : configurationWidgets;
  return (
    <Box py={4} className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box py={0.25} className="MuiBox-searchOverlay" position="relative">
              <DisplayType
                grid={listGridView}
                handleGridView={handleChangeGrid}
              />
              <SearchBar
                callback={translation ? fetchWidgetList : getConfigListWidget}
                callbackParams={
                  translation
                    ? { envID, access_token, id_token }
                    : {
                        envID,
                        access_token,
                        id_token,
                        touchpoint,
                        application: app,
                      }
                }
              />
            </Box>
          </Grid>
          {widgets &&
            widgets.length > 0 &&
            widgets.map(
              ({
                application,
                componentId,
                data,
                isSaved,
                version,
                _id,
                name,
              }) => {
                return listGridView === "grid" ? (
                  <Grid
                    key={translation ? _id : componentId}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <WidgetsItemGrid
                      title={
                        translation
                          ? `${name} ${version} translation`
                          : `${data.component || "global"} configuration`
                      }
                      translation={translation}
                      /* eslint-disable */
                      image={
                        <VfSvgIcon
                          className="MuiSvgIcon-fontSizeXLarge"
                          icon={componentId ? "widget" : "global"}
                          viewBox={56}
                        />
                      }
                      /* eslint-enable */
                      data={data}
                      application={application}
                      componentId={componentId}
                      deployChanges={deployChanges}
                      isSaved={isSaved}
                      widget={name}
                      widgetId={_id}
                      version={version}
                      success={!!isSaved}
                      label={isSaved ? "Ready for deployment" : ""}
                    />
                  </Grid>
                ) : (
                  <Grid key={translation ? _id : componentId} item xs={12}>
                    <WidgetsItem
                      title={
                        translation
                          ? `${name} ${version} translation`
                          : `${data.component || "global"} configuration`
                      }
                      translation={translation}
                      /* eslint-disable */
                      image={
                        <VfSvgIcon
                          className="MuiSvgIcon-fontSizeXLarge"
                          fontSize="large"
                          icon={componentId ? "widget" : "global"}
                          viewBox={56}
                        />
                      }
                      /* eslint-enable */
                      data={data}
                      application={application}
                      componentId={componentId}
                      deployChanges={deployChanges}
                      isSaved={isSaved}
                      widget={name}
                      widgetId={_id}
                      version={version}
                      success={!!isSaved}
                      label={isSaved ? "Ready for deployment" : ""}
                    />
                  </Grid>
                );
              }
            )}
        </Grid>
      </Container>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    envID: state.envs.id,
    configurationWidgets: state.widgets.list,
    translationWidgets: state.translations.list,
    listGridView: state.listGridView.widgetView,
  };
};

const mapDispatchToProps = dispatch => ({
  setListGridViewWidget: payload => dispatch(setListGridView(payload)),
  getConfigListWidget: payload => dispatch(fetchConfigListWidget(payload)),
  fetchWidgetList: params => dispatch(fetchTranslationWidgetList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsItems);
