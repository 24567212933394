const FETCH_APP_VERSIONS = "FETCH_APP_VERSIONS";
const FETCH_APP_VERSIONS_SUCCESS = "FETCH_APP_VERSIONS_SUCCESS";
const FETCH_APP_VERSIONS_FAIL = "FETCH_APP_VERSIONS_FAIL";
const FETCH_APP_VERSIONS_DETAILS = "FETCH_APP_VERSIONS_DETAILS";
const FETCH_APP_VERSIONS_DETAILS_SUCCESS = "FETCH_APP_VERSIONS_DETAILS_SUCCESS";
const FETCH_APP_VERSIONS_DETAILS_FAIL = "FETCH_APP_VERSIONS_DETAILS_FAIL";
const CLOSE_DIALOG = "CLOSE_DIALOG";
const SET_VERSION_FROM = "SET_VERSION_FROM";
const SET_VERSION_TO = "SET_VERSION_TO";

const setVersionFrom = payload => ({
  type: SET_VERSION_FROM,
  payload,
});
const setVersionTo = payload => ({
  type: SET_VERSION_TO,
  payload,
});

const fetchAppVersions = payload => ({
  type: FETCH_APP_VERSIONS,
  payload,
});

const fetchAppVersionsDetails = payload => ({
  type: FETCH_APP_VERSIONS_DETAILS,
  payload,
});

const closeDialog = () => ({
  type: CLOSE_DIALOG,
});

export {
  FETCH_APP_VERSIONS,
  FETCH_APP_VERSIONS_SUCCESS,
  FETCH_APP_VERSIONS_FAIL,
  CLOSE_DIALOG,
  SET_VERSION_FROM,
  SET_VERSION_TO,
  FETCH_APP_VERSIONS_DETAILS,
  FETCH_APP_VERSIONS_DETAILS_SUCCESS,
  FETCH_APP_VERSIONS_DETAILS_FAIL,
  fetchAppVersions,
  closeDialog,
  setVersionFrom,
  setVersionTo,
  fetchAppVersionsDetails,
};
