/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { cloneDeep } from "lodash";

import schemaToTree from "utils/schema-to-tree";
import WidgetFormOrderItem from "./WidgetFormOrderItem";
import VfSvgIcon from "../icons/VfSvgIcon";

const jp = require("jsonpath");

const WidgetFormOrderList = ({
  configForm,
  setConfigTree,
  updateConfigData,
  onAddItem,
  onSetItemSchema,
  onSetItemData,
}) => {
  const { selectedTreeItem } = configForm;
  const { childrenSchema } = selectedTreeItem;
  const items = jp.query(configForm.data, configForm.selectedTreeItem.id)[0];

  const partial = (fn, ...argsToApply) => {
    return (...restArgsToApply) => {
      return fn(...argsToApply, ...restArgsToApply);
    };
  };

  const swapArrayElements = (array, indexA, indexB) => {
    if (array.length === 1) return array;
    array.splice(indexB, 1, array.splice(indexA, 1, array[indexB])[0]);

    const cloneData = cloneDeep(configForm.data);
    jp.apply(cloneData, configForm.selectedTreeItem.id, () => {
      return array;
    });
    const { schema } = configForm;
    const treeData = schemaToTree(undefined, "$", schema, cloneData);
    setConfigTree(treeData);
    return updateConfigData(cloneData);
  };

  // eslint-disable-next-line consistent-return
  const removeArrayElements = (array, index) => {
    let skipDelete = false;
    if (!skipDelete) {
      array.splice(index, 1);
      const cloneData = cloneDeep(configForm.data);
      jp.apply(cloneData, configForm.selectedTreeItem.id, () => {
        return array;
      });
      const { schema } = configForm;
      const treeData = schemaToTree(undefined, "$", schema, cloneData);
      setConfigTree(treeData);
      return updateConfigData(cloneData);
    }
  };

  const addArrayElements = () => {
    let obj = {};
    if (childrenSchema.properties) {
      Object.entries(childrenSchema.properties).forEach(entry => {
        const [key, value] = entry;
        value.type === "array"
          ? (obj[key] = [])
          : value.type === "object"
          ? (obj[key] = {})
          : value.type === "boolean"
          ? (obj[key] = false)
          : value.type === "integer"
          ? (obj[key] = 0)
          : value.enum
          ? // eslint-disable-next-line prefer-destructuring
            (obj[key] = value.enum[0])
          : (obj[key] = value.default);
      });
      delete childrenSchema.title;
    } else {
      obj = childrenSchema;
    }

    onSetItemSchema(childrenSchema);
    onSetItemData(obj);
    onAddItem();
  };

  const swap = partial(swapArrayElements, cloneDeep(items));
  const remove = partial(removeArrayElements, cloneDeep(items));

  // eslint-disable-next-line no-underscore-dangle

  return (
    <>
      <Grid container spacing={2}>
        {items &&
          items.length > 0 &&
          items.map((value, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={value[Object.keys(value)[0]] + index} item xs={12}>
                <WidgetFormOrderItem
                  index={index}
                  item={value}
                  swap={swap}
                  remove={remove}
                  itemsLength={items.length}
                />
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<VfSvgIcon icon="add" viewBox={24} />}
            onClick={addArrayElements}
            data-testid="addItem_btn"
          >
            Add item
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default WidgetFormOrderList;
