/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
import React from "react";
import { Grid, Typography, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

import theme from "AppTheme";
import VfDialog from "../vf/VfDialogFull";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(6, 3, 4, 3),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    padding: theme.spacing(0, 1),
  },
});

const ShowSwaggerDialog = ({
  show,
  setShow,
  env,
  selectedFile,
  setSelectedFile,
}) => {
  const classes = useStyles();

  return (
    <VfDialog
      appBar={
        <>
          <Box className={classes.titleContainer}>
            <VfSvgIcon
              icon={env.title.toLowerCase()}
              viewBox={24}
              fontSize="large"
            />
            <Typography
              variant="body1"
              component="h5"
              className={classes.title}
            >
              {env.title}
            </Typography>
          </Box>
        </>
      }
      openDialog={show}
      buttonCloseAction={() => {
        setSelectedFile(null);
        setShow(false);
      }}
      buttonCloseText="Close"
      // maxWidth="70vw"
      // maxHeight="100%"
      // openFullScreen
    >
      <Container>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <SwaggerUI spec={JSON.parse(selectedFile.fileData)} />
          </Grid>
        </Grid>
      </Container>
    </VfDialog>
  );
};

export default ShowSwaggerDialog;
