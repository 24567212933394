const RESET_MEDIA = "RESET_MEDIA";
const CLEAR_MEDIA_ERROR = "CLEAR_MEDIA_ERROR";
const MEDIA_REQUEST = "MEDIA_REQUEST";
const MEDIA_REQUEST_SUCCESS = "MEDIA_REQUEST_SUCCESS";
const MEDIA_REQUEST_FAIL = "MEDIA_REQUEST_FAIL";
const FILTER_MEDIA_REQUEST = "FILTER_MEDIA_REQUEST";
const FILTER_MEDIA_REQUEST_SUCCESS = "FILTER_MEDIA_REQUEST_SUCCESS";
const FILTER_MEDIA_REQUEST_FAIL = "FILTER_MEDIA_REQUEST_FAIL";
const UPLOAD_MEDIA_REQUEST = "UPLOAD_MEDIA_REQUEST";
const UPLOAD_MEDIA_REQUEST_SUCCESS = "UPLOAD_MEDIA_REQUEST_SUCCESS";
const UPLOAD_MEDIA_REQUEST_FAIL = "UPLOAD_MEDIA_REQUEST_FAIL";
const UPDATE_MEDIA_REQUEST = "UPDATE_MEDIA_REQUEST";
const UPDATE_MEDIA_REQUEST_SUCCESS = "UPDATE_MEDIA_REQUEST_SUCCESS";
const UPDATE_MEDIA_REQUEST_FAIL = "UPDATE_MEDIA_REQUEST_FAIL";
const DELETE_MEDIA_REQUEST = "DELETE_MEDIA_REQUEST";
const DELETE_MEDIA_REQUEST_SUCCESS = "DELETE_MEDIA_REQUEST_SUCCESS";
const DELETE_MEDIA_REQUEST_FAIL = "DELETE_MEDIA_REQUEST_FAIL";
const CLEAR_MEDIA_REQUEST_MESSAGE = 'CLEAR_MEDIA_REQUEST_MESSAGE';

const resetMedia = () => ({
  type: RESET_MEDIA,
});

const clearMediaError = () => ({
  type: CLEAR_MEDIA_ERROR,
});

const clearSuccessMessage = () => ({
  type: CLEAR_MEDIA_REQUEST_MESSAGE
})
const fetchMedia = payload => ({
  type: MEDIA_REQUEST,
  payload,
});

const filterFetchMedia = payload => ({
  type: FILTER_MEDIA_REQUEST,
  payload,
});

const uploadMedia = payload => ({
  type: UPLOAD_MEDIA_REQUEST,
  payload,
});

const updateMedia = payload => ({
  type: UPDATE_MEDIA_REQUEST,
  payload,
});

const deleteMedia = payload => ({
  type: DELETE_MEDIA_REQUEST,
  payload,
});

const uploadMediaError = error => ({
  type: UPLOAD_MEDIA_REQUEST_FAIL,
  error
})

export {
  RESET_MEDIA,
  CLEAR_MEDIA_ERROR,
  MEDIA_REQUEST,
  MEDIA_REQUEST_SUCCESS,
  MEDIA_REQUEST_FAIL,
  FILTER_MEDIA_REQUEST,
  FILTER_MEDIA_REQUEST_SUCCESS,
  FILTER_MEDIA_REQUEST_FAIL,
  UPLOAD_MEDIA_REQUEST,
  UPLOAD_MEDIA_REQUEST_SUCCESS,
  UPLOAD_MEDIA_REQUEST_FAIL,
  UPDATE_MEDIA_REQUEST,
  UPDATE_MEDIA_REQUEST_SUCCESS,
  UPDATE_MEDIA_REQUEST_FAIL,
  DELETE_MEDIA_REQUEST,
  DELETE_MEDIA_REQUEST_SUCCESS,
  DELETE_MEDIA_REQUEST_FAIL,
  CLEAR_MEDIA_REQUEST_MESSAGE,
  resetMedia,
  clearMediaError,
  fetchMedia,
  filterFetchMedia,
  uploadMedia,
  updateMedia,
  deleteMedia,
  uploadMediaError,
  clearSuccessMessage
};
