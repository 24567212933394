/* eslint-disable no-param-reassign */
import _ from "lodash";
import jp from "jsonpath";

const MFA_WIDGET_NAME = "micro-frontend-app";

function extractMFAppsFromDJR(pageDJR) {
  const mFAppComponents = jp.query(
    pageDJR,
    `$..[?(@.__component=='${MFA_WIDGET_NAME}')]`
  );
  return _.map(mFAppComponents, component => {
    return {
      name: component.__props.name,
      url: component.__props.url,
    };
  });
}

const fillPagesMicroFrontendApps = pages => {
  _.forEach(pages, page => {
    if (page.isMicroFrontend) {
      page.microFrontendApps = extractMFAppsFromDJR(page.data);
    } else {
      page.microFrontendApps = [];
    }
  });
};

// eslint-disable-next-line import/prefer-default-export
export { fillPagesMicroFrontendApps };
