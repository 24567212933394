/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
import React, { useState, useCallback, useEffect, Fragment } from "react";
import { Grid, Button, IconButton } from "@material-ui/core";
import uuidv4 from "uuid/v4";
import { set, get, omit } from "lodash";

import VfSvgIcon from "../icons/VfSvgIcon";
import CallPutTreeViewField from "./CallPutTreeViewField";
import ApiParamsTree from "./ApiParamsTree";
import FunctionField from "./FunctionField";
import ArrayList from "./ArrayList";

const ApiParameter = ({
  param,
  setSelectedEffect,
  selectedEffect,
  element,
  modeler,
}) => {
  const [isLeaf, setIsLeaf] = useState(!param.schema);
  const [isArray, setIsArray] = useState(false);
  const [paramProperty, setParamProperty] = useState(null);
  const [childrenSchema, setChildrenSchema] = useState(null);

  // isPutEffect ? "$.children"
  const [expanded, setExpanded] = useState([
    "$",
    `$.children.${param.name}.children`,
  ]);

  const handleSetExpanded = useCallback(value => setExpanded(value), []);

  const handleClickNode = node => {
    setParamProperty(node.id.substring(2));
    if (node.showArray) {
      setIsArray(true);
      setChildrenSchema(node.childrenSchema);
    } else {
      setIsArray(false);
      setChildrenSchema(null);
    }
    if ((!node.children || !node.children.length) && !node.showArray) {
      setIsLeaf(true);
    } else {
      setIsLeaf(false);
    }
  };

  const onDeleteItem = index => {
    const newParamFields = [
      ...(!paramProperty
        ? selectedEffect.arguments?.children[param.name]?.values ?? []
        : get(selectedEffect.arguments, `${paramProperty}.values`) ?? []),
    ];
    newParamFields.splice(index, 1);
    let obj = {};
    if (paramProperty) {
      obj = {
        ...selectedEffect.arguments,
      };
      set(obj, `${paramProperty}.values`, newParamFields);
    } else {
      obj = { ...selectedEffect.arguments };
      obj.children[param.name].values = newParamFields;
    }
    setSelectedEffect({
      ...selectedEffect,
      arguments: {
        ...selectedEffect.arguments,
        ...obj,
      },
    });
  };

  const onAddItem = () => {
    if (isLeaf) {
      const newParamFields = [
        ...(!paramProperty
          ? selectedEffect.arguments?.children[param.name]?.values ?? []
          : get(selectedEffect.arguments, `${paramProperty}.values`) ?? []),
      ];
      newParamFields.push({
        key: uuidv4(),
        path: "",
      });
      let obj = {};
      if (paramProperty) {
        obj = {
          ...(selectedEffect.arguments ?? {}),
        };
        set(obj, `${paramProperty}.values`, newParamFields);
      } else {
        obj = { ...selectedEffect.arguments };
        if (!obj.children[param.name]) obj.children[param.name] = {};
        obj.children[param.name].values = newParamFields;
      }
      setSelectedEffect({
        ...selectedEffect,
        arguments: {
          ...selectedEffect.arguments,
          ...obj,
        },
      });
    } else if (!get(selectedEffect.arguments, paramProperty)) {
      let obj;
      if (paramProperty) {
        obj = {
          ...(selectedEffect.arguments ?? {}),
        };
        set(obj, paramProperty, {});
      }

      const newExpaned = expanded;
      newExpaned.unshift(`$.${paramProperty}`);
      setExpanded(newExpaned);

      setSelectedEffect({
        ...selectedEffect,
        arguments: {
          ...selectedEffect.arguments,
          ...obj,
        },
      });
    } else {
      const obj = omit(selectedEffect.arguments, [paramProperty]);
      setSelectedEffect({
        ...selectedEffect,
        arguments: {
          ...obj,
        },
      });
    }
  };

  const onAddElement = () => {
    const newParamFields =
      get(selectedEffect.arguments, `${paramProperty}`) ?? [];
    if (childrenSchema?.type === "object") {
      newParamFields.push({
        key: uuidv4(),
        children: {},
      });
    } else {
      newParamFields.push({
        key: uuidv4(),
        function: "",
        values: [{ key: uuidv4(), path: "" }],
      });
    }

    const newExpaned = expanded;
    newExpaned.unshift(`$.${paramProperty}`);
    setExpanded(newExpaned);

    const obj = {
      ...(selectedEffect.arguments ?? {}),
    };
    set(obj, `${paramProperty}`, newParamFields);

    setSelectedEffect({
      ...selectedEffect,
      arguments: {
        ...selectedEffect.arguments,
        ...obj,
      },
    });
  };

  const onRemoveElement = index => {
    const newParamFields =
      get(selectedEffect.arguments, `${paramProperty}`) ?? [];

    newParamFields.splice(index, 1);
    const obj = {
      ...(selectedEffect.arguments ?? {}),
    };
    set(obj, `${paramProperty}`, newParamFields);

    setSelectedEffect({
      ...selectedEffect,
      arguments: {
        ...selectedEffect.arguments,
        ...obj,
      },
    });
  };

  const show = !param.schema || !!paramProperty;
  return (
    <Grid container>
      {param.schema && (
        <Grid item xs={3}>
          <ApiParamsTree
            name={param.name}
            args={selectedEffect.arguments}
            schema={param.schema}
            handleClickNode={handleClickNode}
            handleSetExpanded={handleSetExpanded}
            parentExpanded={expanded}
          />
        </Grid>
      )}
      <Grid item xs={param?.schema ? 9 : 12}>
        {show && !isArray && (
          <Grid container spacing={3}>
            {[
              ...((!paramProperty
                ? selectedEffect?.arguments?.children[param.name]?.values
                : get(selectedEffect.arguments, `${paramProperty}.values`)) ??
                []),
            ].map((f, fIndex) => (
              <Grid key={f.key} item xs={12}>
                <Grid
                  container
                  spacing={3}
                  wrap="nowrap"
                  alignItems="center"
                  key={f.key}
                >
                  <Grid item xs>
                    <CallPutTreeViewField
                      key={`${f.key}-field`}
                      title={paramProperty?.split(".")?.pop() || param.name}
                      element={element}
                      modeler={modeler}
                      setSelectedEffect={setSelectedEffect}
                      selectedEffect={selectedEffect}
                      fieldIndex={fIndex}
                      paramName={param.name}
                      paramProperty={paramProperty}
                      fValue={f.path || f.code || f.literal}
                      valueType={
                        f.literal ? "static" : f.code ? "javascript" : "dynamic"
                      }
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton
                      onClick={() => {
                        onDeleteItem(fIndex);
                      }}
                    >
                      <VfSvgIcon icon="delete" viewBox={24} fontSize="large" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs="auto">
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<VfSvgIcon icon="add" viewBox={24} />}
                    onClick={onAddItem}
                  >
                    {isLeaf
                      ? "Add item"
                      : !get(selectedEffect.arguments, paramProperty)
                      ? "Add item"
                      : "Remove item"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FunctionField
                show={
                  !paramProperty
                    ? !!selectedEffect?.arguments?.children[param.name]?.values
                        ?.length
                    : get(selectedEffect.arguments, `${paramProperty}.values`)
                        ?.length
                }
                paramProperty={paramProperty}
                paramName={param.name}
                setSelectedEffect={setSelectedEffect}
                selectedEffect={selectedEffect}
              />
            </Grid>
          </Grid>
        )}
        {show && isArray && (
          <ArrayList
            onAddElement={onAddElement}
            onRemoveElement={onRemoveElement}
            items={get(selectedEffect.arguments, `${paramProperty}`) ?? []}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ApiParameter;
