import React from "react";
import { Grid } from "@material-ui/core";
import FilterMenuEnvironments from "../filters/FilterMenuEnvironments";
import FilterMenuThemes from "../filters/FilterMenuThemes";
import FilterMenuCategories from "../filters/FilterMenuCategories";

export default function PaperTranslationsHeader({
  envs,
  clearEnvironment,
  setEnvironment,
  widgetsCategories,
  handleFilters,
}) {
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs="auto">
        <FilterMenuThemes handleFilters={handleFilters} />
      </Grid>
      <Grid item xs="auto">
        <FilterMenuCategories
          categories={widgetsCategories}
          handleFilters={handleFilters}
        />
      </Grid>
      <Grid item xs>
        <Grid container justify="flex-end">
          <Grid item xs="auto">
            <FilterMenuEnvironments
              envs={envs}
              clearEnvironment={clearEnvironment}
              setEnvironment={setEnvironment}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
