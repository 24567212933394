/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  TextField,
  FormControl,
  CardActions,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import axios from "axios";
import YAML from "yaml";

import theme from "../../AppTheme";
import VfDialog from "../vf/VfDialog";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  card: {
    padding: theme.spacing(0),
    position: "relative",
  },
  cardHeader: {
    padding: theme.spacing(1),
  },
  cardActions: {
    position: "absolute",
    opacity: 0,
    right: 0,
    top: 0,
    zIndex: 1,
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.standard,
    }),
  },
  cardActionsShow: {
    opacity: 1,
  },
});

export default function AddSwaggerDialog({
  show,
  setShow,
  setShowSelectEnvsDialog,
  setSwaggerFile,
}) {
  const classes = useStyles();

  const [swaggerType, setSwaggerType] = useState("File");
  const [showDelete, setShowDelete] = useState(false);
  const [dragReject, setDragReject] = useState(false);
  const [validUrl, setValidUrl] = useState(false);
  const [url, setUrl] = useState("");
  const [file, setFile] = useState(null);

  const validateUrl = value => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  };

  const getFileExtension = filename => {
    return filename.slice(
      (Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1
    );
  };

  const onConfirmAction = async () => {
    try {
      if (swaggerType === "File") {
        const fileExtension = getFileExtension(file[0].name);
        const blob = new Blob(file);
        let content = await blob.text();
        if (fileExtension === "yaml" || fileExtension === "yml") {
          content = YAML.parse(content);
        } else {
          content = JSON.parse(content);
        }
        setSwaggerFile(JSON.stringify(content));
      } else if (swaggerType !== "File" && validUrl) {
        let { data: content } = await axios.get(url);
        const fileExtension = getFileExtension(url);
        if (fileExtension === "yaml" || fileExtension === "yml") {
          content = YAML.parse(content);
        }
        setSwaggerFile(JSON.stringify(content));
      }
      setShow(false);
      setShowSelectEnvsDialog(true);
    } catch (error) {
      console.log("Error in parsing swagger file", error.message);
    }
  };

  return (
    <VfDialog
      title="Add swagger"
      openDialog={show}
      buttonConfirmText="Upload"
      buttonCloseAction={() => {
        setShow(false);
        setSwaggerFile(null);
      }}
      buttonConfirmAction={() => {
        onConfirmAction();
      }}
      buttonConfirmDisabled={!file?.length && !validUrl}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={swaggerType}
            onChange={e => {
              setSwaggerType(e.target.value);
            }}
          >
            <FormControlLabel
              value="File"
              control={<Radio color="primary" inputProps={{ "data-testid": "file_btn" }}/>}
              label="File"
              
            />
            <FormControlLabel
              value="URL"
              control={<Radio color="primary"  inputProps={{ "data-testid": "url_btn" }}/>}
              label="URL"
              />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          {swaggerType === "File" ? (
            <>
              <Card
                onMouseEnter={() => {
                  if (file?.length) {
                    setShowDelete(true);
                  }
                }}
                onMouseLeave={() => {
                  if (file?.length) {
                    setShowDelete(false);
                  }
                }}
                elevation={0}
                className={classNames(classes.card, {
                  "MuiCard-dropzone": true,
                  "MuiCard-white": true,
                  "MuiCard-outlined": true,
                  "MuiCard-rounded": true,
                })}
              >
                <CardActions
                  className={classNames({
                    [classes.cardActions]: true,
                    [classes.cardActionsShow]: showDelete,
                  })}
                >
                  <IconButton
                    onClick={() => {
                      setShowDelete(false);
                      setSwaggerFile(null);
                      setFile(null);
                    }}
                  >
                    <VfSvgIcon icon="delete" viewBox={24} fontSize="small" />
                  </IconButton>
                </CardActions>
                <Dropzone
                  multiple={false}
                  onDropAccepted={acceptedFiles => {
                    setFile(acceptedFiles);
                    setDragReject(false);
                  }}
                  onDropRejected={rejectedFiles => {
                    if (rejectedFiles.length) {
                      setDragReject(true);
                    }
                  }}
                  disabled={!!file?.length}
                  accept={[".json", ".yaml", ".yml"]}
                >
                  {({ getRootProps, getInputProps }) => (
                    <CardActionArea {...getRootProps()}>
                      <CardContent>
                        <input {...getInputProps()} data-testid="upload_input"/>
                        <VfSvgIcon
                          className="MuiSvgIcon-fontSizeXLarge"
                          icon={file?.length ? "document" : "upload"}
                          viewBox={file?.length ? 24 : 56}
                          fontSize="large"
                        />
                        <Typography variant="body2">
                          {file?.length
                            ? file[0]?.name
                            : "Click or drop file here"}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  )}
                </Dropzone>
              </Card>
              {dragReject && (
                <Typography variant="body2" color="secondary">
                  *The appropriate files are json, yaml & yml
                </Typography>
              )}
            </>
          ) : (
            <FormControl fullWidth>
              <TextField
                name="name"
                variant="outlined"
                label="Enter URL"
                type="text"
                value={url}
                onChange={e => {
                  setUrl(e.target.value);
                  setValidUrl(validateUrl(e.target.value));
                }}
                {...(!validUrl && url
                  ? {
                      error: true,
                      helperText: "Please provide a valid url.",
                    }
                  : {})}
              />
            </FormControl>
          )}
        </Grid>
      </Grid>
    </VfDialog>
  );
}
