import React from "react";
import { useParams } from "react-router-dom";

import Breadcrumb from "components/breadcrumb/Breadcrumb";
import ToolbarBottom from "components/toolbarbottom/ToolbarBottom";
import kiosk from "assets/images/kiosk.svg";
import WidgetsDetails from "./WidgetsDetails";
import WidgetsItems from "./WidgetsItems";
import "./Widgets.scss";

const Widgets = ({
  resetChanges,
  deployChanges,
  changeMode,
  applicationList,
  widgetList,
  translation,
}) => {
  const { app, env } = useParams();
  const currentApp = applicationList
    ? applicationList.find(a => a.application === app)
    : null;
  return (
    <main className="has-toolbar">
      <Breadcrumb applications={applicationList} widgets={widgetList} />
      <WidgetsDetails
        translation={translation}
        title={translation ? env : currentApp.data.app}
        image={kiosk}
      />
      <WidgetsItems translation={translation} deployChanges={deployChanges} />
      {!translation ? (
        <ToolbarBottom
          resetChanges={resetChanges}
          deployChanges={deployChanges}
          changeMode={changeMode}
          widgets
        />
      ) : null}
    </main>
  );
};

export default Widgets;
