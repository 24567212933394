import React from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { setTouchpoint } from "actions/touchpointsActions";

const TouchpointsSelection = ({
  image,
  title,
  selected,
  type,
  setTchpoint,
}) => {
  const { locale, env } = useParams();

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-touchpoint": true,
        "MuiCard-selected": selected,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-rounded": true,
        animate: true,
      })}
      onClick={() => {
        setTchpoint(type);
      }}
    >
      <CardActionArea component={Link} to={`/${locale}/env/${env}/${type}`}>
        <CardMedia title={title}>{image}</CardMedia>
        <CardContent>
          <Typography variant="h5" component="h5">
            {title}
          </Typography>
        </CardContent>
        <div className="MuiCardSelection-root">
          <span className="icon icon-check" />
        </div>
      </CardActionArea>
    </Card>
  );
};

const mapDispatchToProps = dispatch => ({
  setTchpoint: touchpoint => dispatch(setTouchpoint(touchpoint)),
});

export default connect(null, mapDispatchToProps)(TouchpointsSelection);
