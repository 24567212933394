/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { cloneDeep } from "lodash";
import jp from "jsonpath";
import uuidv4 from "uuid/v4";
import { atomicsValidationRegistry } from "system-widgets";
import { djrToLayout } from "./djr-to-layout";

const djslAddComponent = (payload, djr, __pageId) => {
  const newDjr = cloneDeep(djr);
  const { name, parent, instanceId, restOfProps } = payload;
  const propsValidationFunc = atomicsValidationRegistry[name];
  const props = { ...restOfProps };
  const hasValidProps = propsValidationFunc && propsValidationFunc(props);
  if (parent === "root") {
    return {
      ...newDjr,
      __props: {
        ...newDjr.__props,
        children: [
          ...newDjr.__props.children,
          {
            __component: name,
            __props: {
              name,
              id: uuidv4().replaceAll(`-`, `_`),
              __instanceId: instanceId,
              __pageId,
              ...props,
            },
          },
        ],
      },
    };
  }
  const idPath = `$..children[?(@.__props.id == "${parent}")]`;
  jp.apply(newDjr, idPath, comp => {
    return {
      ...comp,
      __props: {
        ...comp.__props,
        children: [
          ...(comp.__props.children ?? []),
          {
            __component: name,
            __props: {
              name,
              id: uuidv4().replaceAll(`-`, `_`),
              __instanceId: instanceId,
              __pageId,
              ...props,
            },
          },
        ],
      },
    };
  });
  return newDjr;
};

const deleteComponentById = (json, id) => {
  if (typeof json !== "object") return json;
  if (typeof json === "object" && !json?.__component) return json;
  return {
    ...json,
    __props: {
      ...json.__props,
      children: json?.__props?.children
        ?.filter(child => child?.__props?.id !== id)
        ?.map(child => deleteComponentById(child, id)),
    },
  };
};

const djslDeleteComponent = (id, page) => {
  const currentPage = cloneDeep(page);

  const { djr: currDjr } = currentPage;
  const djr = deleteComponentById(currDjr, id);

  return { djr };
};

const setDjslProps = (djr, newProps, parent) => {
  const newDJR = cloneDeep(djr);
  const propPath = `$..children[?(@.__props.id == '${parent}')]`;
  jp.apply(newDJR, propPath, comp => ({
    ...comp,
    __props: {
      ...comp.__props,
      ...newProps,
    },
  }));

  return newDJR;
};

const getDjslProps = (djr, parent) => {
  const propPath = `$..children[?(@.__props.id == "${parent}")]`;
  return jp.query(djr, propPath)[0].__props;
};

const runLiveEditorDJSLValidators = validators => {
  const response = { isValid: true, messages: [] };

  validators.forEach(validator => {
    const validated = validator();
    if (!validated.isValid) {
      response.isValid = false;
      response.messages = [...response.messages, ...validated.messages];
    }
  });

  return response;
};

const pasteWidget = (parent, page, instanceId, copiedDjr) => {
  const currentPage = cloneDeep(page);
  const clonedDjr = cloneDeep(copiedDjr);
  clonedDjr.__props.__pageId = currentPage.pageInfo._id;
  clonedDjr.__props.__instanceId = instanceId;
  clonedDjr.__props.id = uuidv4().replaceAll(`-`, `_`);

  const { djr } = currentPage;
  const gridPath = `$..children[?(@.__props.id == "${parent}")]`;
  jp.apply(djr, gridPath, child => {
    return {
      ...child,
      ...clonedDjr,
    };
  });

  return { djr };
};

export {
  djslAddComponent,
  djslDeleteComponent,
  getDjslProps,
  setDjslProps,
  runLiveEditorDJSLValidators,
  pasteWidget,
};
