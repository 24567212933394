/* eslint-disable no-underscore-dangle */
import React, { useMemo } from "react";
import { Button, Grid, Typography } from "@material-ui/core";

import VfSvgIcon from "../icons/VfSvgIcon";
import CardAppList from "../apps/CardAppList";
import CardAppsGrid from "../apps/CardAppsGrid";
import { groupBy } from "lodash";

const PaperConfiguratorPerEnv = ({
  env,
  envId,
  envType,
  isGlobal,
  auth,
  grid,
  appsList,
  onShowMore,
  thresholdPerEnv,
  threshold,
  handleClickApp,
  testid
}) => {


  const groupedApps = useMemo(() => groupBy(appsList, "title"), [appsList, envType, thresholdPerEnv]);


  return (
    <Grid container spacing={9}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center" data-testid={testid}>
              <Grid item xs="auto">
                <VfSvgIcon icon={env.toLowerCase()} viewBox={56} />
              </Grid>
              <Grid item xs="auto">
                <Typography component="h5" variant="body1">
                  {env}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {appsList.length ? (
              <Grid container spacing={2}>
                {grid === "list" ? Object.keys(groupedApps).filter((x, index) => index < thresholdPerEnv[envType]).map(key => <Grid item xs={12}>
                  <CardAppList
                    app={groupedApps?.[key][0]}
                    apps={groupedApps[key]}
                    auth={auth}
                    env={env}
                    clickAction={handleClickApp}
                    isConfiguratorRoute={true}
                    envId={envId}
                    envType={envType}
                    isGlobal={isGlobal}
                  />
                </Grid>) : appsList
                  .filter((x, index) => {
                    return index < thresholdPerEnv[envType];
                  })
                  .map(app => <Grid key={app.title + app.version} item xs={12} sm={6} md={4}>
                    <CardAppsGrid
                      app={app}
                      auth={auth}
                      env={env}
                      clickAction={() => handleClickApp(app, envId, env, envType, isGlobal)}
                      isConfiguratorRoute={true}
                    />
                  </Grid>)}
                <Grid item xs={12}>
                  {Object.keys(groupedApps).length - threshold > 0 &&
                    thresholdPerEnv[envType] <= threshold && (
                      <Button onClick={() => onShowMore(envType)} data-testid={`fromCofigurationPage_${env}_viewMore_btn`}>
                        <Typography
                          style={{ textDecoration: "underline" }}
                          component="span"
                          variant="body2"
                        >
                          {`View${Object.keys(groupedApps).length - threshold} more`}
                        </Typography>
                      </Button>
                    )}
                </Grid>
              </Grid>
            ) : (
              <Typography variant="body1" component="h1">
                No Applications
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaperConfiguratorPerEnv;
