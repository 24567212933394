/* eslint-disable no-underscore-dangle */
import {
  APIS_REQUEST,
  APIS_REQUEST_SUCCESS,
  APIS_REQUEST_FAIL,
  SHOW_CREATE_API_DIALOG,
  SAVE_API_REQUEST,
  SAVE_API_REQUEST_SUCCESS,
  SAVE_API_REQUEST_FAIL,
  API_DETAILS_REQUEST,
  API_DETAILS_REQUEST_SUCCESS,
  API_DETAILS_REQUEST_FAIL,
  DELETE_API_REQUEST,
  DELETE_API_REQUEST_SUCCESS,
  DELETE_API_REQUEST_FAIL,
  CLEAR_API_DETAILS,
  DELETE_API_IS_USED_POP_UP_TOGGLE,
  DELETE_VERSION_API_REQUEST,
  DELETE_VERSION_API_REQUEST_SUCCESS,
  DELETE_VERSION_API_REQUEST_FAIL,
} from "actions/apisActions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  show: false,
  currentApi: null,
  deleteApiFailData: {
    popUpBool: false,
    usedBy: "",
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case APIS_REQUEST: {
      return { ...state, loading: true };
    }
    case APIS_REQUEST_SUCCESS: {
      return { ...state, loading: false, error: null, list: payload };
    }
    case APIS_REQUEST_FAIL: {
      return { ...state, loading: false, error };
    }
    case SHOW_CREATE_API_DIALOG: {
      return { ...state, show: payload };
    }
    case SAVE_API_REQUEST: {
      return { ...state, loading: true };
    }
    case SAVE_API_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case SAVE_API_REQUEST_FAIL: {
      return { ...state, loading: false, error };
    }
    case API_DETAILS_REQUEST: {
      return { ...state, loading: true };
    }
    case API_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        currentApi: payload,
      };
    }
    case API_DETAILS_REQUEST_FAIL: {
      return { ...state, loading: false, error };
    }
    case CLEAR_API_DETAILS: {
      return { ...state, currentApi: null };
    }
    case DELETE_API_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_API_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        list: state.list.filter(api => api._id !== payload),
      };
    }
    case DELETE_API_REQUEST_FAIL: {
      return { ...state, loading: false, error };
    }
    case DELETE_API_IS_USED_POP_UP_TOGGLE: {
      return {
        ...state,
        deleteApiFailData: {
          popUpBool: !state.deleteApiFailData.popUpBool,
          usedBy: payload?.usedBy,
          deleteVersion: payload?.deleteVersion,
        },
      };
    }
    case DELETE_VERSION_API_REQUEST: {
      return { ...state, loading: true };
    }
    case DELETE_VERSION_API_REQUEST_SUCCESS: {
      const newVersions = state.currentApi?.versions
        ?.map(version => {
          if (version.name !== payload.versionName) return version;
          const newVersion = version;
          newVersion.envs = version.envs.filter(env => env !== payload.envId);
          return newVersion;
        })
        .filter(version => version.envs.length);
      const newFiles = state.currentApi?.files?.filter(
        file =>
          !(file.env === payload.envId && file.version === payload.versionName)
      );
      return {
        ...state,
        loading: false,
        error: null,
        currentApi: {
          ...state.currentApi,
          versions: newVersions,
          files: newFiles,
        },
      };
    }
    case DELETE_VERSION_API_REQUEST_FAIL: {
      return { ...state, loading: false, error };
    }
    default:
      return state;
  }
};

export default reducer;
