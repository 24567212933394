/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-fragments */
/* eslint-disable camelcase */
import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { fetchApps, showTenantSelectionDialog } from "actions/applicationsActions";
import { updateTenantToken } from "actions/tenantActions";
import { clearUserPermissionsMsg } from "actions/permissionsActions";
import { setSelectedMarket } from "actions/authenticationActions";
import theme from "AppTheme";
import Loader from "components/loader/Loader";
import { IconButton, Typography, FormControl, MenuItem, Select, InputAdornment } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import { useHistory } from "react-router-dom";

import CardLanding from "./CardLanding";
import VfDialog from "../vf/VfDialog";
import VfSvgIcon from "../icons/VfSvgIcon";

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "100vh",
    paddingTop: theme.spacing(6),
  },
  grid: {
    height: "100%",
  },
  divider: {
    background: "linear-gradient(to bottom, rgba(112,112,112,0) 0%,rgba(83,83,83,1) 50%,rgba(56,56,56,0) 100%)",
  },
  modalTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  modalContent: {
    textAlign: "center",
    "& p": {
      marginBottom: 0,
    },
  },
  modalDivider: {
    marginTop: -theme.spacing(2),
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    marginBottom: -theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  modalActions: {
    justifyContent: "center",
  },
  tenantSelector: {
    "& .MuiSelect-select:focus": { backgroundColor: "#fff" },
  },
});

const Landing = ({
  envs,
  auth,
  getApplications,
  userPermissions,
  dispatchClearUserPermissionsMsg,
  tenantSelectionDialog,
  dispatchShowTenantSelectionDialog,
  dispatchSetSelectedMarket,
  dispatchUpdateTenantToken,
}) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const { ctry, access_token, id_token, allowedMarkets } = auth;
  const { accessDeniedMsg } = userPermissions;

  const [openDialog, setOpenDialog] = useState(!!accessDeniedMsg);

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const [selectedTenant, setSelectedTenant] = useState(ctry);

  const tenantSelectionHandler = () => {
    dispatchShowTenantSelectionDialog(false);
    dispatchSetSelectedMarket(allowedMarkets.find(m => m.code.toLowerCase() === selectedTenant.toLowerCase()));
    dispatchUpdateTenantToken({ access_token, id_token, selectedTenant });
    history.push(`/${selectedTenant}/landing`);
  };

  const mappedMarketOptions = allowedMarkets?.map(market => (
    <MenuItem value={market.code} key={market.code}>
      <Typography component="h1" variant="h5">
        {market.title}
      </Typography>
    </MenuItem>
  ));

  return (
    <main className={classes.root}>
      {envs.length ? (
        <Grid container className={classes.grid} wrap="nowrap">
          <Grid item xs={4} data-testid="applications-column">
            <CardLanding
              icon="apps"
              title="App builder"
              historyUrl={`/${ctry}/apps`}
              permissionKey="view_apps"
              getApplications={() => getApplications({ access_token, id_token })}
            />
          </Grid>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Grid item xs={4} data-testid="configurations-column">
            <CardLanding
              icon="config"
              title="Configurator"
              historyUrl={`/${ctry}/configurator`}
              permissionKey="view_configurations"
              getApplications={() => null}
            />
          </Grid>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Grid item xs={4} data-testid="translations-column">
            <CardLanding
              icon="translate"
              title="Translator"
              historyUrl={`/${ctry}/translations`}
              permissionKey="view_translations"
              getApplications={() => null}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Loader height="100vh" size={theme.spacing(12)} />
        </Grid>
      )}
      {accessDeniedMsg && (
        <Dialog disableBackdropClick fullScreen={fullScreen} open={openDialog} onClose={handleOpenDialog} maxWidth="xs">
          <DialogContent className={classes.modalContent}>
            <DialogContentText gutterBottom>{accessDeniedMsg}</DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                dispatchClearUserPermissionsMsg();
                handleOpenDialog();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <VfDialog
        openDialog={tenantSelectionDialog}
        buttonConfirmText="Select"
        buttonConfirmAction={tenantSelectionHandler}
        key="tenant-selection-dialog"
        maxWidth="xs"
        buttonConfirmColor="primary"
        maxHeight="none"
        testId="tenant-select-btn"
      >
        <Grid container spacing={4} direction="column">
          <Grid item container xs={12} justify="flex-end">
            <Grid>
              <IconButton aria-label="close" onClick={() => dispatchShowTenantSelectionDialog(false)}>
                <VfSvgIcon icon="close" viewBox={24} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container justify="center" spacing={2} direction="column">
            <Grid item xs align="center" style={{ fontSize: "4em" }}>
              <LanguageIcon fontSize="inherit" />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="p" align="center">
                You are eligible to access the
                <br />
                below tenants, which one you want to select:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth className={classes.tenantSelector} data-testid="tenant-drop-down">
                <Select
                  value={selectedTenant}
                  onChange={e => setSelectedTenant(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <LanguageIcon color="secondary" />
                    </InputAdornment>
                  }
                >
                  {mappedMarketOptions}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </VfDialog>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.authentication,
    envs: state.envs.list,
    userPermissions: state.userPermissions,
    tenantSelectionDialog: state.applications.showTenantSelectionDialog,
  };
};

const mapDispatchToProps = dispatch => ({
  getApplications: params => dispatch(fetchApps(params)),
  dispatchClearUserPermissionsMsg: () => dispatch(clearUserPermissionsMsg()),
  dispatchShowTenantSelectionDialog: data => dispatch(showTenantSelectionDialog(data)),
  dispatchSetSelectedMarket: data => dispatch(setSelectedMarket(data)),
  dispatchUpdateTenantToken: data => dispatch(updateTenantToken(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
