import {
  SETTINGS_REQUEST_SUCCESS,
  SETTINGS_REQUEST_FAIL,
} from "actions/settingsActions";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case SETTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case SETTINGS_REQUEST_FAIL:
      return {
        ...state,
        ...error,
      };
    default:
      return state;
  }
};
