/* eslint-disable camelcase */
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import classNames from "classnames";
import { setApp } from "../../actions/appsActions";
import { fetchConfigListWidget } from "../../actions/widgetsActions";
import VfSvgIcon from "../icons/VfSvgIcon";

const ApplicationsItem = ({
  application,
  data,
  setApplication,
  getConfigListWidget,
  isSaved,
  auth,
  envID,
}) => {
  const { locale, env, touchpoint } = useParams();
  const { app, tags, version } = data;
  const [anchorEl, setAnchorEl] = useState(null);
  const { access_token, id_token } = auth;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-application": true,
        "MuiCard-white": true,
        "MuiCard-outlined": true,
        "MuiCard-outlined-success": !!isSaved,
        "MuiCard-rounded": true,
        animate: true,
      })}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <CardActionArea
            disableRipple
            disableTouchRipple
            component={Link}
            to={`/${locale}/env/${env}/${touchpoint}/${application}`}
            onClick={() => {
              setApplication(application);
              getConfigListWidget({
                envID,
                access_token,
                touchpoint,
                application,
                id_token,
              });
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="h5">
                <Typography component="span">{app}</Typography>
                <Typography component="span" color="secondary">
                  <Box component="span" ml={2}>
                    {version}
                  </Box>
                </Typography>
              </Typography>
              <Typography variant="body2" component="p" color="textSecondary">
                {tags}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Typography
            className="label"
            variant="body2"
            style={{ color: "#009900" }}
          >
            {isSaved ? "Ready for Deployment" : false}
          </Typography>
        </Grid>
        <Grid item>
          <CardActions disableSpacing>
            <IconButton
              aria-label="add to favorites"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <VfSvgIcon icon="more" viewBox={24} />
            </IconButton>
            <Menu
              id="simple-menu"
              elevation={0}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>VIEW INFO</MenuItem>
              {!isSaved && (
                <MenuItem onClick={handleClose}>PROMOTE TO TESTING</MenuItem>
              )}
              {!isSaved && (
                <MenuItem onClick={handleClose}>PROMOTE TO PRODUCTION</MenuItem>
              )}
            </Menu>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};

const mapStateToProps = state => ({
  auth: state.authentication,
  envID: state.envs.id,
});

const mapDispatchToProps = dispatch => ({
  setApplication: app => dispatch(setApp(app)),
  getConfigListWidget: payload => dispatch(fetchConfigListWidget(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsItem);
