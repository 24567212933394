import { call, put, takeLatest } from "redux-saga/effects";

import {
  CONFIG_LIST_APP_REQUEST,
  CONFIG_LIST_APP_REQUEST_SUCCESS,
  CONFIG_LIST_APP_REQUEST_FAIL,
  CONFIG_LIST_WIDGET_REQUEST,
  CONFIG_LIST_WIDGET_REQUEST_SUCCESS,
  CONFIG_LIST_WIDGET_REQUEST_FAIL,
  CONFIG_RESET_WIDGET_REQUEST,
  CONFIG_RESET_WIDGET_REQUEST_SUCCESS,
  CONFIG_RESET_WIDGET_REQUEST_FAIL,
  CONFIG_DEPLOY_WIDGET_REQUEST,
  CONFIG_DEPLOY_WIDGET_REQUEST_SUCCESS,
  CONFIG_DEPLOY_WIDGET_REQUEST_FAIL,
  CONFIG_DEPLOY_AND_FETCH_WIDGET,
} from "actions/configurationActions";
import configService from "services/ConfigurationService";
import { SHOW_SNACKBAR } from "../actions/applicationsActions";

function* configListAppWorker({ payload }) {
  try {
    const { data ,message} = yield call(configService.fetchConfigListApp, payload);
    if(data) {
      yield put({
        type: CONFIG_LIST_APP_REQUEST_SUCCESS,
        payload: data,
      });
    }else{
      yield put({
        type: SHOW_SNACKBAR,
        payload: {
          show: true,
          message: message,
          severity: 0,
        },
      });
    yield put({ type: CONFIG_LIST_APP_REQUEST_FAIL, error });

    }

  } catch (error) {
    yield put({ type: CONFIG_LIST_APP_REQUEST_FAIL, error });
  }
}

function* configListAppWatcher() {
  yield takeLatest(CONFIG_LIST_APP_REQUEST, configListAppWorker);
}

function* configListWidgetWorker({ payload }) {
  try {
    const { data } = yield call(configService.fetchConfigListWidget, payload);
    yield put({
      type: CONFIG_LIST_WIDGET_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: CONFIG_LIST_WIDGET_REQUEST_FAIL, error });
  }
}

function* configListWidgetWatcher() {
  yield takeLatest(CONFIG_LIST_WIDGET_REQUEST, configListWidgetWorker);
}

function* configResetWidgetWorker({ payload }) {
  try {
    const { data } = yield call(configService.resetConfigApp, payload);
    yield put({
      type: CONFIG_RESET_WIDGET_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: CONFIG_RESET_WIDGET_REQUEST_FAIL, error });
  }
}

function* configResetWidgetWatcher() {
  yield takeLatest(CONFIG_RESET_WIDGET_REQUEST, configResetWidgetWorker);
}

function* configDeployWidgetWorker({ payload }) {
  try {
    const { data ,status,message } = yield call(configService.deployConfigApp, payload);
    yield put({
      type: CONFIG_DEPLOY_WIDGET_REQUEST_SUCCESS,
      payload: data,
    });
    if(data && status === 200){
      yield put({
        type: SHOW_SNACKBAR,
        payload: {
          show: true,
          message: "Configuration Deployed Successfully",
          severity: 3,
        },
      });
    }else{
      yield put({
        type: SHOW_SNACKBAR,
        payload: {
          show: true,
          message: message || "Error Occurred while Deploying",
          severity: 0,
        },
      });
    }
  } catch (error) {
    yield put({ type: CONFIG_DEPLOY_WIDGET_REQUEST_FAIL, error });
  }
}

function* configDeployWidgetWatcher() {
  yield takeLatest(CONFIG_DEPLOY_WIDGET_REQUEST, configDeployWidgetWorker);
}

function* configDeployAndFetchWidgetListWorker({ payload }) {
  yield* configDeployWidgetWorker({ payload });
  yield* configListWidgetWorker({ payload });
}

function* configDeployAndFetchWidgetListWatcher() {
  yield takeLatest(
    CONFIG_DEPLOY_AND_FETCH_WIDGET,
    configDeployAndFetchWidgetListWorker
  );
}

const configSaga = [
  configListAppWatcher(),
  configListWidgetWatcher(),
  configResetWidgetWatcher(),
  configDeployWidgetWatcher(),
  configDeployAndFetchWidgetListWatcher(),
];

export default configSaga;
