import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import jwt from "jsonwebtoken";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Container from "pages/container/Container";
import store from "store/configureStore";
import Liveness from "services/Liveness";
import NetworkService from "services/api";
import { fetchSettings } from "actions/settingsActions";
import theme from "./AppTheme";
import { register } from "./system-widgets";
import { fetchEnvsByLocale } from "actions/envsActions";
import { fetchS3EnvsByLocale } from "actions/s3EnvsActions";
import { fetchTenantBasicInfo } from "actions/tenantActions";
import { userPermissions } from "actions/permissionsActions";
// import { useSmapi } from "hooks/useSmapi";

Liveness.setStore(store);
NetworkService.setupInterceptors(store);
register(store);

const App = () => {
  // Catch page refresh & redirect to homepage
  if (window.performance) {
    if (performance.navigation.type === 1) {
      console.log("This page is reloaded");
      document.location.href = "/";
    }
  }
  // useSmapi(true);//Integrate with SMAPI API for app-builder analytics.
  store.dispatch(fetchSettings());

  if (Object.keys(store.getState().authentication).length) {
    const { ctry, enablePermissions, permissions } = store.getState().authentication;
    const permissionsPayload = { enablePermissions: enablePermissions, permissions: permissions}
    store.dispatch(fetchEnvsByLocale({ locale: ctry?.toLowerCase()}))
    store.dispatch(fetchS3EnvsByLocale({ locale: ctry?.toLowerCase()}))
    store.dispatch(fetchTenantBasicInfo({ locale: ctry?.toLowerCase()}))
    store.dispatch(userPermissions(permissionsPayload))
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Router>
            <Container />
          </Router>
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
