import { combineReducers } from "redux";
import authentication from "./authenticationReducer";
import settings from "./settingsReducer";
import configForm from "./configFormReducer";
import configuration from "./configurationReducer";
import envs from "./envsReducer";
import s3Envs from "./s3EnvsReducer";
import touchpoints from "./touchpointsReducer";
import listGridView from "./listGridViewReducer";
import configHistory from "./historyReducer";
import translations from "./translationsReducer";
import nexusWidgets from "./nexusWidgetsReducer";
import applications from "./applicationsReducer";
import roles from "./rolesReducer";
import userPermissions from "./permissionsReducer";
import media from "./mediaReducer";
import props from "./propReducer";
import tenants from "./tenantsReducer";
import apis from "./apisReducer";
import packages from "./nexusPackagesReducer";
import compareAppVersions from "./compareAppVersionsReducer";

export default combineReducers({
  authentication,
  settings,
  envs,
  s3Envs,
  configuration,
  configForm,
  translations,
  touchpoints,
  listGridView,
  configHistory,
  nexusWidgets,
  applications,
  roles,
  userPermissions,
  media,
  props,
  tenants,
  apis,
  packages,
  compareAppVersions,
});
