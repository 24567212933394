/* eslint-disable no-useless-computed-key */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Container, Paper, Grid as MaterialGrid } from "@material-ui/core";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import createDynamicJourneyRenderer from "@vf-djr/renderer";
import componentRenderingPlugin from "@vf-djr-plugins/component-rendering";
import mapStateToPropsPlugin from "@vf-djr-plugins/map-state-to-props/lib";
import CardHeader from "@material-ui/core/CardHeader";
import * as DCL from "@vf-dcl/dcl-components";
import atomics from "vf-comps/build/components";
import { useDrop } from "react-dnd";
import CardGridCreator from "./CardGridCreator";
import componentWrapper from "./EditorRenderer";
import { MVA10 } from "../../utils/constants";

const theme = createMuiTheme();

const useStyles = makeStyles({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    minHeight: `calc(100vh - ${theme.spacing(9)}px)`,
  },
});

const Grid =
  theme === MVA10 ? props => <MaterialGrid container {...props} /> : DCL.Grid;

const GridItem =
  theme === MVA10 ? props => <MaterialGrid item {...props} /> : DCL.Grid.Item;

const atomicsRegistry = Object.fromEntries(
  atomics.flatMap(c => c.widgets).map(w => [w.name, w.component])
);

const AppsBuilder = ({ djr, spacing, isDeployed }) => {
  const classes = useStyles();

  const registry = {
    ...atomicsRegistry,
    Grid,
    GridItem,
    Fragment,
    CardHeader,
  };

  const render = createDynamicJourneyRenderer({
    registry,
    plugins: {
      initialSetup: {
        connect,
      },
      preRender: {
        __component: componentWrapper,
        __mapStateToProps: mapStateToPropsPlugin,
      },
      propsPlugin: componentRenderingPlugin,
    },
  });

  const [, drop] = useDrop({ accept: "row" });
  return (
    <Paper square elevation={0} className={classNames(classes.root)}>
      <Container maxWidth={false}>
        <MaterialGrid container spacing={3}>
          <MaterialGrid item xs={12}>
            <MaterialGrid container spacing={spacing} ref={drop}>
              {render(djr)}
            </MaterialGrid>
          </MaterialGrid>
          {!isDeployed && (
            <MaterialGrid item xs={12}>
              <CardGridCreator />
            </MaterialGrid>
          )}
        </MaterialGrid>
      </Container>
    </Paper>
  );
};

export default AppsBuilder;
